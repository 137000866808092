<div class="col-12 md:col-12 lg:col-12 col-nopad bredcurm">
    <div class="col-12 md:col-6 lg:col-8 bread-crumb col-nopad">
      <div *ngIf = "breadCrumb">
        <span *ngFor="let eachRow of breadCrumb , let i = index;let last = last;">
          <a [title]="eachRow?.label" (click) = "onClick(eachRow)" translate *ngIf="!last"> {{eachRow?.label}} </a>
          <span *ngIf = "(i < (breadCrumb.length-1))">/</span>
        <span *ngIf="last" class="islast"> {{eachRow?.label}}</span>
        </span>
      </div>
      <!-- <a href="#" translate>label_booking_details</a> -->
    </div>
  </div>