<div class="layout-dashboard page-layout">
  <div class="grid padding-1per">
    <div class="col-12 lg:col-6 md:col-6">
      <h1 class="header" translate>label_email_templates</h1>
    </div>
  </div>


  <p-tabView *ngIf="templates">
    <p-tabPanel [header]='getHeaderName(temp.type)' *ngFor="let temp of templates.slice().reverse()">
      <app-template [template] = "temp" (updateTemplate)="updateTemplate()"></app-template>
    </p-tabPanel>
  </p-tabView>

</div>
