import { Component, OnInit } from '@angular/core';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from '../../app.settings';
import { UiService } from '../../services/ui.service';
import { UserCommonService } from '../../services/user-common.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-role-access',
  templateUrl: './role-access.component.html',
  styleUrls: ['./role-access.component.scss']
})
export class RoleAccessComponent implements OnInit {
  pageHeading = this.commonService.getLabel('label_assign_permission');
  roleGroupData = [];
  breadCrumbArray = [];
  heading_label = '';
  id;
  accessGroupPermissionsList = [];
  accessGroupData: any;
  loadData: boolean = false;
  expandedRows: {} = {};
  mangerName = '';

  constructor(
    private commonBindingDataService: CommonBindingDataService,
    private route: ActivatedRoute,
    private router: Router,
    private uiService: UiService,
    private userCommonService: UserCommonService,
    private commonService: CommonBindingDataService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.heading_label = this.commonBindingDataService.getLabel('label_assign_permission');
    this.id = this.route.snapshot.queryParams.id;
    this.getAccesspermission();
    this.createBreadCrumb();
  }

  getAccesspermission() {
    this.userCommonService.getStaffAccessPermissions(this.id).subscribe((result) => {
      this.accessGroupData = result.accessGroupPermissionsDtoList;
      this.mangerName = result.firstName + ' ' + result.lastName;
      this.getMenuList();
    });
  }

  getMenuList() {
    // old code
    // this.userCommonService.getAccessPermissions().subscribe((result)=>{
    //   this.roleGroupData = result;
    //   if(this.id){
    //     let accessGroupData = JSON.parse(this.storageService.getItem('accessGroup'));
    //     accessGroupData = accessGroupData.accessGroupPermissionsList;
    //     const updated_access = this.roleGroupData.filter((element)=>{
    //       accessGroupData.forEach((e) => {
    //         if(element.accessGroupsList){
    //           element.accessGroupsList.forEach((ele) => {
    //             if(ele.id === e.accessGroupId){
    //               ele.read = e.read;
    //               ele.readWrite = e.readWrite;
    //             }
    //           })
    //         }else if(element.id === e.accessGroupId){
    //           element.read = e.read;
    //           element.readWrite = e.readWrite;
    //         }
    //       });
    //       return element;
    //     });
    //     this.roleGroupData = updated_access;
    //   }
    // });

    this.userCommonService.getAccessPermissions().subscribe((result) => {
      this.roleGroupData = result;
      // expand all row default
      const thisRef = this;
      this.roleGroupData.forEach(function(row) {
        thisRef.expandedRows[row.name] = true;
      });
      // end expand all row default
      
      if (this.id) {
        // let accessGroupData = JSON.parse(this.storageService.getItem('accessGroup'));
        // accessGroupData = accessGroupData.accessGroupPermissionsList;
        const updated_access = this.roleGroupData.filter((element) => {
          this.accessGroupData.forEach((e) => {
            if (element.accessGroupsList) {
              element.accessGroupsList.forEach((ele) => {
                if (ele.id === e.accessGroupId) {
                  ele.read = e.read;
                  ele.readWrite = e.readWrite;
                }
              })
            } else if (element.id === e.accessGroupId) {
              element.read = e.read;
              element.readWrite = e.readWrite;
            }
          });
          return element;
        });
        this.roleGroupData = updated_access;

      }
    });
    this.loadData = true;
  }

  onCancel() {
    this.router.navigate(['franchise/staff/manage'], { queryParams: { fromCancel: true } })
    // if(this.id) {
    //   this.router.navigate(['franchise/staff/manage/edit-staff'],{ queryParams: { id: this.id, fromCancel: true }})
    // }else {
    //   this.router.navigate(['franchise/staff/manage/add-staff'],{ queryParams: { fromCancel: true }})
    // }
  }

  getAccessGroup(ele) {
    if (ele.read || ele.readWrite) {
      this.accessGroupPermissionsList.push({
        'accessGroupId': ele.id,
        'read': ele.read ? ele.read : false,
        'readWrite': ele.readWrite ? ele.readWrite : false
      })
    }
  }

  onSaveRole() {
    this.accessGroupPermissionsList = [];
    // console.log(this.roleGroupData)
    this.roleGroupData.forEach((element) => {
      if (element.accessGroupsList) {
        element.accessGroupsList.forEach((ele) => {
          // console.log(ele)
          this.getAccessGroup(ele)
        })
      }
      this.getAccessGroup(element);

    });

    // console.log(this.accessGroupPermissionsList)
    // this.storageService.setItem('accessGroup',this.accessGroupPermissionsList);
    const data = {
      'userId': this.id,
      'accessGroupPermissionsList': this.accessGroupPermissionsList
    }

    this.userCommonService.updateAccessPermissions(data, this.id).subscribe((result) => {
      this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
      this.router.navigate(['franchise/staff/manage'], { queryParams: { fromCancel: true } })
    });
  }

  onSelectReadOnly(rowDataAccesses, e) {
    console.log('rowDataAccesses', rowDataAccesses)
    if (e.checked === true) {
      rowDataAccesses.read = true;
    } else {
      rowDataAccesses.read = false;
      rowDataAccesses.readWrite = false;
    }
  }

  onSelectReadWrite(rowDataAccesses, e) {
    if (e.checked === true) {
      rowDataAccesses.read = true;
      rowDataAccesses.readWrite = true;
    } else {
      rowDataAccesses.readWrite = false;
    }

  }

  checkIfVisible(row, flag) {
    // console.log('row',row);
    let showFlag = true;
    const hideArrayReadOnly = ['MERGE_CLIENT'];
    const hideArrayReadOnly1 = ['MANAGE_AVAILABILITY'];
    const hideArrayReadWrite = ['MANAGE_DASHBOARD'];

    if (hideArrayReadOnly.includes(row.accessGroupCode) && flag == 'readOnly') {
      showFlag = false;
    }
    if (hideArrayReadOnly1.includes(row.accessGroupCode) && flag == 'readOnly') {
      showFlag = false;
    }
    if (hideArrayReadWrite.includes(row.accessGroupCode) && flag == 'readWrite') {
      showFlag = false;
    }
    return showFlag;
  }


  createBreadCrumb() {
    this.breadCrumbArray = [
      {
        label: this.commonService.getLabel('label_manage_staff'),
        route: 'franchise/staff/manage',
        queryParams: 'fromCancel'
      },
      {
        label: this.pageHeading,
        route: '',
      }
    ];
  }

}
