import { Injectable, OnDestroy } from '@angular/core';
import { RestApiService } from '../services/rest-api.service';
import { StorageService } from '../services/storage.service';
import { Observable, Subject } from 'rxjs';
import { AppSettings } from '../app.settings';
import { environment } from '../../../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class UserCommonService implements OnDestroy {
  public isMetadataLoaded: boolean = false;
  timeZones;
  changeBrand = new Subject();
  metaApiLoaded = new Subject<void>();
  public weekCalenderData: {
    weekEvents,
    weekViewResources,
    checkedCondition: boolean,
    // selectedEventStatus: string,
    addShiftResource,
    startEndDate:'',
  };

  constructor(private restApiService: RestApiService,
    private storageService: StorageService) {
    // console.log('user service loaded');
    this.setGoogleMapScript();
    this.getAdminMetaData();
  }

  openCloseSidebar() {
    if (screen.width < 740) {
      if (!document.getElementsByTagName('body')[0].classList.contains('closed')) {
        document.getElementsByTagName('body')[0].classList.toggle('closed');
      }
    }
  }

  setGoogleMapScript(callback?) {
    const key = environment.mapKey;
    const googleAPIScript = document.createElement('script');
    googleAPIScript.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=visualization,places&callback=initMap&loading=async`;
    googleAPIScript.async = true;
    // googleAPIScript.onload = this.changeMapScriptLoadFlag;
    document.head.appendChild(googleAPIScript);
    window['initMap'] = () => {
      // console.log('callback loaded');
      this.isMetadataLoaded = true;
      this.metaApiLoaded.next();
    };
  }

  changeMapScriptLoadFlag() {
          this.isMetadataLoaded = true;
  }


  getAdminMetaData() {
    this.getMetaData().subscribe(result => {
      this.timeZones = result.timezoneList;
      // this.storageService.setItem(AppSettings.HEADER_DEFAULT_LANGUAGE, result.tenantConfigList.defaultlanguage);
    });
  }

  getEncryptPass(pass) {
    const encryptedBase64Key = AppSettings.LOGIN_SECRET;
    const parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    let encryptedData = null;
    {
      // Encryption process
      const plaintText = pass;
      // console.log('encryptedBase64Key = ' + encryptedBase64Key, 'parsedBase64Key=', parsedBase64Key);
      // console.log('plaintText = ' + plaintText);

      // this is Base64-encoded encrypted data
      encryptedData = CryptoJS.AES.encrypt(plaintText, parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString();
      // console.log('encryptedData = ' + encryptedData);

      const decryptedData = CryptoJS.AES.decrypt(encryptedData, parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      // console.log('DecryptedData = ' + decryptedData);
      const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
      // console.log('DecryptedData = ' + decryptedText);

    }
    return encryptedData;
  }

  getUpdatedStoredValue(key) {
    const loginData = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA));
    if (key == AppSettings.START_DAY_OF_WEEK_FOR_MONTH) {
      if (loginData) {
        // const data = JSON.parse(loginData);
        if (loginData[AppSettings.START_DAY_OF_WEEK_FOR_MONTH]) {
          return loginData[AppSettings.START_DAY_OF_WEEK_FOR_MONTH];
        } else {
          return 0;
        }
      }
    }
  }


  getMetaData(type?: any): Observable<any> {
    return this.restApiService.get('Get MetaData', `/metadata`, 'page-center');
  }

  signOut(): Observable<any> {
    return this.restApiService.delete('delete', '/secure/sessions', 'page-center');
  }

  signIn(d, data): Observable<any> {
    // return this.restApiService.post(d, '/login', data, 'page-center');
    return this.restApiService.post(d, '/sessions', data, 'page-center');
  }

  sessionRole(data): Observable<any> {
    return this.restApiService.post('password', '/secure/session/role', data, 'page-center');
  }
  checkVersion(): Observable<any> {
    return this.restApiService.get('Check Version', '/version', 'page-center');
  }

  // passwordChange(data): Observable<any> {
  //   return this.restApiService.put('/secure/users/profile/password', data, 'page-center');
  // }

  setPassword(data): Observable<any> {
    return this.restApiService.post('password', '/users/set-password', data, 'page-center');
  }

  getUserProfile(): Observable<any> {
    return this.restApiService.get('profile', '/secure/users/profile', 'page-center');
  }

  forgotPassword(data): Observable<any> {
    return this.restApiService.post('password', '/users/forgot-password', data, 'page-center');
  }

  fetchStripe(code, state): Observable<any> {
    return this.restApiService.get('stripe', `/stripe-connect/authorization-redirect-uri?code=${code}&state=${state}`, 'page-center');
  }

  // marketing start
  activeInactiveSegment(data, segmentId): Observable<any> {
    return this.restApiService.put('activeInactiveSegment', `/secure/segments/status/${segmentId}`, data, 'page-center');
  }

  getUserDefinedRoles(): Observable<any> {
    return this.restApiService.get('getUserDefinedRoles', `/secure/roles-list`, 'page-center');
  }

  getUsers(roleId, activated): Observable<any> {
    return this.restApiService.get('get-users', `/secure//users-list/${roleId}`, 'page-center');
  }

  addSegment(data): Observable<any> {
    return this.restApiService.post('addSegment', '/secure/segments', data, 'page-center');
  }

  getSegmentsList(): Observable<any> {
    return this.restApiService.get('getSegmentsList', `/secure/segments/segments-list`, 'page-center');
  }

  addNotification(data): Observable<any> {
    return this.restApiService.post('addmotification', '/secure/admin-announcement', data, 'page-center');
  }

  resendNotification(id): Observable<any> {
    return this.restApiService.post('resendNotification', `/secure/admin-announcement/${id}/resend`, '', 'page-center');
  }
  // marketing end

  getWaffleMenu(): Observable<any> {
    return this.restApiService.get('getWaffleMenu', `/secure/waffle-menu?applyActivatedStatus=true&activated=true&searchText=&offset=0&limit=999&sortColumn=indexOrder&sortType=asc`, 'page-center');
  }

  getBrandListForFilter(id): Observable<any> {
    return this.restApiService.get('getBrandList', `/secure/franchise-brands/${id}/list?deleted=false`, 'page-center')
  }

  getBrandListForStaff(id): Observable<any> {
    return this.restApiService.get('getBrandList', `/secure/staffs-brand/${id}/list`, 'page-center')
  }

  getNotification(): Observable<any> {
    return this.restApiService.get('getNotification', `/secure/notification-request/grid/data?offset=0&limit=999`, 'page-center');
  }

  getNotificationCount(): Observable<any> {
    return this.restApiService.get('getNotification', `/secure/notification-request-count`, 'page-center');
  }

  updateNotification(notificationRequestId): Observable<any> {
    return this.restApiService.put('updateNotification', `/secure/notification-request-viewed/${notificationRequestId}`, 'page-center');
  }

  getLoginAs(d, data): Observable<any> {
    return this.restApiService.post(d, '/sessions', data, 'page-center');
  }

  getAccessPermissions(): Observable<any> {
    return this.restApiService.get('Access Permissions', '/secure/accessgroup', 'page-center');
  }

  getStaffAccessPermissions(userId): Observable<any> {
    return this.restApiService.get('Access Permissions', `/secure/access-permissions/${userId}`, 'page-center');
  }

  updateAccessPermissions(data, userId): Observable<any> {
    return this.restApiService.put('activeInactiveSegment', `/secure/access-permissions/${userId}`, data, 'page-center');
  }

  uploadCroppedImage(url, data): Observable<any> {
    return this.restApiService.post('uploadCroppedImage', `${url}`, data, 'page-center');
  }

  deleteImage(clientId, fileId): Observable<any> {
    return this.restApiService.delete('deleteImage',`/secure/${clientId}/client-banner-files/${fileId}`,
    'page-center');
  }

  ngOnDestroy() {

  }

  removeSpaceBetweenNumber(number) {
    return number.replace(/\D/g, '');
  }

}







