<div class="staff-popup">
  <p-dialog [header]="header" [(visible)]="showAssignPopup"
    styleClass="add-event-dialog filter-container ui-dialog-content assign-dialog" dismissableMask="true"
    [closable]="true" [draggable]="false" blockScroll="true" [resizable]="true" [focusOnShow]="false" position="center"
    autoResize="true" (onHide)="hidePopup()" [dismissableMask]="false" [modal]="true">

    <div class="form-container form-container-column col-12 lg:col-12 pad-t-b-0" id="event">
      <div class="form-item col-12 lg:col-12 md:col-5 pad-zero">
        <div class="form-field">
          <div class="filter-item col-6 lg:col-3 md:col-3 inputSwitch mob-ptb-0 pad-zero">
            <p class="label p-mr-2">Staff</p>
            <p-inputSwitch (onChange)="clickChecked($event,'html')" onLabel="Assets" offLabel="Staff"
              [(ngModel)]="checkedCondition"></p-inputSwitch>
            <p class="label p-ml-2" translate="">grid_asset</p>
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="addEventForm">
      <div class="form-container form-container-column col-12 lg:col-12" id="event">
        <div class="two-items-div">
          <div class="form-item col-12 lg:col-5 md:col-5 short-w">
            <!-- *ngIf="!selectedSchedule && !defaultBrand" -->
            <div class="form-field">
              <div class="form-label">
                <label for="clientId" translate>label_brandName<span class="required-star">*</span></label>
              </div>
              <div class="form-control">
                <p-dropdown formControlName="brandId" [options]="brandNameOptions" (onChange)="onChangeBrand('html','clickOnBrand')"
                  optionDisabled="inactive"></p-dropdown>
              </div>
              <div class="form-errors"
                *ngIf="addEventForm.controls.brandId.errors  && (addEventForm.controls.brandId.dirty || addEventForm.controls.brandId.touched || submitted)">
                <div [hidden]="!addEventForm.controls.brandId.errors.required" translate>
                  field_is_required
                </div>
              </div>
            </div>
          </div>

          <div class="form-item col-12 lg:col-5 md:col-12 short-w">
            <div class="form-field">
              <div class="form-label">
                <label for="staff" translate>label_date <span class="required-star">*</span></label>
              </div>
              <div class="form-control">
                <p-calendar appendTo="body" hourFormat="12" formControlName="startDate" id="startDate"
                  (onClose)="onSelectDates('startDate')" [firstDayOfWeek] = "startDayOfWeek" [showIcon]="true" dateFormat="M dd yy">
                </p-calendar>
              </div>
              <div class="form-errors" *ngIf="
                  addEventForm.controls.startDate.errors &&
                  (addEventForm.controls.startDate.dirty ||
                    addEventForm.controls.startDate.touched)
                ">
                <div [hidden]="!addEventForm.controls.startDate.errors.required" translate>
                  field_is_required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-item col-12 lg:col-12 md:col-12" *ngIf="showEquipment">
          <div class="form-field">
            <div class="form-label">
              <label for="staff" translate>grid_asset<span class="required-star">*</span></label>
            </div>
            <div class="form-control">
              <p-dropdown formControlName="assetId" class="assetListClass" [options]="assetListOptions" id="staff" [filter]="true"
                [placeholder]="'label_select_asset' | translate" (onChange)="onSelectEquipment()">
                <ng-template let-car pTemplate="item">
                  <div class="equipment-circle" [ngClass]="car.class"
                    [style.background-color]="car.color ? car.color : '#39ff14'"></div>
                  <div [style.color]="'#454545'" [style.background-color]="car.bgColor">
                    {{ car.label }}
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="form-errors"
                *ngIf="addEventForm.controls.assetId.errors  && (addEventForm.controls.assetId.dirty || addEventForm.controls.assetId.touched || submitted)">
                <div [hidden]="!addEventForm.controls.assetId.errors.required" translate>
                  field_is_required
                </div>
              </div>
          </div>
        </div>

        <div class="form-item col-12 lg:col-12 md:col-12" *ngIf="showStaff">
          <div class="form-field">
            <div class="form-label">
              <label for="staff" translate>label_employee<span class="required-star">*</span></label>
            </div>
            <div class="form-control">
              <p-dropdown formControlName="staffId" class="staffListClass" [options]="staffList" id="staff" [filter]="true"
                [placeholder]="'label_select_employee' | translate" (onChange)="changeStaff()" optionDisabled="inactive">
              </p-dropdown>
            </div>
            <div class="form-errors"
                *ngIf="addEventForm.controls.staffId.errors  && (addEventForm.controls.staffId.dirty || addEventForm.controls.staffId.touched || submitted)">
                <div [hidden]="!addEventForm.controls.staffId.errors.required" translate>
                  field_is_required
                </div>
              </div>
          </div>
        </div>

        <div class="two-items-div">
          <div class="form-item col-12 lg:col-5 md:col-12 short-w" *ngIf="showStaff">
            <div class="form-field">
              <div class="form-label">
                <label for="staff" translate>label_start_shift</label>
              </div>
              <div class="form-control">
                <div class="form-control filter-drop-down datetime-picker">
                  <p-calendar showTime="true" appendTo="body" hourFormat="12" formControlName="startShift"
                    id="startShift" [stepMinute]="15"
                    [showIcon]="true" timeOnly="true"
                    [firstDayOfWeek]="startDayOfWeek">
                  </p-calendar>
                </div>
                <div class="form-errors" *ngIf="
                  addEventForm.controls.startShift.errors &&
                  (addEventForm.controls.startShift.dirty ||
                    addEventForm.controls.startShift.touched)
                ">
                  <div [hidden]="!addEventForm.controls.startShift.errors.required" translate>
                    field_is_required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-item col-12 lg:col-5 md:col-12 short-w" *ngIf="showStaff">
            <div class="form-field">
              <div class="form-label">
                <label for="staff" translate>label_end_shift</label>
              </div>
              <div class="form-control">
                <p-calendar showTime="true" appendTo="body" hourFormat="12" formControlName="endShift" id="endShift"
                   [stepMinute]="15" [showIcon]="true"
                  timeOnly="true" [maxDate]="maxDate" maxTime="11pm" [firstDayOfWeek]="startDayOfWeek">
                  <!--(onShow)="showStartTime()"-->
                </p-calendar>
              </div>
              <div class="form-errors" *ngIf="
                  addEventForm.controls.endShift.errors &&
                  (addEventForm.controls.endShift.dirty ||
                    addEventForm.controls.endShift.touched)
                ">
                <div [hidden]="!addEventForm.controls.endShift.errors.required" translate>
                  field_is_required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-item col-12 lg:col-12 md:col-12">
          <div class="form-field">
            <div class="form-label mb-2">

              <label for="events" translate>label_event_based_on_asset</label>
            </div>
            <div class="event-list">
              <div class="form-control" *ngFor="let item of eventListForRender; let p = index">
                <div style="display: flex;">
                  <p-checkbox styleClass="p-mr-2 shift-builder-equipment-name" formControlName="allSelect"
                    [id]="'asset' + p" [value]="item.assetId" [name]="item.assetId"
                    (onChange)="changeAllShiftTimeOnEvents($event,p)" 
                    [disabled]="eventDisabled">
                  </p-checkbox>
                  <p class="shift-builder-equipment-name">{{ item.assetName }}</p>
                </div>
                <div [ngClass]="showStaff ? 'margin-l-2' : 'margin-l-2'">
                  <div class="form-control p-my-1" *ngFor="let event of item.eventgrp; let i = index">
                    <p-checkbox formControlName="events" styleClass="p-mr-2" [id]="'eve' + i" [label]="event.label"
                      [value]="event.id" [name]="event.id" (onChange)="changeShiftTimeOnEvents($event, event.id, p)"
                      [disabled]="eventDisabled">
                    </p-checkbox>
                  </div>
                </div>
              </div>
              <div class="form-control" *ngIf="eventListForRender.length == 0">
                <span translate>label_no_events</span>
              </div>
            </div>
            <div class="form-errors p-my-2" *ngIf="
                addEventForm.controls.events.errors &&
                (addEventForm.controls.events.dirty ||
                  addEventForm.controls.events.touched)
              ">
              <div [hidden]="!addEventForm.controls.events.errors.required" translate>
                field_is_required
              </div>
            </div>
          </div>
        </div>

        <div class="form-item col-12 lg:col-12 md:col-12">
          <div class="form-field">
            <div class="form-label mb-2">
              <label for="task" translate>label_tasks</label>
            </div>

            <div class="form-control" *ngIf="taskNameList.length > 0">
              <!--  <span>{{ taskNameList.taskname }}</span>  -->
              <div *ngFor="let task of taskNameList; let i = index" class="task-bottom">
                <p-checkbox formControlName="tasks" styleClass="p-mr-2" [id]="'tas' + i" [label]="task.label"
                  [value]="task.id" [name]="task.id" (onChange)="changeShiftTimeOnTasks($event, task.id, t)"
                   [disabled]="taskDisabled">
                </p-checkbox>
              </div>
            </div>
            <div class="form-errors p-my-2" *ngIf="
                addEventForm.controls.tasks.errors &&
                (addEventForm.controls.tasks.dirty ||
                  addEventForm.controls.tasks.touched)
              ">
              <div [hidden]="!addEventForm.controls.tasks.errors.required" translate>
                field_is_required
              </div>
            </div>


            <!-- <div class="form-control" *ngIf="taskNameList.length == 0 && selectedSchedule"> -->
            <div class="form-control" *ngIf="taskNameList.length == 0">
              <span translate>label_no_task</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="page-buttons">
          <button pButton [label]="'btn_save' | translate" type="submit" class="btn-save bottom-align"
            (click)="onSave()" [disabled]="saveDisabled"></button>
          <!-- (click)="onSave()" -->
        </div>
      </div>
    </form>
  </p-dialog>
</div>