
 <div class="page-container event-page">
  <div class="page-header">
       
         
         <div class="col-9">
           <h1 class="heading" translate>{{pageHeading}}</h1>
   </div>
         <div class="page-actions">
            <button pButton  (click)="onSave()" type="button" 
                [label]="'btn_save'|translate"></button>
            <button pButton (click)="onCancel()" type="button" [label]="'btn_cancel'|translate"></button>
         </div>
   
   
         </div>
    <div class="page-body">
        <form [formGroup]="eventForm">
          <div class="form-container col-12 lg:col-8">
              <div class="form-item col-12 lg:col-10 md:col-5">
                <div class="form-field">
                  <div class="form-label">
                    <label for="messageTitle" translate>label_message_title<span class="required-star">*</span></label>
                  </div>
                  <div class="form-control">
                    <input formControlName="messageTitle" id="messageTitle" class="full-width" type="text" appNoWhiteSpace pInputText appPreventSomeSpecialCharacters allowQuote="yes"/>
                  </div>
                  <div class="form-errors" *ngIf="eventForm.controls.messageTitle.errors  && (eventForm.controls.messageTitle.dirty || eventForm.controls.messageTitle.touched ||
                             submitted)">
                    <div [hidden]="!eventForm.controls.messageTitle.errors.required" translate>
                      field_is_required
                    </div>
                    <div [hidden]="!eventForm.controls.messageTitle.errors.minlength" translate>
                      min_length_1char
                    </div>
                    <div [hidden]="!eventForm.controls.messageTitle.errors.maxlength" translate>
                      max_length_50char
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="form-container col-12 lg:col-8">
                <div class="form-item col-12 lg:col-5 md:col-5">
                  <div class="form-field">
                    <div class="form-label">
                        <label for="segmentId" translate>label_segment<span class = "required-star">*</span></label>
                    </div>
                    <div class="form-control">
                        <p-dropdown formControlName="segmentId" options="segments" ></p-dropdown>
                    </div>
                    <div class="form-errors" *ngIf="eventForm.controls.segmentId.errors  && (eventForm.controls.segmentId.dirty || eventForm.controls.segmentId.touched ||
                               submitted)">
                      <div [hidden]="!eventForm.controls.segmentId.errors.required" translate>
                        field_is_required
                      </div>
                      <div [hidden]="!eventForm.controls.segmentId.errors.minlength" translate>
                        min_length_1char
                      </div>
                      <div [hidden]="!eventForm.controls.segmentId.errors.maxlength" translate>
                        max_length_50char
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" col-12 lg:col-5 md:col-5">
                    <div style="margin-left: 24px;">
                      <div>
                        <label for="messageType" translate >label_message_type<span class="required-star">*</span></label>
                      </div>
                   
                    
                      <div class="radio-btn-container" style="margin-top: 16px;">
                        <input type="radio" formControlName="messageType" id="Email" name="messageType" value="EMAIL" class="custom-control-input" class="full-width" checked="checked"/>
                        <label class="form-label" style="margin-right:16px ;">Email</label>
                       <input type="radio" formControlName="messageType" id="SMS" value="SMS"  name="messageType" />
                       <label class="form-label" style="margin-left:8px ;">SMS</label>

                      </div>
                      <div class="form-errors" *ngIf="eventForm.controls.messageType.errors  && (eventForm.controls.messageType.dirty || eventForm.controls.messageType.touched ||
                                 submitted)">
                        <div [hidden]="!eventForm.controls.messageType.errors.required" translate>
                          field_is_required
                        </div>
                        <div [hidden]="!eventForm.controls.messageType.errors.minlength" translate>
                          min_length_1char
                        </div>
                        <div [hidden]="!eventForm.controls.messageType.errors.maxlength" translate>
                          max_length_50char
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-item col-12 lg:col-10 md:col-5">
                    <div class="form-field">
                      <div class="form-label">
                        <label for="message" translate>label_message_body<span class="required-star">*</span></label>
                      </div>
                      <div class="form-control">
                        <textarea formControlName="message" id="message" class="full-width" appNoWhiteSpace pInputText >
                            </textarea>
                      </div>
                      <div class="form-errors" *ngIf="eventForm.controls.message.errors  && (eventForm.controls.message.dirty || eventForm.controls.message.touched ||
                                 submitted)">
                        <div [hidden]="!eventForm.controls.message.errors.required" translate>
                          field_is_required
                        </div>
                        <div [hidden]="!eventForm.controls.message.errors.minlength" translate>
                          min_length_1char
                        </div>
                        <div [hidden]="!eventForm.controls.message.errors.maxlength" translate>
                          max_length_50char
                        </div>
                      
                      </div>
                    </div>
                    <!-- <div class="form-field" *ngIf="messageType == 'SMS'">
                        <div class="form-label">
                          <label for="message" translate>label_message_body<span class="required-star">*</span></label>
                        </div>
                        <div class="form-control">
                          <textarea formControlName="message" id="message" class="full-width" appNoWhiteSpace pInputText >
                              </textarea>
                        </div>
                        <div class="form-errors" *ngIf="eventForm.controls.message.errors  && (eventForm.controls.message.dirty || eventForm.controls.message.touched ||
                                   submitted)">
                          <div [hidden]="!eventForm.controls.message.errors.required" translate>
                            field_is_required
                          </div>
                          <div [hidden]="!eventForm.controls.message.errors.minlength" translate>
                            min_length_1char
                          </div>
                          <div [hidden]="!eventForm.controls.message.errors.maxlength" translate>
                            max_length_50char
                          </div>
                        
                        </div>
                      </div> -->
                  </div>
                </div>
          </form>
          </div>
    </div>