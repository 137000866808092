import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppSettings } from '../app.settings';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

import { LoaderService } from './../components/loader/loader.service';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';

const httpOptions = {
  headers: new HttpHeaders({
    'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
    'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
    'Accept': AppSettings.HEADER_CONTENT_TYPE
  })
};

@Injectable()
export class RestApiService {
  httpHandleError: HandleError;
  constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler,
    private zone: NgZone, private router: Router,
    private storageService: StorageService, private loaderService: LoaderService,
    private messageService: MessageService,
  ) {
    this.httpHandleError = httpErrorHandler.createHandleError();
  }

  private prependApiUrl(url: string, v2?): string {
    if (v2) {
      return AppSettings.BASE_URL_CUSTOMER + url;
    }
    return AppSettings.BASE_URL + url;
  }

  get(identifier: string, url: string, loader?: string, v2?): Observable<{}> {
    this.showLoader(loader);
    if (v2) {
      return this.handleHttpSuccess(this.callerWithoutBody('get', identifier, url, v2));
    }
    return this.handleHttpSuccess(this.callerWithoutBody('get', identifier, url));
  }

  post(identifier: string, url: string, body: any, loader?: string, v2?: any): Observable<{}> {
    this.showLoader(loader);
    if (v2) {
      return this.handleHttpSuccess(this.callerWithBody('post', identifier, url, body, v2));
    }
    return this.handleHttpSuccess(this.callerWithBody('post', identifier, url, body));
  }

  put(identifier: string, url: string, body?: any, loader?: string, v2?: any): Observable<{}> {
    this.showLoader(loader);
    if (v2) {
      return this.handleHttpSuccess(this.callerWithBody('put', identifier, url, body, v2));
    }
    return this.handleHttpSuccess(this.callerWithBody('put', identifier, url, body));
  }

  // file related custome
  putFile(identifier: string, url: string, body?: any, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithBodyFile('put', identifier, url, body));
  }

  delete(identifier: string, url: string, loader?: string, v2?: any): Observable<{}> {
    this.showLoader(loader);
    if (v2) {
      return this.handleHttpSuccess(this.callerWithoutBody('delete', identifier, url, v2));
    }
    return this.handleHttpSuccess(this.callerWithoutBody('delete', identifier, url));
  }

  callerWithoutBody(method: string, identifier: string, url: string, v2?): Observable<{}> {
    if (identifier !== 'sign-out') {
      const call = (AppSettings.IS_IDLE_TIME) ? this.checkIdleTime() : '';
    }
    const head = { headers: this.getHttpClientHeaders(), withCredentials: true };
    const that = this;
    if (method === 'get') {
      return this.httpClient.get(this.prependApiUrl(url, v2 ? v2 : '',), head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    } else if (method === 'delete') {
      return this.httpClient.delete(this.prependApiUrl(url), head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    }
  }
  callerWithBody(method: string, identifier: string, url: string, body?: any, v2?): Observable<{}> {
    if (identifier !== 'sign-out') {
      const call = (AppSettings.IS_IDLE_TIME) ? this.checkIdleTime() : '';
    }
    const that = this;
    const head = { headers: this.getHttpClientHeaders(), withCredentials: true };
    if (method === 'put') {
      return this.httpClient.put(this.prependApiUrl(url, v2 ? v2 : '',), body, head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    } else if (method === 'post') {
      return this.httpClient.post(this.prependApiUrl(url, v2 ? v2 : ''), body, head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    }
  }


  image(identifier: string, url: string, fileName: string, loader?: string) {
    // responseType: ResponseContentType.Blob
    this.showLoader(loader);
    const head = { headers: this.getHttpClientHeaders() };
    const res = this.httpClient.get(url, head).pipe(
      catchError(this.httpHandleError(identifier, []))
    );
    this.downloadFile(res, this.getContentType(fileName), fileName);
  }

  private getHttpClientHeaders(skipContentType?): HttpHeaders {
    let storageTimeZone = this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE);

    let tz = '';
    if (storageTimeZone !== 'undefined') {
      tz = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE));
    }
    let timeZone = tz ? tz : Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!timeZone) {
      timeZone = AppSettings.DEFAULT_TIME_ZONE_FROM_CONFIG;
    }
    if (this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY) !== undefined
      && this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY) !== null
      && this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY).length > 0) {
      return new HttpHeaders({
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
        'Accept': AppSettings.HEADER_CONTENT_TYPE,
        'Timezone': timeZone,
        // 'X-SESSION-KEY': this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY)
      });
    }
    return new HttpHeaders({
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      // 'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
      'Accept': AppSettings.HEADER_CONTENT_TYPE,
      'Timezone': timeZone
    });
  }



  private handleHttpSuccess(res: Observable<{}>): Observable<{}> {
    return res;
  }

  // downloadFile(data: any, contentType: string, fileName: string) {
  //   const blob = new Blob([data.blob()], { type: contentType });
  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = fileName;
  //   link.click();
  //   this.hideLoader();
  //   // setTimeout(link.remove(), 1000);
  // }

  downloadFile(data: any, contentType: string, fileName: string) {
    const blob = new Blob([data], { type: contentType });
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    this.hideLoader();
    setTimeout(() => {
      link.remove();
    }, 1000);
  }

  private handleError(error: HttpErrorResponse | any) {
    this.hideLoader();
    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Data not found', });
    if (error.status === 400) {
      return throwError(error.error);
    } else if (error.status === 500 || error.status === 403) {
      return throwError(error.error);
    } else if (error.status === 401) {
      this.storageService.setItem(AppSettings.TOKEN_KEY, null);
      this.router.navigate(['/signin']);
    }
    // TODO handle 401 and other errors;
  }

  excel(url: string, fileName: string, loader?: string) {
    this.showLoader(loader);
    // return this.httpClient.get(this.prependApiUrl(url),
    //   { headers: this.getHttpClientHeaders(), withCredentials: true, responseType: 'blob', observe: 'response' }).subscribe(
    //     data => {
    //       let name = data.headers.get('content-disposition');
    //       if (name === undefined || name === null || name === '') {
    //         name = fileName;
    //       } else {
    //         name = name.substring(name.indexOf('='));
    //         name = name.replace('=', '');
    //       }
    //       this.downloadFile(data.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', name)
    //     })
    // // error => this.handleError(error);

    return this.httpClient.get(this.prependApiUrl(url),
      { headers: this.getHttpClientHeaders(), withCredentials: true, responseType: 'blob', observe: 'response' }).subscribe(
        data => {
          let name = data.headers.get('content-disposition');
          if (name === undefined || name === null || name === '') {
            name = fileName;
          } else {
            name = name.substring(name.indexOf('='));
            name = name.replace('=', '');
          }
          this.downloadFile(data.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', name);
        },
        (error) => {
          this.handleError(error);
        }
      );
  }


  private getContentType(fileName: string) {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    switch (extension) {
      case 'jpeg':
        return 'image/jpeg';
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      case 'bmp':
        return 'image/x-ms-bmp';
      case 'pdf':
        return 'application/pdf';
      case 'xls':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    return '';
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(loader?: string): void {
    if (loader !== undefined && loader !== null && 'none' !== loader.toLowerCase()) {
      this.loaderService.show(loader);
    }
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }


  checkIdleTime() {
    const idleTime = moment().add((AppSettings.IDLE_TIME_DAYS), 'd').valueOf();
    this.storageService.setItem('interval', idleTime);
  }

  excelAndDownload(url: string, fileName: string, loader?: string, isPostData?) {
    this.showLoader(loader);

    return this.httpClient.post(this.prependApiUrl(url), isPostData,
      { headers: this.getHttpClientHeaders(), withCredentials: true, responseType: 'blob', observe: 'response' }).subscribe(
        data => {
          let name = data.headers.get('content-disposition');
          if (name === undefined || name === null || name === '') {
            name = fileName;
          } else {
            name = name.substring(name.indexOf('='));
            name = name.replace('=', '');
          }
          this.downloadFile(data.body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', name);
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  callerWithBodyFile(method: string, identifier: string, url: string, body?: any, v2?): Observable<{}> {
    if (identifier !== 'sign-out') {
      const call = (AppSettings.IS_IDLE_TIME) ? this.checkIdleTime() : '';
    }
    const that = this;
    const head = { headers: this.getHttpFileHeaders(), withCredentials: true };
    if (method === 'put') {
      return this.httpClient.put(this.prependApiUrl(url, v2 ? v2 : '',), body, head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    }
  }

  private getHttpFileHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      'Content-Type': 'multipart/form-data',
      // 'Accept': AppSettings.HEADER_CONTENT_TYPE,
    });
  }
}
