<div class="layout-dashboard page-layout" *ngIf = "allStaffInputData && staffCheckBoxList" style="padding: 0;">

    <!-- <div style="display: flex;justify-content: end;margin-right: 5%;">
        <button pButton [label]="'btn_assign' | translate" type="submit" class="small-btn"
        (click)="onassignClick()" ></button>
    </div> -->

    <div class = "grid col-12 padding-1per food-extra-mapping">
            <!-- <form  > -->
                <div class = "col-12 menu-offered" *ngIf = "allStaffInputData && allStaffInputData.length > 0 && staffCheckBoxList.length > 0 ">

                    <div *ngFor="let eachStaff of allStaffInputData; let i= index">
                            <div  class = "grid form-container single-item assign-staff p-ai-center" [ngStyle]="eachStaff.notAvailableForAssign && {'background': 'rgba(102, 102, 102, .3)'}">
                                <!-- <div class="col-12 lg:col-1 md:col-1 extra-small-foodextra-item ">

                                </div> -->
                                <div class="col-12 lg:col-1 md:col-2 medium-foodextra-item text-field ">
                                    <div class="form-field checkbox-container">
                                        <p-checkbox name="staffIds" [(ngModel)]="staffCheckBoxList[i]['value']" binary="true"
                                        [disabled]="staffCheckBoxList[i]['disabled']" class="staff-checkbox">
                                        </p-checkbox>
                                      </div>
                                </div>
                                <div class="col-12 lg:col-4 md:col-2 medium-foodextra-item text-field ">
                                      <div class = "name">{{eachStaff.firstName}} {{eachStaff.lastName}}</div>
                                      <div class = "email">{{eachStaff.email}}</div>
                                      <div class = "email">{{eachStaff.numCountryCode}} {{eachStaff.phoneNum}}</div>
                                </div>
                                <div class="col-12 lg:col-3 schedule-section md:col-2 small-foodextra-item form-field mob-disp-flx">
                                        <div *ngIf="eachStaff.staffAvailabilitiesList"  style="padding: 0;">
                                            <div class="position-rel"  style="padding: 0;" *ngFor = "let eachAvailability of eachStaff.staffAvailabilitiesList">
                                                <div *ngIf = "(eachAvailability.available && !eachAvailability.allDay) ||
                                                (!eachAvailability.available && !eachAvailability.allDay)">
                                                    {{ commonService.convertedTimeAsPerTz1(eachAvailability.startDateTime) | date: "h:mm a" }} --
                                                     {{ commonService.convertedTimeAsPerTz1(eachAvailability.endDateTime) | date: "h:mm a" }}
                                                </div>
                                                <div *ngIf = "eachAvailability.available && eachAvailability.allDay">
                                                    <span>I'm Available All Day</span>
                                                </div>
                                                <div *ngIf = "eachAvailability.available && !eachAvailability.allDay">
                                                    <span>Partially Available</span>
                                                </div>
                                                <div *ngIf = "!eachAvailability.available && eachAvailability.allDay">
                                                    <span>Unavailable All Day</span>
                                                </div>
                                                <div *ngIf = "!eachAvailability.available && !eachAvailability.allDay">
                                                    <span>Partially Unavailable</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div *ngIf="!eachStaff.staffAvailabilitiesList || eachStaff.staffAvailabilitiesList.length == 0">
                                          <div class="col-12"  style="padding: 0;">
                                            Available All Day
                                          </div>
                                        </div> -->
                                </div>

                                <div class="col-12 lg:col-4 schedule-section md:col-2 small-foodextra-item form-field mob-disp-flx ">
                                    <div class="col-12">
                                        <div class="" *ngIf = "eachStaff.staffShiftDetailList">
                                            <div class="col-12 position-rel" *ngFor = "let eachSchedule of eachStaff.staffShiftDetailList"
                                            style="padding: 0;">
                                               <!-- <div class="col-6 position-rel">
                                                    {{ eachSchedule.startDateTime | date: "h:mm a" }} -- {{ eachSchedule.endDateTime | date: "h:mm a" }}
                                                </div> -->
                                                <!--  events grid > 0 -->
                                                <div class="col-12 position-rel" *ngIf = "eachSchedule.eventsGrid && eachSchedule.eventsGrid.length > 0" style="padding: 0;">
                                                    <div *ngFor = "let eachEvent of eachSchedule.eventsGrid; let i =index" class="event-block"
                                                    [ngClass] = "(eachStaff.staffShiftDetailList.length > 1) ?'event-border':''">
                                                        <div>{{eachEvent.name}}</div>
                                                        <div>{{ commonService.convertedTimeAsPerTz1(eachEvent.startDateTime) | date: "h:mm a" }} -- {{ commonService.convertedTimeAsPerTz1(eachEvent.endDateTime) | date: "h:mm a" }}</div>
                                                    </div>
                                                </div>
                                                <!-- task grid > 0 -->
                                                <div class="col-12 position-rel" *ngIf = "eachSchedule.tasksGrid && eachSchedule.tasksGrid.length > 0" style="padding: 0;">
                                                    <div *ngFor = "let eachTask of eachSchedule.tasksGrid; let i =index" class="event-block"
                                                    [ngClass] = "(eachStaff.staffShiftDetailList.length > 1) ?'event-border':''">
                                                        <div>{{eachTask.taskName}}</div>
                                                        <div>{{ commonService.convertedTimeAsPerTz1(eachTask.taskStartDate) | date: "h:mm a" }} -- {{ commonService.convertedTimeAsPerTz1(eachTask.taskEndDate) | date: "h:mm a" }}</div>
                                                    </div>
                                                </div>
                                                <div class="col-12 position-rel" *ngIf = "eachSchedule.eventId === null && eachSchedule.tasksGrid.length == 0 && 
                                                eachSchedule.eventsGrid.length == 0" style="padding: 0;">
                                                    <div class="event-block"
                                                    [ngClass] = "(eachStaff.staffShiftDetailList.length > 1) ?'event-border':''">
                                                        <div>Open Shift</div>
                                                        <div>{{ commonService.convertedTimeAsPerTz1(eachSchedule.startDateTime) | date: "h:mm a" }} -- {{ commonService.convertedTimeAsPerTz1(eachSchedule.endDateTime) | date: "h:mm a" }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf = "!eachStaff.staffShiftDetailList || eachStaff.staffShiftDetailList.length == 0">
                                            <div class="position-rel">
                                                No Event Or Task Assigned
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div *ngIf = "eachStaff.staffShiftDetailList">
                                    <div ></div>
                                    <div class="col-12 lg:col-4 md:col-2 small-foodextra-item form-field mob-disp-flx ">
                                        <div class="event-block">
                                            <div>Feb Fair</div>
                                            <div> 9:39 am - 11:30 am</div>
                                        </div>
                                        <div class="event-block">
                                            <div>Feb Fair</div>
                                            <div> 9:39 am - 11:30 am</div>
                                        </div>
                                    </div>
                                </div> -->

                            </div>

                    </div>
                </div>

            <!-- </form> -->
            <div *ngIf = "allStaffInputData.length == 0 && staffCheckBoxList.length == 0"
                 class = "col-12 menu-offered no-records" #other_content >
                No Staff available for selected brand
            </div>

    </div>

</div>
