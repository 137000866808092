<div class="week-container-mobile">
    <div class="grid week-change p-m-0" *ngIf = "showWeekChange">
        <div class="col-9 lg:col-9 md:col-6 grid p-justify-even p-align-center p-m-0">
            <div class="fa fa-angle-left pointer" (click)="prevWeek()"></div>
            <div class="date-summery">
                {{firstDay | date: 'MMM-dd'}} To {{lastDay | date: 'MMM-dd-yyyy'}}
            </div>
            <div class="fa fa-angle-right pointer" (click)="nextWeek()"></div>
        </div>
        <div class="col-3 lg:col-3 md:col-6">
            <!-- <button pButton (click)="getCurrentWeekSchedule()" class="small-btn todays-btn" type="button"
            [label]="'label_today'|translate"></button> -->
            <div class="today-btn" (click)="getCurrentWeekSchedule()" translate>label_today</div>
        </div>
    </div>
    <div>
        <div class="grid day-row p-m-0">
            <div class="day-cell" [ngClass] = "eachDay.selected?'selected-date':''"
             *ngFor="let eachDay of weekArray; let i = index" (click) = clickOnDate(eachDay,i)>
                <div>
                    <p class="short-day">{{eachDay.startOfDate | date:'EEEEE'}}</p>
                    <div class="dateview">
                        <div class = "date-div" style="justify-content: center;">
                            <div class="border-radius" > <!-- [ngClass]="todaysDate(eachDay)?'highlite':''" -->
                                {{eachDay.startOfDate | date:'d '}}
                            </div>
                        </div>
                        <div class = "indicator-div">
                            <div [ngClass]="eachDay.available?'indicator':'empty-indicator'">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
