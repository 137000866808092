
<div class="page-container">
    <div class="page-header">
      <div class="page-heading">
        <h1 class="heading" translate>label_campaign</h1>
      </div>
      <div class="page-actions">

      </div>
    </div>

    <div class="page-filters">
      <div class="filter-left-container grid">
        <div class="filter-item col-4">
          <div class="form-control input-container">
             <span class="p-input-icon-left">
               <i class="pi pi-search"></i>
               <input type="text" pInputText (keyup)= "onSearch($event)" [(ngModel)] = "search" class="search-input prefix-pad"
               [placeholder]="'label_search_campaign_tooltip'|translate" appPreventSpecialCharacters pTooltip="{{'label_search_campaign_tooltip' | translate}}">
             </span>
          </div>
        </div>
        <div class="filter-item col-4 lg:col-2 md:col-3 "  *ngIf="isAdminLogin">
          <div class="form-control input-container " >
            <p-multiSelect  #brandButton [options]="brandNameOptions" (onChange)="onSelectBrand($event)" [(ngModel)]="selectedBrandFromFilter"
              [maxSelectedLabels]="1" [showHeader]="false" [filter]="false" selectedItemsLabel="{0} Brands selected" placeholder="Select Brand" >
            </p-multiSelect>
          </div>
        </div>
        <div class="filter-item col-4 lg:col-2 md:col-3 ">
          <div class="form-control input-container " >
            <p-dropdown [(ngModel)]="selectedStatus" [options]="statusOptions" (onChange)="onStatus($event)"></p-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="page-body md:col-12">
      <app-mi-grid-v2 [url]="gridurl" [columnDefs]="columns"
          (actionBtnClickedEvent)="onActionBtnClicked($event)" [actionButtonsDefs]="buttons" [isExportVisible]="false"
          divClass="data-grid-lines" [searchBar]="searchBar"
          [isInputBrandId] = "franchiseLogin">
      </app-mi-grid-v2>
    </div>

  </div>

  <p-dialog [dismissableMask]="false"
    [modal] = "true"[header]="'Campaign Details' | translate" [(visible)]="showDetailsPopup" *ngIf="showDetailsPopup && imgurl" class="campaign-details-dialog"
    dismissableMask="true" [closable] = "true" [draggable]="false" blockScroll="true" [resizable]="true" [focusOnShow]="false" >
    <iframe [src]="imgurl" width="100%" height="100%" ></iframe>
  </p-dialog>