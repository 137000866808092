import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from './../../models/field-config.interface';
import { Component } from '@angular/core';
import { Field } from '../../models/field.interface';

@Component({
  selector: 'app-mi-select',
  templateUrl: './mi-select.component.html',
  styleUrls: ['./mi-select.component.scss'],
})
export class MiSelectComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;
}
