import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageTransform, base64ToFile } from 'ngx-image-cropper';
import { UserCommonService } from '../../services/user-common.service';
import { LoaderService } from '../loader/loader.service';
import { AppSettings } from '../../app.settings';
import { UiService } from '../../services/ui.service';
import { CommonBindingDataService } from '../../services/common-binding-data.service';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss']
})
export class ImageCropComponent implements OnInit, OnChanges {

  @Input() url = `/secure/files?type=banner`;
  @Input() aspectRatio = '3 / 4';
  @Input() resizeToWidth: any = 4000;
  @Input() resizeToHeight: any = 6500;
  @Input() cropperMinWidth: any = 300;
  @Input() cropperMinHeight: any = 400;
  @Input() disableFile: boolean;
  @Input() grafixIamge: any = {};
  @Input() uploadedImage: string = '';
  @Input() clientId: string = '';
  @Output() uploadEvent = new EventEmitter<any>();
  @Output() selectImage = new EventEmitter<any>();
  @ViewChild('file') file: ElementRef;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isUploadDisabled = true;
  transform: ImageTransform = {
    translateUnit: 'px'
  };
  scale = 1;
  originalFileName = 'sampleImage.png'
  imageExist = false;
  maxFileSize = AppSettings.FILE_UPLOAD_MAX_SIZE_FOR_MAIL_ATTACHMENTS_IN_BYTE;
  fileId = '';

  constructor(
    private loaderService: LoaderService,
    private userCommonService: UserCommonService,
    private commonService: CommonBindingDataService,
    private uiService: UiService,
  ) {
    if (this.grafixIamge) {
      this.uploadedImage = this.grafixIamge.signUrl;
    }
  }

  ngOnInit(): void {
    if (this.grafixIamge && this.grafixIamge.signUrl) {
      // console.log('grafixIamge', this.grafixIamge)
      this.uploadedImage = this.grafixIamge.signUrl;
      this.fileId = this.grafixIamge.id;
    }
  }

  ngOnChanges() {
    if (this.grafixIamge && this.grafixIamge.signUrl) {
      this.imageExist = true;
      // console.log('grafixIamge ch', this.grafixIamge)
      this.uploadedImage = this.grafixIamge.signUrl;
      this.fileId = this.grafixIamge.id;
    } else {
      this.imageExist = false;
      this.uploadedImage = '';
      this.fileId = '';
    }
  }

  onFileChange(event: any): void {
    this.imageChangedEvent = event;
  }

  chooseBtnClick() {
    const img = document.getElementById('file').click();
  }

  fileChangeEvent(event: any): void {
    // console.log('event', event);
    // if (event.srcElement.value !== '') {
    let fileSize = event.target.files[0].size;
    let type = event.target.files[0].type;
    if (type == 'image/jpeg' || type == 'image/jpg' || type == 'image/png') {
      // console.log('fileSize',fileSize);
      if (fileSize > this.maxFileSize) {
        this.imageChangedEvent = null;
        event.target.value = '';
        this.isUploadDisabled = true;
        this.uiService.showMessage(this.commonService.getLabel('max_banner_upload_size'), AppSettings.MESSAGE_TYPE.ERROR);
        event = '';
        return;
      } else {
        this.imageExist = false;
        this.isUploadDisabled = false;
        this.imageChangedEvent = event;
        this.selectImage.emit();
        // console.log('orgifile', event);
        this.originalFileName = event.target.files[0].name;
      }
    } else {
      this.imageChangedEvent = null;
      event.target.value = '';
      this.isUploadDisabled = true;
      this.uiService.showMessage(this.commonService.getLabel('error_file_type_image'), AppSettings.MESSAGE_TYPE.ERROR);
      event = '';
      return;
    }
    // }
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    // console.log('croppedImage'); //  this.croppedImage, event
    // console.log('height width', this.resizeToHeight, this.resizeToWidth);
  }

  base64ImageToBlob(str) {
    const pos = str.indexOf(';base64,');
    const type = str.substring(5, pos);
    const b64 = str.substr(pos + 8);
    const imageContent = atob(b64);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);
    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const blob = new Blob([buffer], { type: type });
    // console.log('type', type);
    return blob;
  }

  onUploadClick() {
    const fileToReturn = <File>this.base64ImageToBlob(this.croppedImage);
    this.onUploadImage({ files: fileToReturn });

    // const fileToReturn = this.base64ToFile(
    //   this.croppedImage,
    //   'tempfilename1',
    // );
    // this.onUploadImage({ files: fileToReturn });

    // const fileToReturn = this.blobToFile(
    //   this.croppedImage,
    //   'tempfilename1',
    // )
    // this.onUploadImage({ files: fileToReturn });

    console.log('fileToReturn', fileToReturn);
    // this.previewFile(fileToReturn);
    // return fileToReturn; 
  }

  // previewFile(file: File) {
  //   const url = URL.createObjectURL(file);
  //   const img = document.createElement('img');
  //   img.src = url;
  //   img.width = 100; // Adjust as needed
  //   document.getElementById('uniquebtndiv').append(img);

  //   // Clean up the URL after use
  //   img.onload = () => {
  //     URL.revokeObjectURL(url);
  //   };
  // }



  // blobToFile(blob: Blob, fileName: string): File {
  //   return new File([blob], fileName, {
  //     type: blob.type,
  //     lastModified: Date.now(),
  //   });
  // }

  // base64ToFile(data, filename) {
  //   const arr = data.split(',');
  //   const mime = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   let u8arr = new Uint8Array(n);

  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, { type: mime });
  // }

  public onUploadImage(data): void {
    // this.loaderService.show('page-center');
    const formData: FormData = new FormData();
    const file = data.files;
    formData.append('file[]', file, this.originalFileName);

    // console.log('data11', data, file, this.originalFileName);
    // for (const [key, value] of (formData as any).entries()) {
    //   console.log(`${key}:`, value);
    // }
    // return;
    this.userCommonService.uploadCroppedImage(this.url, formData).subscribe(response => {
      this.imageExist = true;
      this.uploadedImage = response.fileData.signUrl;
      this.fileId = response.fileData.id;
      this.isUploadDisabled = false;
      this.loaderService.hide();
      this.imageChangedEvent = '';
      // this.deleteImage();
      // console.log('image',response.fileData.signUrl);
      this.uploadEvent.emit({ fileId: response.fileData.id });
    });
  }

  deleteImage() {
    // this.file.nativeElement.value = '';
    this.uploadedImage = '';
    this.isUploadDisabled = true;
    this.imageExist = false;
    // this.userCommonService.deleteImage(this.clientId, this.fileId).subscribe(response => { 
    //   this.grafixIamge
    // });
    this.uploadEvent.emit({ fileId: '' });
  }

}