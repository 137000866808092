import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FieldConfig } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';

@Component({
  selector: 'app-mi-button',
  templateUrl: './mi-button.component.html',
  styleUrls: ['./mi-button.component.css'],
})
export class MiButtonComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;
}
