<!-- <div class="layout-dashboard page-layout">

    <div class="grid padding-1per">
        <div class=" grid col-12 md:col-6 lg:col-6">
            <div class="col-1 md:col-1 lg:col-1 back-arrow-div">
               <div class = "back-arrow-icon" (click) = "goBack()"></div>
            </div>
            <div class="col-10 md:col-11 lg:col-11">
                <h1 class="header">{{pageHeading}}</h1>
            </div>
        </div>
    
        <div class="col-12 md:col-6 lg:col-6 p-justify-end page-buttons">
            <button pButton  (click)="onSave()" type="button" 
                [label]="'btn_save'|translate"></button>
            <button pButton (click)="onCancel()" type="button" [label]="'btn_cancel'|translate"></button>
        </div>
    </div>

    <form [formGroup]="eventForm">
        <div class="grid form-container filter-container padding-1per">
            <div class="grid col-12 lg:col-10 md:col-12 pad-t-b-0">
                <div class="form-field col-12 lg:col-5 md:col-6 pad-t-b-0">
                    <div class="form-field col-12 lg:col-12 md:col-12">
                        <div class="form-label">
                            <label for="roleId" translate>label_role_type<span class = "required-star">*</span></label>
                        </div>
                        <div class="form-control">
                            <p-dropdown formControlName="roleId" [options]="roles"
                            (onChange)="getUserList(eventForm.controls.roleId.value)"></p-dropdown>
                        </div>
                        <div class="form-errors"
                            *ngIf="eventForm.controls.roleId.errors  && (eventForm.controls.roleId.dirty || eventForm.controls.roleId.touched ||
                             submitted)">
                            <div [hidden]="!eventForm.controls.roleId.errors.required" translate>
                                field_is_required
                            </div>
                            <div [hidden]="!eventForm.controls.roleId.errors.minlength" translate>
                                min_length_1char
                            </div>
                            <div [hidden]="!eventForm.controls.roleId.errors.maxlength" translate>
                                max_length_50char
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-field col-12 lg:col-5 md:col-6 pad-t-b-0" *ngIf = "showOrderCount">
                    <div class="form-field col-12 lg:col-12 md:col-12">
                        <div class="form-label">
                            <label for="orderCount" translate>label_order_count<span class = "required-star">*</span></label>
                        </div>
                        <div class="form-control">
                            <input formControlName="orderCount"  class="full-width" type="text" 
                            (keyup)="getUserList(eventForm.controls.roleId.value,eventForm.controls.orderCount.value)" appTwoDecimal  pInputText />
                        </div>
                        <div class="form-errors"
                            *ngIf="eventForm.controls.orderCount.errors  && (eventForm.controls.orderCount.dirty || eventForm.controls.orderCount.touched
                            || submitted)">
                            <div [hidden]="!eventForm.controls.orderCount.errors.required" translate>
                                field_is_required
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="grid col-12 lg:col-10 md:col-12 pad-t-b-0">
                <div class="form-field col-12 lg:col-5 md:col-6 pad-t-b-0">
                    <div class="form-field col-12 lg:col-12 md:col-12">
                        <div class="form-label">
                            <label for="userIds" translate>label_user<span class = "required-star">*</span></label>
                        </div>
                        <div class="form-control">
                            <p-multiSelect [options]="users" appendTo="body" formControlName="userIds" [style]="{'width': '100%','height': '33px','padding-right': '0'}" filter="true">
                            </p-multiSelect>
                        </div>
                        <div class="form-errors"
                            *ngIf="eventForm.controls.userIds.errors  && (eventForm.controls.userIds.dirty || eventForm.controls.userIds.touched ||
                             submitted)">
                            <div [hidden]="!eventForm.controls.userIds.errors.required" translate>
                                field_is_required
                            </div>
                            <div [hidden]="!eventForm.controls.userIds.errors.minlength" translate>
                                min_length_1char
                            </div>
                            <div [hidden]="!eventForm.controls.userIds.errors.maxlength" translate>
                                max_length_50char
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-field col-12 lg:col-5 md:col-6 pad-t-b-0">
                    <div class="form-field col-12 lg:col-12 md:col-12">
                        <div class="form-label">
                            <label for="segmentName" translate>label_segment_name<span class = "required-star">*</span></label>
                        </div>
                        <div class="form-control">
                            <input formControlName="segmentName"  class="full-width my-1" type="text" appNoWhiteSpace pInputText />
                        </div>
                        <div class="form-errors"
                            *ngIf="eventForm.controls.segmentName.errors  && (eventForm.controls.segmentName.dirty || eventForm.controls.segmentName.touched
                            || submitted)">
                            <div [hidden]="!eventForm.controls.segmentName.errors.required" translate>
                                field_is_required
                            </div>
                            <div [hidden]="!eventForm.controls.segmentName.errors.minlength" translate>
                                min_length_1char
                            </div>
                            <div [hidden]="!eventForm.controls.segmentName.errors.maxlength" translate>
                                max_length_50char
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
  
    <div class="grid padding-1per">
        <div class="col-12 md:col-12 lg:col-12 p-justify-end page-buttons pad-r-web">
            <button pButton  (click)="onSave()" type="button" 
                [label]="'btn_save'|translate"></button>
            <button pButton (click)="onCancel()" type="button" [label]="'btn_cancel'|translate"></button>
        </div>
    </div>

   

</div> -->
<div class="page-container event-page">
  <div class="page-header">


    <div class="col-9">
      <h1 class="heading" translate>{{pageHeading}}</h1>
    </div>
    <div class="page-actions">
      <button pButton  (click)="onSave()" type="button" 
                  [label]="'btn_save'|translate"></button>
      <button pButton (click)="onCancel()" type="button" [label]="'btn_cancel'|translate"></button>
    </div>


  </div>
  <div class="page-body">
    <form [formGroup]="eventForm">
      <div class="form-container col-12 lg:col-8">
        <div class="form-item  col-12 lg:col-5 md:col-5">
          <div class="form-field">
            <div class="form-label">
              <label for="roleId" translate>label_role_type<span class="required-star">*</span></label>
            </div>
            <div class="form-control">
              <p-dropdown formControlName="roleId" [options]="roles"
                (onChange)="getUserList(eventForm.controls.roleId.value)"></p-dropdown>
            </div>
            <div class="form-errors" *ngIf="eventForm.controls.roleId.errors  && (eventForm.controls.roleId.dirty || eventForm.controls.roleId.touched ||
                                 submitted)">
              <div [hidden]="!eventForm.controls.roleId.errors.required" translate>
                field_is_required
              </div>
              <div [hidden]="!eventForm.controls.roleId.errors.minlength" translate>
                min_length_1char
              </div>
              <div [hidden]="!eventForm.controls.roleId.errors.maxlength" translate>
                max_length_50char
              </div>
            </div>
          </div>
        </div>
        <div class="form-item col-12 lg:col-5 md:col-5" *ngIf = "showOrderCount">
          <div class="form-field">
            <div class="form-label">
              <label for="orderCount" translate>label_order_count<span class="required-star">*</span></label>
            </div>
            <div class="form-control">
              <input formControlName="orderCount"  class="full-width" type="text"  appTwoDecimal
                (keyup)="getUserList(eventForm.controls.orderCount.value,eventForm.controls.orderCount.value)" appTwoDecimal  pInputText />
            </div>
            <div class="form-errors" *ngIf="eventForm.controls.orderCount.errors  && (eventForm.controls.orderCount.dirty || eventForm.controls.orderCount.touched ||
                                 submitted)">
              <div [hidden]="!eventForm.controls.orderCount.errors.required" translate>
                field_is_required
              </div>
              <div [hidden]="!eventForm.controls.orderCount.errors.minlength" translate>
                min_length_1char
              </div>
              <div [hidden]="!eventForm.controls.orderCount.errors.maxlength" translate>
                max_length_50char
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-container col-12 lg:col-8">
        <div class="form-item col-12 lg:col-5 md:col-5">
          <div class="form-field">
            <div class="form-label">
              <label for="userIds" translate>label_user<span class="required-star">*</span></label>
            </div>
            <div class="form-control">
              <p-multiSelect [options]="users" appendTo="body" formControlName="userIds" [style]="{'width': '100%','height': '33px','padding-right': '0'}" filter="true" selectedItemsLabel="{0} items selected">
              </p-multiSelect>
            </div>
            <div class="form-errors" *ngIf="eventForm.controls.userIds.errors  && (eventForm.controls.userIds.dirty || eventForm.controls.userIds.touched ||
                                       submitted)">
              <div [hidden]="!eventForm.controls.userIds.errors.required" translate>
                field_is_required
              </div>
              <div [hidden]="!eventForm.controls.userIds.errors.minlength" translate>
                min_length_1char
              </div>
              <div [hidden]="!eventForm.controls.userIds.errors.maxlength" translate>
                max_length_50char
              </div>
            </div>
          </div>
        </div>

        <div class="form-item  col-12 lg:col-5 md:col-5">
          <div class="form-field">
            <div class="form-label">
              <label for="segmentName" translate>label_segment_name<span class="required-star">*</span></label>
            </div>
            <div class="form-control">
              <p-dropdown formControlName="segmentName" [options]="roles"
                (onChange)="getUserList(eventForm.controls.segmentName.value)"></p-dropdown>
            </div>
            <div class="form-errors" *ngIf="eventForm.controls.segmentName.errors  && (eventForm.controls.segmentName.dirty || eventForm.controls.segmentName.touched ||
                                           submitted)">
              <div [hidden]="!eventForm.controls.segmentName.errors.required" translate>
                field_is_required
              </div>
              <div [hidden]="!eventForm.controls.segmentName.errors.minlength" translate>
                min_length_1char
              </div>
              <div [hidden]="!eventForm.controls.segmentName.errors.maxlength" translate>
                max_length_50char
              </div>
            </div>
          </div>
        </div>
      </div>


    </form>
  </div>
</div>