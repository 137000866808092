import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-bar-chart-sales-events-week-day',
  templateUrl: './bar-chart-sales-events-week-day.component.html',
  styleUrls: ['./bar-chart-sales-events-week-day.component.scss']
})
export class BarChartSalesEventsWeekDayComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('barCanvas') barCanvas: ElementRef | undefined;
  barChart: any;
  actualValue = '';
  comparedValue = '';
  @Input() eventSalesGivebackObjectToPass;
  constructor() { }

  ngOnInit(): void {
    this.getSetLastValues();
  }

  ngAfterViewInit(): void {
    this.barChartMethod();
  }

  ngOnChanges() {
    this.barChartMethod();
    console.log('eventSalesGivebackObjectToPass', this.eventSalesGivebackObjectToPass);
  }

  barChartMethod() {
    this.barChart = new Chart(this.barCanvas?.nativeElement, {
      type: 'bar',
      data: {
        labels: this.eventSalesGivebackObjectToPass.labels,
        datasets: [{
          label: 'Primary Data',
          yAxisID: 'A',
          data: this.eventSalesGivebackObjectToPass.eventSalesDataSet1,
          backgroundColor: FILL_COLORS.blue,
          borderColor: BORDER_COLORS.blue,
          borderWidth: 1,
          barPercentage: 1,
          categoryPercentage: 0.3,
          barThickness: 30,
          maxBarThickness: 40,
        },
        {
          label: 'Compared Data',
          yAxisID: 'A',
          data: this.eventSalesGivebackObjectToPass.eventSalesDataSet2,
          backgroundColor: AppSettings.GRAPH_SECONDARY_COMPARED_DEFAULT_COLOR,
          borderColor: AppSettings.GRAPH_SECONDARY_COMPARED_DEFAULT_COLOR,
          borderWidth: 1,
          barPercentage: 1,
          categoryPercentage: 0.3,
          barThickness: 30,
          maxBarThickness: 40,
        },]
      },
      options: {
        scales: {
          x: {
            title: {
              color: 'red',
              display: true,
              text: 'Days of Week',
              font: {
                family: 'Montserrat',
                size: 15,
                weight: 'bold',
              },
              padding: 15,
            },
            grid: {
              display: false,
              offset: false,
            },
             border: {
              color: 'black',
              width: 2,
            },
            ticks: {
              font: {
                family: 'Montserrat',
              },
            }
          },
          A: {
            beginAtZero: true,
            type: 'linear',
            position: 'left',
            min: 0,
            max: this.getHighestValue(),// this.eventSalesGivebackObjectToPass.mode === 'Event Count' ? this.getHighestValue() : null,
            title: {
              color: 'red',
              display: true,
              text: this.generateXAxisLabel(this.eventSalesGivebackObjectToPass.mode),
              font: {
                family: 'Montserrat',
                size: 15,
                weight: 'bold',
              },
              padding: 15
            },
            grid: {
            },
             border: {
              color: 'black',
              width: 2,
            },
            ticks: {
              font: {
                family: 'Montserrat',
              },
              // stepSize: this.eventSalesGivebackObjectToPass.mode === 'Event Count' ? 1 : 0,
            }
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            labels: {
              usePointStyle: true
            }
          },
          tooltip: {
            yAlign: 'bottom',
            displayColors: false,
            titleFont: {
                family: 'Montserrat',
                // size: 16,
                weight: 'bold',
            },
            titleMarginBottom: -6,
            bodySpacing: 6,
            bodyFont: {
                family: 'Montserrat',
            },
            padding: 12,
            callbacks: {
              title: (context) => {
                return context[0].label;
              },
              beforeBody: (context) => {
                return '_'.repeat(20);
              },
              label: (context) => {
                // console.log(context);
                const label = [];
                label.push(this.eventSalesGivebackObjectToPass.mode === 'Event Count' ? `Event Count: ${context.formattedValue}` : `Sales: $${context.formattedValue}`);
                label.push(`Daily ${this.eventSalesGivebackObjectToPass.mode === 'Event Count' ? 'Count' : 'Sales'} %: ${this.getDailySalesPercent(context.raw, context.dataset.data)}%`);
                return label;
              }
            }
          }

        },
        layout: {
          autoPadding: true
        },
        events: ['mousemove', 'mouseout', 'click'],
        interaction: {
          mode: 'x',
          axis: 'x'
        },
      },
    });
  }

  generateXAxisLabel(mode) {
    if (mode === "Event Count") {
      return mode;
    } else {
      return mode + " ( in USD )"
    }
  }

  generateUnit(mode?) {
    const selectedMode = mode ? mode : this.eventSalesGivebackObjectToPass.mode;
    if (selectedMode === "Event Count") {
      return '';
    } else {
      return 'US$';
    }
  }

  getSetLastValues() {
    const set1 = this.eventSalesGivebackObjectToPass.eventSalesDataSet1;
    const set2 = this.eventSalesGivebackObjectToPass.eventSalesDataSet2;
    this.actualValue = set1[set1.length - 1];
    this.comparedValue = set2[set2.length - 1];
  }

  getDailySalesPercent(dayValue, array) {
    // var valueForDay = dayValue.replace(/\,/g,'')
    const valueForDay = Number(dayValue);
    const sum = array.reduce((partialSum, a) => partialSum + a, 0);
    const percent = (valueForDay / sum) * 100;
    return percent.toFixed(2);
  }

  // getHighestValue() {
  //   let max = Math.max(...this.eventSalesGivebackObjectToPass.eventSalesDataSet1, ...this.eventSalesGivebackObjectToPass.eventSalesDataSet2);
  //   let extarPad = Math.floor(max / 10);
  //   let returnVal = (max + (extarPad > 1 ? extarPad : 1));
  //   // console.log('max',max, '--','pad',extarPad,'--', returnVal);
  //   return returnVal;
  // }

  getHighestValue() {
    // replace this with actual array
    const max = Math.max(...this.eventSalesGivebackObjectToPass.eventSalesDataSet1, ...this.eventSalesGivebackObjectToPass.eventSalesDataSet2);
    const digit = Math.floor(Math.log10(max)) + 1;
    let multiplier;
    if (digit === 1) {
      return Math.ceil(max) + 1;
    } else if (digit === 2) {
      multiplier = 1;
    } else if (digit > 2) {
      const str = "1" + "0".repeat(digit - 2);
      multiplier = Number(str);
    }
    multiplier = multiplier * 5;
    console.log(`Multiplier: ${multiplier}`);
    let roundedMax = Math.round((max) / multiplier) * multiplier;
    if (roundedMax <= max) {
      roundedMax = roundedMax + multiplier;
    }
    console.log('Rounded: Max' + roundedMax);
    return roundedMax;
  }

  ngOnDestroy() {
    this.barChart.destroy();
  }
}

export const FILL_COLORS = {
  blue: 'rgb(25, 138, 215, 0.7)',
  yellow: 'rgb(247, 212, 32, 0.7)',
  green: 'rgb(77, 179, 96, 0.7)',
};

export const BORDER_COLORS = {
  blue: 'rgb(25, 138, 215, 1)',
  yellow: 'rgb(247, 212, 32, 1)',
  green: 'rgb(77, 179, 96, 1)',
};
