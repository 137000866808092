<form [formGroup]="emailTemplateForm">
  <div class="form-container filter-container padding-1per">
    <div class="grid col-12 lg:col-10 md:col-12 pad-t-b-0">

      <div class="form-field col-12 lg:col-10 md:col-10 pad-t-b-0">
        <div class="form-field lg:col-6 md:col-6 sm:col-12">
          <div class="form-label">
            <label for="from" translate>From<span class="required-star">*</span></label>
          </div>
          <div class="form-control">
            <input id="from" class="full-width" type="text" formControlName="from" appPreventSomeSpecialCharacters allowQuote="no"/>
          </div>
        </div>
      </div>

      <div class="form-field col-12 lg:col-10 md:col-10 pad-t-b-0">
        <div class="form-field lg:col-6 md:col-6 sm:col-12">
          <div class="form-label">
            <label for="cc" translate>CC</label>
          </div>
          <div class="form-control">
            <input id="cc" class="full-width" type="text" formControlName="cc" appPreventSomeSpecialCharacters allowQuote="no"/>
          </div>
        </div>
      </div>

      <div class="form-field col-12 lg:col-10 md:col-10 pad-t-b-0">
        <div class="form-field lg:col-6 md:col-6 sm:col-12">
          <div class="form-label">
            <label for="bcc" translate>BCC</label>
          </div>
          <div class="form-control">
            <input id="bcc" class="full-width" type="text" formControlName="bcc" appPreventSomeSpecialCharacters allowQuote="no"/>
          </div>
        </div>
      </div>

      <div class="form-field col-12 lg:col-10 md:col-10 pad-t-b-0">
        <div class="form-field lg:col-6 md:col-6 sm:col-12">
          <div class="form-label">
            <label for="subject" translate>Subject<span class="required-star">*</span></label>
          </div>
          <div class="form-control">
            <input id="subject" class="full-width" type="text" formControlName="subject" appPreventSomeSpecialCharacters allowQuote="yes"/>
          </div>
          <div class="form-errors"
              *ngIf="emailTemplateForm.controls.subject.errors  && (emailTemplateForm.controls.subject.dirty || emailTemplateForm.controls.subject.touched ||
               emailsubmitted)">
              <div [hidden]="!emailTemplateForm.controls.subject.errors.required" translate>
                  field_is_required
              </div>
          </div>
        </div>
      </div>

      <div class="form-field col-12 pad-t-b-0">
        <div class="form-field col-12 lg:col-12 md:col-12 pad-0">
          <div class="form-label">
            <label translate>Template<span class="required-star">*</span></label>
          </div>
          <div class="form-control">
            <p-editor formControlName="template" [style]="{'height':'320px'}"></p-editor>
          </div>
          <div class="form-errors"
              *ngIf="emailTemplateForm.controls.template.errors  && (emailTemplateForm.controls.template.dirty || emailTemplateForm.controls.template.touched ||
               emailsubmitted)">
              <div [hidden]="!emailTemplateForm.controls.template.errors.required" translate>
                  field_is_required
              </div>
          </div>
        </div>
      </div>

      <div class="form-field col-12 pad-t-b-0">
        <div class="form-field col-12 lg:col-12 md:col-12 pad-0">
          <div class="form-label">
            <label translate>Footer<span class="required-star">*</span></label>
          </div>
          <div class="form-control">
            <p-editor formControlName="footer" [style]="{'height':'220px'}"></p-editor>
          </div>
          <div class="form-errors"
              *ngIf="emailTemplateForm.controls.footer.errors  && (emailTemplateForm.controls.footer.dirty || emailTemplateForm.controls.footer.touched ||
               emailsubmitted)">
              <div [hidden]="!emailTemplateForm.controls.footer.errors.required" translate>
                  field_is_required
              </div>
          </div>
        </div>
      </div>

      <div class="col-12 md:col-12 lg:col-12 p-justify-end page-buttons">
        <button pButton (click)="onSave()" type="button" [label]="'btn_save'|translate"></button>
        <button pButton (click)="onCancel()" type="button" [label]="'btn_cancel'|translate"></button>
      </div>

    </div>
  </div>
</form>
