import * as moment from 'moment';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem, Message, PrimeNGConfig } from 'primeng/api';
import { Subscription, timer } from 'rxjs';

import { AppSettings } from './../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { Router } from '@angular/router';
import { StorageService } from './../../services/storage.service';
import { UiService } from '../../services/ui.service';
import { UserCommonService } from './../../services/user-common.service';
import { map } from 'rxjs/operators';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { MultiSelect } from 'primeng/multiselect';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  host: {
    '(window:click)': 'onClick($event)',
  },
})
export class HeaderComponent implements OnInit {
  defaultProfileImage = AppSettings.DEFAULT_USER_IMAGE;
  profileImage = '';
  notificationInterval;
  userFullName: string;
  items: MenuItem[];
  message: Message[] = [];
  profileUrl = AppSettings.DEFAULT_USER_IMAGE;
  changePasswordPopup = false;
  editProfilePopup = false;
  subscription: Subscription;
  myDate = new Date(1496314866000);
  myDateLong = 1496314866000;
  minuts = 240;
  notifications: any = [];
  unReadNotificatonsCount = 0;
  limit = 5;
  offset = 0;
  showMoreOff = true;
  secondsCounter: any;
  logoutSession: any;
  loginData: any;
  isFranchise = false;
  isStaff = true;
  isAdmin = false;
  isStaffManager = false;
  subscribedVariable: Subscription;
  subscribedUnAuthorizedVariable: Subscription;
  waffleMenu: any;
  displayNotification = false;
  notificationsCount: any;
  notifyTimerSubscription: Subscription;
  checkVersionTimerSubscription: Subscription;
  checkTimeZoneTimerSubscription: Subscription;
  versionCode: any;
  updatedVersion: any;
  brandNameOptions: any = [];
  selectedBrandFromFilter = [];
  loggedInUserId;

  salectedColor = 'white';
  showBrandfilter: boolean;
  previousBrand = [];
  readOnlySwape = true;
  readOnlyTime = true;
  readOnlyClient = true;
  readOnlyCampaign = true;
  readOnlyNoReplies = true;
  readOnlyStaff = true;
  loginRole;
  updatedTimezone: any;
  newTimeZone: any;
  broadcastData;

  constructor(
    private uiService: UiService,
    private userCommonService: UserCommonService,
    private commonBindingDataService: CommonBindingDataService,
    private franchiseService: FranchiseService,
    private router: Router,
    private commonService: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private primengConfig: PrimeNGConfig,
    private _eref: ElementRef
  ) {
    this.readOnlySwape = commonBindingDataService.checkIfHavePermission('MANAGE_SHIFT_SWAP', true);
    this.readOnlyTime = commonBindingDataService.checkIfHavePermission('MANAGE_TIME_OFF', true);
    this.readOnlyClient = commonBindingDataService.checkIfHavePermission('MANAGE_CLIENT', true);
    this.readOnlyStaff = this.commonBindingDataService.checkIfHavePermission('MANAGE_SHIFT_SCHEDULE', true);
    this.readOnlyNoReplies = commonBindingDataService.checkIfHavePermission('MANAGE_NO_REPLIES', true);
    this.readOnlyCampaign = commonBindingDataService.checkIfHavePermission('MANAGE_CAMPAIGN', true);
  }

  onClick(event) {
    // if (!this._eref.nativeElement.contains(event.target)) //
    const profileMenu = document.querySelector('.user-profile');
    if (profileMenu.classList.contains('toggle-menu')) {
      if (!event.target.parentNode.parentNode.classList.contains('toggle-menu')) {
        this._eref.nativeElement.querySelector('.user-profile').classList.remove('toggle-menu');
      }
    }
  }

  ngOnInit() {
    if (screen.width > 480) {
      this.showBrandfilter = true;
    } else {
      this.showBrandfilter = false;
    }
    this.getUserProfileImage();
    this.subscribedVariable = this.commonService.isProfileUpdated.subscribe(result => {
      this.getUserProfileImage();
    });
    this.loginRole = this.commonBindingDataService.getLoginRole();
    this.subscribedUnAuthorizedVariable = this.commonService.getUnAuthorized.subscribe(result => {
      if (this.loginRole == AppSettings.USER_ROLE_MANAGER) {
        this.getUaserAccessAndUpdate();
      } else {
        this.router.navigate(['/franchise/events/list']);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
    this.getWaffleMenu();
    this.primengConfig.ripple = true;
    this.setSessionInterval();
    this.storageService.logout.subscribe(res => {
      this.logout();
    });

    this.items = [
      {
        label: 'Logout',
        icon: 'fa fa-sign-out',
        command: event => {
          this.doSignOut();
        }
      }
    ];

    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.userFullName = userObj.firstName + ' ' + userObj.lastName;
      if (
        userObj.userDetails !== undefined &&
        userObj.userDetails !== null &&
        userObj.userDetails.profileImage !== null
      ) {
        this.profileUrl =
          AppSettings.GET_FILE_THUMB_URL + userObj.userDetails.profileImage;
      }
    }

    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    if (this.loginData) {
      const staffAsManager = JSON.parse(this.storageService.getItem('staffAsManager'));
      const data = JSON.parse(this.loginData);
      this.loggedInUserId = data.franchiseId ? data.franchiseId : data.id;
      if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE) {
        this.isFranchise = true;
      }
      // || (data.roles[0].roleCode == AppSettings.STAFF_ROLES['Manager'] && staffAsManager === "true"  remove for hide profile menu for staff manager
      if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE ||
        data.roles[0].roleCode === AppSettings.USER_ROLE_ADMIN) {
        this.isStaff = false;
      }
      if (data.roles[0].roleCode === AppSettings.USER_ROLE_ADMIN) {
        this.isAdmin = true;
      }
      if (data.roles[0].roleCode == AppSettings.STAFF_ROLES['Manager'] && staffAsManager === "true") {
        this.isStaffManager = true;
      }
    }
    if (this.isFranchise || this.isStaffManager) {
      // timer(0, 600000) call the function immediately and every 10 min for fetch notification count
      this.notifyTimerSubscription = timer(0, 600000).pipe(
        map(() => {
          this.getNotificationCount(); // call notification api
        })
      ).subscribe();
    }
    this.getBrandList(this.loggedInUserId);

    //call version api after 3sec and every 15min
    this.checkVersionTimerSubscription = timer(3000, 900000).pipe(
      map(() => {
        this.checkNewVersion(); // call notification api
      })
    ).subscribe();

    // call timezone check api after 3sec and every 5min
    if (!this.isAdmin) {
      this.checkTimeZoneTimerSubscription = timer(3000, Number(AppSettings.TIMEZONE_TIME)).pipe(
        map(() => {
          this.checkNewTimeZone(); // call timezone check api
        })
      ).subscribe();
    }
  }

  getBrandList(id?) {
    this.userCommonService.getBrandListForFilter(id).subscribe((result) => {
      this.brandNameOptions = result.map(element => ({
        label: element.brandName, value: element.brandId, brandId: element.brandId,
        activated: element.activated, color: element.color,
        deleted: element.deleted, inactive: false,
      }));
      if (this.storageService.getItem('persistSelectedBrands')) {
        this.selectedBrandFromFilter = JSON.parse(this.storageService.getItem('selectedBrands'));
      } else {
        this.selectedBrandFromFilter = this.brandNameOptions.map((item) => item.value.trim());
      }
      this.onSelectBrand();
    });
  }

  onSelectBrand(event?) {
    if (this.selectedBrandFromFilter.length === 0) {
      this.previousBrand[0] = event ? event.itemValue.value : '';
      setTimeout(() => {
        this.selectedBrandFromFilter = this.previousBrand;
        this.storageService.setItem('selectedBrands', this.selectedBrandFromFilter);
        this.storageService.setItem('persistSelectedBrands', true);
      }, 300);
    } else {
      if (this.selectedBrandFromFilter.length == 1) {
        const found = this.brandNameOptions.find(c => c.value == this.selectedBrandFromFilter[0]);
        if (found) {
          this.salectedColor = found.color;
        }
      } else {
        this.salectedColor = 'white';
      }
      this.storageService.setItem('selectedBrands', this.selectedBrandFromFilter);
      this.storageService.setItem('persistSelectedBrands', true);
      this.userCommonService.changeBrand.next(this.selectedBrandFromFilter);
    }
  }

  checkVerion() {
    this.franchiseService.checkVersion().subscribe((result) => {
      if (result !== undefined) {
        this.versionCode = result.versionsDto.version;
        // console.log('updated version', this.updatedVersion);
      }
    }, (error) => {
      console.log(error);
    });
  }


  checkNewVersion() {
    this.updatedVersion = JSON.parse(this.storageService.getItem('latestVersion'));
    // console.log('local version', this.updatedVersion);
    this.checkVerion();
    setTimeout(() => {
      if (this.versionCode !== this.updatedVersion) {
        // console.log('new version found');
        this.confirmationService.confirm({
          message: this.commonService.getLabel('label_new_version_fount'),
          accept: () => {
            location.reload();
            this.storageService.setItem('latestVersion', this.versionCode);
          }
        });
      }
    }, 2000);
  }

  checkTimeZone() {
    this.franchiseService.checkTimeZone().subscribe((result) => {
      if (result !== undefined) {
        this.newTimeZone = result.defaultTimezone;
        // console.log('updated version', this.updatedVersion);
        this.broadcastData = result;
      }
    }, (error) => {
      console.log(error);
    });
  }


  checkNewTimeZone() {
    this.updatedTimezone = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE));
    // console.log('local version', this.updatedTimezone);
    this.checkTimeZone();
    if (this.isStaff && !this.isStaffManager) {
      setTimeout(() => {
        if (this.newTimeZone !== this.updatedTimezone) {
          const msg = this.commonService.getLabel('label_new_timezone_found1');
          const msg2 = this.commonService.getLabel('label_new_timezone_found2');
          // console.log('new version found');
          this.confirmationService.confirm({
            message: msg + ' ' + this.newTimeZone + '. ' + msg2,
            accept: () => {
              this.storageService.setItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE, this.newTimeZone);
              location.reload();
            }
          });
        }
      }, 2000);
    }
  }



  getWaffleMenu() {
    // this.userCommonService.getWaffleMenu().subscribe((result) => {
    //   this.waffleMenu = result.data;
    // });
    setTimeout(() => {
      if (this.isFranchise || this.isStaffManager) {
        this.userCommonService.getWaffleMenu().subscribe((result) => {
          this.waffleMenu = result.data;
        });
      }
    }, 500);
  }

  getUserProfileImage() {
    const storageProfileImage = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_USER_PROFILE));
    this.profileImage = (storageProfileImage) ? storageProfileImage : this.defaultProfileImage;
  }

  onChooseProfile(event: any) {
    event.preventDefault();
    const toggleClass = 'toggle-menu';
    event.currentTarget.classList.toggle(toggleClass);
  }

  onProfileClick() {
    if (this.isAdmin) {
      this.router.navigate(['/admin/profile']);
    } else {
      this.router.navigate(['/franchise/profile']);
    }
  }


  onSideMenuClick(event?: any) {
    this.uiService.sidebarToggled();
    if (event) {
      event.preventDefault();
    }
    document.getElementsByTagName('body')[0].classList.toggle('closed');
  }

  doSignOut() {
    this.confirmationService.confirm({
      message: this.commonService.getLabel('msg_logout'),
      accept: () => {
        this.franchiseService.setStatus = null;
        this.franchiseService.eventsFilters = null;
        this.logout();
      }
    });
  }

  logout() {
    // Actual logic to perform a confirmation
    this.userCommonService.signOut().subscribe(results => {
      this.franchiseService.setStatus = null;
      this.franchiseService.eventsFilters = null;
      this.storageService.removeItem(AppSettings.USER_DETAILS_EMAIL);
      this.storageService.removeItem(AppSettings.USER_DETAILS_NAME);
      this.storageService.removeItem(AppSettings.USER_DETAILS);
      this.storageService.removeItem(AppSettings.TOKEN_KEY);
      this.storageService.removeAll();
      this.storageService.removeAllCookies();
      this.storageService.removeItem('latestVersion');
      sessionStorage.clear();
      this.router.navigate(['/signin']);
      // location.reload();
    }, (error) => {
      this.errorMessage(error);
    });
  }

  onCancelEditProfile(event: any) {
    this.editProfilePopup = !this.editProfilePopup;
  }

  onCancelChangePassword(event: any) {
    this.changePasswordPopup = !this.changePasswordPopup;
  }

  errorMessage(error) {
    error = JSON.parse(error);
    this.message = [];
    this.message.push({
      severity: 'error',
      summary: 'error',
      detail: error.general[0].message
    });
  }

  setSessionInterval() {
    const sessionInterval = timer(10000, 1000);
    this.secondsCounter = sessionInterval.subscribe(n => {
      const idleSessionTime = this.storageService.getItem(AppSettings.INTERVAL);
      if (idleSessionTime) {
        if (+idleSessionTime < new Date().getTime()) {
          this.confirmYouThere();
          this.secondsCounter.unsubscribe();
        } else {
          clearTimeout(this.logoutSession);
        }
      }
    });
  }

  confirmYouThere() {
    this.sessionTimeout();
    const message = this.commonService.getLabel('lbl_confirm_idle_session');
    this.confirmationService.confirm({
      message: message,
      rejectVisible: false,
      accept: () => {
        this.refreshSession();
      },
      reject: () => {
        this.refreshSession();
      }
    });
  }

  sessionTimeout() {
    this.logoutSession = setTimeout(() => {
      const idleSessionTime = this.storageService.getItem(AppSettings.INTERVAL);
      if (+idleSessionTime < new Date().getTime()) {
        this.logout();
      } else {
        clearTimeout(this.logoutSession);
      }
    }, AppSettings.IDLE_TIME_ON_CONFIRM_MSG);
  }

  refreshSession() {
    // const idleTime = moment().add(AppSettings.IDLE_TIME, 'm').valueOf();
    const idleTime = moment().add(AppSettings.IDLE_TIME_DAYS, 'd').valueOf();
    this.storageService.setItem('interval', idleTime);
    clearTimeout(this.logoutSession);
    // this.userCommonService.sesionRefresh().subscribe(() => { });
    this.setSessionInterval();
    window.location.reload();
  }

  redirectToUrl(url) {
    // console.log(url);
    // console.log(url.includes('http'));

    if (url.includes('http')) {
      // window.location.href = url;
      return url;
    } else {
      // window.location.href = '//'+url;
      return '//' + url;
    }

  }

  showNotification() {
    this.displayNotification = true;
  }

  getNotificationCount() {
    this.userCommonService.getNotificationCount().subscribe((result) => {
      this.notificationsCount = result;
      // console.log('notitication count', this.notificationsCount);
    });
  }

  getNotificationList() {
    this.userCommonService.getNotification().subscribe((result) => {
      this.notifications = result.data;
    });

    setTimeout(() => {
      this.getNotificationCount();
    }, 1000);
  }

  checkPermission(type) {
    if (type == 'SWITCH_SHIFT') {
      if (this.readOnlySwape) {
        return true;
      } else {
        return false;
      }
    }
    if (type == 'TIME_OFF') {
      if (this.readOnlyTime) {
        return true;
      } else {
        return false;
      }
    }
    if (type == 'CLIENT_TEMPLATE_UPDATE') {
      if (this.readOnlyClient) {
        return true;
      } else {
        return false;
      }
    }
    if (type == 'MAILCHIMP_NEW_CAMPAIGN') {
      if (this.readOnlyCampaign) {
        return true;
      } else {
        return false;
      }
    }
    if (type == 'FUTURE_BOOKING_REQUEST') {
      if (this.readOnlyNoReplies) {
        return true;
      } else {
        return false;
      }
    }

    if (type == 'CLAIM_OPEN_SHIFT') {
      if (this.readOnlyStaff) {
        return true;
      } else {
        return false;
      }
    }
  }

  navNotify(type, id) {
    if (type == 'SWITCH_SHIFT') {
      this.router.navigate(['/franchise/staff/time-off'], { queryParams: { from: 'switchShift' } });
      this.displayNotification = false;
      this.updateNotification(id);
    }
    if (type == 'TIME_OFF') {
      this.router.navigate(['/franchise/staff/time-off'], { queryParams: { from: 'timeOff' } });
      this.displayNotification = false;
      this.updateNotification(id);
    }
    if (type == 'CLIENT_TEMPLATE_UPDATE') {
      this.router.navigate(['franchise/client']);
      this.displayNotification = false;
      this.updateNotification(id);
    }
    if (type == 'MAILCHIMP_NEW_CAMPAIGN') {
      this.router.navigate(['franchise/marketing/campaign']);
      this.displayNotification = false;
      this.updateNotification(id);
    }
    if (type == 'FUTURE_BOOKING_REQUEST') {
      this.router.navigate(['/franchise/futureBookings']);
      this.displayNotification = false;
      this.updateNotification(id);
    }
    if (type == 'CLAIM_OPEN_SHIFT') {
      this.router.navigate(['/franchise/staff/schedule']);
      this.displayNotification = false;
      this.updateNotification(id);
    }
  }

  updateNotification(id) {
    this.userCommonService.updateNotification(id).subscribe((result) => {
      // this.notifications = result.data;
    });
    setTimeout(() => {
      this.getNotificationCount();
    }, 1000);
  }

  @ViewChild(MultiSelect) brandButton: MultiSelect;

  openMultiselect(e) {
    this.showBrandfilter = true;
    setTimeout(() => {
      this.brandButton.show();
      e.stopPropagation();
    }, 500);
  }

  hideBrandfilter() {
    if (screen.width < 480) {
      this.showBrandfilter = false;
    }
  }

  getUaserAccessAndUpdate() {
    const loginData = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA));
    const loggedInUserId = loginData.id;
    this.userCommonService.getStaffAccessPermissions(loggedInUserId).subscribe((result) => {
      this.createPermissionArray(result.accessGroupPermissionsDtoList);
      // if (this.commonService.sidebarItems) {
      //   for (let index = 0; index < this.commonService.sidebarItems.length; index++) {
      //     const element = this.commonService.sidebarItems[index];
      //     if (this.commonService.checkIfHavePermission(element.title, true)) {
      //       this.router.navigate(element.routerLink);
      //       break;
      //     }
      //   }
      // }
        this.router.navigate(['/franchise/events/list']);
        setTimeout(() => {
          window.location.reload();
        }, 500);
    });
  }

  createPermissionArray(data) {
    if (data) {
      // this.storageService.setItem('permissionArray', []);
      const accessArray = data.map(eachAccess => ({
        accessCode: eachAccess.accessGroupCode,
        readonly: eachAccess.read,
        readWrite: eachAccess.readWrite
      }));
      this.storageService.setItem('permissionArray', accessArray);

    }
  }



  ngOnDestroy(): void {
    if (this.isFranchise || this.isStaffManager) {
      this.notifyTimerSubscription.unsubscribe();
    }
    this.checkVersionTimerSubscription.unsubscribe();
    this.subscribedUnAuthorizedVariable.unsubscribe();
    if (this.checkTimeZoneTimerSubscription) {
      this.checkTimeZoneTimerSubscription.unsubscribe();
    }
  }
}
