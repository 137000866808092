<div class="file-upload-wrapper" *ngIf="disableBackground"></div>
<p-fileUpload
  [withCredentials]="cred"
  name="file[]"
  [url]="fileUploadUrl"
  auto="auto"
  (onUpload)="onUpload($event)"
  (onBeforeSend)="onBeforeSend($event)"
  (onError)="onUploadError($event)"
  ngDefaultControl
  [accept]="accept"
  [chooseLabel]="uploadButtonLabel"
  [maxFileSize]="maxFileSize"
  [invalidFileSizeMessageSummary]="maxSizeMessage"
  (onSelect)="validateFileSize($event)"
>
</p-fileUpload>

