import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-menu-item-details',
  templateUrl: './menu-item-details.component.html',
  styleUrls: ['./menu-item-details.component.scss']
})
export class MenuItemDetailsComponent implements OnInit {

  pageHeading = this.commonService.getLabel('Food Extra Items');
  breadCrumbArray = [];
  ID;
  loginData;

  itemDetails;
  allItems;

  allSelected = []
  files: TreeNode[];
  selectedNodes: TreeNode[];
  selectedNodesIdsArray = [];
  cols: any[];
  primaryTreeStructure = [];
  fromComponentData;
  fromComponentName;
  storedFoodExtraForMenu: any = [];
  isFormValid = true;
  breadcrumbHeading2: any;
  breadcrumbLink2: any;
  isUniqueError = false;
  isRequireError = false;
  accessCode = 'MANAGE_MENU';
  accessReadOnly = this.commonService.checkIfHavePermission('MANAGE_MENU', true);
  accessReadWrite = this.commonService.checkIfHavePermission('MANAGE_MENU', true, true);
  displayType: any;
  fromFBR;
  showBreadCrumb = true;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
    private franchiseService: FranchiseService,
    private commonService: CommonBindingDataService,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    this.fromFBR = this.route.snapshot.queryParams.fromComponent;

    this.checkFromComponent();
    this.createBreadCrumb();

    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'price', header: 'Price' },
      { field: 'sequence', header: 'Sequence' },
    ];
    this.ID = this.route.snapshot.queryParams.id;
    // this.files = <TreeNode[]>this.sampleData.data;
    // this.selectedNodes = <TreeNode[]>this.allSelected;
    this.getMenuItemDetails();
  }

  checkFromComponent() {
    this.fromComponentData = JSON.parse(this.storageService.getItem(AppSettings.MENU_ITEM_SELECTED));
    if (this.fromComponentData) {
      this.storageService.removeItem('fromAddCategory');
      this.storageService.removeItem('categoryId');
      this.fromComponentName = this.fromComponentData.from;
      if (this.fromComponentName && this.fromComponentName == 'template') {
        this.pageHeading = `Food Extra For  ${this.fromComponentData.menu.name}`;
      }
      if (this.fromComponentName && (this.fromComponentName == 'client')) {
        this.pageHeading = `Food Extra For  ${this.fromComponentData.menu.name}`;
        if (this.fromComponentData.clientId) {
          this.breadcrumbHeading2 = this.commonService.getLabel('label_edit_client');
        } else {
          this.breadcrumbHeading2 = this.commonService.getLabel('btn_add_client');
        }
      } if (this.fromComponentName && (this.fromComponentName == 'event')) {
        this.pageHeading = `Food Extra For  ${this.fromComponentData.menu.name}`;
        if (this.fromComponentData && this.fromComponentData.eventId) {
          this.breadcrumbHeading2 = this.commonService.getLabel('label_edit_event');
          this.breadcrumbLink2 = `franchise/events/edit-event?id=${this.fromComponentData.eventId}&tabIndex=${this.fromComponentData.tabIndex}`;

          // if (this.isCloneEvent && this.isCloneEvent === 'true') {
          // } else {
          //   this.breadcrumbHeading2 = this.commonService.getLabel('lbl_add_event');
          // }
        } else {
          this.breadcrumbHeading2 = this.commonService.getLabel('lbl_add_event');
          this.breadcrumbLink2 = `franchise/events/edit-event?id=&tabIndex=${this.fromComponentData.tabIndex}`;
        }
      }
      if (this.fromFBR && this.fromFBR === 'fbr') {
          this.showBreadCrumb = false;
        }
    }
  }

  checkDisable() {
    return !this.accessReadWrite
  }

  getMenuItemDetails() {
    this.franchiseService.getMenuItemDetails(this.ID).subscribe((result) => {
      // this.pageHeading = this.pageHeading+" for "+result.name;
      this.pageHeading = this.pageHeading;
      this.itemDetails = result;
      this.getAllMenuItem(this.itemDetails.brandId);
      // this.prepareGeneralInputObject();
    });
  }

  getAllMenuItem(brandId?) {
    let deletedFlag: any = false;
    if (this.fromComponentName && (this.fromComponentName == 'event') && this.fromComponentData && this.fromComponentData.eventId) {
      deletedFlag = 'all';
    }
    this.franchiseService.getFoodExtraItemsWithCategory(deletedFlag, brandId).subscribe((result) => {
      // console.log(result);
      this.allItems = result.data;
      this.prepareTreeStructure();
      this.findAlreadySelected();
    });
  }

  findAlreadySelected() {
    this.allSelected = [];
    const storedFoodExtraForMenu = JSON.parse(this.storageService.getItem(AppSettings.FOOD_EXTRA_FOR_MENU_ITEM_SELECTED));

    if (this.fromComponentName && this.fromComponentName == 'template' && this.fromComponentData.templateId) {
      this.checkForItemDetailsForFoodExtra();
    } else if (this.fromComponentName && this.fromComponentName == 'client') {
      this.checkForItemDetailsForFoodExtra();
    } else if (this.fromComponentName && this.fromComponentName == 'event') {
      this.checkForItemDetailsForFoodExtra();
    } else {
      if (storedFoodExtraForMenu) {
        this.checkForItemDetailsForFoodExtra();
      } else {
        this.itemDetails.foodCategoryItemMappingList.forEach(eachCat => {
          eachCat.foodItemsIds.forEach(eachMenu => {
            const menuFromMainTree = this.findRequiredNodeInTreeStructure(eachCat.foodExtraCategoryId, eachMenu);
            if (menuFromMainTree && menuFromMainTree.foodExtraItemName) {
              const menu = {
                // partialSelected: true,
                parent: eachCat.foodExtraCategoryId,
                key: 'previouslySelected',
                "data": {
                  id: eachMenu.id,
                  categoryId: eachCat.foodExtraCategoryId,
                  name: eachMenu.foodExtraItemName,
                  price: eachMenu.sellingPrice.toFixed(2),
                  sequence: eachMenu.sequence > 0 ? eachMenu.sequence : '',
                  signUrl: eachMenu.signUrl,
                  isUniqueSequence: true,
                }
              };
              this.allSelected.push(menu);
            }
          });
        });
      }
    }

    this.selectedNodes = <TreeNode[]>this.allSelected;
    // console.log('already selectedNodes', this.selectedNodes);
    this.prepareTreeStructure();
  }

  checkForItemDetailsForFoodExtra() {
    const storedFoodExtraForMenu = JSON.parse(this.storageService.getItem(AppSettings.FOOD_EXTRA_FOR_MENU_ITEM_SELECTED));
    if (storedFoodExtraForMenu) {
      const existItemIndex = storedFoodExtraForMenu.findIndex(e =>
      (e.templateId == this.fromComponentData.templateId
        && e.categoryId == this.fromComponentData.menu.categoryId
        && e.itemId == this.fromComponentData.menu.id));
      let requiredFoodExtras = [];
      if (existItemIndex > -1) {
        requiredFoodExtras = storedFoodExtraForMenu[existItemIndex].foodExtra;
      }

      requiredFoodExtras.forEach(eachCat => {
        eachCat.foodItemsIds.forEach(eachMenu => {
          const menuFromMainTree = this.findRequiredNodeInTreeStructure(eachCat.foodExtraCategoryId, eachMenu);
          const menu = {
            // partialSelected: true,
            parent: eachCat.foodExtraCategoryId,
            key: 'previouslySelected',
            "data": {
              id: eachMenu.id,
              categoryId: eachCat.foodExtraCategoryId,
              name: menuFromMainTree ? menuFromMainTree.foodExtraItemName : eachMenu.foodExtraItemName,
              price: eachMenu.sellingPrice.toFixed(2),
              sequence: eachMenu.sequence,
              signUrl: menuFromMainTree ? menuFromMainTree.signUrl : eachMenu.signUrl,
              isUniqueSequence: true,
            }
          };

          if (menuFromMainTree) {
            this.allSelected.push(menu);
          }
        });
      });
      // console.log('selected nodes', this.allSelected);
    }
  }

  findRequiredNodeInTreeStructure(extraCatId, extraItem) {
    const cat = this.allItems.find(eachCat => eachCat.foodExtraCategoryId == extraCatId);
    if (cat) {
      const item = cat.foodExtraItemList.find(eachMenu => eachMenu.id == extraItem.id);
      if (item) {
        return item;
      }
    } else {
      return {};
    }
  }

  prepareTreeStructure() {
    this.primaryTreeStructure = [];
    const primeryTree = [];
    this.allItems = this.commonService.sortItems(this.allItems, 'foodExtraCategoryName');
    this.allItems.forEach(eachCat => {
      // console.log('eachCat', eachCat);
      // console.log('all Selected', this.allSelected);

      const isCatSelected = this.allSelected.find(menu => (menu.data.categoryId === eachCat.foodExtraCategoryId));
      if (eachCat.foodExtraCategoryDeleted && !isCatSelected && this.fromComponentName == 'event'
        && this.fromComponentData && this.fromComponentData.eventId) {
        return;
      }
      let firstLevel;
      firstLevel = {
        // partialSelected: true,
        data: {
          isCategory: true,
          id: eachCat.foodExtraCategoryId,
          name: eachCat.foodExtraCategoryName,
          price: '--',
          sequence: '',
          signUrl: '',
        },
        children: []
      }
      if (eachCat.foodExtraItemList.length > 0) {
        const temp = eachCat.foodExtraCategoryId;
        // eachCat.foodExtraItemList = this.commonService.sortItems(eachCat.foodExtraItemList,'foodExtraItemName');
        eachCat.foodExtraItemList = this.commonService.sortItems(eachCat.foodExtraItemList, 'foodExtraItemName');
        eachCat.foodExtraItemList.forEach(eachFoodExtra => {
          let node = { data: {} };
          node = this.generateTreeNode(eachFoodExtra, temp);
          // console.log('node22', node);
          // node.data['categoryId'] = temp1;
          const isMenuSelected = this.allSelected.find(menu => (menu.data.id === eachFoodExtra.id &&
            menu.data.categoryId === eachCat.foodExtraCategoryId));
          if (!eachFoodExtra.foodExtraItemDeleted) {
            firstLevel['children'].push(node);
          } else if (eachFoodExtra.foodExtraItemDeleted && isMenuSelected &&
            this.fromComponentName && this.fromComponentName == 'event'
            && this.fromComponentData && this.fromComponentData.eventId) {
            firstLevel['children'].push(node);
          }
          // console.log('categoryId', eachCat.foodExtraCategoryId);
          // firstLevel.children = this.commonService.sortItems(firstLevel.children.data,'name');
        });
      }
      primeryTree.push(firstLevel);
      // console.log('primaryTree 23', primeryTree);
    });
    // console.log('primaryTreeStructure',this.primaryTreeStructure);
    this.prepareFinalTreeStructure(primeryTree);
  }

  generateTreeNode(eachItem, eachCatId) {
    
    const node = {
      data: {
        id: eachItem.id,
        categoryId: eachCatId,
        name: eachItem.foodExtraItemName,
        price: eachItem.sellingPrice,
        sequence: eachItem.sequence ? eachItem.sequence : 0,
        signUrl: eachItem.signUrl,
        isUniqueSequence: true,
      }
    }
    // console.log('node11', node);
    return node;
  }

  prepareFinalTreeStructure(primeryTree) {
    const tree = this.primaryTreeStructure;
    // console.log('2 primaryTreeStructure 2',this.primaryTreeStructure);
    // this.primaryTreeStructure = [];
    primeryTree.forEach(eachCat => {
      let selectedMenuCount = 0
      eachCat.children.forEach(eachMenu => {
        const isMenuSelected = this.allSelected.find(menu => (menu.data.id === eachMenu.data.id &&
          menu.data.categoryId === eachMenu.data.categoryId));
        if (isMenuSelected) {
          const chnagedData = {
            id: isMenuSelected.data.id,
            categoryId: isMenuSelected.data.categoryId,
            name: isMenuSelected.data.name,
            price: isMenuSelected.data.price,
            sequence: isMenuSelected.data.sequence,
            signUrl: isMenuSelected.data.signUrl,
            isUniqueSequence: true,
          }
          eachMenu.data = chnagedData;
          selectedMenuCount++;
        }
      });
      if (selectedMenuCount > 0 && eachCat.children.length > 0) {
        if (selectedMenuCount < eachCat.children.length) {
          eachCat.partialSelected = true;
        }
        if (selectedMenuCount >= eachCat.children.length) {
          const tempObj = { ...eachCat.data };
          const tempNode = { data: tempObj };
          this.allSelected.push(tempNode);
        }
      }
    });
    this.primaryTreeStructure = [...primeryTree];
    // console.log('updated primaryTreeStructure before', this.primaryTreeStructure);
    this.files = <TreeNode[]>this.primaryTreeStructure;
  }

  nodeSelect(event) {
    // console.log('node selected', event)
    // console.log('selected nodes', this.selectedNodes)
    this.checkIfCategoryForSequence(event);
    this.checkInNodesTree(event, '');
    this.valueChanged(event.node.data);

    // const existIndex = this.selectedNodes.findIndex(eachNode => eachNode.data.categoryId == event.node.data.categoryId &&
    //   eachNode.data.id == event.node.data.id);
    // if (existIndex > -1) {
    //   this.selectedNodes[existIndex].data.sequence = '';
    // }
    // this.isFormValid = false;
    // console.log('selectedNodes', this.selectedNodes);
    this.findSelectedItems();
  }

  nodeUnselect(event) {
    // console.log('name unSelected', event)
    // console.log('selected nodes11', this.selectedNodes)

    this.checkInNodesTree(event, 0);
    this.valueChanged(event.node.data);
    this.findSelectedItems();

    // const existIndex = this.selectedNodes.findIndex(eachNode => eachNode.data.categoryId == event.node.data.categoryId &&
    //   eachNode.data.id == event.node.data.id);
    // if (existIndex > -1) {
    //   this.selectedNodes[existIndex].data.sequence = '';
    //   this.isFormValid = false;
    // }
  }

  findSelectedItems() {
    const selected = [];
    this.selectedNodes.forEach(eachElement => {
      if (!eachElement.children) {
        selected.push(eachElement.data.id);
      }
    });
    this.selectedNodesIdsArray = selected;
    // console.log(selected);
    return selected;
  }

  checkIfCategoryForSequence(event) {
    if (event.node.data.isCategory) {
      let sequenceCount = 1;
      event.node.children.forEach(element => {
        element.data.sequence = element.data.sequence ? element.data.sequence : sequenceCount;
        sequenceCount++;
      });
    }
  }

  checkInNodesTree(event, sequence) {
    this.primaryTreeStructure.forEach(eachCat => {
      const selectedMenuCount = 0
      eachCat.children.forEach(eachMenu => {
        if (eachMenu.data.id === event.node.data.id &&
          eachMenu.data.categoryId === event.node.data.categoryId) {
          eachMenu.data.sequence = eachMenu.data.sequence > 0 ? +eachMenu.data.sequence : sequence;
          eachMenu.data['isUniqueSequence'] = (sequence == 0) ? true : eachMenu.data['isUniqueSequence'];
        }
        if (event.node.data.isCategory && eachMenu.data.categoryId === event.node.data.id) {
          eachMenu.data.sequence = eachMenu.data.sequence ? +eachMenu.data.sequence : sequence;
          eachMenu.data['isUniqueSequence'] = (sequence == 0) ? true : eachMenu.data['isUniqueSequence'];
        }
      });

    });
    // this.primaryTreeStructure = [...primeryTree];
    // console.log('updated primaryTreeStructure after', this.primaryTreeStructure);
    this.files = <TreeNode[]>this.primaryTreeStructure;
    this.checkSelectedSequenceValid()
  }

  checkSelectedSequenceValid() {
    this.isUniqueError = false;
    this.isRequireError = false;
    this.selectedNodes.forEach(element => {
      if (element.data && !element.data.isCategory && !element.data.isUniqueSequence) {
        this.isUniqueError = true;
      }
      if (element.parent && element.data && !element.data.isCategory) {
        if (element.data.sequence <= 0 || element.data.sequence == '' || element.data.sequence == null) {
          element.data.sequence == ''
          this.isRequireError = true;
        }
      }
    });
    // if (this.isUniqueError || this.isRequireError) {
    //   this.isMenuValid = false;
    // } else {
    //   this.isMenuValid = true;
    // }
    // console.log('isExist', isExist);
  }

  valueChanged(changedNode?) {
    // console.log('changedNode', changedNode)

    // const isExist = this.selectedNodes.filter(eachNode => eachNode.data.categoryId && eachNode.data.categoryId == changedNode.categoryId &&
    //   eachNode.data.id !== changedNode.id && eachNode.data.sequence == changedNode.sequence);
    // if (isExist.length > 0) {
    //   changedNode['isUniqueSequence'] = false;
    // } else {
    //   changedNode['isUniqueSequence'] = true;
    // }

    // console.log('files', this.files);
    const nodeIndexInSelected = this.selectedNodes.findIndex(eachNode => eachNode.data.categoryId && eachNode.data.categoryId == changedNode.categoryId &&
      eachNode.data.id == changedNode.id);
    if (nodeIndexInSelected > -1) {
      this.selectedNodes[nodeIndexInSelected].data.sequence = +changedNode.sequence > 0 ? changedNode.sequence : '';
      this.selectedNodes[nodeIndexInSelected].data.price = changedNode.price;
    }

    const allSelected = [...this.selectedNodes];
    const catId = changedNode.isCategory ? changedNode.id : changedNode.categoryId;
    const nodesInCategory = allSelected.filter(eachNode => eachNode.data.categoryId && eachNode.data.categoryId == catId);
    nodesInCategory.forEach(eachNodeInCat => {
      const isExist = nodesInCategory.find(eachNode => eachNode.data.categoryId == eachNodeInCat.data.categoryId &&
        eachNode.data.id !== eachNodeInCat.data.id && eachNode.data.sequence == eachNodeInCat.data.sequence &&
        eachNode.data.sequence > 0);
      if (isExist) {
        eachNodeInCat.data['isUniqueSequence'] = false;
        this.checkInNodesTreeForFlag(eachNodeInCat, false);
      } else {
        eachNodeInCat.data['isUniqueSequence'] = true;
        this.checkInNodesTreeForFlag(eachNodeInCat, true);
      }
    });

    this.selectedNodes = [...allSelected];

    // if(changedNode.sequence == '' || changedNode.sequence == null){
    //   this.isFormValid = false;
    // }
    this.checkSelectedSequenceValid();
    // console.log('selected nodes', this.selectedNodes);
    // console.log('updated files', this.files)
  }

  checkInNodesTreeForFlag(node, uniqueFlag) {
    // console.log('node:', node, uniqueFlag)
    this.primaryTreeStructure.forEach(eachCat => {
      const selectedMenuCount = 0
      eachCat.children.forEach(eachMenu => {
        if (eachMenu.data.id === node.data.id &&
          eachMenu.data.categoryId === node.data.categoryId) {
          eachMenu.data['sequence'] = node.data.sequence > 0 ? node.data.sequence : '';
          eachMenu.data['isUniqueSequence'] = uniqueFlag;
        }
      });
    });
    this.files = <TreeNode[]>this.primaryTreeStructure;
    this.checkSelectedSequenceValid()
  }

  checkUniqueInCategory(changedNode) {
    if (changedNode.data.categoryId) {
      const allItemsInCategory = this.selectedNodes.filter(eachNode => eachNode.data.categoryId && eachNode.data.categoryId == changedNode.categoryId &&
        eachNode.data.id !== changedNode.id && eachNode.data.sequence == changedNode.sequence);
    }
  }

  onSave() {
    // console.log('isFormValid ',this.isFormValid);
    // console.log('selectedNodes ', this.selectedNodes);
    // return;
    this.checkSelectedSequenceValid();
    if (this.isFormValid && !this.isUniqueError && !this.isRequireError) {
      const treeStructure = []
      this.selectedNodes.forEach(eachSelected => {
        let menuInDataSet;
        if ((!eachSelected.children) || (eachSelected.key == 'previouslySelected')) { // && eachSelected.parent
          const tempSelected = eachSelected;
          this.files.forEach(eachCat => {
            if (eachCat.data.id == tempSelected.data.categoryId || eachCat.data.id == tempSelected.parent) {
              menuInDataSet = eachCat.children.find(eachChild => eachChild.data.id == tempSelected.data.id);
            }
          });
          if (menuInDataSet) {
            const parentId = (eachSelected.key == 'previouslySelected') ?
              eachSelected.parent : (eachSelected.parent ? eachSelected.parent.data.id : eachSelected.data.categoryId);
            const parentExistIndex = treeStructure.findIndex(eachParent => eachParent.foodExtraCategoryId == parentId);
            if (parentExistIndex > -1) {
              const childObject = {
                id: menuInDataSet.data.id,
                sellingPrice: +menuInDataSet.data.price,
                sequence: +menuInDataSet.data.sequence
              }
              treeStructure[parentExistIndex].foodItemsIds.push(childObject);
            } else {
              const completeObject = {
                foodExtraCategoryId: parentId,
                foodItemsIds: [
                  {
                    id: menuInDataSet.data.id,
                    sellingPrice: +menuInDataSet.data.price,
                    sequence: +menuInDataSet.data.sequence
                  },
                ]
              }
              treeStructure.push(completeObject);
            }
          }
        }
      })
      // console.log('required tree', treeStructure);

      const requestBody = this.prepareGeneralInputObject();
      requestBody.foodCategoryItemMappingList = treeStructure;
      if (this.fromComponentName && this.fromComponentName == 'template') {
        this.saveForTemplate(treeStructure);
        this.onCancel();
      } else if (this.fromComponentName && (this.fromComponentName == 'client' || this.fromComponentName == 'event')) {
        this.saveForTemplate(treeStructure);
        this.onCancel();
      } else {
        this.saveForMenu(requestBody);
      }
    } else {
      if (this.isRequireError) {
        this.uiService.showMessage(this.commonService.getLabel('error_food_extra_sequence_required'), AppSettings.MESSAGE_TYPE.ERROR);
        return;
      }
      if (this.isUniqueError) {
        this.uiService.showMessage(this.commonService.getLabel('error_food_extra_sequence_unique'), AppSettings.MESSAGE_TYPE.ERROR);
        return;
      }
      this.uiService.showMessage(this.commonService.getLabel('error_invalid_food_extra'), AppSettings.MESSAGE_TYPE.ERROR);
    }
  }



  prepareGeneralInputObject() {
    const object = {
      id: this.itemDetails.id,
      name: this.itemDetails.name,
      description: this.itemDetails.description,
      price: this.itemDetails.price.toFixed(2),
      imageFileId: this.itemDetails.imageFileId,
      foodCategoryItemMappingList: []
    }
    return object;
  }

  saveForTemplate(treeStructure) {
    this.storedFoodExtraForMenu = [];
    this.storedFoodExtraForMenu = JSON.parse(this.storageService.getItem(AppSettings.FOOD_EXTRA_FOR_MENU_ITEM_SELECTED));
    const foodExtraForMenuItem = {
      templateId: this.fromComponentData.templateId,
      categoryId: this.fromComponentData.menu.categoryId,
      itemId: this.fromComponentData.menu.id,
      foodExtra: [...treeStructure]
    }
    console.log(
      'treeStructure', treeStructure, 'foodExtraForMenuItem', foodExtraForMenuItem, 'storedFoodExtraForMenu', this.storedFoodExtraForMenu)
    if (this.storedFoodExtraForMenu) {
      const existItemIndex = this.storedFoodExtraForMenu.findIndex(e =>
      (e.templateId == foodExtraForMenuItem.templateId
        && e.categoryId == foodExtraForMenuItem.categoryId
        && e.itemId == foodExtraForMenuItem.itemId));
      if (existItemIndex > -1) {
        this.storedFoodExtraForMenu[existItemIndex] = { ...foodExtraForMenuItem };
      } else {
        this.storedFoodExtraForMenu.push(foodExtraForMenuItem);
      }
    } else {
      const tempArr = [{ ...foodExtraForMenuItem }];
      this.storedFoodExtraForMenu = [...tempArr];
    }
    this.storageService.setItem(AppSettings.FOOD_EXTRA_FOR_MENU_ITEM_SELECTED, this.storedFoodExtraForMenu);
  }

  saveForMenu(requestBody) {
    this.confirmationService.confirm({
      message: this.commonService.getLabel('msg_edit_menu'),
      accept: () => {
        this.franchiseService.updateMenu(requestBody).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          if (this.loginData) {
            const data = JSON.parse(this.loginData);
            if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
              this.router.navigate(['franchise/menu-section/menu-items'], { queryParams: { fromCancel: 'fromCancel' } });
            } else if (data.roles[0].roleCode === AppSettings.USER_ROLE_ADMIN) {
              this.router.navigate(['admin/menu-section/menu-items'], { queryParams: { fromCancel: 'fromCancel' } });
            } else {
              this.router.navigate(['franchise/menu-section/menu-items'], { queryParams: { fromCancel: 'fromCancel' } });
            }
          }

        }, error => {
        }
        );
      }
    });
  }

  onCancel() {
    if (this.loginData) {
      const data = JSON.parse(this.loginData);
      if (this.fromComponentName && this.fromComponentName == 'template') {
        this.storageService.removeItem(AppSettings.MENU_ITEM_SELECTED);
        if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
          this.router.navigate(['franchise/menu-section/templatelist/add-templatelist'], { queryParams: { id: this.fromComponentData.templateId } });
        } else {
          this.router.navigate(['admin/menu-section/templatelist/add-templatelist'], { queryParams: { id: this.fromComponentData.templateId } });
        }
        return;
      } else if (this.fromComponentName && this.fromComponentName == 'client') {
        this.storageService.removeItem(AppSettings.MENU_ITEM_SELECTED);
        if (this.fromFBR && this.fromFBR === 'fbr') {
          window.history.back();
        } else {
          this.router.navigate(['franchise/client/edit-client'], {
            queryParams: {
              id: this.fromComponentData.clientId, tabIndex: this.fromComponentData.tabIndex,
              fromCancel: 'fromCancel'
            }
          });
        }
        return;
      } else if (this.fromComponentName && this.fromComponentName == 'event') {
        this.storageService.removeItem(AppSettings.MENU_ITEM_SELECTED);
        if (this.fromFBR && this.fromFBR === 'fbr') {
          this.router.navigate(['franchise/events/add-event'], {
            queryParams: {
              id: this.fromComponentData.eventId, tabIndex: this.fromComponentData.tabIndex,
              eventType: this.fromComponentData.eventType, isClone: this.fromComponentData.isClone,
              fromCancel: 'fromCancel',fromComponent: 'fbr'
            }
          });
        } else {
          this.router.navigate(['franchise/events/edit-event'], {
            queryParams: {
              id: this.fromComponentData.eventId, tabIndex: this.fromComponentData.tabIndex,
              eventType: this.fromComponentData.eventType, isClone: this.fromComponentData.isClone,
              fromCancel: 'fromCancel'
            }
          });
        }
        return;
      } else if (this.fromComponentName && this.fromComponentName == 'menu') {
        this.storageService.removeItem(AppSettings.MENU_ITEM_SELECTED);
        if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
          this.router.navigate(['franchise/menu-section/menu-items'], { queryParams: { fromCancel: 'fromCancel' } });
        } else {
          this.router.navigate(['admin/menu-section/menu-items'], { queryParams: { fromCancel: 'fromCancel' } });
        }

        return;
      } else {
        if (JSON.parse(this.storageService.getItem('fromAddCategory'))) {
          if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
            this.router.navigate(['franchise/menu-section/menu-categories/add-menu-category']);
          } else {
            this.router.navigate(['admin/menu-section/menu-categories/add-menu-category']);
          }
        } else {
          if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
            this.router.navigate(['franchise/menu-section/menu-categories/edit-menu-category'], { queryParams: { id: JSON.parse(this.storageService.getItem('categoryId')) } });
          } else {
            this.router.navigate(['admin/menu-section/menu-categories/edit-menu-category'], { queryParams: { id: JSON.parse(this.storageService.getItem('categoryId')) } });
          }
        }
      }
    }
  }

  createBreadCrumb() {
    const data = JSON.parse(this.loginData);
    if (this.fromComponentName && this.fromComponentName == 'template') {
      this.accessCode = 'MANAGE_MENU';
      this.breadCrumbArray = [
        {
          label: this.commonService.getLabel('Franchise Template'),
          route: data?.roles[0]?.roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager'] ? 'franchise/menu-section/templatelist/' : 'admin/menu-section/templatelist/',
          queryParams: { fromCancel: 'fromCancel' }
        },
        {
          label: this.fromComponentData.templateId ? 'Edit Template' : 'Add Template',
          route: data?.roles[0]?.roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager'] ? `franchise/menu-section/templatelist/add-templatelist` : `admin/menu-section/templatelist/add-templatelist`,
          // queryParams: this.fromComponentData.templateId?this.fromComponentData.templateId:'',
          queryParams: { id: this.fromComponentData.templateId },
          useSameQueryParams: true,
        },
        {
          label: 'Manage Food Extra',
          route: '',
        }
      ];
    } else if (this.fromComponentName && this.fromComponentName == 'event') {
      this.accessCode = 'MANAGE_EVENT';
      let breadcrumbLink2 = `franchise/events/add-event`;
      this.displayType = this.fromComponentData.eventType;
      let queryParams = { id: '', tabIndex: this.fromComponentData.tabIndex, eventType: this.displayType, fromComponent: '' };
      if (this.fromComponentData && this.fromComponentData.eventId) {
        // queryParams = { id: this.fromComponentData.eventId, tabIndex: this.fromComponentData.tabIndex }
        queryParams = {
          id: this.fromComponentData.eventId, tabIndex: this.fromComponentData.tabIndex,
          eventType: this.fromComponentData.eventType, fromComponent: ''
        }
        this.breadcrumbHeading2 = this.commonService.getLabel('label_edit_event');
        breadcrumbLink2 = `franchise/events/edit-event`;
      }
      
      let startLabel;
      let startRoute;
      if (this.fromFBR && this.fromFBR === 'fbr') {
        startLabel = this.commonService.getLabel('label_FBR');
        startRoute = 'franchise/futureBookings';
        queryParams = { id: '', tabIndex: this.fromComponentData.tabIndex, eventType: this.displayType, fromComponent: 'fbr' };

      } else {
        startLabel = this.commonService.getLabel('label_manage_events');
        startRoute = 'franchise/events';
      }
      this.breadCrumbArray = [
        {
          label: startLabel,
          route: startRoute,
          queryParams: { fromCancel: 'fromCancel' },
          displayType: this.displayType
        },
        {
          label: this.breadcrumbHeading2,
          route: breadcrumbLink2,
          queryParams: queryParams,
          useSameQueryParams: true,
        },
        {
          label: this.pageHeading,
          route: '',
        }
      ];
    } else if (this.fromComponentName && this.fromComponentName == 'client') {
      this.accessCode = 'MANAGE_CLIENT';
      let breadcrumbLink2 = `franchise/client/add-client`;
      let queryParams = { id: '', tabIndex: 1, editClient: false };
      if (this.fromComponentData && this.fromComponentData.clientId) {
        queryParams = { id: this.fromComponentData.clientId, tabIndex: this.fromComponentData.tabIndex, editClient: true }
        this.breadcrumbHeading2 = this.commonService.getLabel('label_edit_client');
        breadcrumbLink2 = `franchise/client/edit-client`;
      }
      let firstLink = {
        label: this.commonService.getLabel('label_manage_client'),
        route: 'franchise/client',
        queryParams: { fromCancel: 'fromCancel' }
      }
      if (this.fromFBR && this.fromFBR === 'fbr') {
        firstLink = {
          label: this.commonService.getLabel('label_FBR'),
          route: 'franchise/futureBookings',
          queryParams: { fromCancel: 'fromCancel' }
        }
      }
      this.breadCrumbArray = [
        firstLink,
        {
          label: this.breadcrumbHeading2,
          route: breadcrumbLink2,
          queryParams: queryParams,
        },
        {
          label: this.pageHeading,
          route: '',
        }
      ];
    } else {
      this.accessCode = 'MANAGE_MENU';
      this.breadCrumbArray = [
        {
          label: this.commonService.getLabel('label_menu_items'),
          route: data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager'] ? 'franchise/menu-section/menu-items' : 'admin/menu-section/menu-items',
          queryParams: { fromCancel: 'fromCancel' }
        },
        {
          label: this.pageHeading,
          route: '',
        }
      ];
    }
  }

  checkAccessReadWrite() {
    return this.commonService.checkIfHavePermission(this.accessCode, true, true);
  }

}
