import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { ErrorBindingService } from '../../services/error-binding.service';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';
import { AdminService } from '../../../admin/services/admin.service';

@Component({
  selector: 'app-menu-editable-food-extra-category-list',
  templateUrl: './menu-editable-food-extra-category-list.component.html',
  styleUrls: ['./menu-editable-food-extra-category-list.component.scss']
})
export class MenuEditableFoodExtraCategoryListComponent implements OnInit {

  eventDetails;
  items: any = [];
  eventForm: UntypedFormGroup;
  categoryId;
  isClone;
  showAdditionalPaymentLabel = false;
  pageHeading = this.commonService.getLabel('label_food_extra_add_category');
  countryCodes = AppSettings.COUNTRY_CODE;
  generatedCampaignLink;

  fromDate = null;
  toDate = null;
  minDate: Date = new Date();
  maxDate: Date;
  firstStartDate = new Date();
  stateListOptions: any = [];
  countryListOptions = AppSettings.COUNTRY_LIST;
  deliveryOptions = AppSettings.DELIVERY_OPTIONS;

  yesNoOptions = AppSettings.YES_NO_OPTIONS;
  commentsOptions = AppSettings.CAMPAIGN_COMMENTS_OPTIONS;
  defaultImage = AppSettings.DEFAULT_NO_IMAGE;
  imageUrl;
  uploadedImgFileId;
  chooseLabel;
  UploadFileUrl = AppSettings.BASE_URL + '/secure/files?type=campaign';
  loginData;
  selectionType = 'optional';

  place = 'dsds';
  eventErrors = {
    campaignName: {
      hasError: false,
      error: []
    }
  };

  topDonorsNumberRequired = false;
  topCommentsNumberRequired = false;
  submitted = false;
  quantityRequired = false;
  validMinimumQuantity = true;
  validMaximumQuantity = true;
  breadCrumbArray = [];
  selectionalOptional = false;

  list1: any[] = [];
  list2: any[] = [];
  brandNameOptions = [];
  loggedInUserId;
  details;
  loginUserRole;
  accessReadOnly = this.commonService.checkIfHavePermission('MANAGE_MENU', true);
  accessReadWrite = this.commonService.checkIfHavePermission('MANAGE_MENU', true, true);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private errorBindingService: ErrorBindingService,
    private franchiseService: FranchiseService,
    private commonService: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
    private storageService: StorageService,
    private adminService: AdminService,
  ) {
    this.loggedInUserId = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA))?.franchiseId;
  }

  ngOnInit(): void {
    this.loginUserRole = this.commonService.getLoginRole();
    this.imageUrl = this.defaultImage;
    this.chooseLabel = this.commonService.getLabel('label_upload_image');

    this.categoryId = this.route.snapshot.queryParams.id;
    this.isClone = this.route.snapshot.queryParams.isClone;

    this.initForm();
    this.getBrandList(this.loggedInUserId);
    if (this.categoryId) {
      this.pageHeading = this.commonService.getLabel('label_food_extra_edit_category');
      this.getDetails();
    }

    // this.list1 = [{brand: 'abc'},{brand: 'def'}, {brand: 'ghi'}];
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    this.createBreadCrumb();
  }

  getBrandList(id) {
    if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE || this.loginUserRole == AppSettings.STAFF_ROLES['Manager']) {
      const isNew = this.categoryId?false:true;
      this.franchiseService.getBrandList(id, isNew).subscribe((result) => {
        this.brandNameOptions = result.map(element => ({
          label: element.brandName,
          value: element.brandId,
        }));  
      });
      setTimeout(() => {
        this.getSelectedBrand();
      }, 900);
    } else {
      this.adminService.getAllBrandsList().subscribe((result) => {
        this.brandNameOptions = result.map(element => ({ label: element.brandName,
           value: element.id })); 
      });
      setTimeout(() => {
        this.getSelectedBrand();
      }, 800);
    }
  }

  getSelectedBrand(details?) {
    if (details) {
      this.eventForm.controls.brandId.setValue(details?.brandId);
      this.fetchFoodExtraList(this.eventDetails?.type ? this.eventDetails.type: 'optional');
    } else {
      const selectedBrand = this.eventForm.controls.brandId.value ? this.eventForm.controls.brandId.value :
        this.brandNameOptions[0].value;
      this.fetchFoodExtraList(this.eventDetails?.type ? this.eventDetails?.type: 'optional');
    }
  }

  onChangeBrand() {
    const selectedBrand = this.eventForm.controls.brandId.value ? this.eventForm.controls.brandId.value : '';
    this.fetchFoodExtraList(this.selectionType);
  }

  fetchFoodExtraList(type?) {
    const brandId = this.eventForm.controls.brandId.value ? this.eventForm.controls.brandId.value : '';
    this.franchiseService.getAllFoodExtraListWithBrand(brandId, type).subscribe((result) => {
      const sourceList = result.data.map(eachFoodExtra => ({
        id: eachFoodExtra.id,
        activated: eachFoodExtra.activated,
        selection: eachFoodExtra.selection,
        signUrl: eachFoodExtra.signUrl ? eachFoodExtra.signUrl : AppSettings.DEFAULT_NO_IMAGE,
        itemName: eachFoodExtra.itemName,
        sellingPrice: eachFoodExtra.sellingPrice,
        minQtyAllowed: eachFoodExtra.minQtyAllowed,
        maxQtyAllowed: eachFoodExtra.maxQtyAllowed,
      }))
      // console.log(sourceList, this.list1)
      this.list1 = [...sourceList];
      this.list2 = [];
    });
  }

  getDetails() {
    this.franchiseService.getFoodExtraCatDetails(this.categoryId).subscribe((result) => {
      this.eventDetails = result;
      this.initForm();
      this.getSelectedBrand(this.eventDetails);
      this.eventForm.controls.brandId.disable();
    });
  }

  initForm() {
    this.eventForm = this.formBuilder.group({
      categoryName: [{value: this.eventDetails ? this.eventDetails.categoryName : '', disabled: !this.accessReadWrite},
      [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      brandId: [this.eventDetails ? this.eventDetails.categoryName : ''],
      categoryType: [{value: this.eventDetails ? this.eventDetails.type : '', disabled: !this.accessReadWrite},
       [Validators.required]],
      minQuantity: [this.eventDetails ? this.eventDetails.minQtyAllowed : '',
      [Validators.required, Validators.maxLength(50)]],
      maxQuantity: [this.eventDetails ? this.eventDetails.maxQtyAllowed : '',
      [Validators.required, Validators.maxLength(10)]],
      foodExtraItemIds: [this.eventDetails ? this.eventDetails.foodExtraItemIds : []],
    });
    setTimeout(() => {
      if (this.eventDetails && this.eventDetails.type) {
        this.onClickOptions(this.eventDetails.type);
        setTimeout(() => {
          this.setSelectedFoodItems();
        }, 1000);
      }
    }, 100);
  }

  setSelectedFoodItems() {
    if (this.eventDetails && this.eventDetails?.foodExtraItemIds !==null && this.eventDetails?.foodExtraItemIds.length > 0) {
      // this.list2 = [];
      if (this.list2.length < 1) {
        const selectedItemArray = this.eventDetails.foodExtraItemIds;
        const selectedList = this.list1.filter(eachExtra => (selectedItemArray.includes(eachExtra.id)));
        this.list2 = [...selectedList];

        this.list2.forEach(element => {

        });
        const unSelectedList = this.list1.filter(eachExtra => (!selectedItemArray.includes(eachExtra.id)));
        this.list1 = [...unSelectedList];
        // console.log(this.list1, 'list2', this.list2)
      }
    }
  }

  onSave() {
    this.submitted = true;
    if (this.eventForm.valid && this.validMinimumQuantity && this.validMaximumQuantity) {
      const eventModel = this.getFormData();
      // console.log(eventModel)
      // return false;
      if (this.categoryId) {
        this.confirmationService.confirm({
          message: this.commonService.getLabel('msg_edit_food_extra_cat'),
          accept: () => {
            this.franchiseService.updateFoodExtraCategory(eventModel).subscribe((result) => {
              this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
              if (this.loginData) {
                const loginData = JSON.parse(this.loginData);
                if (loginData.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || loginData.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
                  this.router.navigate(['franchise/menu-section/food-extras/'], { queryParams: { fromCancel: 'fromCancel' } });
                } else {
                  this.router.navigate(['admin/menu-section/food-extras/'], { queryParams: { fromCancel: 'fromCancel' } });
                }
              }
            }, (error) => {
              console.log(error);
            });
          }
        });
      } else {
        this.confirmationService.confirm({
          message: this.commonService.getLabel('msg_add_food_extra_cat'),
          accept: () => {
            this.franchiseService.addFoodExtraCategory(eventModel).subscribe((result) => {
              this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
              if (this.loginData) {
                const loginData = JSON.parse(this.loginData);
                if (loginData.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || loginData.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager'] ) {
                  this.router.navigate(['franchise/menu-section/food-extras']);
                } else {
                  this.router.navigate(['admin/menu-section/food-extras']);
                }
              }
            }, (error) => {
              console.log(error);
            });
          }
        });
      }
    } else {
      // this.errorBindingService.getFormValidationErrors(this.eventForm);
    }
  }

  getFormData() {
    const formControl = this.eventForm.getRawValue();
    const editableEventModel: any = {
      'categoryName': formControl.categoryName.trim(),
      'type': formControl.categoryType,
      'minQtyAllowed': formControl.minQuantity ? formControl.minQuantity : '',
      'maxQtyAllowed': formControl.maxQuantity ? formControl.maxQuantity : '',
      foodExtraItemIds: this.list2.map(e => e.id),
      'brandId': formControl.brandId.trim(),
    };
    if (this.eventDetails && this.eventDetails.id) {
      editableEventModel.id = this.eventDetails.id;
    }
    // console.log(editableEventModel);
    // return;
    return editableEventModel;
  }

  onClickOptions(type = 'optional') {
    this.selectionType = type;
    if (type === 'optional') {
      this.eventForm.controls.minQuantity.setValue('');
      this.eventForm.controls.maxQuantity.setValue('');
      this.eventForm.controls.minQuantity.setValidators(null);
      this.eventForm.controls.maxQuantity.setValidators(null);
      this.eventForm.controls.minQuantity.disable();
      this.eventForm.controls.maxQuantity.disable();
      this.quantityRequired = false;
      this.selectionalOptional = true;
    } else {
      this.eventForm.controls.minQuantity.enable();
      this.eventForm.controls.maxQuantity.enable();
      this.eventForm.controls.minQuantity.setValidators([Validators.required]);
      this.eventForm.controls.maxQuantity.setValidators([Validators.required]);
      this.quantityRequired = true;
      this.selectionalOptional = false;
    }
    this.eventForm.controls.minQuantity.updateValueAndValidity();
    this.eventForm.controls.maxQuantity.updateValueAndValidity();
    this.list1 = [];
    this.list2 = [];
    this.fetchFoodExtraList(type);
  }

  checkMinMaxValueValid(fromInput:string) {
    // if ((Number(this.eventForm.controls.maxQuantity.value) && Number(this.eventForm.controls.minQuantity.value))) {
    //   if (fromInput === 'minQuantity') {
    //     if (Number(this.eventForm.controls.minQuantity.value) >= Number(this.eventForm.controls.maxQuantity.value)) {
    //       this.validMinimumQuantity = false;
    //     } else {
    //       this.validMinimumQuantity = true;
    //       this.validMaximumQuantity = true;
    //     }
    //   }
    //   if (fromInput === 'maxQuantity') {
    //     if (Number(this.eventForm.controls.maxQuantity.value) <= Number(this.eventForm.controls.minQuantity.value)) {
    //       this.validMaximumQuantity = false;
    //     } else {
    //       this.validMaximumQuantity = true;
    //       this.validMinimumQuantity = true;
    //     }
    //   }
    // }

    const minimumQuantity = Number(this.eventForm.controls.minQuantity.value);
    const maximumQuantity = Number(this.eventForm.controls.maxQuantity.value);
  
    if (!isNaN(minimumQuantity) && !isNaN(maximumQuantity)) {
      if (fromInput === 'minQuantity') {
        this.validMinimumQuantity = minimumQuantity < maximumQuantity;
      }
  
      if (fromInput === 'maxQuantity') {
        this.validMaximumQuantity = maximumQuantity > minimumQuantity;
      }
    }
    
  }

  onCancel() {
    if (this.loginData) {
      const data = JSON.parse(this.loginData);
      if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
        this.router.navigate(['franchise/menu-section/food-extras'], { queryParams: { fromCancel: 'fromCancel' } });
      } else {
        this.router.navigate(['admin/menu-section/food-extras'], { queryParams: { fromCancel: 'fromCancel' } });
      }
    }
  }

  goBack() {
    window.history.back();
  }
  createBreadCrumb() {

    this.breadCrumbArray = [
      {
        label: this.commonService.getLabel('label_manage_food_extra'),
        route: '',
        queryParams: { fromCancel: 'fromCancel' }
      },
      {
        label: this.pageHeading,
        route: '',
      }
    ];

    const data = JSON.parse(this.loginData);
    this.breadCrumbArray.forEach(bcrum => {
      if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
        bcrum.route = 'franchise/menu-section/food-extras'
      } else {
        bcrum.route = 'admin/menu-section/food-extras'
      }
    })

    // console.log('this.breadCrumbArray', this.breadCrumbArray);

  }
}

