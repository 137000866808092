import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppSettings } from '../../modules/shared/app.settings';
import { MbscCalendarEvent, MbscEventcalendar, MbscEventcalendarOptions, MbscEventcalendarView, MbscResource } from '@mobiscroll/angular';
import { Router } from '@angular/router';
import { FranchiseService } from '../../modules/franchise/services/franchise-service.service';
import { UserCommonService } from '../../modules/shared/services/user-common.service';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';
import { StorageService } from '../../modules/shared/services/storage.service';
import { print } from '@mobiscroll/print';

@Component({
  selector: 'app-print-week',
  templateUrl: './print-week.component.html',
  styleUrls: ['./print-week.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PrintWeekComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked, OnDestroy {
  accessShiftSchedule = this.commonBindingDataService.checkIfHavePermission('MANAGE_SHIFT_SCHEDULE', true, true);
  accessShiftScheduleAvailability = this.commonBindingDataService.checkIfHavePermission('MANAGE_AVAILABILITY', true, true);
  showaddEventForm = false;
  showStaffAvailability = false;
  selectedSchedule: any;
  clickedEventDate: Date;
  selectedResource: any;
  today: any = new Date();
  tempEventsArray = [];
  tempTaskArray = [];
  isDataLoaded = false;

  dayViewSelectedDate: any = new Date();
  @Output() refreshCalendar: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedDate: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('calendar') calendar: any;

  isFromDayCalendarEvent: boolean = false;
  newDayEventId: any;
  displayDateForDayView: any = new Date();
  startDayOfWeek = this.userCommonService.getUpdatedStoredValue(AppSettings.START_DAY_OF_WEEK_FOR_MONTH);
  startDate: any;
  endDate: any;
  offset = new Date().getTimezoneOffset();
  
  myModules = [print];

  weekView: MbscEventcalendarView = {
    timeline: {
      type: 'week',
      eventList: true,
      weekNumbers: false,
      rowHeight: 'variable',
      eventHeight: 'variable',
    }
  };

  calendarOptions: MbscEventcalendarOptions = {
    theme: 'ios',
    themeVariant: 'light',
    dragToCreate: false,
    dragToMove: false,
    clickToCreate: 'single',
    dragToResize: false,
    dateFormatLong: 'DDD DD MMM',
    height: 'auto',
    firstDay: this.startDayOfWeek,
    selectedDate: new Date(),
    slots: [{
      id: 1,
      name: '',
    }],
    onEventClick: (args) => {
      // console.log(args); 
      if (args.event.leaveApplied && args.event.allDay) {
        return;
      }
      if (!args.event.id) {
        this.getEventDetails(args.event.id, args.event.start, false, args.event.end);
        this.selectedResource = args.event.resource;
      } else {
        this.getEventDetails(args.event.id, args.event.start, true, args.event.end);
      }

    },
    onEventCreate: (args) => {
      return this.accessShiftSchedule;
    },
    onEventCreated: (args) => {
      // console.log(args);
      this.newDayEventId = args.event.id;
      this.dateIsClicked(args.event.start, args.event.resource);
      this.selectedResource = args.event.resource;
      if (this.accessShiftSchedule) {
        this.showaddEventForm = false;
        setTimeout(() => {
          this.getEventDetails('', args.event.start, false, args.event.end);
        }, 300);
      }
    },
    renderScheduleEventContent: function (data) {
    }
  }


  @Input() weekEvents: MbscCalendarEvent[];
  @Input() weekViewResources: MbscResource[];
  @Input() checkedCondition: boolean;
  @Input() addShiftResource: any[];
  @Input() startEndDate: any;
  @Input() showPrintDialog: boolean;
  extraLastEvent: MbscCalendarEvent = {
    id: Math.random(),
    description: '',
    start: '',
    end: '',
    title: 'extra last',
    eventNames: '',
    taskNames: ''
  };

  taskCardColor: any;
  loggedInUserId: any;
  userId: any;
  private secondWindow: Window | null = null;
  private checkWindowInterval: any;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private franchiseService: FranchiseService,
    private userCommonService: UserCommonService,
    private commonService: CommonBindingDataService,
    private commonBindingDataService: CommonBindingDataService,
    private storageService: StorageService,
  ) {
    // this.displayDateForDayView =  new Date(moment(new Date()).add(this.startDayOfWeek, 'days').valueOf());
    // console.log(this.displayDateForDayView)
  }

  getStartAndEndWeekDate() {
    this.startDate = this.startDate;
    this.endDate = this.endDate;
  }

  ngOnChanges(changes?) {
    if (changes?.checkedCondition?.currentValue != undefined) {
      this.checkedCondition = changes?.checkedCondition?.currentValue;
    }
    this.dayViewSelectedDate = new Date(this.startEndDate.start);
    this.trggerClick();
  }

  ngOnInit(): void {
    this.getStartAndEndWeekDate();
    this.loggedInUserId = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA))?.franchiseId;
    this.weekEvents = this.userCommonService.weekCalenderData['weekEvents'];
    this.weekViewResources = this.userCommonService.weekCalenderData['weekViewResources'];
    this.checkedCondition = this.userCommonService.weekCalenderData['checkedCondition'];
    this.addShiftResource = this.userCommonService.weekCalenderData['addShiftResource'];
    this.startEndDate = this.userCommonService.weekCalenderData['startEndDate'];
    this.displayDateForDayView = this.startEndDate.start;
    this.startDate = this.userCommonService.weekCalenderData['startDate'];
    this.endDate = this.userCommonService.weekCalenderData['endDate'];
    this.getTaskColor();

    setTimeout(() => {
      document.getElementById("print-weekly-schedule").click();
      // window.print();
    }, 500);
  }

  @ViewChild('myCal', { static: false })
  myCal!: MbscEventcalendar;

   print() {
    // console.log('mycal1')
    this.router.navigate(['franchise/staff/schedule'],{ queryParams: { fromCancel: 'fromCancel' } });
    this.myCal.print();
  }

  ngAfterViewInit() {
    // Force change detection if needed
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    // this.trggerClick();
    this.cdr.detectChanges();
  }

  getEventName(i, j) {
    // return this.tempEventsArray[i][j];
    return this.tempEventsArray[i].eventList[j];
  }

  getTaskName(i, j) {
    // return this.tempEventsArray[i][j];
    return this.tempEventsArray[i].taskList[j];
  }

  getIndex(data) {
    // return (this.tempEventsArray[data.id] && (this.tempEventsArray[data.id].length > 0)) ?true:false;
    return this.tempEventsArray.findIndex(e => e.id == data.id);
  }

  addExtraParamsInweekViewResources() {
    let tempArray = this.weekEvents;
    this.weekEvents.forEach(eachEvent => {
      eachEvent.tooltip = `${eachEvent.eventName1},${eachEvent.eventName2},${eachEvent.taskName1},${eachEvent.taskName2}`;
      eachEvent.event1 = 'extra added event2121';
    });
  }


  getEventNames(index) {
    // console.log('index', index);
  }

  splitArray(data, type) {
    // console.log('list', data);
    let twoArray = data.split(';');
    if (type == 'event') {
      // return twoArray[0].replace(/undefined,/g, '').replace(/,undefined/g, '') .split(',');
      return twoArray[0].split(',');
    }
    if (type == 'task') {
      return twoArray[1].split(',');
    }
  }

  getTaskColor() {
    this.taskCardColor = '';
    if (this.loggedInUserId) {
      this.franchiseService.getSettings(this.loggedInUserId).subscribe(result => {
        if (result) {
          this.taskCardColor = result.taskCardColor;
        }
      })
    }
  }



  trggerClick() {
    const that = this;
    const nodes = Array.from(document.getElementsByClassName('mbsc-ios mbsc-ltr mbsc-timeline-header-date'));
    nodes.forEach(x => {

      x.addEventListener('click',
        function () {
          const val = (x as HTMLInputElement).innerText;
          that.selectedDate.emit(val);
        }
      )
    });
  }

  addExtraParamsInweekViewResourcesOld() {
    if (this.weekViewResources) {
      for (let index = 0; index < this.weekViewResources.length; index++) {
        let scheduleSum = 0;
        const eachStaff = this.weekViewResources[index];
        this.weekEvents.forEach(eachEvent => {
          eachEvent.tooltip = 'Time22: ' + eachEvent.title;
          // eachEvent.event2 = 'extra added event 22';
          eachEvent.tooltip = eachEvent.eventNames.join();

          // eachStaff['shiftHours'] += this.commonService.msToHHMMWithName(eachEvent.shiftTime)
          if (eachStaff.id === eachEvent.resource && eachEvent.shiftTimeToSum) {
            scheduleSum += eachEvent.shiftTimeToSum && eachEvent.shiftTimeToSum > 0 ? (+eachEvent.shiftTimeToSum) : 0;
          }
        });
        eachStaff['shiftHours'] = scheduleSum;
      }
      const tempArray = this.weekEvents.filter(eachEvent => eachEvent.title !== '');
      // this.weekEvents = [];
      this.weekEvents = [...tempArray];
      // console.log('weekevents',this.weekEvents);
    }
  }

  getSlotName(args: any) {
    // const calDate: any = new Date(args.date).getTime();
    const calDate: any = this.commonBindingDataService.convertedUTCTime(args.date);
    if (this.addShiftResource.includes(calDate)) {
      return true
    }
  }

  getBackgroundColorForDayView(data) {
    let color = '#000';
    if (data.original.eventCount == 0 && data.original.taskCount > 0) {
      color = this.taskCardColor;
    } else {
      if (this.checkedCondition) {
        if (data.currentResource.name.toLowerCase() == 'unassigned') {
          color = '#000';
        } else {
          color = '#FFF';
        }
      } else {
        if (data.currentResource.name.toLowerCase() == 'unassigned' && data.color != "#FFF") {
          color = '#FFF';
        } else if (data.color == "#FFF") {
          color = '#000';
        } else {
          color = '#FFF';
        }
      }
    }

    return color;
  }

  checkIfShiftHrExceed(resourse) {
    const shiftHrs = this.convertToHrs(resourse.shiftHours);
    if (+shiftHrs && (+shiftHrs > +resourse.workingHours)) {
      return 'red';
    } else {
      return '';
    }
  }

  getEquipmentColor(data) {
    // console.log(data.original.description);
    const colors = data.original.description.map((item) => {
      return item.assetColor
    })
    // console.log([...new Set(colors)]);

    return [...new Set(colors)];
  }



  getEventDetails(id, endDate, isAllowed, endTime) {
    this.showaddEventForm = false;
    if (isAllowed) {
      this.franchiseService.getScheduleDetails(id).subscribe((result) => {
        this.selectedSchedule = result;
        if (new Date(endDate).getTime() >= new Date().getTime() || new Date().getTime() <= new Date(endTime).getTime()) {
          this.selectedSchedule.disabled = false;
        } else {
          this.selectedSchedule.disabled = true;
        }
        // this.selectedSchedule.emit(result);
        this.showaddEventForm = true;
      });
    } else {
      // this.showaddEventForm = false;
      const clickedDate = new Date(endDate);
      clickedDate.setHours(0, 0, 0, 0);
      // if (this.today <= new Date(endDate).getTime()) {
      this.clickedEventDate = clickedDate;
      this.selectedSchedule = false;
      if (this.accessShiftSchedule) {
        this.showaddEventForm = true;
      }
      // }
    }
  }

  dateIsClicked(eve, resource) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.showaddEventForm = false;
    const clickedDate = new Date(eve);
    clickedDate.setHours(0, 0, 0, 0);
    if (today.getTime() <= new Date(eve).getTime() && resource !== 'DEACTIVATED') {

      this.clickedEventDate = clickedDate;
      this.isFromDayCalendarEvent = true;
      this.selectedSchedule = false;
      this.showaddEventForm = true;
    } else {
      this.weekEvents = this.weekEvents.filter(item => item.id !== this.newDayEventId);
    }
  }

  popUpEvent(eve) {
    if (this.isFromDayCalendarEvent) {
      this.weekEvents = this.weekEvents.filter(item => item.id !== this.newDayEventId);
    }
    this.showaddEventForm = eve;
  }

  updatedRecord(eve) {
    const obj = {
      firstDay: this.startDate,
      lastDay: this.endDate
    }
    this.showStaffAvailability = false;
    this.refreshCalendar.emit(obj);
  }

  navigatePage(type, inst, eve) {
    this.displayDateForDayView = new Date(this.displayDateForDayView);
    if (type) {
      this.displayDateForDayView = this.displayDateForDayView.setDate(this.displayDateForDayView.getDate() - 7);
    } else {
      this.displayDateForDayView = this.displayDateForDayView.setDate(this.displayDateForDayView.getDate() + 7);
    }
    this.getAndSetCalendarDates();
  }

  navigateToday() {
    this.displayDateForDayView = new Date();
    this.getAndSetCalendarDates();
    const obj = {
      firstDay: this.startDate,
      lastDay: this.endDate
    }
    this.dayViewSelectedDate = this.displayDateForDayView;
    this.refreshCalendar.emit(obj);
  }

  getAndSetCalendarDates() {
    this.getStartAndEndWeekDate();
    const obj = {
      firstDay: this.startDate,
      lastDay: this.endDate
    }
    this.startEndDate.start = this.startDate;
    this.startEndDate.end = this.endDate;

    // this.dayViewSelectedDate = new Date(this.displayDateForDayView);
    this.refreshCalendar.emit(obj);
  }

  convertToHrs(sec) {
    return sec ? (sec / 3600000).toFixed(1) : 0;
  }

  openCalendar(event: any) {

    const displayDate = new Date(this.displayDateForDayView)
    if (displayDate.getDate() == new Date().getDate()) {
      this.displayDateForDayView = new Date();
    }
    this.getStartAndEndWeekDate();

    const obj = {
      firstDay: this.startDate,
      lastDay: this.endDate
    }
    this.startEndDate.start = this.startDate;
    this.startEndDate.end = this.endDate;

    this.dayViewSelectedDate = this.displayDateForDayView;
    this.calendar.showOverlay(this.calendar.inputfieldViewChild.nativeElement);
    // this.refreshCalendar.emit(obj);
  }

  onSelectDayViewDate(eve) {
    this.displayDateForDayView = new Date(eve);
    this.getAndSetCalendarDates();
  }

  openToolTip(data) {
    return data.original?.partialTime;
  }

  // added new function for week cell not click in mobile device
  onCellClick(event: any): void {
    // console.log(event)
    this.newDayEventId = event.id;
    this.dateIsClicked(event.start, event.resource);
    this.selectedResource = event.resource;
    setTimeout(() => {
      this.getEventDetails('', event.date, false, event.end);
    }, 300);

  }

  // on week staff click show availability popup
  checkAvailability(resource) {
    if (!this.checkedCondition && this.accessShiftScheduleAvailability) {
      if (resource.name != 'Unassigned' && resource.name != 'Deactivated') {
        this.showStaffAvailability = true;
        this.userId = resource.id;
      }
    } else {
      return false;
    }
  }

  back() {
    history.back()
  }


  @HostListener("window:afterprint", [])
  onWindowAfterPrint() {
  }
  @HostListener("window:onbeforeunload", ['$event'])
  onWindowBeforeUnload($event: any) {
  }

  renderEventsAndTaskListOnPrint(list, from) { // in use for print
    let returnDiv = '';
    if (list.length > 0 && from == 'events') {
      list.forEach(element => {
        returnDiv += `
        <div class="event-div" style="display: flex;padding-left: 5px;padding-top: 5px;">
          <div class="fa fa-circle circle-class" style="margin-top: 2px;"></div>
                <div class="event-text" style="text-align: left; padding-left: 5px;">
                ${element}
                </div>
          </div>
        </div>`
      });
    }
    if (list.length > 0 && from == 'task') {
      list.forEach(element => {
        returnDiv += `
        <div class="event-div" style="display: flex;padding-left: 5px;padding-top: 5px;">
          <div class="fa fa-check circle-class" style="margin-top: 2px;"></div>
                <div class="event-text" style="text-align: left; padding-left: 5px;">
                ${element}
                </div>
          </div>
        </div>`
      });
    }
    return returnDiv;
  }

  ngOnDestroy(): void {
    this.isDataLoaded = false;
    this.userCommonService.weekCalenderData.weekEvents = [];
    this.userCommonService.weekCalenderData.weekViewResources = [];
    this.userCommonService.weekCalenderData.checkedCondition = false;
    this.userCommonService.weekCalenderData.addShiftResource = [];
    this.userCommonService.weekCalenderData.startEndDate = '';
  }
}
