<div class="page-container">
    <div class="page-header">
      <div class="page-heading">
        <h1 class="heading p-ml-1" translate>label_manage_customers</h1>
      </div>
    </div>
  
    <div class="page-filters">
      <div class="filter-left-container grid">
        <div class="filter-item col-10 lg:col-3 md:col-4">
          <div class="form-control input-container">
             <span class="p-input-icon-left">
               <i class="pi pi-search"></i>
               <input type="text" pInputText (keyup)= "onSearch($event)" [(ngModel)] = "search" class="search-input prefix-pad"
               [placeholder]="'label_search'|translate" appPreventSpecialCharacters pTooltip="{{'label_search_franchise_text' | translate}}">
             </span>
          </div>

         
        </div>
        <div class="form-item col-10 lg:col-3 md:col-4">
        
         
            <div class="form-control">
              <p-autoComplete  [(ngModel)]="franchiseName"  [suggestions]="franchiseList" class="search-input prefix-pad"
                (completeMethod)="searchFranchise($event)"
                [autocomplete]="'no'" [minLength]="1" (onSelect)="setSelectedFranchiseDetails($event)"
             
                 placeholder="Search by franchise name">
             <!--  <ng-template let-country pTemplate="item">
               
                
                  <div>{{country.franchiseName}}</div>
                </ng-template> -->
           
                <ng-template let-ref pTemplate="item">
         
                  <span style="font-size:16px">{{ref.franchiseName}}</span>
                </ng-template>
              </p-autoComplete>
              <input [(ngModel)]="franchiseId" [hidden]="true" /> 
            
          </div>
        </div>
        <div class="filter-item col-12 lg:col-2 md:col-3">
          <div class="form-control input-container">
            <p-dropdown [options]="generalStatusOptions" (onChange)="onClickFilter()"
                [(ngModel)]="selectedStatus">
            </p-dropdown>
          </div>
        </div>    
      </div>
     
  
      <div class="filter-right-container">
        <div class="filter-item" *ngIf="isExportVisible">
          <p-button [title]="'btn_export'|translate" (click)="exportExcel()" icon="pi pi-sign-in" iconPos="left" class="extra-small-btn"></p-button>
        </div>
      </div>
    </div>
  
    <div class="page-body">
      <app-mi-grid-v2 [url]="gridurl" [columnDefs]="columns" (actionBtnClickedEvent)="onActionBtnClicked($event)" [actionButtonsDefs]="buttons" [isExportVisible]="false"
          divClass="data-grid-lines" [searchBar]="searchBar" [exportUrl]="exportBaseUrl" (isRecordsVisible)="isRecordsVisible($event)"
          [useV2] = "true">
      </app-mi-grid-v2>
    </div>
  
  </div>

  
   <p-dialog   [dismissableMask]="false"
    [modal] = "true"[header]="'Generate Report' | translate" [(visible)]="showGenerateReport" *ngIf="showGenerateReport" styleClass="add-report-dialog form-container filter-container"
    dismissableMask="true" [closable] = "false" [draggable]="false" blockScroll="true" [resizable]="true" [focusOnShow]="false">
  <app-generate-reports-data [useV2]="true" (outputToggle)="close($event)" [isPost]="false" [isPostDataObj]="postData" [exportCompleteUrl]="exportCompleteUrl"></app-generate-reports-data>
</p-dialog>