import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MiGridComponent } from '../../mi-grid/mi-grid.component';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { UiService } from '../../../services/ui.service';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { FranchiseService } from '../../../../franchise/services/franchise-service.service';
import { StorageService } from '../../../services/storage.service';
import { UserCommonService } from '../../../services/user-common.service';
import { AdminService } from '../../../../admin/services/admin.service';
import { AppSettings } from '../../../app.settings';

@Component({
  selector: 'app-legacy-data',
  templateUrl: './legacy-data.component.html',
  styleUrls: ['./legacy-data.component.scss']
})
export class LegacyDataComponent implements OnInit {

  loginData;
  gridurl = '/secure/vya-customers/grid-data';
  updatedUrl = '';
  columns: any[] = [];
  buttons: any[] = [];
  searchBar = 'top';
  timer = null;
  ID;
  search;
  params;
  valuesOptions: any = [];
  selectedValue = '';
  selectedBrands = [];
  subscribeBrand: Subscription;
  @ViewChild(MiGridComponent) gridRef: MiGridComponent;
  brandNameOptions: any = [];
  statusOptions: any = [];
  selectedStatus;
  selectedBrandFromFilter = [];
  showBrandfilter: boolean;
  loggedInUserId;
  loginUserRole;
  franchiseLogin: boolean = false;
  isAdminLogin = true;
  previousBrand = [];
  franchiseId = null;
  imgurl;
  showDetailsPopup = false;
  uploadedFiles;
  label_upload_contact = this.commonBindingDataService.getLabel('label_upload_contact');
  acceptfile = ".csv";
  UploadFileUrl = AppSettings.BASE_URL + '/secure/vya-customers/import-csv';
  selectedCheckbox: any = [];
  showDeleteRow = false;
  uniqueId = 'legacy_data';
  saveFromCancelOffset = true;
  legacyDataReadWrite = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
    private commonBindingDataService: CommonBindingDataService,
    private franchiseService: FranchiseService,
    private storageService: StorageService,
    private adminService: AdminService) {
      this.franchiseId = this.storageService.getItem('FRANCHISE_ID');
      this.legacyDataReadWrite = this.commonBindingDataService.checkIfHavePermission('MANAGE_LEGACY_DATA', true, true);
      if(this.franchiseId && this.franchiseId !== 'null') {
        this.gridurl = this.gridurl + '?franchiseId='+JSON.parse(this.franchiseId);
        this.applyFilter();
      }else {
        this.gridurl = this.gridurl + '?franchiseId=null';
        this.applyFilter();
      }
      
  }

  ngOnInit() {
    this.route.queryParams
    .subscribe(params => {
      if (params.fromCancel) {
        this.saveFromCancelOffset = this.franchiseService.onlyPageOffSetSave;
      }else {
        this.saveFromCancelOffset = false;
      }
    });
    this.statusOptions = AppSettings.CAMPAIGN_STATUS;
    this.loginUserRole = this.commonBindingDataService.getLoginRole();
    if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE) {
      this.franchiseLogin = true;
      this.isAdminLogin = false;
      
    }else {
      this.franchiseLogin = false;
      this.isAdminLogin = true;
    }
    this.getBrandList();
    setTimeout(() => {
      this.franchiseService.onlyPageOffSetSave = false;
      this.saveFromCancelOffset = false;
    }, 1000);
    this.createGrid();
  }

  createGrid() {
    this.columns = [];
    if(this.legacyDataReadWrite){
      this.columns.push({
        field: 'id', width: '50px',
        header: '', sort: false, resize: false, class: 'eclipse', isCheckBox: true,
      });   
    }
    this.columns.push({
      field: 'firstName', width: '100px',
      header: this.commonBindingDataService.getLabel('label_first_name'), sort: false, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'lastName', width: '100px',
      header: this.commonBindingDataService.getLabel('label_last_name'), sort: false, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'email', width: '190px',
      header: this.commonBindingDataService.getLabel('label_email'), sort: false, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'phone', width: '110px',
      header: this.commonBindingDataService.getLabel('label_phone'), sort: false, resize: true, class: 'eclipse col-status',
      htmlRenderer: (data) => {
        return `<div>${data.phone} </div>`
      }
    });

    this.columns.push({
      field: 'optOutEmail', width: '110px',
      header: this.commonBindingDataService.getLabel('label_brand_opt_in'), sort: false, resize: true, class: 'eclipse col-status',
      htmlRenderer: (data) => {
        
        if(data.emailOptInBrands.length){
          let brands = [];
          data.emailOptInBrands.forEach(element => {
            let found = this.brandNameOptions.find((el)=>el.value == element.brandId);
            brands.push(found)
          });
          let brandNames=[];
          brands.forEach(element => {
            brandNames.push(element.label)
          });
          return `<div> ${brandNames.toString()} </div>`;
        }else {
          return `<div> - </div>`;
        }
      }
    });
    if(this.legacyDataReadWrite){
      this.buttons.push({ icon: '', class: 'edit-pencil-icon', label: 'Edit' });
      this.buttons.push({ icon: '', class: 'event-delete-icon', label: 'Delete Contact' });
    }

  }

  getBrandList() {
    this.adminService.getAllBrandsList().subscribe((result) => {
      this.brandNameOptions = result.map(element => ({
        label: element.brandName, value: element.id, brandId: element.brandId,
        activated: element.activated, color: element.color,
        deleted: element.deleted, inactive: false,
      }));
      this.selectedBrandFromFilter = this.brandNameOptions.map((item) => item.value.trim());
      this.onClickFilter();
    });
  }
  
  onSelectBrand(e) {
    if(this.selectedBrandFromFilter.length === 0){
      this.previousBrand[0] = e.itemValue;
      setTimeout(() => {
        this.selectedBrandFromFilter = this.previousBrand;
      }, 300);
    }
    setTimeout(() => {
      this.onClickFilter();
    }, 300);
  }

  deleteAllRecords() {
    let that = this;
    let msg = this.commonBindingDataService.getLabel('msg_delete_all_contacts');
    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.franchiseService.deleteAllCustomers().subscribe((result)=>{
          that.selectedCheckbox.length = 0;
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.onClickFilter();
        },
        error => {
          this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
        })
      }
    });
  }

  onActionBtnClicked(e: any) {
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    const actionType = e.class;
    const data = e.row;
    if (actionType !== undefined) {
      switch (actionType) {
        case 'edit-pencil-icon':
          this.franchiseService.onlyPageOffSetSave = true;
          this.router.navigate(['franchise/marketing/add-legacy-data'],
          { queryParams: { id: data.id } });
          break;
        case 'event-delete-icon':
          this.onDeleteContact(data.id)
          break;
      }
    }
  }

  onSelectAll(e) {
    this.selectedCheckbox = e;
    this.setShowDeleteRow();
  }

  onSelectCheckbox(e) {
    this.selectedCheckbox = e;
    this.setShowDeleteRow();
  }

  onDeleteContact(deleteLegacyContact?) {
    let that = this;
    let msg = this.commonBindingDataService.getLabel('msg_delect_legacy_contact');
    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.franchiseService.deleteLegacyContact(deleteLegacyContact).subscribe((result)=>{
          that.selectedCheckbox.length = 0;
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.onClickFilter();
        },
        error => {
          this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
        })
      }
    });
  }

  onDeleteSelectedContacts(e?) {
    let that = this;
    if(this.selectedCheckbox.length > 0) {
      let msg = this.commonBindingDataService.getLabel('msg_delete_selected_contacts');
      this.confirmationService.confirm({
        message: msg,
        accept: () => {
          this.franchiseService.deleteMultipleCustomers(this.selectedCheckbox).subscribe((result)=>{
            that.selectedCheckbox.length = 0;
            this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
            this.onClickFilter();
          },
          error => {
            this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
          })
        }
      });
    }else {
      this.uiService.showMessage('Please select atleast one contact', AppSettings.MESSAGE_TYPE.ERROR);
    }
    
  }

  onClickFilter() {
    this.applyFilter('apply');
  }

  onSearch(e) {
    const qoutes = `'"`;
    const regexStr = '^[-a-zA-Z0-9_@.!*?,()^' + qoutes + ' ]*$';
    if (!new RegExp(regexStr).test(e.key) || e.keyCode === 16 || e.keyCode === 18) {
      return;
    }
    if ((this.search && this.search.length >= 3) || this.search.length === 0) {
      if (this.search.length >= 3 && this.search.trim() === '') {
        return;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.applyFilter('apply'), AppSettings.SEARCH_DELAY_INTERVAL);
      // this.applyFilter('apply');
    }
  }

  applyFilter(event?) {
    if (event === 'apply') {
      this.params = '';

      if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE) {
        this.params = '';
      }else {
        this.params += `&brandIds=${this.selectedBrandFromFilter}`;
      }

      if (this.search !== undefined && this.search !== null) {
        this.gridRef.searchText = this.search.trim();
      }

      this.updatedUrl = this.gridurl + this.params;
      this.gridRef.updateUrl(this.updatedUrl);
      this.setShowDeleteRow();
      
    }
  }

  setShowDeleteRow() {
    if(this.gridRef.allSelectFlag && this.gridRef.totalRecords > 0) {
      this.showDeleteRow = true;
    }else {
      this.showDeleteRow = false;
    }
  }

  onResetFilter() {
    this.gridRef.searchText = '';
    this.search = '';
    this.gridRef.updateUrl(this.gridurl);
  }

  goBack() {
    window.history.back();
  }

  addLegacyData(){
    this.router.navigate(['franchise/marketing/add-legacy-data'])
  }

  uploadFile(event) {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  onUploadFile(response) {
    if (response.originalEvent && response.originalEvent.status == 200) {
      this.uiService.showMessage(response.originalEvent.body.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
      this.applyFilter('apply');
    } else {
      this.uiService.showMessage(response.error.error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
    }
  }

  onUploadFailed(response) {
    this.uiService.showMessage(response.error.error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
  }

}