<div class="page-container task-page">
  <div class="col-12 col-nopad">
    <app-bread-crumb [breadCrumb]="breadCrumbArray"></app-bread-crumb>
  </div>
  <div class="page-header">
    <div class="page-heading">
      <h1 class="heading p-ml-1">{{heading_label}}</h1>
    </div>
    <div class="page-actions">
      <button pButton class="small-btn" (click)="onSaveRole()" type="button" [label]="'btn_save'|translate"></button>
      <button pButton class="small-btn cancel-btn" (click)="onCancel()" type="button"
        [label]="'btn_cancel'|translate"></button>
    </div>
  </div>

  <div class="page-body">
    <div class="form-container col-12 lg:col-6 md:col-6 sm-col-7 pad-l-0" *ngIf="loadData">
      <h4 class="m-0"><span style="font-weight: normal;">Manager Name -</span> {{mangerName}}</h4>
    </div>
    <div class="form-container col-12 lg:col-5 md:col-6 sm-col-7 pad-l-0" *ngIf="loadData">
      <p-table [value]="roleGroupData" dataKey="name" class="role-access-table" [expandedRowKeys]="expandedRows">
        <ng-template pTemplate="header">
          <tr>
            <th>Name</th>
            <th class="width-150-px width-90-px-sm" style="width: 135px;text-align: center" translate>label_viewer</th>
            <th class="width-150-px width-90-px-sm" style="width: 135px;text-align: center" translate>label_editor</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns">
          <tr>
            <td style="text-align: left;">
              <a href="#" [pRowToggler]="rowData" *ngIf="rowData.accessGroupsList">
                <span>{{rowData.name}}</span>
                <i [ngClass]="expanded ? 'pi pi-angle-down' : 'pi pi-angle-right'"></i>
              </a>
              <span *ngIf="!rowData.accessGroupsList">{{rowData.name}}</span>
            </td>
            <td class="width-150-px  width-90-px-sm">
              <p-checkbox *ngIf=" !rowData.accessGroupsList && checkIfVisible(rowData, 'readOnly')"
                class="multiple-checked viewer" binary="true" [(ngModel)]="rowData.read"
                (onChange)="onSelectReadOnly(rowData, $event)"></p-checkbox>
            </td>
            <td class="width-150-px  width-90-px-sm">
              <p-checkbox class="multiple-checked editor" binary="true" [(ngModel)]="rowData.readWrite"
                (onChange)="onSelectReadWrite(rowData, $event)"
                *ngIf=" !rowData.accessGroupsList && checkIfVisible(rowData, 'readWrite')"></p-checkbox>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-rowData let-rowIndex="rowIndex" class="expanded-row">
          <tr *ngFor="let row of rowData.accessGroupsList">
            <td class="childNode">{{row.name}}</td>
            <td class="width-150-px  width-90-px-sm">
              <!-- [disabled] = "row.accessGroupCode == 'MANAGE_EVENT'"  -->
              <p-checkbox *ngIf="checkIfVisible(row, 'readOnly')" [disabled]="row.accessGroupCode == 'MANAGE_EVENT' 
                      || row.accessGroupCode == 'ASSIGN_STAFF_TO_EVENT'
                       || row.accessGroupCode == 'ASSIGN_EQUIPMENT_TO_EVENT'
                       || row.accessGroupCode == 'MANAGE_TASK'
                       || row.accessGroupCode == 'ASSIGN_STAFF_TO_TASK'
                       || row.accessGroupCode == 'ASSIGN_EQUIPMENT_TO_TASK'" class="multiple-checked viewer" binary="true"
                [(ngModel)]="row.read" (onChange)="onSelectReadOnly(row, $event)"></p-checkbox>
            </td>
            <!-- <td  *ngIf="row.name == 'Merge Client'"></td> -->
            <td class="width-150-px  width-90-px-sm">
              <p-checkbox *ngIf="checkIfVisible(row, 'readWrite')" class="multiple-checked editor" binary="true"
                [(ngModel)]="row.readWrite" (onChange)="onSelectReadWrite(row, $event)"></p-checkbox>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</div>