import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AdminService } from '../../../admin/services/admin.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';
import { UserCommonService } from '../../services/user-common.service';
import { LoaderService } from '../loader/loader.service';

@Component({
  selector: 'app-admin-setting-editable',
  templateUrl: './admin-setting-editable.component.html',
  styleUrls: ['./admin-setting-editable.component.scss']
})
export class AdminSettingEditableComponent implements OnInit {
  defaultProfile = AppSettings.DEFAULT_NO_IMAGE;
  profileUrl;
  pageForm: UntypedFormGroup;
  details;
  loginData;
  loginUserId;
  stripeLink;
  chooseLabel;
  fileId;
  waffleId;
  countryCodes = AppSettings.COUNTRY_CODE;
  timeZoneOptions = [];
  isStaff = true;
  backRedirectUrl = '/admin/manage-waffle';
  pageHeading = this.commonService.getLabel('label_add_waffle_menu');
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private formBuilder: UntypedFormBuilder,
    private adminService: AdminService,
    private uiService: UiService,
    private confirmationService: ConfirmationService,
    private commonService: CommonBindingDataService,
    public userCommonService: UserCommonService,
    public loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.setTimeZoneOptions();
    this.chooseLabel = this.commonService.getLabel('label_upload_icon');
    this.getProfileUrl(null);
    this.initForm();
    this.waffleId = this.route.snapshot.queryParams.id;
    if(this.waffleId){
      this.pageHeading = this.commonService.getLabel('label_edit_waffle_menu');
      this.getWaffleDetails();
    }
    
    
  }

  setTimeZoneOptions() {
    if (this.userCommonService && this.userCommonService.timeZones) {
      this.timeZoneOptions = this.userCommonService.timeZones.map(eachTimeZone =>
        ({ label: eachTimeZone.timezone, value: eachTimeZone.timezone }));
      this.timeZoneOptions.unshift({ label: this.commonService.getLabel('Select'), value: '' });
    }
  }

  getProfileUrl(data) {
    if (data ) {
      this.profileUrl = data.signUrl;
    } else {
      this.profileUrl = this.defaultProfile;
    }
  }

  initializeForm() {
    const formDataFromstorageData = JSON.parse(this.storageService.getItem('waffleFormData'));
    if (formDataFromstorageData) {
      const formData = formDataFromstorageData;
      this.initFormFromStorage(formData);
    } else {
      this.initForm();
    }
  }

  initFormFromStorage(formData) {
    this.pageForm = this.formBuilder.group({
      menuTitle: [this.details ? this.details.menuTitle : '', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      targetUrl: [this.details ? this.details.targetUrl : '', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      fileId: [this.details ? this.details.fileId : '',[Validators.required, Validators.minLength(1)]],
    });
  }

  initForm() {
    this.pageForm = this.formBuilder.group({
      menuTitle: [this.details ? this.details.menuTitle : '', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      targetUrl: [this.details ? this.details.targetUrl : '', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      fileId: [this.details ? this.details.fileId : '',[Validators.required, Validators.minLength(1)]],
    });
  }


  onSave() {

    const eventModel = this.getFormData();

    if(this.waffleId){

      this.confirmationService.confirm({
        message: this.commonService.getLabel('msg_save_menu'),
        accept: () => {
          this.adminService.updateWaffleMenu(eventModel).subscribe((result) => {
            this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
            this.router.navigate([this.backRedirectUrl]);
          }, (error) => {
            console.log(error);
          });
        }
      });

    }else{

      this.confirmationService.confirm({
        message: this.commonService.getLabel('msg_save_menu'),
        accept: () => {
          this.adminService.addWaffleMenu(eventModel).subscribe((result) => {
            this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
            this.router.navigate([this.backRedirectUrl]);
          }, (error) => {
            console.log(error);
          });
        }
      });
    }
    

    
  }

  getFormData() {
    const formControl = this.pageForm.controls;
    const editableEventModel: any = {
      'menuTitle': formControl.menuTitle.value.trim(),
      'targetUrl': formControl.targetUrl.value.trim(),
      'fileId': formControl.fileId.value ? formControl.fileId.value.trim() : '',
    };

    if (this.waffleId) {
      editableEventModel.id = this.waffleId;
    }
    
    return editableEventModel;

  }

  onUploadProfileImage(response) {

    
    this.profileUrl = response.originalEvent.body.fileData.signUrl;
    this.fileId = response.originalEvent.body.fileData.id;
    this.pageForm.controls.fileId.setValue(this.fileId);
  }

  onCancel() {
    this.router.navigate([this.backRedirectUrl]);
  }

  getWaffleDetails() {
    this.adminService.getWaffleDetails(this.waffleId).subscribe((result) => {
      this.details = result;
      this.getProfileUrl(result);
      this.initializeForm();
    });
  }

  
}
