import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { UiService } from '../../services/ui.service';
import { MiGridComponent } from '../mi-grid/mi-grid.component';

@Component({
  selector: 'app-shared-notifications',
  templateUrl: './shared-notifications.component.html',
  styleUrls: ['./shared-notifications.component.css']
})
export class SharedNotificationsComponent implements OnInit {

  @Input() fromComponent: any;
  gridurl = '/secure/admin-announcement/list/data';
  exportBaseUrl = `/secure/assets/export-xls`;
  updatedUrl = '';
  columns: any[] = [];
  buttons: any[] = [];
  searchBar = 'top';
  exportButton = 'top';

  search;
  params;
  timer = null;
  @ViewChild(MiGridComponent) gridRef: MiGridComponent;

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
    private commonBindingDataService: CommonBindingDataService,
    private franchiseService: FranchiseService) {
  }

  ngOnInit() {
    this.createGrid();
  }

  createGrid() {
    /*  this.columns.push({
       field: 'createdAt', width: '120px',
       header: this.commonBindingDataService.getLabel('label_date'), sort: true, resize: true, class: 'eclipse',
       htmlRenderer: (data) => {
         return this.commonBindingDataService.getUTCDateWithLocalTime(data.createdAt);
       }
     });
  */
    this.columns.push({
      field: 'segmentName', width: '150px',
      header: this.commonBindingDataService.getLabel('label_segment'), sort: true, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'messageType', width: '100px',
      header: this.commonBindingDataService.getLabel('label_message_type'), sort: true, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'messageTitle', width: '180px',
      header: this.commonBindingDataService.getLabel('label_message_title'), sort: true, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'message', width: '200px',
      header: this.commonBindingDataService.getLabel('label_message'), sort: true, resize: true, class: 'eclipse',
    });

    this.buttons.push({ icon: '', class: 'fa fa-paper-plane fa-lg status activateNotifaction', label: 'Resend' });
    // this.buttons.push({
    //   field: 'eventHold',
    //   label: '',
    //   class: 'event-hold-icon', show: (data) => {
    //     if (data.activated) {
    //       return '<div class="event-hold-icon" title = "Deactivate"></div>';
    //     } else {
    //       return '<div class="event-open-icon" title = "Activate"></div>';
    //     }
    //   }
    // });
  }


  onActionBtnClicked(e: any) {
    const actionType = e.class;
    const data = e.row;
    if (actionType !== undefined) {
      switch (actionType) {
        case 'fa fa-paper-plane fa-lg status activateNotifaction':
          this.changeEventStatus(data);
          break;
        case 'delete':
          break;
      }
    }
  }

  changeEventStatus(data) {
    const objectToSend = {};
    const msg = this.commonBindingDataService.getLabel('msg_resend_notification');
    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.franchiseService.resendNotification(data.id).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          // this.applyFilter('apply');
          this.gridRef.updateUrl(this.gridurl);
        }, error => {
        }
        );
      }
    });
  }

  onSearch() {
    if ((this.search && this.search.length >= 3) || this.search.length === 0) {
      if (this.search.length >= 3 && this.search.trim() === '') {
        return;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.applyFilter('apply'), AppSettings.SEARCH_DELAY_INTERVAL);
      // this.applyFilter('apply');
    }
  }

  applyFilter(event?) {
    if (event === 'apply') {
      this.params = '';

      if (this.search !== undefined && this.search !== null) {
        this.gridRef.searchText = this.search.trim();
      }
      this.updatedUrl = this.gridurl + this.params;
      this.gridRef.updateUrl(this.updatedUrl);
    }
  }

  onResetFilter() {
    this.gridRef.searchText = '';
    this.search = '';
    this.gridRef.updateUrl(this.gridurl);
  }

  onAdd() {
    if (this.fromComponent === 'admin') {
      this.router.navigate(['admin/marketing/notifications/notification-send']);
    } else {
      this.router.navigate(['franchise/marketing/notifications/notification-send']);
    }
  }

  goBack() {
    window.history.back();
  }

}
