
<div class="page-container">
    <div class="page-header">
      <div class="page-heading">
        <h1 class="heading" translate>label_manage_template</h1>  
      </div> 
      <div class="page-actions">
        <button pButton (click)="onAdd()" class = "large-btn" type="button" [label]="'label_food_manage_template' | translate"></button>
      </div>
    </div>

    <div class="page-filters">
        <div class="filter-left-container grid">
          <div class="filter-item col-2">
            <div class="form-control input-container">
               <span class="p-input-icon-left">
                 <i class="pi pi-search"></i>
                 <input type="text" pInputText (keyup)= "onSearch($event)" [(ngModel)] = "search" class="search-input prefix-pad"
                 [placeholder]="'label_search'|translate" appPreventSpecialCharacters pTooltip="{{'label_search_template_text' | translate}}">
               </span>
            </div>
          </div>
          <div class="filter-item col-6 lg:col-2 md:col-3 " >
            <div class="form-control input-container " >
              <p-multiSelect  #brandButton [options]="brandNameOptions" (onChange)="onSelectBrand($event)" [(ngModel)]="selectedBrandFromFilter"
                [maxSelectedLabels]="1" [showHeader]="false" [filter]="false" selectedItemsLabel="{0} Brands selected" placeholder="Select Brand" >
              </p-multiSelect>
            </div>
        </div>
        </div>
      </div>

    <div class="page-body">
      <app-mi-grid-v2 [url]="gridurl" [columnDefs]="columns"
          (actionBtnClickedEvent)="onActionBtnClicked($event)" [actionButtonsDefs]="buttons" [isExportVisible]="false"
          divClass="data-grid-lines" [searchBar]="searchBar" *ngIf=" show == true"
          [uniqueId] = "uniqueId" [fromCancelOffset]="saveFromCancelOffset" [isInputBrandId] = "isInputBrandId">
      </app-mi-grid-v2>
    </div>
  </div>
