import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';

@Injectable()
export class MiTranslateLoaderService implements TranslateLoader {

  getTranslation(lang: string): Observable<any> {
    return of({
      Username: 'Email',
      Password: 'PasPasssword',
      'Username is should be greater than x': 'Username is should be greater than {{minval}}',
      forgot_password: 'Forgot Password?',


      grid_title: 'TITLE',
      grid_trial_start_date: 'START DATE',
      grid_trail_end_date: 'END DATE',
      grid_start_date: 'Start Date',
      grid_end_date: 'End Date',
      grid_target_amount: 'Target Amount',
      grid_total_amount: 'Total Amount',
      grid_patients: 'PATIENTS',
      grid_address: 'Address',
      grid_assets: 'Equipments',
      grid_asset: 'Equipment',
      grid_staff: 'Staff',
      grid_actions: 'Actions',
      grid_submited_date: 'Submitted  Date & Time',
      lbl_no_records_found: 'No events found.',
      label_no_records_found: 'No Records Found',
      label_no_attachments_found: 'No Attachments Found',
      lbl_sold_out: 'Sold out.',

      sidebar_lbl_customers: 'Customers',
      sidebar_lbl_merchants: 'Merchants',
      sidebar_lbl_auctions: 'Auctions',
      sidebar_lbl_support: 'Support',
      sidebar_lbl_notifications: 'Notifications',
      sidebar_lbl_admin_users: 'Admin users',
      sidebar_lbl_settings: 'Settings',
      sidebar_lbl_events: 'Events',
      sidebar_lbl_menu: 'Menu',
      sidebar_lbl_campaign: 'Campaign',
      sidebar_lbl_reports: 'Reports',
      sidebar_lbl_marketing: 'Marketing',
      sidebar_lbl_franchise: 'Manage Franchise',
      sidebar_lbl_client: 'Clients',
      sidebar_lbl_asset: 'Equipment',
      sidebar_lbl_task: 'Tasks',
      sidebar_lbl_staff: 'Staff',
      sidebar_lbl_dashboard: 'Dashboard',
      sidebar_lbl_schedule: 'Schedule',
      sidebar_lbl_clockin_out: 'Clock In/Clock Out',
      sidebar_lbl_availability: 'Availability',
      sidebar_lbl_request_time_off: 'Request Time Off',
      sidebar_lbl_chat: 'chat',
      sidebar_lbl_admin_setting: 'Manage Waffle',
      sidebar_lbl_migration: 'Migration',


      msg_logout: 'Are you sure that you want to perform this action?',
      lbl_confirm_idle_session: `Session has been idle over its time limit,
      It will be disconnected in 5 minutes. Press Yes button now to continue session.`,

      // franchise start
      lbl_showing_entries: '{{pageNum}} to {{onePageEntries}} of {{totalEntries}}',
      msg_activate_menu: 'Are you sure that you want to activate this menu item?',
      msg_deactivate_menu: 'Are you sure that you want to deactivate this menu item?',
      msg_save_menu: 'Are you sure that you want to save this changes?',
      msg_change_status: 'Are you sure that you want to change this status?',
      msg_add_event: 'Are you sure that you want to add this event?',
      msg_edit_event: 'Are you sure you want to save this change?',
      msg_add_menu: 'Are you sure that you want to add this menu item?',
      msg_edit_menu: 'Are you sure that you want to save this changes?',
      msg_event_hold: 'Are you sure that you want to hold this Event?',
      msg_event_open: 'Are you sure that you want to open this Event?',
      msg_campaign_open: 'Are you sure that you want to open this campaign?',
      msg_campaign_hold: 'Are you sure that you want to hold this campaign?',
      msg_add_campaign: 'Are you sure that you want to add this campaign?',
      msg_edit_campaign: 'Are you sure that you want to save this changes?',
      msg_comment_open: 'Are you sure that you want to open this comment?',
      msg_comment_hold: 'Are you sure that you want to hold this comment?',
      msg_donation_refund: 'Are you sure that you want to refund this donation?',
      msg_add_food_extra_cat: 'Are you sure that you want to add this food extra category?',
      msg_edit_food_extra_cat: 'Are you sure that you want to update this food extra category?',
      msg_edit_template: 'Are you sure that you want to update this template',
      msg_add_template: 'Are you sure that you want to add this template?',
      msg_change_status_in_bulk: 'Are you sure that you want to change the status for all selected orders?',
      msg_change_status_in_bulk_on_all_pages: `Are you sure you want to change the status of all orders?`,
      msg_food_extra_cat_open: 'Are you sure that you want to activate this category?',
      msg_food_extra_cat_hold: 'Are you sure that you want to deactivate this category?',
      msg_food_extra_cat_delete: 'Are you sure that you want to delete this category?',
      
      msg_food_extra_item_add_menu: 'Are you sure that you want to add this item?',
      msg_food_extra_item_edit_menu: 'Are you sure that you want to update this item?',
      msg_food_extra_item_open: 'Are you sure that you want to activate this item?',
      msg_food_extra_item_hold: 'Are you sure that you want to deactivate this item?',
      msg_food_extra_item_delete: 'Are you sure that you want to delete this item?',
      msg_menu_category_delete: 'Are you sure that you want to delete this category?',
      
      msg_edit_menu_cat: 'Are you sure that you want to update this menu category?',
      msg_add_menu_cat: 'Are you sure that you want to add this menu category?',
      msg_asset_add: 'Are you sure that you want to add this equipment?',
      msg_asset_edit: 'Are you sure that you want to update this equipment?',
      msg_asset_open: 'Are you sure that you want to activate this equipment?',
      msg_asset_hold: 'Are you sure that you want to deactivate this equipment?',
      msg_segment_add: 'Are you sure that you want to create this segment?',
      msg_segment_open: 'Are you sure that you want to activate this segment?',
      msg_segment_hold: 'Are you sure that you want to deactivate this segment?',
      msg_send_notification: 'Do you want to send the notification?',
      msg_resend_notification: 'Do you want to resend the notification?',
      // msg_event_time_change: 'Are you sure you want to make this change? Please note all previous order times will remain the same.',
      msg_event_time_change: 'You are changing the event times for a Kurbside event. Orders already placed will still have the customer chosen time slot if applicable. Do you want to proceed with the change ?',
      msg_asset_overlap: 'Are you sure you want change event date & time?',
      msg_event_delete: 'Are you sure you want to delete this Event?',
      msg_task_delete:'Are you sure you want to delete this Task?',
      msg_staff_report_delete: 'Do you want to delete the staff clock in-clock out data?',
      msg_staff_report_approve: 'Do you want to approve the staff clock in-clock out data?',
      msg_staff_report_reset: 'Do you want to reset the staff clock in-clock out data?',
      msg_staff_report_reject: 'Do you want to reject the staff clock in-clock out data?',
      msg_event_delete_popup: 'Are you sure you want to perform delete action?',
      msg_event_restore: 'Are you sure you want to restore this Event?',
      msg_cancel_payment_confirmation: 'Canceling your payment will cancel your order. Are you sure you want to continue?',
      msg_cancel_donation_payment_confirmation: 'Canceling this payment will cancel your donation. Are you sure you want to continue?',
      msg_client_activate: 'Are you sure that you want to activate this client?',
      msg_attachment_delete: 'Are you sure that you want to delete this file?',
      msg_client_hold: 'Are you sure that you want to deactivate this client?',
      msg_fbr_delete: 'Are you sure that you want to delete this Request?',
      msg_fbr_add_secondary_msg_start: 'The no-reply contact will be added as secondary contact under',
      msg_fbr_add_secondary_msg_end: ' Do you want to proceed?',
      msg_email_template_delete: 'Are you sure you want to delete this Email Template? Once deleted you will not be able to recover it.',
      msg_signature_template_delete: 'Are you sure you want to delete this Signature Template?',
      msg_task_activate: 'Are you sure that you want to activate this task?',
      msg_task_hold: 'Are you sure that you want to deactivate this task?',
      msg_task_hold_series: 'Are you sure that you want to deactivate this task series?',
      msg_task_activate_series: 'Are you sure that you want to activate this task series?',
      msg_staff_activate: 'Are you sure that you want to activate this staff?',
      msg_staff_hold: 'Are you sure that you want to deactivate this staff?',
      msg_staff_schedule_open: 'Are you sure that you want to delete this staff schedule?',
      msg_task_edit: 'Are you sure that you want to update this task?',
      msg_task_add: 'Are you sure that you want to add this task?',
      msg_availability_delete: 'Are you sure that you want to delete this availability?',
      msg_availability_add: 'Are you sure that you want to add this availability?',
      msg_availability_update: 'Are you sure that you want to update this availability?',
      msg_revoke_leave: 'Are you sure you want to revoke the time-off request?',
      msg_request_leave: 'Input this Request for Time-Off?',
      msg_claim_shift: 'Do you want to claim this shift?',
      msg_update_request_leave: 'Do you want to update request for Time-Off?',
      msg_select_minimum: 'Select at least one event or task',
      msg_changeTimeZone: `Changing your time zone setting may affect 
      existing staff shifts spanning multiple days <br> and time off requests. Do you want to proceed?`,

      btn_cancel: 'Cancel',
      btn_assign: 'Assign',
      btn_save: 'Save',
      btn_update: 'Update',
      btn_apply: 'Apply',
      btn_manage: 'Manage',
      btn_manage_food_extra: 'Manage Food Extra Items',
      btn_reset: 'Reset',
      btn_edit: 'Edit',
      btn_activate: 'Activate',
      btn_deactivate: 'Deactivate',
      btn_change: 'Change',
      btn_change_all: 'Change All',
      btn_submit: 'Submit',
      btn_accept: 'Accept',
      btn_reject: 'Reject',
      btn_request_switch: 'Request Staff Switch',
      btn_route: 'Show Route',
      btn_create_route: 'Create Route',
      btn_create: 'Create',
      btn_notify: 'Notify',
      btn_completed: 'Completed',
      btn_send: 'Send',
      btn_download_qr_code: 'Download QR',
      btn_export: 'Export To Excel',
      btn_import_client: 'Import',
      btn_upload_attachment: 'Upload',
      btn_add_client: 'Add Client',
      btn_default_menu: 'Deafult Menu',
      btn_send_email: 'Send Email',
      btn_create_template: 'Create Template',
      btn_download_template: 'Download Template',
      btn_add_task: 'Add Task',
      btn_add_staff: 'Add Staff',
      btn_publish_notify: 'Publish & Notify',
      btn_save_notify: 'Save & Notify',
      btn_viewed: 'Viewed',
      btn_clock_in: 'Clock In',
      btn_clock_out: 'Clock Out',
      btn_delete: 'Delete',

      msg_success_summary: 'Success Message',
      msg_error_summary: 'Error Message',
      msg_warning_summary: 'Warning Message',
      msg_info_summary: 'Info Message',
      msg_select_image: 'Please select an image.',

      label_change_master_password: 'Change Master Password',
      label_current_super_password: 'Current Super Password',
      label_new_super_password: 'New Super Password',
      label_confirm_super_password: 'Confirm Super Password',
      label_password: 'Password',
      label_current_password: 'Current Password',
      label_new_password: 'New Password',
      label_confirm_password: 'Confirm Password',
      label_manage_menu: 'Manage Menu',
      label_enter_password: 'Enter Password',
      label_search_text: 'Enter at least three letters',
      label_search_event_text: 'Search by Event Name,Task Name, Email, Business Name',
      label_search_asset_text: 'Search by Equipment Name, Make, Model, Registration Number',
      label_search_orders_text: 'Search by Name, Email',
      label_search_event_orders_text: 'Search by Name, Email, Recipient Name',
      label_search_reports_text: 'Search by Event Name, Event Code, Order Code, Recipient Name',
      label_search_campaign_reports_text: 'Search by Campaign Code, Name',
      label_search_all_donation_text: 'Search by Campaign Code, Event Code',
      label_search_campaign_text: 'Search by Name, Code',
      label_search_menu_text: 'Search by Category',
      label_search_menu_category: 'Search by Category Name',
      label_search_template_text: 'Search by Template Name',
      label_search_comment_text: 'Search by Name, Email, Comment',
      label_search_food_extra_cat_text: 'Search by Category',
      label_search_message_title: 'Search by Message Title, Type',
      label_search_segment: 'Search by Segment',
      label_search_menu_item_text: 'Search Menu Item by Name',
      label_search_image_text: 'Search Image Name',
      label_search_name: 'Search by Name',
      label_search_aging_reports_text: 'Search by Client Name, Contact Name',
      label_notify_staff_for_open_shift: 'Send notifications to staff for Open Shifts',
      label_first_reminder: 'First Reminder (in Hours)',
      label_second_reminder: 'Second Reminder (in Hours)',
      label_auto_conf_email_left:'Days before Event Start Date to send:',
      label_auto_conf_email_right:'(valid options are 1-30 days)',
      labelDateHeader: 'CreateBlockedDate',

      lbl_add_event: 'Add Event',
      lbl_add_task: 'Add Task',
      label_yes: 'Yes',
      label_no: 'No',
      label_search: 'Search',
      label_manage_events: 'Manage Events',
      label_event_details: 'Event Details',
      label_manage_campaign: 'Manage Campaign',
      label_manage_comments: 'Manage Comments',
      label_comments: 'Comments',
      label_donations: 'Donations',

      label_add_event: 'Add Event',
      label_edit_event: 'Edit Event',
      label_code: 'Code',
      label_businessName: 'Business Name',
      label_brandName: 'Brand Name',
      label_brand: 'Brand',
      label_event_code: 'Event Code',
      label_event: 'Event',
      label_date: 'Date',
      label_expire_date: 'Expire Date',
      label_time: 'Time',
      label_address: 'Address',
      label_customer_address: 'Address',
      label_min_delivery_time: 'Minimum Delivery Time (Hours)',
      label_orders: 'Orders',
      label_grand_total: 'Grand Total',
      label_action: 'Actions',
      label_: 'Manage Events',
      label_event_name: 'Event Name',
      label_event_start_date_time: 'Event Start Date and Time',
      label_event_end_date_time: 'Event End Date and Time',
      label_order_start_date_time: 'Order Start Date and Time',
      label_order_end_date_time: 'Order End Date and Time',
      label_delivery: 'Delivery',
      label_link: 'Link',
      label_city: 'City',
      label_state: 'State',
      label_county: 'County',
      label_zip_code: 'Zip Code',
      label_contact_name: 'Contact Name',
      label_contact_email: 'Contact Email',
      label_contact_phone: 'Contact Phone',
      label_contact_number: 'Contact Number',
      label_contact_title: 'Contact Title',
      label_contact_type: 'Contact Type',
      label_entry_fee: 'Entry Fee',
      label_flag_review: 'Flag For Review',
      label_collected_amount: 'Collected Amount',
      label_cash: 'Cash',
      label_check: 'Check',
      label_credt_card: 'Credit Card',
      label_event_sales_summary: 'Event Sales Summary',
      label_event_sales_type: 'Event Sales Type',
      label_event_attachment: 'Event Attachment',
      label_client_attachment: 'Client Attachment',
      label_event_sales_collected: 'Event Sales-Collected',
      label_giveback_subtotal: 'Giveback Subtotal',
      label_tips: 'Tips',
      label_net_event_sales: 'Net Event Sales',
      label_balance: 'Balance',
      label_emails_sent: 'Emails Sent',
      label_event_sales: 'Event Sales',
      label_giveback_paid: 'Giveback Paid?',
      label_client_invice: 'Client Invoiced?',
      label_collected: 'Collected',
      label_givebck_settled_date: 'Giveback Settled Date',
      label_invoiced_settled_date: 'Invoice Settled Date',
      label_giveback_check: 'Giveback Check #',
      label_invoice_check: 'Invoice #',
      label_order: 'Orders',
      label_grand_totals: 'Grand Totals',
      lable_sales_total: 'Sales Total',
      lable_sales: 'Sales',
      lable_last_event: 'Last Event',
      label_thank_you_email: 'Thank You Sent',



      label_secondary_contact_name: 'Secondary Contact Name',
      label_secondary_contact_email: 'Secondary Contact Email',
      label_secondary_contact_phone: 'Secondary Contact Phone',
      label_alternate__phone: 'Alternate Phone',


      label_secondary_contact_number: 'Secondary Contact Number',
      label_key: 'Key',
      label_values: 'Values',
      label_special_instruction_label: 'Special Instruction Label',
      label_special_instruction: 'Special Instructions',
      label_delivery_message: 'Delivery Message',
      label_default_delivery_message: 'Please allow +/- 15 minutes for deliveries',
      label_additional_payment_field_label: 'Additional Payment Field Label',
      label_gratuity_field_label: 'Gratuity Field Label',
      label_field_label: 'Field Label',
      label_additional_donation: 'Additional Donation Amount',
      label_donation_amount: 'Donation Amount',
      label_donation_amount_header: 'Donation Amount (Include cc charge)',
      label_gratuity: 'Accept Gratuity',
      label_receipentNameRequired: 'Make Recipient Name Mandatory',
      label_hide_item_incremental: 'Disable Product Quantities',
      label_menu_offered: 'Menu Offered',
      label_photo: 'Photo',
      //label_available:'Available',
      label_category_name: 'Category Name',
      label_manage_template_category: 'Manage Template Category',
      label_item: 'Item',
      label_description: 'Description',
      label_price: 'Price',
      label_selling_price: 'Selling Price',
      label_quantity: 'Quantity',
      label_receipt_total: 'Total:',
      label_product_total: 'Total:',
      label_available: 'Available',
      label_sequence: 'Sequence',
      label_gift: 'Gift',
      label_name: 'Name',
      label_order_price: 'Order Price',
      label_order_date: 'Order Date',
      label_order_code: 'Order Code',
      label_status: 'Status',
      label_event_status: 'Event Status',
      label_change_bulk_status: 'Change Status In Bulk',
      label_reports: 'Order Report',
      label_admin_reports: 'Reports',
      label_payout_report: 'Payout Report',
      label_campaign_reports: 'Campaign Reports',
      label_all_donations: 'All Donations',
      label_from_date: 'From Date',
      label_to_date: 'To Date',
      label_phone: 'Phone',
      label_email: 'Email',
      label_view_details: 'View Details',
      label_order_details: 'Order Details',
      label_view_orders: 'View Orders',
      label_sales_tax: 'Sales Tax',
      label_name_and_email: 'Name & Email',

      label_food_total: 'Food Total',
      label_active_event: 'Active',
      label_deleted_event: 'Deleted',
      lebel_expired_event: 'Expired',
      label_hold_event: 'Hold',
      label_sms_notification: 'Receive SMS Order Notifications',
      label_email_notification: 'Receive Email Order Notifications',
      label_draw_event_region: 'Map Region',
      label_notify_staff: 'Notify Staff',
      label_email_opt_in: 'Receive Email Notifications',

      label_food_extra_category: 'Category',
      label_add_template: 'Add Template',
      label_edit_manage_template: 'Edit Template',
      label_type: 'Type',
      label_min_quentity: 'Min Quantity',
      label_max_quentity: 'Max Quantity',
      label_manage_food_extra: 'Manage Food Extra',
      label_manage_template: 'Manage Template',
      label_food_extra_add_category: 'Add Category',
      label_food_manage_template: 'Add Template',
      label_food_extra_edit_category: 'Edit Category',
      label_food_extra_category_name: 'Category Name',
      label_food_extra_category_type: 'Type',
      label_make_selection_optional: 'Make selection of items optional (Optional)',
      label_make_selection_pos: 'Is POS Template',
      label_require_range: 'Require a specific quantity or range of items (Mandatory)',
      label_manage_food_extra_items: 'Manage Food Extra Items for',
      label_manage_food_extra_item: 'Manage Food Extra Items',
      label_food_extra_items: 'Food Extra Items',

      label_template_name: 'Template Name',
      label_attachments: 'Attachments',
      label_attachment: 'Attachment',
      label_template_count: 'Template Count',
      label_is_Pos: 'Is POS',
      label_default_menu: 'Default Menu',


      label_manage_menu_items: 'Manage Menu Items for',
      label_manage_menu_item: 'Manage Menu Items',
      label_manage_menu_template:'Manage Menu Template',

      label_food_extra_add_item: 'Add Food Extra Item',
      label_food_extra_edit_item: 'Edit Food Extra Item',
      label_food_extra_item_name: 'Item Name',
      label_selection: 'Selection',
      label_min_quantity_allowed: 'Minimum Quantity Allowed',
      label_max_quantity_allowed: 'Maximum Quantity Allowed',
      label_manage_menu_category: 'Manage Menu Category',
      label_menu_items: 'Menu Items',
      label_menu_category: 'Menu Category',
      label_manage_menu_food_extra_category: 'Food Extra Category',
      label_manage_menu_food_extra_category_items: 'Food Extra Category Items',
      label_manage_menu_status: 'status',
      label_menu_add_category: 'Add Menu Category',
      label_menu_edit_category: 'Edit Menu Category',
      label_menu_category_name: 'Menu Category Name',
      label_menu_template_name: 'Template Name',
      label_manage_asset: 'Manage Equipment',
      label_equipments: 'Equipments',
      label_equipment: 'Equipment',
      label_assets: 'Equipment',
      label_add_asset: 'Add Equipment',
      label_asset_code: 'Code',
      label_asset_name: 'Name',
      label_registration_number: 'Registration Number',
      label_make: 'Make',
      label_model: 'Model',
      label_asset_type: 'Equipment Type',
      label_color: 'Color',
      label_send_color: 'Notification Sent Color',
      label_not_send_color: 'Notification Not Sent Color',
      label_inventory: 'Inventory',
      label_edit_asset: 'Edit Equipment',

      label_total: 'Total',
      label_bank_name: 'Bank Name',
      label_bank_acc_no: 'Bank Account Number',
      label_profile: 'Profile',
      label_country: 'Country',
      label_location_notes: 'Location Notes',
      label_order_attribute: 'Order Attribute',
      label_enterItem_name: 'Enter item name',
      label_enter_description: 'Enter description',
      label_enter_price: 'Enter price',
      label_enter_quentity: 'Enter quantity',
      label_all: 'All',
      // label_first_name: 'First name',
      // label_last_name: 'Last name',
      label_brand_owner_name: 'Brand Owner Name',
      label_franchise_owner_name: 'Franchise Owner Name',
      label_brand_code: 'Brand Code',
      label_num_country_code: 'Country Code',
      label_num_phone_number: 'Contact number',
      label_franchise_name: 'Franchise Name',
      label_franchise_email: 'Franchise Email',
      label_franchise_country_code: 'Franchise Country Code',
      label_franchise_phone_number: 'Franchise Phone Number',
      label_connected_account: 'Connected Account',
      label_connect_account: 'Connect Account',
      label_upload_image: 'Upload Image',
      label_comma_separated_values: 'Comma separated values',
      label_additional_donation_amount: 'Additional Donation Amount',
      label_gratuity_amount: 'Gratuity Amount',
      label_export_franchise_reports: 'Export Franchise Reports',
      label_export_event_reports: 'Export Event Reports',
      label_export_order_reports: 'Export Order Reports',
      label_export_franchise_payout_reports: 'Export Franchise Payout Reports',
      label_export_Only_Payout_reports: 'Payout Reports',
      label_export_campaign_reports: 'Campaign Reports',
      label_add_campaign: 'Add Campaign',
      label_edit_campaign: 'Edit Campaign',
      label_campaign_name: 'Campaign Name',
      label_campaign_code: 'Campaign Code',
      label_campaign_link: 'Campaign Link:',
      label_campaign_start_date: 'Start Date',
      label_campaign_end_date: 'End Date',
      label_business_name: 'Business Name',
      label_target_amount: 'Target Amount',
      label_campaign_description: 'Campaign Description',
      label_image_for_donation_page: 'Image For Donation Only Page',
      label_minimum_donation: 'Minimum Donation Amount',
      label_enable_cc_charges: 'Enable Prompt To Pay For Credit Card Charges',
      label_included_cc_chrges: 'Included Credit Card Charges:',
      label_included_cc_chrges_header: 'Included CC Charges:',
      label_credit_card_chrges: 'Convenience Fee:',
      label_credit_card_chrges_without_col: 'Convenience Fee',
      label_campaign_donation: 'Campaign Donation:',
      label_display_top_doners: 'Display Top Donors',
      label_top_number_of_doners_to_display: 'Top Number Of Donors To Display',
      label_display_comments: 'Display Comments',
      label_top_number_of_comments_to_display: 'Top Number Of Comments To Display',
      label_campaign_associated: 'Campaign Associated',
      label_enable_donation: 'Enable Donation',
      label_donation_label: 'Donation Field Label',
      label_giveback_percent: 'Giveback Percentage (for the food subtotal)',
      label_total_giveback_amount: 'Total Giveback amount from cash collected',
      label_for_payment_terms: 'Payment Terms',
      label_for_booked_email_sent: 'Book',
      label_for_confirmed_email_sent: 'Conf',
      label_for_thank_you_email: 'TY',
      label_for_client_invoice: 'Invoice Sent',
      label_for_giveback_paid: 'Giveback Sent',
      label_event_type: 'Event Type',
      label_auto_status: 'Auto Status',
      label_order_no: 'Order Number',
      label_Name_of_Doner: 'Donor Name',
      label_doner: 'Donor Name',
      label_Donation_date: 'Date Of Donation',
      label_comment_date: 'Date Of Comment',
      label_total_amount_donated: 'Total Amount Donated',
      label_palce_your_order: 'Place Your Order',
      label_include_cc_charges: 'Include credit card processing charges in my donation',
      label_include_cc_charges_description: `Convenience fee are taken out of your donation, you can offer to cover these
      fees to help raise even more money!`,
      label_role_type: 'Role Type',
      label_user: 'User',
      label_segment_name: 'Segment Name',
      label_segment: 'Segment',
      label_created_at: 'Created At',
      label_users_match_your_conditions: 'users match your conditions',
      label_segments: 'Segments',
      label_create_new: 'Create New',
      label_add_segment: 'Create New Segment',
      label_add_notification: 'Create New Notification',
      label_message_type: 'Message Type',
      label_message_description: 'Description',
      label_message_body: 'Message Body',
      label_message_title: 'Message Title',
      label_message: 'Message',
      label_notifications: 'Notifications',
      label_staff_reminder: 'Staff Reminder',
      label_event_notification :'Event Notifications',
      label_event_automatic_confirmation_email : 'Automatic Confirmation Email:',
      label_event_confirmation_email:'Automatic Thank You Email:',
      label_order_count: 'Order Count',
      label_email_templates: 'Email Templates',
      label_email_header_order_receipt: 'Order Receipt',
      label_email_header_forgot_password: 'Forgot Password',
      label_email_header_donation_receipt: 'Donation Receipt',
      label_recipient_name_label: 'Recipient Name Label',

      label_is_kurbside_event: 'Is Kurbside Event?',
      label_time_slots: 'Time slots (Minutes)',
      label_throttle_per_slot: 'Orders Per Slot',
      label_maximum_order_allowed: 'Maximum Orders Allowed',
      label_radius: 'Radius',
      label_radius_in_km: 'Radius (Miles)',

      label_manage_client: 'Manage Clients',
      label_FBR: 'No-Reply',
      label_manage_FBR: 'Manage No-Replies',
      label_edit_FBR: 'Edit No-Reply',
      label_manual_status: 'Manual Status',
      label_search_client: 'Search Client by Email, Number, Name & Business Name',
      label_search_client_by_Item: 'Search by Item Name',
      label_client_name: 'Client Name',
      label_alternate_contact: 'Alternate Contact',
      label_add_client: 'Add Client',
      label_edit_client: 'Edit Client',
      label_alternate_phone: 'Alternate Phone',
      label_giveback_percent_label: 'Giveback Percentage',
      label_admin_notes: 'Admin Notes',
      label_event_notes: 'Event Notes',
      label_email_notes: 'Email Notes',
      label_driver_notes: 'Driver Notes',
      label_client_notes: 'Client Notes',
      label_client_industry: 'Client Industry',
      label_event_industry: 'Event Industry',
      label_primary_contact_name: 'Primary Contact Name',
      label_primary_contact_title: 'Primary Contact Title',
      label_primary_contact_email: 'Primary Contact Email',
      label_primary_contact_phone: 'Primary Contact Phone Number',
      label_secondary_contact_title: 'Secondary Contact Title',
      label_personal_details: 'Personal Details',
      label_primary_contact_details: 'Primary Contact Details',
      label_secondary_contact_details: 'Secondary Contact Details',
      label_other_details: 'Other Details',
      label_primart_contact: 'Primary Contact',
      label_secondary_contact: 'Secondary Contact',
      label_title: 'Title',
      label_search_email: 'Search by Email Subject',
      label_search_staff_log: 'Search by Title, Notes & Attachment',
      label_search_equipment_log: 'Search by Title, Notes & Attachment',
      label_search_food_extra_text: 'Search by Item Name',
      label_create_template: 'Create Email Template',
      label_edit_template: 'Edit Email Template',
      label_create_noreplay_template: 'Create No-Reply Email Template',
      label_edit_noreplay_template: 'Edit No-Reply Email Template',
      msg_noreplay_template_delete: 'Are you sure that you want to delete this template?',
      label_subject: 'Email Subject',
      label_cc: 'CC',
      label_bcc: 'BCC',
      label_compose: 'Compose',
      label_confirm_email: 'Confirmation Email:',
      label_thank_you_email_label: 'Thank You Email:',
      label_booking_email: 'Booking Email:',
      label_custom_email: 'Custom Email:',
      label_booking_email_sent: 'Booking Email Sent',
      label_confirm_email_sent: 'Confirmation Email Sent',
      label_create_sign_template: 'Create Signature Template',
      label_edit_sign_template: 'Edit Signature Template',

      // Task
      label_manage_task: 'Manage Tasks',
      label_task_name: 'Task Name',
      label_task_code: 'Task Code',
      label_task_completed: 'Completed',
      label_start_date_time: 'Start Date & Time',
      label_end_date_time: 'End Date & Time',
      label_expiry_date_time: 'Expiry Date',
      label_associated_event: 'Associated Event',
      label_staff: 'Staff',
      label_task_status :'Completed',
      label_add_task: 'Add Task',
      label_edit_task: 'Edit Task',
      label_frequency: 'Frequency',
      label_custom: 'Custom',
      label_notes: 'Notes',
      label_weekdays: 'Week Days',
      label_months: 'Month',
      label_in_a_day: 'In a Day',
      label_last_day: 'Last day of the month',
      label_task_details: 'Task Details',
      label_start_time: 'Start Time',
      label_end_time: 'End Time',
      label_search_task: 'Search by Task Name, Associated Event, Staff ',

      // Staff
      label_manage_staff: 'Manage Staff',
      label_search_staff: 'Search by Name, Email, Phone Number',
      label_staff_fullName: 'Staff Full Name',
      label_staff_type: 'Staff Type',
      label_available_week: 'Available Week',
      label_available_week_hours: 'Preferred Hours per Week',
      label_select_role: 'Select Role',
      label_add_staff: 'Add Staff',
      label_edit_staff: 'Edit Staff',
      label_emergency_contact_name: 'Emergency Contact Name',
      label_emergency_contact_number: 'Emergency Contact Number',
      label_hourly_rate: 'Hourly Rate',
      label_staff_details: 'Staff Details',
      label_staff_code: 'Staff Code',

      // Staff Schedule
      label_staff_schedule: 'Staff Schedule',
      label_start_lead: 'Start Lead',
      label_end_lead: 'End Lead',
      label_event_based_on_asset: 'Events',
      label_events: 'Events',
      label_select_staff: 'Select Staff',
      label_select_asset: 'Select Equipment',
      label_employee: 'Employee',
      label_select_employee: 'Select Employee',
      label_start_shift: 'Start Shift',
      label_end_shift: 'End Shift',
      label_task: 'Task',
      label_tasks: 'Tasks',
      lable_clock_in: 'Clock-In',
      lable_clock_out: 'Clock-Out',
      lable_reason: 'Reason',


      label_schedule_shift: 'Shift on',
      label_no_task: 'No Tasks are allocated',
      label_no_equipments: 'No Equipments are allocated',
      label_no_events: 'No Events are allocated',

      // quick event
      label_create_event: 'Create Event',
      label_select_business_name: 'Select Business Name',
      label_more_details: 'More Details',
      label_online_order_count: 'Online Order Count',
      label_online_order_sales_total: 'Online Order Sales Total',
      label_giveback_amount: 'Giveback Amount',
      label_sales_tax_amount: 'Sales Tax Amount',

      label_template_type: 'Template Name',
      label_event_types: 'Event Types',
      label_autoresponse: 'Auto Send Email',
      label_template: 'Email body',
      label_send_email: 'Send Email',
      label_schedule_mail: 'Schedule Mail',
      label_notification_mail: 'Email',
      label_notification_sms: 'SMS',
      label_schedule_date_time: 'Schedule Date & Time',
      label_settings: 'Settings',
      label_subject_name: 'Email Subject',
      label_sent_date: 'Date & Time',
      label_email_info: 'Email Information',
      label_email_content: 'Email Content',
      label_email_details: 'Email Details',
      label_send_on: 'Sent On',
      label_scheduled_on: 'Scheduled On',
      label_unassigned: 'Unassigned',
      label_deactivated: 'Deactivated',
      label_offline_menu: 'Offline Menu',
      label_online_menu: 'Online Menu',
      label_email_signature: 'Email Signature',
      label_event_copied: 'Event is copied',

      alert_payment_term_not_menu: 'Payment Terms must be "Menu" for credit card prompt.',
      error_userid_required: 'Email is required',
      error_email_id_required: 'Please enter email address',
      error_email_id_invalid: 'Please enter valid Email address',
      error_password_required: 'Please enter password',
      error_password_not_match: 'Password should be same as new password',
      error_userid_valid: 'Enter valid email',
      error_invalid_input: 'Invalid input',
      error_four_decimal: 'Invalid amount or maximum decimal limit reached',
      error_password_validation: `Password must contain 8 to 20 characters, including uppercase, lowercase letters,
       numbers and special characters.`,
      error_fill_required_field: 'Please fill out all required fields.',
      error_fill_proper_google_address: 'Please select proper Google provided Address',
      error_addressLatitude: 'Please select proper Google provided Address',
      error_menu_selection_invalid: 'Please select menu correctly.',
      error_select_atlest_one_menu: 'Please select at least one Menu Item.',
      error_sequence_should_be_unique: 'Items can not be repeated in the sequence. Please enter a different sequence number for each item.',
      error_valid_menu: 'Selected menu should be valid',
      error_donation_required: 'Donation amount is required',
      error_name_required: 'Name is required',
      error_end_date_greater_than_start_date: 'Please make sure that End Date and Time is greater than Start Date and Time.',
      error_same_day: 'Start and End Date of Kurbside event should be same',
      error_invalid_menu: 'Invalid menu selection',
      error_client_access: 'You are not authorized to create a client.',
      error_invalid_food_extra: 'Invalid Food extra selection',
      error_menu_sequence_required: 'Menu Sequence Required',
      error_menu_sequence_unique: 'Sequence should be unique',
      error_food_extra_sequence_required: `sequence is Required`,
      error_food_extra_sequence_unique: `Sequence should be unique`,
      error_select_brand: 'Please select brand first.',
      error_no_menu_message: 'For selected brand Template/Menu-item not assigned from menu section',
      error_expiry_date_less_than_start_date: 'Please make sure the Expiry Date occurs after the Start Date.',
      error_order_end_date_greater_than_order_start_date: 'Please make sure that End Date and Time is greater than Start Date and Time.',
      error_order_date_less_than_event_end_date: 'Please make sure that order End Date and Time less than or equal to event End Date and Time.',
      error_order_start_date_less_than_event_end_date: 'Please make sure that order Start Date and Time less than or equal to event End Date and Time.',
      error_order_start_date_less_than_order_end_date: 'Please make sure that order Start Date and Time less than or equal to order End Date and Time.',
      error_base_location_in_polygon: 'Event Address should be within the marked boundaries.',
      error_selected_location_in_polygon: 'Sorry, the address you have entered is not within our delivery area. Please try another address.',
      error_draw_event_region: 'Event Address should be within the map region.',
      error_mismatch_address_and_region: `Event Address should be within the marked boundaries.`,
      error_30_days_date_validation: 'Date Range should not exceed 30 days.',
      error_6_month_validation: 'Date Range should not exceed 6 months only.',
      error_select_google_address: 'Please enter a valid address',
      error_giveback_min_value: 'Minimum value of giveback should be 0',
      error_giveback_max_value: 'Maximum value of giveback should be 100',
      error_start_end_date_same: 'Start Date & End Date should be same',
      error_atleast_one_day_week: 'Atleast one week day should be in the selected date range',
      error_start_time_should_be_greater: 'Start Shift Time should be greater or equal to Current Time',
      error_start_time_should_be_greater_end: 'End Shift Time should be greater or equal to Current Time',
      error_end_time_should_be_greater: 'End Shift Time should be greater or equal to Start Time',
      error_end_time_should_be_greater_than_start: 'Please make sure End Shift Time should be greater or equal to Start Shift Time',
      error_end_time_should_be_greater_than_today: 'Please make sure End Shift Time should occurs before the 11:59 PM',
      error_start_time_should_be_greater_than_today: 'Please make sure Start Shift Time should occurs after the 12:00 AM',
      error_choose_business_name: 'Please select Business Name',
      error_choose_valid_time_slot: 'Please select time slot according to event time',
      error_select_atleast_staff: 'Please select at least one staff from the list',
      error_equipment_will_reset: 'Selected Equipment will not be set for recurring events',
      error_please_select_staff: 'Please select Staff',
      error_select_from_to_date: 'Please select from and to date',
      error_please_set_other_as_default_template_deactive: 'Please set another template as a default template before deactivating this template',
      error_please_set_other_as_default_template_delete: 'Please set another template as a default template before deleting this template',
      max_file_upload_size: 'Maximum 3MB file size allowed',
      max_file_attachment_upload_size: 'Maximum 10MB file size allowed',
      max_email_attachment_upload_size: 'Maximum 5MB file size allowed',
      max_noreply_email_attachment_upload_size: 'Maximum 5MB file size allowed',
      max_signature_upload_size: 'Maximum 2MB file size allowed',
      min_length_1char: 'Minimum 1 characters required',
      min_length_2char: 'Minimum 2 characters required',
      min_length_3char: 'Minimum 3 characters required',
      min_length_8char: 'Minimum 8 characters required',
      max_length_4char: 'Maximum 4 characters allowed',
      max_length_5char: 'Maximum 5 characters allowed',
      max_length_9char: 'Maximum 9 characters allowed',
      max_length_10char: 'Maximum 10 characters required',
      max_length_12char: 'Maximum 12 characters allowed',
      max_length_20char: 'Maximum 20 characters allowed',
      max_length_30char: 'Maximum 30 characters allowed',
      max_length_40char: 'Maximum 40 characters allowed',
      max_length_50char: 'Maximum 50 characters allowed',
      max_length_75char: 'Maximum 75 characters allowed',
      max_length_100char: 'Maximum 100 characters allowed',
      max_length_120char: 'Maximum 120 characters allowed',
      max_length_160char: 'Maximum 160 characters allowed',
      max_length_250char: 'Maximum 250 characters allowed',
      max_length_400char: 'Maximum 400 characters allowed',
      max_length_500char: 'Maximum 500 characters allowed',
      max_length_1000char: 'Maximum 1000 characters allowed',
      min_length_10number: 'Minimum 10 digits allowed',
      max_length_10number: 'Maximum 10 digits allowed',
      max_length_20number: 'Maximum 20 digits allowed',
      max_length_5number: 'Maximum 5 digits allowed',
      max_length_4number: 'Maximum 4 digits are allowed',
      max_31number_allowed: 'Maximum 31 number is allowed',
      min_1number_allowed: 'Minimum 1 number is allowed',
      max_length_5000char: 'Maximum 5000 characters allowed',
      max_length_200char: 'Maximum 200 characters allowed',
      max_length_2000char: 'Maximum 2000 characters allowed',
      field_is_required: 'Field is required',
      invalid_minimum_quantity: 'Invalid Minimum Quantity',
      invalid_max_quantity: 'Invalid Maximum Quantity',
      food_extra_min_level_error: 'Less than minimum set at Food Extra category level is not allowed',
      food_extra_max_level_error: 'Greater than maximum set at Food Extra category level is not allowed',




      // franchise end

      label_header_name: 'Kona Schoolside',
      label_find_event_name: 'Find your event:',
      label_btn_find: 'Find',
      label_enter_code: 'ENTER EVENT CODE',
      label_or: 'OR',
      label_search_event_code: 'For specific events:',
      label_search_event_zip: 'For all public events in your area:',
      label_search_event_address: 'For available Kona Ice deliveries in your area:',
      label_enter_name: 'ENTER EVENT NAME',
      label_enter_zipcode: 'ENTER ZIP CODE',
      label_enter_address: 'ADDRESS / LOCATION NOTES',
      label_enter_assets: 'ENTER EVENT EQUIPMENT CODE',
      error_event_code_or_name_required: 'Please enter event code or event name.',
      error_event_code_or_zip_code_or_event_address: 'Please enter Event Code or Zip Code or Event Address.',
      error_client_secrete_missing: 'Your payment was unable to be processed. Please try again.',
      label_btn_place_your_order: 'Place Your Order Here',
      label_kurbside_event: '-Kurbside',
      label_btn_order: 'ORDER',
      label_recipient_name: 'Recipient Name',
      label_recipient: 'Recipient',
      label_first_name: 'First Name',
      label_last_name: 'Last Name',
      label_email_address: 'Email Address',
      label_phone_number: 'Phone Number',
      label_card_number: 'Card Number',
      label_expiry: 'Expiry',
      label_cvv: 'CVV',
      label_zip: 'Zip',
      label_to_pay: 'To Pay',
      label_btn_place_order: 'Place Order',
      label_enter_first_name: 'Enter First Name',
      label_enter_last_name: 'Enter Last Name',
      error_first_name_required: 'First name is required',
      error_last_name_required: 'Last name is required',
      label_enter_email_address: 'Enter Email Address',
      error_email_required: 'Email address is required',
      error_email_invalid: 'Enter valid email address',
      error_multi_email_invalid: 'Enter valid email addresses separated by commas.',
      error_clientReportEmailId: 'Please enter valid email addresses in Send Order Report.',
      error_event_zip_code_required: 'Zip Code required',
      error_event_zip_code_max_length: 'Max length of Zip Code should be 5',
      error_event_code_required: 'Event code required.',
      label_btn_lets_go: 'LET’S GO',
      label_event_code_with_key: 'Event Code: {{code}}',
      label_product: 'Product',
      label_kiddie_kona: 'Kiddie Kona',
      label_qty: 'Qty',
      label_enter_basic_details: 'Enter Basic Details',
      label_ordered_items: 'Ordered Items',
      error_contact_number_required: 'Phone number is required',
      error_address_required: 'Address is required',
      error_customer_address_required: 'Address is required',
      error_city_required: 'City is required',
      error_state_required: 'State is required',
      error_zip_required: 'Zip code is required',
      error_terms_field_required: 'Field is required',
      error_key_value_required: 'Key value is required',
      label_btn_almost_done: 'ALMOST DONE',
      label_food_cost: 'Food Cost(s):',
      label_service_tax: 'Sales Tax:',
      label_sub_total: 'Subtotal:',
      label_sub_total_order_details: 'Subtotal',
      label_delivery_fee_franchise: 'Delivery Fees',
      label_minimum_order_amount: 'Minimum Order Amount',
      label_delivery_fee: 'Delivery Fees:',
      label_convenience_fee: 'Convenience Fee:',
      label_total_amount: 'Total:',
      label_optional_donation: 'Optional Donation:',
      label_dollar_amount: '$0.00',
      label_special_instructions: 'Special Instructions?',
      label_any_special_instruction: 'Any Special Instructions?',
      label_minimum_amount_required: 'Minimum Total Required',
      label_minimum_total_should_be: 'Minimum total should be ',
      label_confirm: 'CONFIRM',
      label_order_type: 'Order Type',
      label_pickup: 'Pickup',
      label_total_price: 'Total Price:',
      label_condition: `*doesn't include tax`,
      label_checkout: 'CHECKOUT',
      label_order_complete: 'Order Complete',
      label_view_receipt: 'View Receipt',
      label_order_confirmation: 'Order Confirmation Number',
      label_thank_you: 'Thank You',
      error_event_details_not_found: 'Event details not found.',
      error_please_select_product_before_checkout: 'No product is selected, please select product.',
      error_please_select_product: 'Please select product',
      error_this_field_required: 'This field is required',
      error_event_code_max_length: 'Event code should not be grater than 20 characters.',
      error_contact_number_min_length: 'Phone number should be 10 digit',
      error_contact_number_max_length: 'Phone number should be 10 digit',
      error_zip_min_length: 'Minimum 5 digits allowed',
      error_zip_max_length: 'Zip code should be 5 digit',
      error_admin_zip_max_length: 'Maximum 10 digits allowed',
      error_address_max_length: 'Address should be 100 characters.',
      error_customer_address_max_length: 'Address should be 100 characters.',
      error_order_closed: 'Ordering is closed for this event.',
      label_pay: 'PAY',
      error_max_donation_amount: 'Max Donation amount is 9999',
      error_max_gratuity_amount: 'Max Gratuity amount is 9999',
      label_payment: 'PAYMENT',
      label_add_donation_comment: 'ADD DONATION COMMENT',
      label_view_top_doners: 'VIEW TOP DONORS',
      label_view_comments: 'VIEW COMMENTS',
      label_comment: 'COMMENT',
      label_for_any_questions: 'For any questions on your order',
      label_total_pay_price: 'Total Price: ',
      label_order_fail: 'Order Failed',
      label_go_to_home: 'Go To Home',
      label_select: 'Select',
      label_btn_one_last_thing: 'ONE LAST THING',
      error_sales_tax_min_value: 'Minimum value of sales tax should be 0',
      error_sales_tax_max_value: 'Maximum value of sales tax should be 100',
      error_giveback_percentage_min_value: 'Minimum value of giveback percentage should be 0',
      error_giveback_percentage_max_value: 'Maximum value of giveback percentage should be 100',
      error_delivery_fee_min_value: 'Minimum Delivery value is $1.00',
      error_delivery_fee_max_value: 'Maximum Delivery value is $2.00',
      label_select_image: 'Select Image',
      label_refund: 'Refund',
      label_refund_amount: 'Refund Amount',
      label_total_order_amount: 'Total Amount ',
      label_custom_amount: 'Custom Amount ',
      label_save: 'REFUND',
      label_refund_cancel: 'CANCEL',
      label_submit: 'SUBMIT',
      label_payment_status: 'Payment status',
      label_delivery_window: 'Delivery window',
      label_cancel: 'CANCEL',
      label_terms_agreement: ' Terms of Service',
      label_terms_customer_form: ' Terms of Service',
      label_customer_form_terms: ' By clicking here, I agree to',
      label_make_anonymous_order_donation: 'Make Anonymous Donation',
      label_promotional_emails_notification: ' I wish to receive occasional promotional emails and notifications from Kona Ice',
      label_privacy_policy: 'Privacy Policy',
      label_cookies: 'Cookies',
      label_taxIdEin: 'Tax ID / EIN',
      label_assign_asset: 'Assign Equipment',
      label_add_new_client: 'Add New Client',
      label_weather_type: 'Weather Type',
      label_categoty: 'Category',
      label_start_location: 'Start Location',
      label_end_location: 'End Location',
      label_print: 'Print',
      label_new_version_fount: 'New version found. Please reload window.',
      label_new_timezone_found1: 'Your franchise has changed the timezone to',
      label_new_timezone_found2: 'Your staff portal timezone will be synched with your franchise timezone.',
      label_timezone: 'Time Zone',
      label_order_address: 'Order Address',
      label_event_address: 'Event Address',
      label_use_time_slots: 'Use Time Slots',
      label_sold_out: 'Sold Out',
      label_minimum_donation_is: 'Minimum Donation is',
      label_my_cart: 'My Cart',
      label_add_extras: 'Add Extras/Flavors',
      label_delivery_time: 'Choose your delivery time',
      label_food_extra_for: 'Food Extra For',
      label_transaction_id: 'Transaction Id',
      label_no_comments_found: 'No Comments Found',
      label_donors: 'Donors',
      label_donation: 'Donation',
      label_anonymous_user: 'Anonymous User',
      label_no_donors_found: 'No Donors Found',
      error_comment_required: 'Comment Is Required',
      label_merge: 'Merge',
      label_merge_client: 'Merge Client',

      // Admin Started
      label_manage_franchise: 'Manage Franchise',
      label_search_franchise_text: 'Search by First Name, Last Name, Email',
      label_search_franchise_text_migration: 'Search by Franchise Name or Code',
      label_add_franchise: 'Add Franchise',
      label_edit_franchise: 'Edit Franchise',
      label_primary_email: 'Primary Email',
      label_primary_contact: 'Primary Contact Number',
      label_billing_name: 'Billing Name',
      label_billing_address: 'Billing Address',
      label_user_email: 'User Email',
      label_user_contact: 'User Contact Number',
      label_giveback: 'Giveback',
      label_add_setting: 'Add Settings',
      label_edit_setting: 'Update Settings',
      label_franchise_details: 'Franchise Details',
      label_billing_details: 'Billing Details',
      label_login_details: 'Login Details',
      //label_manage_template_categorys :'Manage Category Template',
      label_manage_template_category_for: 'Manage Category For',
      label_franchise_code: 'Franchise Code',
      label_billing_city: 'Billing City',
      label_billing_state: 'Billing State',
      label_billing_zip_code: 'Billing Zip Code',
      label_username: 'Username',
      label_franchise_migration: 'Franchise Migration',
      label_partial_migration: 'Partial Migration',
      label_data_aggregation: 'Data Aggregation',
      mobilePhone: 'Alternate Number',

      msg_franchise_activate: 'Are you sure that you want to activate this franchise?',
      msg_franchise_hold: 'Are you sure that you want to deactivate this franchise?',
      msg_contact_activate: 'Are you sure that you want to activate this contact?',
      msg_contact_hold: 'Are you sure that you want to deactivate this contact?',

      msg_brand_activate: 'Are you sure that you want to activate this brand?',
      msg_brand_delete:'Are you sure that you want to delete this brand?',
      msg_brand_hold: 'Are you sure that you want to deactivate this brand?',

      msg_template_activate: 'Are you sure that you want to activate this template?',
      msg_template_hold: 'Are you sure that you want to deactivate this template?',
      msg_template_delete: 'Are you sure that you want to delete this template?',
      
      label_enter_seller_franchise_code: 'Enter Seller Franchise Code',
      label_enter_buyer_franchise_code: 'Enter Buyer Franchise Code',
      label_seller_franchise_code: 'Seller Franchise Code',
      label_buyer_franchise_code: 'Buyer Franchise Code',
      label_seller_franchise_name: 'Seller Franchise Name',
      label_buyer_franchise_name: 'Buyer Franchise Name',
      label_data_migrate: 'Data to Migrate',
      label_type_migrate: 'Type of Migration',
      lable_enter_zip_codes: 'Enter Zip Code(s)',
      label_all_zips: 'All Zip Codes',
      btn_migrate: 'Migrate',
      btn_aggregate: 'Aggregate',
      lable_signature_name: 'Signature Name',

      label_data_fields_selected: 'Data fields Selected',
      label_type_migration: 'Type of Migration',
      label_zipcodes: 'Zip Codes',
      label_submited_datetime: 'Submitted Date Time',
      label_selected_brands: 'Selected Brand(s)',

      btn_add_franchise: 'Add Franchise',
      btn_add_contact: 'Add Contact',
      label_add_contact: 'Add Contact',
      label_edit_contact: 'Edit Contact',
      label_select_contact_type: 'Select Contact Type',

      label_client: 'Client',
      label_client_details: 'Client Details',
      label_give_back_percentage: 'Giveback Percentage',
      label_sales_tax_rate: 'Sales Tax Rate',
      label_select_client_industry: 'Select Client Industry',

      label_manage_customers: 'Customers',
      label_dashboard: 'Dashboard',
      label_is_prepay_event: 'Is Prepay Event?',
      label_add: 'Add',
      label_today: 'Today',
      label_file_name: 'File Name',
      label_revoke: 'Revoke',
      label_okay: 'Okay',
      label_completed: 'Completed',

      error_brandId: 'Please enter Brand Name',
      error_startDateTime: 'Please enter Event Start Date and Time',
      error_endDateTime: 'Please enter Event End Date and Time',
      error_prepayTabStartDateTime: 'Please enter Event PrePay Start Date and Time',
      error_prepayTabEndDateTime: 'Please enter Event PrePay End Date and Time',
      error_orderStartDateTime: 'Please enter Order Start Date and Time',
      error_orderEndDateTime: 'Please enter Order End Date and Time',
      error_eventName: 'Please enter Event Name',
      error_city: 'Please enter City',
      error_zipCode: 'Please enter Zip Code',
      error_address: 'Please enter Address',
      error_state: 'Please enter State',
      error_deliveryFee: 'Please enter Delivery fee',
      error_maxAllowedOrders: 'Please enter Max Allowed Orders',
      error_gratuityFieldLabel: 'Please enter Gratuity',
      error_recipientNameLabel: 'Please enter Recipient Name Label',
      error_weatherType: 'Please enter Weather Type',
      error_contactName: 'Please enter Contact Name',
      error_contactTitle: 'Please enter Contact Title',
      error_givebackCheck: 'Please enter Giveback Check',
      error_invoiceCheck: 'Please enter Invoice Number',
      error_key: 'Please enter the Key',

      // Staff Started
      label_contacts: 'Contact',
      label_tips_collected: 'Tips Collected',
      label_online_sales: 'Online Sales',
      label_no_equipments_assigned: 'No Equipment Assigned',
      label_clocked_in: 'Clocked In',
      label_clocked_out: 'Clocked Out',
      label_clocked_in_time: 'Total Time',
      label_clock_in: 'Clock In',
      label_clock_out: 'Clock Out',
      // label_timer_running: 'Timer is Running',
      // label_total_time: 'Total Clocked In Time',
      label_add_availability: 'Add Availability',
      label_edit_availability: 'Edit Availability',
      label_timer_running: 'Current Clocked In Time',
      label_total_time: 'Total Clocked In Time',
      label_reccurring_type: 'Recurring Type',
      label_associated_staff: 'Associated Staff',
      label_task_start_date: 'Task Start Date',
      label_task_end_date: 'Task End Date',
      label_todays_shift: "TODAY's SHIFT",
      label_todays_task: "TODAY's TASK(S)",
      label_no_shifts: 'No Shifts assigned to you today',
      label_no_tasks: 'No Tasks assigned to you today',
      label_no_staff_assigned: 'No Staff Assigned',
      label_no_events_assinged: 'No Event Assinged',
      label_no_notes: 'No Notes added',
      label_DNR: 'Do Not Repeat',
      no_event_for_this_shift: 'No Events / Tasks assigned to this shift',
      label_staff_assigned_to: 'Staff Assigned To',
      label_staff_assigned: 'Staff Assigned',
      label_change_to: 'Change To',
      label_shift_date_and_time: 'Shift Date and Time',

      label_request_time_offs: 'Request Time Offs',
      label_edit_request_time_off: 'Edit Request Time Off',

      label_clientranking_report: 'Client Ranking Report',
      lable_search_placeholder: 'Search By Client Name',
      lable_occurrence: 'Occurrence',
      lable_series: 'Series',

      label_corporate_template: 'Corporate Template',
      label_franchise_template: 'Franchise Template',
      msg_customer_deactivate: 'Are you sure that you want to deactivate this customer?',
      msg_customer_activate: 'Are you sure that you want to activate this customer?',


      // franchise dashboard
      lable_sales_giveback: 'Sales/Giveback',
      lable_sales_event_day_of_week: 'Sales/Events by Day of Week',
      lable_prepay_events: 'Prepay Events',
      lable_event_sales_average: 'Event Sales Average',


      lable_client_dashboard_sales: 'Sales',
      lable_client_dashboard_givebacks: 'Giveback',
      lable_client_dashboard_years: 'Year',
      lable_client_dashboard_events: 'Number of Events',

      lable_event_sales_by_location: 'Sales By Location',
      lable_event_sales_by_industry: 'Sales By Industry',
      lable_staff_overview: 'Staff Overview',
      lable_top_clients: 'Top Clients',
      lable_equipment_summary: 'Equipment Summary',

      label_add_waffle_menu: 'Add New Waffle Menu',
      label_edit_waffle_menu: 'Edit Waffle Menu',
      label_upload_icon: 'Upload Icon',
      label_intranet_app_display_name: 'Intranet App Display Name',
      label_intranet_app_link: 'Intranet App Link',
      label_manage_waffle_menu: 'Manage Waffle Menu',
      btn_add_new: 'Add New',
      label_intranet_icon: 'Icon',
      msg_no_sales_data: 'No sales data available for the selected time period. Please select a different time period.',
      msg_waffle_activate: 'Are you sure that you want to activate this waffle menu?',
      msg_waffle_deactivate: 'Are you sure that you want to deactivate this waffle menu?',
      msg_waffle_delete: 'Are you sure that you want to delete this waffle menu?',
      msg_contact_delete:'Are you sure you want to delete the contact details?',
      msg_blockOffDate_delete: 'Are you sure you want to delete this block off date? Once deleted you will not be able to recover it.',
      btn_add_date: 'Add Date',
      label_select_date: 'Select Date',
      labelAddBlockDate: 'Create Blocked Date',
      label_manage_timeOff: 'Manage Time Off',
      number_Of_days: 'Number Of Days',
      leave_status: 'Status',
      label_leave_dates: 'Leave Start and End Date',
      label_leave_reason:'Leave Reason',
      label_reject_reason:'Reject Reason',
      label_staff_name: 'Staff Name',
      msg_accept_switchShift: 'Do you want to approve this shift swap?',
      msg_reject_switchShift: 'Do you want to reject this shift swap?',
      label_manage_shift:'Manage Shift Swap Requests',
      label_event_task_name: 'Event/Task Name',
      label_task_change_info: 'Task start date, end date, associated event, staff, equipment will be saved for selected occurrence only even if you opt to save in series.',
      label_events_change_info: 'Event start date and end date will be saved for selected occurrence only even if you opt to save in series.',
      label_add_recu_staff_info: 'For recurring task, staff will be allocated for the first occurrence only.',
      btn_save_client_temp: 'Save & Update Client',
      label_campaign: 'Campaign',
      label_schedule_date: 'Schedule Date',
      label_search_campaign_tooltip: 'Search by Campaign Name',
      msg_campaign_activate: 'Are you sure that you want to activate this campaign?',
      msg_campaign_deactivate: 'Are you sure that you want to deactivate this campaign?',
      msg_campaign_optIn: 'Are you sure that you want to Opt-In this campaign?',
      msg_campaign_optOut: 'Are you sure that you want to Opt-Out this campaign?',
      label_upload_contact: 'Upload Contacts',
      label_add_new_contact: 'Add New Contact',
      label_legacy_data: 'Legacy Data',
      label_search_legacy_data: 'Search by First Name, Last Name & Email',
      msg_delect_legacy_contact: 'Are you sure that you want to delete this contact?',
      msg_delete_selected_contacts: 'Are you sure that you want to delete the contact(s)?',
      msg_delete_all_contacts: 'Are you sure that you want to delete the contact(s)?',
      label_add_legacy_contact: 'Add Legacy Contact',
      label_edit_legacy_contact: 'Edit Legacy Contact',
      label_company_name: 'Company Name',
      label_firstName2: 'First Name2',
      label_lastName2: 'Last Name2',
      label_address1: 'Address1',
      label_address2: 'Address2',
      label_dob: 'Birth Date',
      label_user_set_opt_out_mail: 'Opt Out Email',
      label_opt_out_phone: 'Opt Out Phone',
      label_opt_out_mobile_phone: 'Opt Out Mobile Phone',
      label_opt_out_Address: 'Opt Out Address',
      label_country_code: 'Country Code',
      label_mobile_phone: 'Mobile Phone',
      label_delete_contacts: 'Delete',
      label_seedRecord: 'Seed Record',
      label_userOptOutEmail: 'User OptOut Email',
      label_userOptOutAddress: 'User OptOut Address',
      label_emailDeliverable: 'Email Deliverable',
      label_usableEmail: 'Usable Email',
      label_usableAddress: 'Usable Address',
      label_validEmail: 'Valid Email',
      label_assign_permission: 'Assign Permissions',
      label_view: 'View',
      label_read_write: 'Read - Write',
      label_viewer: 'Viewer',
      label_editor: 'Editor',
      label_alt_contact_number: 'Alternate Phone',
      label_opt_out_in_email: 'Opt In/Out Email',
      label_brand_opt_in: 'Brand Opt In',
      label_select_email_template: 'Select Email Template',
      label_search_FBR: 'Search Request by Brand Name, Name, Email & Number ',
      label_edit_full_FBR: 'Edit No-Reply',
      label_create_client: 'Create Client',
      label_set_as_primary: 'Set as Primary',
      label_set_as_primary_contact: 'Are you sure want to set this as primary contact?',
      label_send_report: 'Send Report',
      label_prepay_report_msg: 'Are you sure want to generate prepay orders report?',
      label_no_reply_email: 'No-Reply Email Templates',
      label_event_date_time: 'Event Date and Time',
      label_publish: 'Publish',
      label_save_publish: 'Save & Publish',
      label_industry_change_message: 'Do you wish to update the industry type for all events associated to this client?',
      label_no_replies: 'No-Replies',
      label_set_Staff_notification: 'Set Message',
      label_broadcast_msg: 'Message',
      label_add_broadcast: 'Add Message',
      label_del_broadcastMessage: 'Are you sure want to delete this message?',
      label_publish_broadcastMessage: 'Are you sure want to publish this message?',
      label_search_message: 'Search by Message',
      label_enter_broadcast_message: 'Enter message',
      label_message_receiver: 'Message Receiver',
      label_add_new_staff_notes: 'Add New Staff Notes',
      label_edit_staff_notes: 'Edit Staff Notes',
      label_add_new_equipment_log: 'Add New Equipment Log',
      label_edit_equipment_log: 'Edit Equipment Log',
      label_aging_report: 'Aging Report',
      label_past: 'No Events in the Past..',
      label_future: 'No Future Events in the Next..',
      lable_select_brand: 'Select Brand',
      lable_event_sales_from: 'Event Sales From',
      lable_event_sales_to: 'Event Sales To',
      label_event_sales_range: 'Event Sales Range',
      label_aging_sales_range_from: 'Sales Range From',
      label_aging_sales_range_to: 'Sales Range To',
      label_send_to_all: 'Send To All',
      label_send_days: 'Last Send (Days)',
      
      msg_staff_note_delete: 'Are you sure want to delete staff notes?',

      label_audience: 'Audience:',
      label_delivered: 'Delivered:',
      label_subject1: 'Subject:',
      label_exportToGoogle: 'Save New Events to Google Calendar',
      label_banner_text : 'Banner Text',
      label_show_banner_text: 'Show Banner Text',
      label_show_banner_image: 'Show Banner Image',
    });
  }
}
