import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { UiService } from '../../services/ui.service';
import { MiGridComponent } from '../mi-grid/mi-grid.component';

@Component({
  selector: 'app-shared-segments',
  templateUrl: './shared-segments.component.html',
  styleUrls: ['./shared-segments.component.css']
})
export class SharedSegmentsComponent implements OnInit {

  @Input() fromComponent: any;
  gridurl = '/secure/segments/segments-grid-list';
  exportBaseUrl = `/secure/assets/export-xls`;
  updatedUrl = '';
  columns: any[] = [];
  buttons: any[] = [];
  searchBar = 'top';
  exportButton = 'top';

  search;
  params;
  timer = null;
  @ViewChild(MiGridComponent) gridRef: MiGridComponent;

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
    private commonBindingDataService: CommonBindingDataService,
    private franchiseService: FranchiseService) {
  }

  ngOnInit() {
    this.createGrid();
  }

  createGrid() {
    this.columns.push({
      field: 'segmentName', width: '25%',
      header: this.commonBindingDataService.getLabel('label_segment'), sort: true, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'createdAt', width: '25%',
      header: this.commonBindingDataService.getLabel('label_created_at'), sort: true, resize: true, class: 'eclipse',
      htmlRenderer: (data) => {
        return this.commonBindingDataService.getUTCDateWithLocalTime(data.createdAt);
      }
    });

    this.columns.push({
      field: 'contactMatchCount', width: '25%',
      header: this.commonBindingDataService.getLabel('label_users_match_your_conditions'), sort: true, resize: true, class: 'eclipse',
    });


    this.buttons.push({
      field: 'eventHold',
      label: '',
      width: '25%',
      class: 'event-hold-icon', show: (data) => {
        if (data.activated) {
          return '<div class="event-hold-icon" title = "Deactivate"></div>';
        } else {
          return '<div class="event-open-icon" title = "Activate"></div>';
        }
      }
    });
  }


  onActionBtnClicked(e: any) {
    const actionType = e.class;
    const data = e.row;
    if (actionType !== undefined) {
      switch (actionType) {
        case 'event-hold-icon':
          this.changeEventStatus(data);
          break;
        case 'delete':
          break;
      }
    }
  }

  changeEventStatus(data) {
    let objectToSend = {};
    let msg = this.commonBindingDataService.getLabel('msg_segment_open');
    if (data.activated) {
      msg = this.commonBindingDataService.getLabel('msg_segment_hold');
    }
    objectToSend = {
      activated: !data.activated
    };
    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.franchiseService.activeInactiveSegment(objectToSend, data.segmentId).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          // this.applyFilter('apply');
          this.gridRef.updateUrl(this.gridurl);
        }, error => {
        }
        );
      }
    });
  }


  onSearch() {
    if ((this.search && this.search.length >= 3) || this.search.length === 0) {
      if (this.search.length >= 3 && this.search.trim() === '') {
        return;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.applyFilter('apply'), AppSettings.SEARCH_DELAY_INTERVAL);
      // this.applyFilter('apply');
    }
  }

  applyFilter(event?) {
    if (event === 'apply') {
      this.params = '';

      if (this.search !== undefined && this.search !== null) {
        this.gridRef.searchText = this.search.trim();
      }
      this.updatedUrl = this.gridurl + this.params;
      this.gridRef.updateUrl(this.updatedUrl);
    }
  }

  onResetFilter() {
    this.gridRef.searchText = '';
    this.search = '';
    this.gridRef.updateUrl(this.gridurl);
  }

  onAdd() {
    if (this.fromComponent === 'admin') {
      this.router.navigate(['admin/marketing/segments/segment-add']);
    } else {
      this.router.navigate(['franchise/marketing/segments/segment-add']);
    }
  }

  goBack() {
    window.history.back();
  }

}
