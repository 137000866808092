import { Component, OnInit, ViewChild } from '@angular/core';
import { AppSettings } from '../../app.settings';
import { MiGridComponent } from '../mi-grid/mi-grid.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { UiService } from '../../services/ui.service';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { StorageService } from '../../services/storage.service';
import { UserCommonService } from '../../services/user-common.service';
import { AdminService } from '../../../admin/services/admin.service';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {

  loginData;
  gridurl = '/secure/mailchimp-campaigns/list/grid-data';
  updatedUrl = '';
  columns: any[] = [];
  buttons: any[] = [];
  searchBar = 'top';
  timer = null;
  ID;
  search;
  params;
  valuesOptions: any = [];
  selectedValue = '';
  selectedBrands = [];
  subscribeBrand: Subscription;
  @ViewChild(MiGridComponent) gridRef: MiGridComponent;
  brandNameOptions: any = [];
  statusOptions: any = [];
  selectedStatus;
  selectedBrandFromFilter = [];
  showBrandfilter: boolean;
  loggedInUserId;
  loginUserRole;
  franchiseLogin: boolean = false;
  isAdminLogin = true;
  previousBrand = [];
  franchiseId = null;
  imgurl;
  showDetailsPopup = false;
  campaignReadWrite = false;
  exposeCampaignReport: any;
  

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
    private commonBindingDataService: CommonBindingDataService,
    private franchiseService: FranchiseService,
    private storageService: StorageService,
    private adminService: AdminService,
    private domSanitizer: DomSanitizer) {
      this.franchiseId = this.storageService.getItem('FRANCHISE_ID');
      this.campaignReadWrite = this.commonBindingDataService.checkIfHavePermission('MANAGE_CAMPAIGN', true, true);
      if(this.franchiseId && this.franchiseId !== 'null') {
        this.gridurl = this.gridurl + '?franchiseId='+JSON.parse(this.franchiseId);
        this.applyFilter();
      }else {
        this.gridurl = this.gridurl + '?franchiseId=null';
        this.applyFilter();
      }
      
  }

  ngOnInit() {
    this.exposeCampaignReport = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA)).exposeCampaignReport;
    this.statusOptions = AppSettings.CAMPAIGN_STATUS;
    this.loginUserRole = this.commonBindingDataService.getLoginRole();
    if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE) {
      if(this.statusOptions[3]?.value == 'paused') {
        this.statusOptions.splice(-1, 1);
      }
      this.franchiseLogin = true;
      this.isAdminLogin = false;
      
    }else {
      this.franchiseLogin = false;
      this.isAdminLogin = true;
    }
    this.getBrandList();
    this.getAdminSettings();
  }

  getAdminSettings(){
    this.adminService.getAdminSettings().subscribe((result) => {
      if(result){
        this.exposeCampaignReport = result.exposeCampaignReport;
        this.createGrid();
      }
    });
  }

  onStatus(e) {
    console.log(e.value)
    this.selectedStatus = e.value;
    this.applyFilter('apply');
  }

  createGrid() {
    this.columns = [];
    this.columns.push({
      field: 'title', width: '180px',
      header: this.commonBindingDataService.getLabel('label_campaign_name'), sort: false, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'brandName', width: '100px',
      header: this.commonBindingDataService.getLabel('label_brandName'), sort: false, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'sendTimeInMills', width: '120px',
      header: this.commonBindingDataService.getLabel('label_schedule_date_time'), sort: false, resize: true, class: 'eclipse',
      htmlRenderer: (data) => {
        let start = this.commonBindingDataService.convertedTimeAsPerTz1(data.sendTimeMills);
        const date = data.sendTimeMills ? this.commonBindingDataService.getLocalDateTime(start): '-'
        // console.log(date)
        return `<div>${date} </div>`
      }
    });

    this.columns.push({
      field: 'status', width: '90px',
      header: this.commonBindingDataService.getLabel('label_status'), sort: false, resize: true, class: 'eclipse col-status',
      htmlRenderer: (data) => {
        if(data.status == 'schedule'){
          return `<div>Scheduled </div>`
        }else {
          return `<div>${data.status} </div>`
        }
      }
    });

    this.buttons.push({ icon: '', class: 'view-details-icon', label: 'View Campaigns' });
    if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE || this.loginUserRole == AppSettings.USER_ROLE_MANAGER) {
      if(this.campaignReadWrite){
        this.buttons.push({
          field: 'eventHold',
          label: '',
          class: 'event-opt-icon', show: (data) => {
              if (data.franchiseOptOutCampaign) {
                return '<div class="opt-in" title = "Opt-in"></div>';
              } else {
                return '<div class="opt-out" title = "Opt-out"></div>';
              }
          }
        });
      }

    } 
    if(this.loginUserRole == AppSettings.USER_ROLE_ADMIN){
        this.buttons.push({
          field: 'eventActivate',
          label: '',
          class: 'event-hold-icon', show: (data) => {
            if (data.activated) {
              return '<div class="event-hold-icon deactivate" title = "Deactivate"></div>';
            } else {
              return '<div class="event-open-icon activate" title = "Activate"></div>';
            }
          }
        });
      }
      if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE || this.loginUserRole == AppSettings.USER_ROLE_MANAGER) {
        if(this.exposeCampaignReport){
          this.buttons.push({
            field: 'viewReport',
            label: '',
            class: 'orders-icon', show: (data) => {
              if (data.status == '<div>sent </div>') {
                return '<div class="orders-icon" title = "View Report"></div>';
              } else {
                return '';
              }
            }
          });
        }
      }

  }

  getBrandList() {
    this.adminService.getAllBrandsList().subscribe((result) => {
      this.brandNameOptions = result.map(element => ({
        label: element.brandName, value: element.id, brandId: element.brandId,
        activated: element.activated, color: element.color,
        deleted: element.deleted, inactive: false,
      }));
      this.selectedBrandFromFilter = this.brandNameOptions.map((item) => item.value.trim());
      this.onClickFilter();
    });
  }
  
  onSelectBrand(e) {
    if(this.selectedBrandFromFilter.length === 0){
      this.previousBrand[0] = e.itemValue;
      setTimeout(() => {
        this.selectedBrandFromFilter = this.previousBrand;
      }, 300);
    }
    setTimeout(() => {
      this.onClickFilter();
    }, 300);
  }

  onActionBtnClicked(e: any) {
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    const actionType = e.class;
    const data = e.row;
    if (actionType !== undefined) {
      switch (actionType) {
        case 'event-hold-icon':
          this.changeCampaignStatus(data)
          break;
        case 'event-opt-icon':
          this.changeCampaignOpt(data)
          break;
        case 'view-details-icon':
          this.imgurl = data.previewImageUrl;
          this.imgurl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.imgurl)
          this.showDetailsPopup = true;
          break;
        case 'orders-icon':
          this.router.navigate(['franchise/marketing/campaign-report'], { queryParams: { id: data.id, brandId: data.brandId } });
          break;
      }
    }
  }

  changeCampaignOpt(data) {
    let objectToSend = {};
    let msg = this.commonBindingDataService.getLabel('msg_campaign_optIn');
    if (data.franchiseOptOutCampaign) {
      let franchiseOptOutCampaignId = data.franchiseOptOutCampaign.id;
      this.confirmationService.confirm({
        message: msg,
        accept: () => {
          this.franchiseService.optInCampaign(franchiseOptOutCampaignId).subscribe((result) => {
            this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
            this.applyFilter('apply');
          }, error => {
          }
          );
        }
      });
    }else {
      msg = this.commonBindingDataService.getLabel('msg_campaign_optOut');
      objectToSend = {
        masterCampaignId: data.id,
        franchiseId: JSON.parse(this.franchiseId),
        brandId: data.brandId
      };
      this.confirmationService.confirm({
        message: msg,
        accept: () => {
          this.franchiseService.optOutCampaign(objectToSend).subscribe((result) => {
            this.uiService.showMessage('Campaign opt-outed successfully ', AppSettings.MESSAGE_TYPE.SUCCESS);
            this.applyFilter('apply');
          }, error => {
          }
          );
        }
      });
    }
  }

  changeCampaignStatus(data) {
    let objectToSend = {};
    let msg = this.commonBindingDataService.getLabel('msg_campaign_activate');
    if (data.activated) {
      msg = this.commonBindingDataService.getLabel('msg_campaign_deactivate');
    }
    objectToSend = {
      id: data.id,
      activated: !data.activated
    };

    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.franchiseService.activateDeactivateCampaign(objectToSend).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.applyFilter('apply');
        }, error => {
        }
        );
      }
    });
  }

  onClickFilter() {
    this.applyFilter('apply');
  }

  onSearch(e) {
    const qoutes = `'"`;
    const regexStr = '^[-a-zA-Z0-9_@.!*?,()^' + qoutes + ' ]*$';
    if (!new RegExp(regexStr).test(e.key) || e.keyCode === 16 || e.keyCode === 18) {
      return;
    }
    if ((this.search && this.search.length >= 3) || this.search.length === 0) {
      if (this.search.length >= 3 && this.search.trim() === '') {
        return;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.applyFilter('apply'), AppSettings.SEARCH_DELAY_INTERVAL);
      // this.applyFilter('apply');
    }
  }

  applyFilter(event?) {
    
    if (event === 'apply') {
      this.params = '';
      if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE) {
        const status = this.selectedStatus ? this.selectedStatus:'sent,schedule';
        this.params = '&statuses='+status;
      }else {
        const status = this.selectedStatus ? this.selectedStatus:'sent,schedule,paused';
        this.params += `&brandIds=${this.selectedBrandFromFilter}`+'&statuses='+status;
      }

      if (this.search !== undefined && this.search !== null) {
        this.gridRef.searchText = this.search.trim();
      }

      this.updatedUrl = this.gridurl + this.params;
      this.gridRef.updateUrl(this.updatedUrl);
    }
  }

  onResetFilter() {
    this.gridRef.searchText = '';
    this.search = '';
    this.gridRef.updateUrl(this.gridurl);
  }

  goBack() {
    window.history.back();
  }

}
