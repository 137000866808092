
<div class="page-container" style="padding-left: 0px;padding-right: 0px;">
  <!-- <div class="page-header" style="padding-right: .5em;">
    <div class="page-heading">
    </div>
    <div class="page-actions grid">
      <div class="col-8" style="text-align: right;">(.doc(x), .xls(x), .txt, .pdf, .png, .jpg, .jpeg. Max size allowed is 10mb)</div>
      <div class="col-4 small-btn import-file-button">
         <app-file-uploader  [inputUrl] = "UploadFileUrl"
         accept=".xls,.xlsx,.jpg,.jpeg,.png,.ico,.tiff,.bmp,.txt,.odt,.docx,.pdf"

         [uploadButtonLabel]="btnUploadLabel" (afterFileUploadEvent)="onUploadFile($event)"
          (afterFileUploadFailed)="onUploadFailed($event)"
         >
         </app-file-uploader>
      </div>
    </div>
  </div> -->

  <div class="grid col-12" style="justify-content: end;color: #568EE8;padding-right: 0;margin-left: 0px;">
    <div class="col-8 mob-left-text">Files allowed: .doc(x), .xls(x), .txt, .pdf, .png, .jpg, .jpeg. Max size allowed is 10mb</div>
    <div class="col-4 small-btn import-file-button">
        <app-file-uploader  [inputUrl] = "UploadFileUrl"
        accept=".xls,.xlsx,.jpg,.jpeg,.png,.ico,.tiff,.bmp,.txt,.odt,
        .docx,.pdf"
        [uploadButtonLabel]="btnUploadLabel"
        [maxFileSizeMessage] = "maxFileSizeMessage"
        (afterFileUploadEvent)="onUploadFile($event)"
        (afterFileUploadFailed)="onUploadFailed($event)"
        >
        </app-file-uploader>
    </div>
  </div>

  <div class="page-body">
    <app-mi-grid-v2 [url]="gridurl" [columnDefs]="columns"
        (actionBtnClickedEvent)="onActionBtnClicked($event)" [actionButtonsDefs]="buttons"
        divClass="data-grid-lines" [searchBar]="searchBar" [isExportVisible] = "false"
        [noRecordsMsgFromParent] = "noRecordsMsgFromParent">
    </app-mi-grid-v2>
  </div>

</div>
