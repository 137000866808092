import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { UiService } from '../../services/ui.service';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { StorageService } from '../../services/storage.service';
import { ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { UserCommonService } from '../../services/user-common.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-assign-bulk-event-to-asset',
  templateUrl: './assign-bulk-event-to-asset.component.html',
  styleUrls: ['./assign-bulk-event-to-asset.component.scss']
})
export class AssignBulkEventToAssetComponent implements OnInit {
  @Input() showAssignPopup: any;
  @Output() assignPopUpEvent: EventEmitter<any> = new EventEmitter<any>();


  accessEventsAssignStaff = this.commonBindingDataService.checkIfHavePermission('ASSIGN_STAFF_TO_EVENT', true, true);
  accessEventsAssignEquipment = this.commonBindingDataService.checkIfHavePermission('ASSIGN_EQUIPMENT_TO_EVENT', true, true);
  accessTaskAssignStaff = this.commonBindingDataService.checkIfHavePermission('ASSIGN_STAFF_TO_TASK', true, true);
  accessTaskAssignEquipment = this.commonBindingDataService.checkIfHavePermission('ASSIGN_EQUIPMENT_TO_TASK', true, true);
  addEventForm: UntypedFormGroup;
  submitted = false;
  userId = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA))?.franchiseId;
  @Input() checkedCondition: boolean;
  @Input() assignPopupInputObj: any;

  @Input() showaddEventForm: any;
  @Input() clickedEventDate: any;
  @Input() selectedSchedule: any;
  @Input() selectedResource: any;
  @Input() staffAssetId: any;

  @Output() popUpEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatedRecord: EventEmitter<any> = new EventEmitter<any>();
  startDayOfWeek = this.userCommonService.getUpdatedStoredValue(AppSettings.START_DAY_OF_WEEK_FOR_MONTH);
  staffList: any[] = [];
  eventList: any[] = [];
  taskList: any[] = [];
  taskNameList: any[] = [];
  assetNameList: any[] = [];
  assetList: any[] = [];
  selectedTaskList: any[] = [];
  eventListForRender: any[] = [];
  events: any;
  eventsStartimes = [];
  eventsEndtimes = [];
  // labelHeader: any;
  header: any;
  timezone: any = Intl.DateTimeFormat().resolvedOptions().timeZone;
  isEventMandatory: boolean = true;
  isTaskMandatory: boolean = true;
  maxDate: any;
  firstStartDate = new Date();
  isTimeRoundUp: boolean;
  timeError1: boolean = true;
  timeError2: boolean = true;
  timeError3: boolean = true;
  pastScheduleCreation: boolean = false;
  totalEventsCount = 0
  selectedAsset = [];
  tasks: any;
  loggedInUserId = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA))?.franchiseId;
  brandNameOptions = [];
  shiftbrandId: any;
  defaultBrand: any;
  selectedBrandFromFilter: any;
  timeList = AppSettings.STAFF_SCHEDULE_TIME_SLOT;
  startLead: any = this.timeList[0];
  endLead: any = this.timeList[0];

  //** new addded start */
  showSwitch = false;
  assetListOptions: any[] = [];
  selectedAssetsForView: any;
  assetsListForView: any = [];
  showEquipment = true;
  showStaff = false;
  checkedEventsForEquipment = [];
  eventDisabled: boolean = false;
  taskDisabled: boolean = false;
  saveDisabled: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonBindingDataService,
    private uiService: UiService,
    private franchiseService: FranchiseService,
    private storageService: StorageService,
    private confirmationService: ConfirmationService,
    private commonBindingDataService: CommonBindingDataService,
    private datePipe: DatePipe,
    private userCommonService: UserCommonService

  ) {
    this.header = this.commonBindingDataService.getLabel('Assign Events');
    this.selectedBrandFromFilter = JSON.parse(this.storageService.getItem('selectedBrands'));
  }

  ngOnInit(): void {
    this.clickedEventDate = (this.assignPopupInputObj && this.assignPopupInputObj.inputDate) ? this.assignPopupInputObj.inputDate : new Date().setHours(0, 0, 0, 0);
    this.getAndCheckLeads();
    // this.checkIfPastScheduleCreated(); //** commented in refactoring */
    this.getAssetListData();
    this.initForm();
    // this.getPreFillData(); //** commented in refactoring */
    this.firstStartDate = null;
    this.getBrandList(this.loggedInUserId, true);
    this.checkPermissions();
    // if (this.assignPopupInputObj) {
    this.clickChecked();
    // }
  }


  // checkIfPastScheduleCreated() {
  //   // if (new Date(this.clickedEventDate).getTime() <= new Date().getTime()) {
  //   if (new Date(this.clickedEventDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
  //     this.pastScheduleCreation = true;
  //     // console.log('past', this.pastScheduleCreation)
  //   }
  // }

  initForm() {
    this.addEventForm = this.formBuilder.group({
      assetId: '',
      staffId: [{
        value: (this.selectedResource && this.selectedResource !== 'UNASSIGNED') ?
          this.selectedResource : '', disabled: false
      }], // disabled: this.selectedSchedule.disabled
      brandId: [{ value: this.defaultBrand ? this.defaultBrand : '', disabled: false }, [Validators.required]],
      startShift: [{ value: '', disabled: false }],
      endShift: [{ value: '', disabled: false }],
      events: [{ value: [], disabled: false }],
      tasks: [{ value: [], disabled: false }],
      allSelect: [''],
      startDate: [{ value: '' }, [Validators.required]],
    });
  }

  clickChecked(e?, from?) {
    this.getselectedDate();
    this.submitted = false;
    this.addEventForm.controls.staffId.setValue('');
    this.addEventForm.controls.assetId.setValue('');
    this.addEventForm.controls.allSelect.setValue('');
    this.resetEventsTaskSelection();

    if (this.assignPopupInputObj && !from) {
      if (this.assignPopupInputObj.equpmentMode) {
        this.selectedResource = this.assignPopupInputObj.equipmentId;
        this.checkedCondition = true;
        this.showEquipment = true;
        this.showStaff = false;
      } else {
        this.selectedResource = this.assignPopupInputObj.staffId;
        this.checkedCondition = false;
        this.showEquipment = false;
        this.showStaff = true;
      }
    } else if ((e && e.checked) || this.checkedCondition) {
      this.assignPopupInputObj = null;
      this.selectedResource = null;

      this.checkedCondition = true;
      this.showEquipment = true;
      this.showStaff = false;
    } else {
      this.assignPopupInputObj = null;
      this.selectedResource = null;

      this.showEquipment = false;
      this.showStaff = true;
    }

    this.checkMinimumData();
    // setTimeout(() => {
    //   this.addEventForm.controls.startDate.setValue(new Date(this.clickedEventDate));
    //   if (this.clickedEventDate) {
    //     this.onSelectDates('startDate');
    //   }
    // }, 1000);
    this.checkPermissions();
  }


  checkMinimumData() {
    this.getselectedDate();
    this.setSelectedBrand();
    if (this.clickedEventDate) {
      this.addEventForm.controls.startDate.setValue(new Date(this.clickedEventDate));
      // let startShift = this.addEventForm.controls.startDate.value;
      // this.addEventForm.controls.startShift.setValue(startShift);
    }
    const controls = this.addEventForm.controls;
    if (controls.brandId.value && controls.startDate.value) {
      this.getPreFillData();
    }
  }

  getAvailabilityTitle(ele) {
    const returnArr = [];
    ele.forEach(element => {
      let label = ''
      if (element.available && element.allDay) {
        label = 'Available All Day';
      } else if (element.available && !element.allDay) {
        label = 'Partially Available - ' + new Date(element.startDateTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) +
          ' - ' + new Date(element.endDateTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      } else if (!element.available && !element.allDay) {
        label = 'Partially Unavailable  - ' + new Date(element.startDateTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) +
          ' - ' + new Date(element.endDateTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      } else if (!element.available) {
        label = 'Unavailable All Day';
      }
      returnArr.push(label)
    });

    return returnArr;
  }

  getBrandList(id, isNew?) {
    this.franchiseService.getBrandList(id, isNew).subscribe((result) => {
      this.brandNameOptions = result.map(element => ({ label: element.brandName, value: element.brandId, activated: element.activated, deleted: element.deleted, inactive: false }));
      if (this.brandNameOptions.length == 1) {
        this.defaultBrand = result[0].brandId;
        this.initForm();
        this.onChangeBrand('html');
      }
      this.brandNameOptions.unshift({ label: this.commonService.getLabel('Select'), value: '', activated: true, deleted: false, inactive: false });
    });
    if (this.selectedSchedule) {
      this.addEventForm.controls.brandId.setValue(this.selectedSchedule.brandId);
      setTimeout(() => {
        this.onChangeBrand('html');
        this.disabledBrand();
      }, 900);
    } else if (!this.selectedSchedule && this.selectedBrandFromFilter.length == 1) {
      this.defaultBrand = this.selectedBrandFromFilter[0];
      this.initForm();
      setTimeout(() => {
        this.onChangeBrand('html');
      }, 500);
    }
  }

  disabledBrand() {
    const activeBrands = this.brandNameOptions.filter(brand => {
      return brand.activated === true && brand.deleted === false && brand.value !== this.selectedSchedule.brandId;
    });
    const selectedBrand = this.brandNameOptions.filter(brand => {
      return brand.value == this.selectedSchedule.brandId;
    });
    this.brandNameOptions = [];
    this.brandNameOptions = [...activeBrands, ...selectedBrand];
    setTimeout(() => {
      this.brandNameOptions.forEach(element => {
        if (element.activated == false || element.deleted == true) {
          element.inactive = true;
        } else {
          element.inactive = false;
        }
      });
      this.addEventForm.controls.brandId.disable();
    }, 800);
  }

  onChangeBrand(from?, click?) {
    if (from && from == 'html') {
      this.staffList = [];
      this.eventListForRender = [];
      this.taskNameList = [];
      this.checkPreviousStaff(this.selectedResource);
      this.addEventForm.controls.startShift.setValue('');
      this.addEventForm.controls.endShift.setValue('');
      this.addEventForm.controls.events.setValue('');
      this.addEventForm.controls.tasks.setValue('');
      this.addEventForm.controls.allSelect.setValue('');
      this.shiftbrandId = this.addEventForm.controls.brandId.value;

      if (this.defaultBrand && this.selectedResource && this.selectedResource !== 'UNASSIGNED' && !click && click !== 'clickOnBrand') {
        this.addEventForm.controls.brandId.setValue(this.defaultBrand);
      }

      //** for Staff mode */
      if (this.showStaff) {
        setTimeout(() => {
          if (this.assignPopupInputObj && this.assignPopupInputObj.staffId) {
            this.addEventForm.controls.staffId.setValue(this.assignPopupInputObj.staffId);
          }
          this.getPreFillData();
        }, 1000);
      }

      //** for equipment selection */ 
      if (this.showEquipment) {
        setTimeout(() => {
          this.getAssetListData(this.addEventForm.controls.brandId.value);
          this.addEventForm.controls.startDate.setValue(new Date(this.clickedEventDate));
          if (this.clickedEventDate) {
            // this.onSelectDates('startDate');
            this.checkMinimumData();
          }
        }, 1000);
      }
    }
  }

  checkPreviousStaff(staff) {
    setTimeout(() => {
      const previousStaff = this.staffList.some(function (el) {
        return el.value === staff;
      });
      if (previousStaff) {
        this.addEventForm.patchValue({
          staffId: this.selectedResource ? this.selectedResource : '',
        });
      } else {
        this.addEventForm.controls.staffId.setValue('');
      }
    }, 1000);
  }

  getPreFillData() {
    let fromDate: any;
    if (this.selectedSchedule) {
      fromDate = this.selectedSchedule.startDateTime;
      this.maxDate = new Date(this.selectedSchedule.startDateTime);
      this.maxDate.setHours(23, 59, 0);
      this.shiftbrandId = this.selectedSchedule.brandId;
    } else {
      fromDate = new Date(this.clickedEventDate).getTime();
      this.maxDate = new Date(this.clickedEventDate);
      this.maxDate.setHours(23, 59, 0);
      if (this.addEventForm.controls.brandId.value == '') {
        this.shiftbrandId = [AppSettings.DOMAIN_VALUES.KONA_BRAND_ID, 
        AppSettings.DOMAIN_VALUES.TOMS_BRAND_ID,
        AppSettings.DOMAIN_VALUES.ANNS_BRAND_ID];
      } else {
        this.shiftbrandId = this.addEventForm.controls.brandId.value;
      }
    }

    if (this.showStaff) {
      this.callUserList(fromDate);
      if (this.assignPopupInputObj && this.assignPopupInputObj.staffMode
        && this.assignPopupInputObj.staffId && !this.addEventForm.controls.staffId.value) {
        this.addEventForm.controls.staffId.setValue(this.assignPopupInputObj.staffId);
      }
    }

    //** commented in refactoring */
    // if (!this.selectedSchedule || this.selectedSchedule.assetId == null) {
    // this.getAllEventsList();
    // }

    this.getAllEventsList();
    this.getAllTaskList();

    //** commented in refactoring */
    // this.showaddEventForm = true;
    // const header = this.commonBindingDataService.getLabel('label_schedule_shift');
    // if (this.selectedSchedule) {
    //   this.addEventForm.patchValue({
    //     staffId: this.selectedSchedule.userId && this.selectedSchedule.userId !== 'UNASSIGNED'
    //       ? this.selectedSchedule.userId : '',
    //     startShift: new Date(this.selectedSchedule.startDateTime),
    //     endShift: new Date(this.selectedSchedule.endDateTime),
    //     // assets: this.selectedSchedule.assetId,
    //   });
    // }

    // if (this.staffAssetId && !this.selectedSchedule) {
    //   this.addEventForm.patchValue({
    //     staffId: this.staffAssetId,
    //   });
    // }
  }

  callUserList(fromDate?) {
    let start = this.commonService.convertedUTCTime(new Date(fromDate));
    const params = new HttpParams()
      .set('fromDate', start)
      .set('toDate', '')
      .set('brandIds', this.shiftbrandId)
      .set('eventId', '')
      .set('activated', 'true')
      .set('applyActivatedStatus', 'true')
      .set('limit', '200');

    this.franchiseService.getAllScheduledStaffWithStatus(params).subscribe((result) => {
      this.staffList = result.data.map(element => {
        const obj = {
          label: '',
          value: '',
          code: [],
          // disabled: !element.activated,
          inactive: element.notAvailableForAssign
        };
        if (element.staffAvailabilitiesList && element.staffAvailabilitiesList.length > 0) {
          if (element.staffAvailabilitiesList.length == 1) {
            obj.label = element.firstName + ' ' + element.lastName;
            obj.value = element.userId;
            obj.code = this.getAvailabilityTitle(element.staffAvailabilitiesList);
          } else if (element.staffAvailabilitiesList.length > 1) {
            const sortedByUpdated = element.staffAvailabilitiesList.sort((a, b) => a.updatedAt - b.updatedAt);
            obj.label = element.firstName + ' ' + element.lastName;
            obj.value = element.userId;
            obj.code = this.getAvailabilityTitle(sortedByUpdated);
          }
        } else {
          obj.label = element.firstName + ' ' + element.lastName;
          obj.value = element.userId;
          obj.code[0] = '';
        }
        return obj;
      });

      if (this.selectedSchedule && this.selectedSchedule.userId != null) {
        const obj = {
          label: '',
          value: '',
          code: [],
          disabled: true
        };
        const userId = this.selectedSchedule.userId;
        obj.label = this.selectedSchedule.firstName + ' ' + this.selectedSchedule.lastName;
        obj.value = this.selectedSchedule.userId;
        obj.code[0] = '';

        const userExists = this.staffList.some(function (el) {
          return el.value === userId;
        });

        if (!userExists) {
          this.staffList.push(obj);
        }
      }

      //** commented in refactoring */
      // if (this.selectedSchedule && this.selectedSchedule.userId && this.selectedSchedule.userId !== 'UNASSIGNED') {
      //   this.addEventForm.patchValue({
      //     staffId: this.selectedSchedule.userId,
      //   });
      // }

    });
  }

  getselectedDate() {
    let selectedtDate;
    const startDateValue = this.addEventForm.controls.startDate.value;
    if (startDateValue && (((startDateValue === parseInt(startDateValue)) || startDateValue instanceof Date))) {
      selectedtDate = this.addEventForm.controls.startDate.value;
      // console.log('if1',selectedtDate)
    } else if (this.assignPopupInputObj && this.assignPopupInputObj.inputDate) {
      selectedtDate = this.assignPopupInputObj.inputDate;
      // console.log('if2',selectedtDate)
    } else if (this.addEventForm.controls.startDate.value) {
      selectedtDate = this.addEventForm.controls.startDate.value;
      // console.log('if3',selectedtDate)
    } else {
      selectedtDate = new Date().setHours(0, 0, 0, 0);
      // console.log('if3',selectedtDate)
    }
    this.clickedEventDate = selectedtDate;
  }

  setSelectedBrand() {
    let inputBrand;
    if (this.assignPopupInputObj && this.assignPopupInputObj.inputBrandId && !this.addEventForm.controls.brandId.value) {
      inputBrand = this.assignPopupInputObj.inputBrandId;
    } else if (this.addEventForm.controls.brandId.value) {
      inputBrand = this.addEventForm.controls.brandId.value;
    }
    if (inputBrand) {
      this.addEventForm.controls.brandId.setValue(inputBrand);
      this.getAssetListData(this.addEventForm.controls.brandId.value);
    }
  }


  getDropContent(arr, selected?) {
    let returnString = ``;
    if (arr && arr.length > 0 && !selected) {
      arr.forEach(element => {
        returnString += `<span style="float:right; color: '#333333';"><b>${element}</b></span><br>`
      });
    }
    if (arr && arr.length == 1 && selected) {
      returnString += `<span style="float:right; color: '#333333';"><b>${arr[0]}</b></span><br>`
    }
    if (arr && arr.length > 1 && selected) {
      returnString += `<span style="float:right; color: '#333333';"><b>${arr[0] + '...'}</b></span><br>`
    }
    return returnString;
  }

  getUnavailableStatus(arr, selected?) {
    let returnString = ``;
    if (arr && arr.length > 0 && !selected) {
      arr.forEach(element => {
        returnString += element;
      });
    }
    return returnString;
  }

  callTaskList() { //** for staff and Equipment mode task list,  and staff mode preselected task */
    if (this.addEventForm.controls.brandId.value == '') {
      this.shiftbrandId = '';
    }
    let start = this.commonService.convertedUTCTime(this.clickedEventDate);
    const obj = {
      userId: '',
      startDateTime: start, //new Date(this.clickedEventDate).getTime(),
      brandId: this.shiftbrandId
    };

    this.franchiseService.getAllTasksForStaff(obj).subscribe((result: any) => {
      const preselectedTasks = [];
      if (result) {
        this.taskNameList = [];
        const selectedStaff = this.addEventForm.controls.staffId.value;
        result.forEach(ele => {
          let tzStartTime = new Date(this.commonBindingDataService.convertedTimeAsPerTz1(ele.taskStartDate)).getTime();
          let tzEndTime = new Date(this.commonBindingDataService.convertedTimeAsPerTz1(ele.taskEndDate)).getTime();

          const obj1 = {
            startDateTime: new Date(ele.taskStartDate).toLocaleTimeString(),
            endDateTime: new Date(ele.taskEndDate).toLocaleTimeString(),
            id: ele.id,
            checked: false,
            label: this.getLabelForTask(ele),
            start: tzStartTime,
            end: tzEndTime,
          }
          this.taskNameList.push(obj1);

          const startDate = new Date(this.addEventForm.controls.startDate.value);
          const selectedStartOfDay = startDate.setHours(0, 0, 1, 0);
          const selectedEndOfDay = startDate.setHours(23, 59, 59, 0);

          if (selectedStaff && ele.taskUsersList) {
            const users = ele.taskUsersList.map(user => user.id);

            if (users.includes(selectedStaff)) {
              // implemented multiday task functionality
              ele.taskUsersList.forEach(eachStaff => {
                eachStaff.shiftList.forEach(eachShift => {
                  if(eachShift.shiftStartDateTime >= selectedStartOfDay && eachShift.shiftStartDateTime<=selectedEndOfDay) {
                    preselectedTasks.push(ele.id);
                    ele.checked = true;
  
                    this.eventsStartimes.push(tzStartTime);
                    this.eventsEndtimes.push(tzEndTime);
                  }
                })
              });
              
            }

            // if (users.includes(selectedStaff)) {

            //   preselectedTasks.push(ele.id);
            //   ele.checked = true;

            //   // this.eventsStartimes.push(ele.taskStartDate);
            //   // this.eventsEndtimes.push(ele.taskEndDate);
            //   this.eventsStartimes.push(tzStartTime);
            //   this.eventsEndtimes.push(tzEndTime);
            // }
          }
        })

        if (this.selectedSchedule) {
          this.markCheckedEvent();
        }
      }

      if (preselectedTasks.length > 0) {
        this.addEventForm.controls.tasks.setValue(preselectedTasks);
      }

      const eventStarts = [...new Set(this.eventsStartimes)];
      const eventEnd = [...new Set(this.eventsEndtimes)];
      this.eventsStartimes = [...eventStarts];
      this.eventsEndtimes = [...eventEnd];

      setTimeout(() => {
        if (this.eventsStartimes.length > 0) {
          const minTime = Math.min(...this.eventsStartimes);
          const maxTime = Math.max(...this.eventsEndtimes);
          this.setStartAndEndShiftValue(minTime, maxTime);
        }
        const start = this.addEventForm.controls.startShift.value;
        const end = this.addEventForm.controls.endShift.value;
        if (start && end && (new Date(start).getDate()) !== new Date(end).getDate()) {
          const newStart = new Date(new Date(start).setDate(new Date(end).getDate())).setHours(0, 1, 0);
          this.addEventForm.controls.startShift.setValue(new Date(newStart));
        }
      }, 1000);
      setTimeout(() => {
        this.checkAllSelectedOrNot();
      }, 900);
    });
  }

  getAllEventsList() {
    if (this.addEventForm.controls.brandId.value == '') {
      this.shiftbrandId = '';
    }
    let fromDate, toDate;
    if (this.selectedSchedule) {
      fromDate = new Date(this.selectedSchedule.startDateTime);
      fromDate.setHours(0, 0, 0);
      toDate = new Date(this.selectedSchedule.endDateTime);
      toDate.setHours(23, 59, 59);
      this.shiftbrandId = this.selectedSchedule.brandId;
    } else {
      fromDate = new Date(this.clickedEventDate);
      fromDate.setHours(0, 0, 0);
      toDate = new Date(this.clickedEventDate);
      toDate.setHours(23, 59, 59);
    }

    let start = this.commonService.convertedUTCTime(fromDate);
    let end = this.commonService.convertedUTCTime(toDate);
    const obj = {
      limit: 999,
      offset: 0,
      // fromDate: fromDate.getTime(),
      // toDate: toDate.getTime(),
      fromDate: start,
      toDate: end,
      brandIds: [this.shiftbrandId],
      applyActivatedStatus: false,
      activated: false,
      deleted: false
    }

    if (obj.fromDate && obj.brandIds.length >= 1) {
      this.franchiseService.getEventGroupByAssets(obj).subscribe((result) => {
        if (result && result.data.length > 0) {
          this.arrangeEvents(result);
          this.sortEventList();
          this.markCheckedEvent();
        }
      });
    }
  }

  arrangeEvents(result) {
    this.eventListForRender = [];
    result.data.forEach(element => {
      const assetObj = {
        assetColor: element.assetColor,
        assetId: element.assetId,
        assetName: element.assetName == 'unAssigned' ? 'UNASSIGNED' : element.assetName,
        eventgrp: [],
        assetSelected: true,
      }
      if (element.eventsGrid.length > 0) {
        const data = element.eventsGrid.map((item) => {
          let tzStartTime = new Date(this.commonBindingDataService.convertedTimeAsPerTz1(item.startDateTime)).getTime();
          let tzEndTime = new Date(this.commonBindingDataService.convertedTimeAsPerTz1(item.endDateTime)).getTime();
          const obj = {
            startDateTime: new Date(item.startDateTime).toLocaleTimeString(),
            endDateTime: new Date(item.endDateTime).toLocaleTimeString(),
            id: item.id,
            name: item.name,
            label: this.getLabel(item),
            checked: false,
            start: tzStartTime,
            end: tzEndTime,
            preOrder: item.preOrder,
            staffsList: item.staffsList,
          };
          assetObj.eventgrp.push(obj);
          this.totalEventsCount++;
        })
      }
      this.eventListForRender.push(assetObj);
    });
    if (this.showStaff && this.addEventForm.controls.staffId.value) {
      this.findSelectedevents(this.eventListForRender);
    }
    if (this.showEquipment && this.addEventForm.controls.assetId.value) {
      this.onSelectEquipment();
    }
  }

  sortEventList() {
    let unAssignedIndex = -1;
    this.eventListForRender.find(function (item, i) {
      if (item.assetId === 'unAssigned') {
        unAssignedIndex = i;
        return;
      }
    });
    // this.eventListForRender.push(this.eventListForRender.splice(unAssignedIndex, 1)[0]);
    if (unAssignedIndex > -1) {
      const UnassignedObject = this.eventListForRender[unAssignedIndex];
      this.eventListForRender.splice(unAssignedIndex, 1);
      this.eventListForRender.splice(0, 0, UnassignedObject)
    }
    // console.log(this.eventListForRender);
  }

  resetFormValues() {
    this.addEventForm.controls.events.setValue(null);
    this.addEventForm.controls.tasks.setValue(null);
    this.addEventForm.controls.allSelect.setValue(null);
    this.addEventForm.controls.events.updateValueAndValidity();
    this.addEventForm.controls.tasks.updateValueAndValidity();
  }

  getLabel(element, fromEquipment?) {
    // const assetName = element.assetsList.map((item) => item.assetName);
    let label;
    let start = this.commonBindingDataService.convertedTimeAsPerTz1(element.startDateTime);
    let end = this.commonBindingDataService.convertedTimeAsPerTz1(element.endDateTime);

    if (!fromEquipment) { // from Staff selection
      const staffName = element.staffsList.map((item) => item.firstName + ' ' + item.lastName);
      if (staffName.length > 0) {
        label = element.name + ' | ' + start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) +
          ' - ' + end.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + ' | ' + staffName.join(', ') + ` | ${element.zipCode}`;
      } else if (staffName.length == 0) {
        label = element.name + ' | ' + start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) +
          ' - ' + end.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + ` | ${element.zipCode}`;
      }
    }

    if (fromEquipment) {
      const staffName = element.staffNames ? element.staffNames : '';
      if (staffName.length > 0) {
        label = element.name + ' | ' + start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) +
          ' - ' + end.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + ' | ' + staffName + `| ${element.zipCode}`;
      } else if (staffName.length == 0) {
        label = element.name + ' | ' + start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) +
          ' - ' + end.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + ` | ${element.zipCode}`;
      }
    }
    return label;
  }

  getLabelForTask(element, fromEquipment?) {
    let label;
    let start = this.commonBindingDataService.convertedTimeAsPerTz1(element.taskStartDate);
    let end = this.commonBindingDataService.convertedTimeAsPerTz1(element.taskEndDate);
    if (!fromEquipment) { // from Staff selection
      const staffName = element.taskUsersList.map((item) => item.firstName + ' ' + item.lastName);
      if (staffName.length > 0) {
        label = element.taskName + ' | ' + start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) +
          ' - ' + end.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + ' | ' + staffName.join(', ') + ` | ${element.zipCode}`;
      } else if (staffName.length == 0) {
        label = element.taskName + ' | ' + start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) +
          ' - ' + end.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + ` | ${element.zipCode}`;
      }
    }
    if (fromEquipment) { // from Staff selection 
      const staffName = element.staffNames ? element.staffNames : '';
      if (staffName.length > 0) {
        label = element.taskName + ' | ' + start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) +
          ' - ' + end.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + `${element.zipCode ? '|' + element.zipCode : ''}`;
      } else if (staffName.length == 0) {
        label = element.taskName + ' | ' + start.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) +
          ' - ' + end.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + `${element.zipCode ? '|' + element.zipCode : ''}`;
      }
    }
    return label;
  }

  getAllTaskList() {
    this.eventList = [];
    this.eventListForRender = [];
    this.eventsStartimes = [];
    this.eventsEndtimes = [];
    this.resetFormValues();
    this.events = [];
    this.tasks = [];

    if (this.selectedSchedule) {
      this.clickedEventDate = new Date(this.selectedSchedule.startDateTime);
      this.clickedEventDate.setHours(0, 0, 0);
    }
    const obj = {
      userId: this.addEventForm.controls.staffId.value,
      startDateTime: new Date(this.clickedEventDate).getTime()
    };
    this.callTaskList();
  }

  markCheckedEvent() {
    if (this.selectedSchedule) {
      const events = this.selectedSchedule.staffScheduleEventList.map((item) => item.eventId);
      const tasks = this.selectedSchedule.staffScheduleTaskList.map((item) => item.taskId);
      this.addEventForm.controls.events.setValue(events);
      this.addEventForm.controls.tasks.setValue(tasks);
      this.addEventForm.controls.events.updateValueAndValidity();
      this.addEventForm.controls.tasks.updateValueAndValidity();
      this.events = events;
      this.tasks = tasks;
      this.eventListForRender.forEach(element => {
        if (element.eventgrp.length > 0) {
          element.eventgrp.forEach(item => {
            for (let index = 0; index < events.length; index++) {
              if (item.id == events[index]) {

                let shiftStartDate;
                let shiftEndDate;
                if (this.selectedSchedule) {
                  shiftEndDate = new Date(this.selectedSchedule.endDateTime);
                  shiftStartDate = new Date(this.selectedSchedule.startDateTime);
                } else if (this.clickedEventDate) {
                  shiftEndDate = new Date(this.clickedEventDate);
                  shiftStartDate = new Date(this.clickedEventDate);
                  shiftEndDate.setHours(23, 59, 59);
                }
                const eventStart = new Date(item.start);
                const eventEnd = new Date(item.end);

                if (eventEnd > shiftEndDate) {
                  eventEnd.setDate(shiftEndDate.getDate());
                  eventEnd.setHours(23, 59, 59);
                } else {
                  eventEnd.setDate(shiftEndDate.getDate());
                  // if (shiftEndDate.getDate() == eventEnd.getDate()) {
                  //   eventEnd.setHours(shiftEndDate.getHours(), shiftEndDate.getMinutes(), shiftEndDate.getSeconds());
                  // }
                }

                if (shiftStartDate > eventStart) {
                  eventStart.setDate(shiftStartDate.getDate());
                  eventStart.setHours(0, 1, 0);
                }

                this.eventsStartimes.push(eventStart.getTime());
                this.eventsEndtimes.push(eventEnd.getTime());
                // this.eventsStartimes.push(item.start);
                // this.eventsEndtimes.push(item.end);
                item.checked = true;
              }
            }
            // console.log(this.eventsEndtimes);
          });
        }
      });

      this.taskNameList.forEach(item => {
        for (let index = 0; index < tasks.length; index++) {
          if (item.id == tasks[index]) {

            let shiftStartDate;
            let shiftEndDate;
            if (this.selectedSchedule) {
              shiftEndDate = new Date(this.selectedSchedule.endDateTime);
              shiftStartDate = new Date(this.selectedSchedule.startDateTime);
            } else if (this.clickedEventDate) {
              shiftEndDate = new Date(this.clickedEventDate);
              shiftStartDate = new Date(this.clickedEventDate);
              shiftEndDate.setHours(23, 59, 59);
            }
            const taskStart = new Date(item.start);
            const taskEnd = new Date(item.end);

            if (taskEnd > shiftEndDate) {
              taskEnd.setDate(shiftEndDate.getDate());
              taskEnd.setHours(23, 59, 59);
            } else {
              taskEnd.setDate(shiftEndDate.getDate());
            }

            if (shiftStartDate > taskStart) {
              taskStart.setDate(shiftStartDate.getDate());
              taskStart.setHours(0, 1, 0);
            }

            this.eventsStartimes.push(taskStart.getTime());
            this.eventsEndtimes.push(taskEnd.getTime());
            item.checked = true;
          }
        }
      });
      this.checkAllSelectedOrNot();
    }
  }

  checkFormIsValid() {
    this.addValidations();
    if (!this.addEventForm.valid) {
      this.addEventForm.markAllAsTouched();
      this.uiService.showMessage(this.commonService.getLabel('error_fill_required_field'), AppSettings.MESSAGE_TYPE.ERROR);
    }
    return this.addEventForm.valid;
  }

  getFormData() {
    const formControl = this.addEventForm.controls;
    let startDateTime = new Date(formControl.startShift.value);
    let endDateTime = new Date(formControl.endShift.value);
    if (this.clickedEventDate) {
      const start = new Date(formControl.startShift.value);
      const end = new Date(formControl.endShift.value);
      const startTime = this.commonService.getTimeFromMM(start).toString();
      const endTime = this.commonService.getTimeFromMM(end).toString();
      const sTime: any = startTime.split(':');
      const eTime: any = endTime.split(':');

      startDateTime = new Date(this.clickedEventDate);
      startDateTime.setHours(sTime[0], sTime[1], 0);
      endDateTime = new Date(this.clickedEventDate);
      endDateTime.setHours(eTime[0], eTime[1], 1);
    }
    const addEventModel: any = {
      userId: formControl.staffId.value,
      brandId: formControl.brandId.value,
      startDateTime: this.commonService.convertedUTCTime(startDateTime),
      endDateTime: this.commonService.convertedUTCTime(endDateTime),
      // assetId: formControl.assets.value,
      staffScheduleEventList: formControl.events.value ? this.getEventList(formControl.events.value) : [],
      // staffScheduleTaskList: this.selectedTaskList
      staffScheduleTaskList: formControl.tasks.value ? this.getFinalTaskList(formControl.tasks.value) : [],
    };

    if (this.selectedSchedule) {
      addEventModel.id = this.selectedSchedule.id;
    }
    return addEventModel;
  }

  getEventList(events) {
    let eventList = [];
    if (this.selectedSchedule) {
      const seletecEventList = [];
      if (this.selectedSchedule.staffScheduleEventList.length > 0) {
        this.selectedSchedule.staffScheduleEventList.filter((item) => {
          if (events.includes(item.eventId)) {
            const obj = {
              eventId: item.eventId,
              id: item.id
            };
            seletecEventList.push(obj);
          }
        });
        for (let index = 0; index < events.length; index++) {
          if ((seletecEventList.findIndex(item => item.eventId === events[index])) == -1) {
            const obj = {
              eventId: events[index]
            };
            seletecEventList.push(obj);
          }
        }
        eventList = seletecEventList;
      } else {
        eventList = events.map((item) => ({ eventId: item }));
      }
    } else {
      eventList = events.map((item) => ({ eventId: item }));
    }
    return eventList;
  }

  getFinalTaskList(tasks) {
    let taskList = [];
    if (this.selectedSchedule) {
      const seletecTaskList = [];
      if (this.selectedSchedule.staffScheduleTaskList.length > 0) {
        this.selectedSchedule.staffScheduleTaskList.filter((item) => {
          if (tasks.includes(item.taskId)) {
            const obj = {
              taskId: item.taskId,
              id: item.id
            };
            seletecTaskList.push(obj);
          }
        });
        for (let index = 0; index < tasks.length; index++) {
          if ((seletecTaskList.findIndex(item => item.taskId === tasks[index])) == -1) {
            const obj = {
              taskId: tasks[index]
            };
            seletecTaskList.push(obj);
          }
        }
        taskList = seletecTaskList;
      } else {
        taskList = tasks.map((item) => ({ taskId: item }));
      }
    } else {
      taskList = tasks.map((item) => ({ taskId: item }));
    }
    return taskList;
  }

  onSave() {
    // if (this.checkMinimumSelect()) { validation remove for client issue 449
    if (this.showEquipment) { // equipment mode
      const selectedEvents = this.checkedEventsForEquipment; //this.getSelectedEventsIds();
      const data = this.prepareRequestFotEquipmentMode();
      this.saveEquipmentModeData(data);
    } else { // staff mode
      this.submitted = true;
      if (this.timeError1 === false) {
        this.uiService.showMessage(this.commonService.getLabel('error_start_time_should_be_greater'), AppSettings.MESSAGE_TYPE.ERROR);
      } else if (this.timeError2 === false) {
        this.uiService.showMessage(this.commonService.getLabel('error_end_time_should_be_greater'), AppSettings.MESSAGE_TYPE.ERROR);
      } else if (this.timeError3 === false) {
        this.uiService.showMessage(this.commonService.getLabel('error_end_time_should_be_greater_than_start'), AppSettings.MESSAGE_TYPE.ERROR);
      } else if (this.checkFormIsValid()) {
        const addEventModel = this.getFormData();
        addEventModel.scheduleType = 'save';
        // console.log(addEventModel);
        // return;
        this.scheduleEvent(addEventModel);
      }
    }
    // }
  }

  checkMinimumSelect() {
    if (this.addEventForm.controls.events.value && this.addEventForm.controls.events.value.length == 0
      && this.addEventForm.controls.tasks.value && this.addEventForm.controls.tasks.value.length == 0) {
      this.uiService.showMessage(this.commonService.getLabel('msg_select_minimum'), AppSettings.MESSAGE_TYPE.ERROR);
      return false;
    } else {
      return true;
    }
  }

  prepareRequestFotEquipmentMode() {
    const startDate = new Date(this.addEventForm.controls.startDate.value.setHours(0, 0, 0, 1));
    const endDate = new Date(this.addEventForm.controls.startDate.value.setHours(23, 59, 59, 1));
    const obj = {
      brandId: this.addEventForm.controls.brandId.value,
      assetId: this.addEventForm.controls.assetId.value,
      eventIds: this.addEventForm.controls.events.value ? this.addEventForm.controls.events.value : [],
      taskIds: this.addEventForm.controls.tasks.value ? this.addEventForm.controls.tasks.value : [],
      startDateTime: this.commonBindingDataService.convertedUTCTime(startDate),
      endDateTime: this.commonBindingDataService.convertedUTCTime(endDate),
    }
    return obj;
  }

  saveEquipmentModeData(data) {
    this.addValidations();
    if (this.addEventForm.valid) {
      this.franchiseService.assignEquipmentToBulkEvents(data).subscribe((result) => {
        if (result) {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          const obj = {
            deleted: false
          };
        }
        this.hidePopup();
      }, (error) => {
        console.log(error);
      });
    } else {
      this.addEventForm.touched;
    }
  }

  addValidations() {
    if (this.showEquipment) {
      this.addEventForm.controls.assetId.setValidators([Validators.required]);
      this.addEventForm.controls.staffId.removeValidators([Validators.required]);
      this.addEventForm.controls.staffId.updateValueAndValidity();
      this.addEventForm.controls.assetId.updateValueAndValidity();
    } else {
      this.addEventForm.controls.staffId.setValidators([Validators.required]);
      this.addEventForm.controls.assetId.removeValidators([Validators.required]);
      this.addEventForm.controls.assetId.updateValueAndValidity();
      this.addEventForm.controls.staffId.updateValueAndValidity();
    }
  }

  getSelectedEventsIds() {
    // console.log('list', this.eventListForRender)
    // console.log('control', this.addEventForm.controls.events.value);
    let checkCount = 0;
    const checkedValue = [];
    this.eventListForRender.forEach(element => {
      if (element.eventgrp.length > 0) {
        element.eventgrp.forEach(item => {
          if (item.checked == true) {
            checkedValue.push(item.id);
            checkCount++;
          }
        });
      }
    });
    return checkedValue;
  }

  //** commented in refactoring */
  // onSaveAndNotify() {
  //   this.submitted = true;
  //   if (this.checkFormIsValid()) {
  //     const addEventModel = this.getFormData();
  //     addEventModel.scheduleType = 'publish';
  //     this.scheduleEvent(addEventModel);
  //   } else {

  //   }
  // }

  scheduleEvent(addEventModel) {
    if (this.selectedSchedule) {
      this.franchiseService.assignStaffToBulkEventsTask(addEventModel).subscribe((result) => {
        if (result) {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          const obj = {
            deleted: false
          };
          // this.updatedRecord.emit(obj);
        }
        this.hidePopup();
      }, (error) => {
        console.log(error);
      });
    } else {
      this.franchiseService.assignStaffToBulkEventsTask(addEventModel).subscribe((result) => {
        if (result) {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          const obj = {
            deleted: false
          };
          this.updatedRecord.emit(obj);
        }
        this.hidePopup();
      }, (error) => {
        console.log(error);
      });
    }
  }

  hidePopup(from?) {
    this.addEventForm.reset();
    this.checkedCondition = false;
    this.assignPopUpEvent.emit(false);
  }

  onSelectDates(type) {
    this.resetEventsTaskSelection();
    const startDate = this.addEventForm.controls.startDate.value;
    if (this.showEquipment) {
      this.addEventForm.controls.startShift.setValue(startDate);
    }
    this.clickedEventDate = this.addEventForm.controls.startShift.value;
    this.checkMinimumData();

    //** commented in refactoring start */
    // this.timeError1 = true;
    // this.timeError2 = true;
    // this.timeError3 = true;
    // const currentTime = new Date().getTime();
    // const startTime = this.addEventForm.controls.startShift.value;
    // const endTime = this.addEventForm.controls.endShift.value ?
    //   this.addEventForm.controls.endShift.value : this.addEventForm.controls.startShift.value;

    // var todayEndTime;
    // var todayStartTime;
    // if (this.selectedSchedule) {
    //   todayEndTime = new Date(this.selectedSchedule.startDateTime);
    //   todayStartTime = new Date(this.selectedSchedule.startDateTime);
    //   if (startTime) {
    //     startTime.setDate(todayStartTime.getDate());
    //     startTime.setMonth(todayStartTime.getMonth());
    //   }
    //   if (endTime) {
    //     endTime.setDate(todayEndTime.getDate());
    //     endTime.setMonth(todayEndTime.getMonth());
    //   }

    // } else if (this.clickedEventDate) {
    //   todayEndTime = new Date(this.clickedEventDate);
    //   todayStartTime = new Date(this.clickedEventDate);

    //   if (startTime) {
    //     startTime.setDate(todayStartTime.getDate());
    //     startTime.setMonth(todayStartTime.getMonth());
    //   }
    //   if (endTime) {
    //     endTime.setDate(todayEndTime.getDate());
    //     endTime.setMonth(todayEndTime.getMonth());
    //   };
    // }
    // if (this.showStaff && todayEndTime) {
    //   todayEndTime.setHours(23, 59, 59);
    //   todayStartTime.setHours(0, 0, 0);
    // }
    //** commented in refactoring end */

    // if (type == 'startShift') {
    //   //this.setEndTime();
    //   this.setStartDate();
    // }

    //** commented in refactoring */
    // if (startTime && endTime && startTime > endTime) {
    //   this.uiService.showMessage(this.commonService.getLabel('error_end_time_should_be_greater_than_start'), AppSettings.MESSAGE_TYPE.ERROR);
    //   this.timeError3 = false;
    // }

    // this.checkEquipmentModeValid();
    // if (this.showStaff) {
    //   let startDate = this.addEventForm.controls.startShift.value ? this.addEventForm.controls.startShift.value : new Date();
    //   let startTime = startDate.setHours(0, 0, 0, 0);
    //   this.clickedEventDate = new Date(startTime);
    //   // console.log('start', this.addEventForm.controls.startShift.value, this.clickedEventDate)
    //   this.getPreFillData();
    // }
  }


  findNextRoundUp(date) {
    const start = moment(date);
    const remainder = 15 - (start.minute() % 15);
    const dateTime = moment(start).add(remainder, 'minutes');
    const startDate = dateTime['_d'];
    this.addEventForm.controls.startShift.setValue(startDate);
    this.isTimeRoundUp = true;
  }

  setStartDate() {
    if (!this.addEventForm.controls.startShift.value) {
      this.addEventForm.controls.startShift.value ? this.findNextRoundUp(this.addEventForm.controls.startShift.value) : this.findNextRoundUp(new Date());
    }
  }

  setEndTime() {
    // if (!this.addEventForm.controls.endDateTime.value ||
    //   (this.addEventForm.controls.endDateTime.value <= this.addEventForm.controls.startDateTime.value)) {
    this.firstStartDate = null;//new Date();
    const start = this.addEventForm.controls.startShift.value;
    this.addEventForm.controls.endShift.setValue(start);
    const addedHrTime = this.addEventForm.controls.endShift.value.setHours(start.getHours() + 1);
    this.addEventForm.controls.startShift.value.setHours(start.getHours() - 1);
    this.addEventForm.controls.endShift.setValue(new Date(addedHrTime));
    // }
  }

  // set End Date Time as Start Date Time only when it end date time is blank or null
  showStartTime() {
    const endDate = this.addEventForm.controls.endShift.value;
    if (endDate == null || endDate == '') {
      this.firstStartDate = null;//new Date();
      const start = this.addEventForm.controls.startShift.value;
      this.addEventForm.controls.endShift.setValue(start);
      const addedHrTime = this.addEventForm.controls.endShift.value.setHours(start.getHours() + 1);
      this.addEventForm.controls.startShift.value.setHours(start.getHours() - 1);
      this.addEventForm.controls.endShift.setValue(new Date(addedHrTime));
    }
  }

  validateEventTime(startDate, endDate, id, pointer) {
    let todayEndTime;
    let todayStartTime;
    if (this.selectedSchedule) {
      todayEndTime = new Date(this.selectedSchedule.startDateTime);
      todayStartTime = new Date(this.selectedSchedule.startDateTime);
    } else {
      todayEndTime = new Date(this.clickedEventDate);
      todayStartTime = new Date(this.clickedEventDate);
    }

    todayEndTime.setHours(23, 59, 59);
    todayStartTime.setHours(0, 1, 0);

    if (endDate.getTime() > todayEndTime.getTime()) {
      // this.uiService.showMessage(this.commonService.getLabel('error_end_time_should_be_greater_than_today'), AppSettings.MESSAGE_TYPE.ERROR);
      this.addEventForm.controls.endShift.setValue(todayEndTime);
      // this.addEventForm.controls.startShift.setValue(null);
      // this.uncheeckEvent(id, pointer);
    }
    if (startDate.getTime() < todayStartTime.getTime()) {
      this.addEventForm.controls.startShift.setValue(todayStartTime);
      // this.addEventForm.controls.endShift.setValue(null);
      // this.uiService.showMessage(this.commonService.getLabel('error_start_time_should_be_greater_than_today'), AppSettings.MESSAGE_TYPE.ERROR);
      // this.uncheeckEvent(id, pointer);
    }
  }

  uncheeckEvent(id, pointer) {
    this.eventListForRender[pointer].eventgrp.forEach(element => {
      if (element.id == id) {
        element.checked = false;
        const start = this.eventsStartimes.indexOf(element.start);
        if (start > -1) {
          this.eventsStartimes.splice(start, 1);
        }
        const end = this.eventsEndtimes.indexOf(element.end);
        if (end > -1) {
          this.eventsEndtimes.splice(end, 1);
        }
      }
    });

    let checkCount = 0;
    const checkedValue = [];
    this.eventListForRender.forEach(element => {
      if (element.eventgrp.length > 0) {
        element.eventgrp.forEach(item => {
          if (item.checked == true) {
            checkedValue.push(item.id);
            checkCount++;
          }
        });
      }
    });

    this.addEventForm.controls.events.setValue(checkedValue);
  }

  changeAllShiftTimeOnEvents(event, assetIndex) {
    // console.log(event)
    // for (let eachAssetGroupIndex = 0; eachAssetGroupIndex < this.eventListForRender.length; eachAssetGroupIndex++) {
    const selectedEvents = this.addEventForm.controls.events.value;
    const eachAssetGroup = this.eventListForRender[assetIndex].eventgrp;
    // ** add/remove asset
    const assetIndexPresent = event.checked.findIndex(a => a == this.eventListForRender[assetIndex].assetId)
    if (assetIndexPresent > -1) {
      this.selectedAsset.push(this.eventListForRender[assetIndex].assetId);
    } else {
      const assetIndexFound = this.selectedAsset.findIndex(assetId => assetId == this.eventListForRender[assetIndex].assetId);
      if (assetIndexFound > -1) {
        this.selectedAsset.splice(assetIndexFound, 1);
      }
    }
    if (selectedEvents && selectedEvents.length > 0) {
      this.addEventForm.controls.allSelect.setValue(this.selectedAsset);
    }
    // console.log(this.selectedAsset, this.addEventForm.controls.allSelect.value)

    // ** add/remove event for eachAsset
    for (let eachEventIndex = 0; eachEventIndex < eachAssetGroup.length; eachEventIndex++) {
      const eachEvent = eachAssetGroup[eachEventIndex];
      if (assetIndexPresent > -1) {
        this.changeShiftTimeOnEvents(event, eachEvent.id, assetIndex, 'AllSelect');
      } else {
        this.changeShiftTimeOnEvents(event, eachEvent.id, assetIndex, 'AllUnSelect');
      }
    }
    // }
  }

  changeShiftTimeOnTasks(event, id, pointer) {
    let taskExist;
    // console.log('task', event, id, pointer);
    taskExist = event.checked.find(x => x == id);
    if (taskExist != undefined) {
      this.taskNameList.forEach(element => {
        if (element.id == id) {
          let shiftStartDate;
          let shiftEndDate;
          if (this.selectedSchedule) {
            shiftEndDate = new Date(this.selectedSchedule.endDateTime);
            shiftStartDate = new Date(this.selectedSchedule.startDateTime);
          } else if (this.clickedEventDate) {
            shiftEndDate = new Date(this.clickedEventDate);
            shiftStartDate = new Date(this.clickedEventDate);
            shiftEndDate.setHours(23, 59, 59);
          }
          const eventStart = new Date(element.start);
          const eventEnd = new Date(element.end);

          if (eventEnd > shiftEndDate) {
            if ((new Date(eventEnd).getDate()) !== shiftEndDate.getDate()) {
              eventEnd.setDate(shiftEndDate.getDate());
              eventEnd.setHours(23, 59, 59);
            }
          } else {
            if (shiftEndDate) {
              eventEnd.setDate(shiftEndDate.getDate());
            }
          }

          if (shiftStartDate > eventStart) {
            if ((new Date(eventStart).getDate()) !== shiftStartDate.getDate()) {
              eventStart.setDate(shiftStartDate.getDate());
              eventStart.setHours(0, 1, 0);
            }
          }

          this.eventsStartimes.push(eventStart.getTime());
          this.eventsEndtimes.push(eventEnd.getTime());
          element.checked = true;
        }
      });
    }
    if (taskExist == undefined) {
      let index;
      this.taskNameList.forEach(element => {
        if (element.id == id) {
          element.checked = false;
          let shiftStartDate;
          let shiftEndDate;
          if (this.selectedSchedule) {
            shiftEndDate = new Date(this.selectedSchedule.endDateTime);
            shiftStartDate = new Date(this.selectedSchedule.startDateTime);
          } else if (this.clickedEventDate) {
            shiftEndDate = new Date(this.clickedEventDate);
            shiftStartDate = new Date(this.clickedEventDate);
          }
          const eventStart = new Date(element.start);
          const eventEnd = new Date(element.end);
          if (eventEnd > shiftEndDate) {
            if ((new Date(eventEnd).getDate()) !== shiftEndDate.getDate()) {
              eventEnd.setDate(shiftEndDate.getDate());
              eventEnd.setHours(23, 59, 59);
            }
          } else {
            if (shiftEndDate) {
              eventEnd.setDate(shiftEndDate.getDate());
            }
          }

          if (shiftStartDate > eventStart) {
            if ((new Date(eventStart).getDate()) !== shiftStartDate.getDate()) {
              eventStart.setDate(shiftStartDate.getDate());
              eventStart.setHours(0, 1, 0);
            }
          } else {
            if (shiftStartDate) {
              eventStart.setDate(shiftStartDate.getDate());
            }
          }

          // this.eventsStartimes = this.getUniqueArray(this.eventsStartimes);
          // this.eventsEndtimes = this.getUniqueArray(this.eventsEndtimes);
          const start = this.eventsStartimes.indexOf(eventStart.getTime());
          // console.log(eventStart.getTime());
          if (start > -1) {
            this.eventsStartimes.splice(start, 1);
          }
          const end = this.eventsEndtimes.indexOf(eventEnd.getTime());
          if (end > -1) {
            this.eventsEndtimes.splice(end, 1);
          }
        }
      });
    }

    let checkTasksCount = 0;
    const checkedTasksValue = [];
    this.taskNameList.forEach(eachTask => {
      if (eachTask.checked == true || event.checked.includes(eachTask.id)) {
        checkedTasksValue.push(eachTask.id);
        checkTasksCount++;
      }
    });

    this.addEventForm.controls.tasks.setValue(checkedTasksValue);
    if (this.eventsStartimes.length > 0 && this.eventsEndtimes.length > 0) {
      const minTime = Math.min(...this.eventsStartimes);
      const maxTime = Math.max(...this.eventsEndtimes);
      if (this.selectedSchedule) {
        this.setStartAndEndShiftValue(minTime, maxTime, this.selectedSchedule.startDateTime);
      } else if (this.clickedEventDate) {
        this.setStartAndEndShiftValue(minTime, maxTime, this.clickedEventDate);
      }

      if (this.showStaff) {
        this.validateEventTime(this.addEventForm.controls.startShift.value, this.addEventForm.controls.endShift.value, id, pointer)
      }
    } else {
      if (this.showStaff) {
        this.eventsStartimes = [];
        this.eventsEndtimes = [];
        this.addEventForm.controls.startShift.setValue(null);
        this.addEventForm.controls.endShift.setValue(null);
      }
    }
    setTimeout(() => {
      if (this.showStaff) {
        // this.checkValidation();
      }
    }, 1000);
    if (this.showEquipment) {
      this.makeTaskSelected(event);
    }
    // console.log('task', this.addEventForm.controls.tasks.value)
  }

  getUniqueArray(inputArray) {
    const uniArray = [];
    inputArray.forEach(element => {
      const findElement = uniArray.find(e => e == element);
      if (!findElement) {
        uniArray.push(element);
      }
    });
    return uniArray;
  }

  makeTaskSelected(event) {
    const selectedTask = event.checked;
    this.addEventForm.controls.tasks.setValue(selectedTask);
  }

  changeShiftTimeOnEvents(event, id, pointer, fromAllSelect?) {  // change time depends on events
    let eventExist;
    if (!fromAllSelect) {
      eventExist = event.checked.find(x => x == id);
    }
    if (fromAllSelect && fromAllSelect == 'AllSelect') {
      eventExist = id;
    }
    // console.log('eventExist ', eventExist)

    if (eventExist != undefined) {
      this.eventListForRender[pointer].eventgrp.forEach(element => {
        if (element.id == id) {
          let shiftStartDate;
          let shiftEndDate;
          if (this.selectedSchedule) {
            shiftEndDate = new Date(this.selectedSchedule.endDateTime);
            shiftStartDate = new Date(this.selectedSchedule.startDateTime);
          } else if (this.clickedEventDate) {
            shiftEndDate = new Date(this.clickedEventDate);
            shiftStartDate = new Date(this.clickedEventDate);
            shiftEndDate.setHours(23, 59, 59);
          }
          const eventStart = new Date(element.start);
          const eventEnd = new Date(element.end);

          if (eventEnd > shiftEndDate) {
            if (!element.preOrder) {
              if ((new Date(eventEnd).getDate()) !== shiftEndDate.getDate()) {
                eventEnd.setDate(shiftEndDate.getDate());
                eventEnd.setHours(23, 59, 59);
              }
            }
            // eventEnd.setDate(shiftEndDate.getDate());
            // eventEnd.setHours(23, 59,59);
            // eventEnd = eventEnd;
          } else {
            if (shiftEndDate) {
              eventEnd.setDate(shiftEndDate.getDate());
            }
            // if (shiftEndDate.getDate() == eventEnd.getDate()) {
            //   eventEnd.setHours(shiftEndDate.getHours(), shiftEndDate.getMinutes(), shiftEndDate.getSeconds());
            // }
          }

          if (shiftStartDate > eventStart) {
            if (!element.preOrder) {
              if ((new Date(eventStart).getDate()) !== shiftStartDate.getDate()) {
                eventStart.setDate(shiftStartDate.getDate());
                eventStart.setHours(0, 1, 0);
              }
            }
            // eventStart.setDate(shiftStartDate.getDate());
            // eventStart.setHours(0, 1,0);

            // eventStart = eventStart;
          }

          this.eventsStartimes.push(eventStart.getTime());
          this.eventsEndtimes.push(eventEnd.getTime());
          element.checked = true;
        }
      });
    }

    if (eventExist == undefined) {
      let index;
      this.eventListForRender[pointer].assetSelected = false; //** unselect asset */
      this.eventListForRender[pointer].eventgrp.forEach(element => {
        if (element.id == id) {
          element.checked = false;
          let shiftStartDate;
          let shiftEndDate;
          if (this.selectedSchedule) {
            shiftEndDate = new Date(this.selectedSchedule.endDateTime);
            shiftStartDate = new Date(this.selectedSchedule.startDateTime);
          } else if (this.clickedEventDate) {
            shiftEndDate = new Date(this.clickedEventDate);
            shiftStartDate = new Date(this.clickedEventDate);
            // shiftEndDate.setHours(23, 59,59);
          }
          const eventStart = new Date(element.start);
          const eventEnd = new Date(element.end);

          // eventStart.setDate(startTime.getDate());
          // eventStart.setMonth(startTime.getMonth());
          //
          // eventEnd.setDate(endTime.getDate());
          // eventEnd.setMonth(endTime.getMonth());

          if (eventEnd > shiftEndDate) {
            if (!element.preOrder) {
              if ((new Date(eventEnd).getDate()) !== shiftEndDate.getDate()) {
                eventEnd.setDate(shiftEndDate.getDate());
                eventEnd.setHours(23, 59, 59);
              }
            }
            // eventEnd.setDate(shiftEndDate.getDate());
            // eventEnd.setHours(23, 59,59);
          } else {
            if (shiftEndDate) {
              eventEnd.setDate(shiftEndDate.getDate());
            }
          }

          if (shiftStartDate > eventStart) {
            if (!element.preOrder) {
              if ((new Date(eventStart).getDate()) !== shiftStartDate.getDate()) {
                eventStart.setDate(shiftStartDate.getDate());
                eventStart.setHours(0, 1, 0);
              }
            }
            // eventStart.setDate(shiftStartDate.getDate());
            // eventStart.setHours(0, 1,0);
          } else {
            if (shiftStartDate) {
              eventStart.setDate(shiftStartDate.getDate());
            }
          }

          const start = this.eventsStartimes.indexOf(eventStart.getTime());
          if (start > -1) {
            this.eventsStartimes.splice(start, 1);
          }
          const end = this.eventsEndtimes.indexOf(eventEnd.getTime());
          if (end > -1) {
            this.eventsEndtimes.splice(end, 1);
          }
        }
      });
    }


    let checkCount = 0;
    const checkedValue = [];
    this.eventListForRender.forEach(element => {
      if (element.eventgrp.length > 0) {
        element.eventgrp.forEach(item => {
          if (item.checked == true || event.checked.includes(item.id)) {
            checkedValue.push(item.id);
            checkCount++;
          }
        });
      }
    });

    this.addEventForm.controls.events.setValue(checkedValue);

    if (this.eventsStartimes.length > 0 && this.eventsEndtimes.length > 0) {
      const minTime = Math.min(...this.eventsStartimes);
      const maxTime = Math.max(...this.eventsEndtimes);

      if (this.selectedSchedule) {
        this.setStartAndEndShiftValue(minTime, maxTime, this.selectedSchedule.startDateTime);
      } else if (this.clickedEventDate) {
        this.setStartAndEndShiftValue(minTime, maxTime, this.clickedEventDate);
      }
      if (this.showStaff) {
        this.validateEventTime(this.addEventForm.controls.startShift.value, this.addEventForm.controls.endShift.value, id, pointer)
      }
    } else {
      if (this.showStaff) {
        this.eventsStartimes = [];
        this.eventsEndtimes = [];
        this.addEventForm.controls.startShift.setValue(null);
        this.addEventForm.controls.endShift.setValue(null);
      }
    }

    this.addEventForm.controls.events.updateValueAndValidity();
    if (!fromAllSelect) {
      this.checkAllSelectedOrNot();
      this.checkForAsssetGroup(pointer);
    }
    setTimeout(() => {
      if (this.showStaff) {
        // this.checkValidation();
      }
    }, 1000);
    // }
    // console.log('events01', this.addEventForm.controls.events.value);
  }

  checkAllSelectedOrNot() {
    const makeAllAssetAsSelected = [];
    this.addEventForm.controls.allSelect.setValue(makeAllAssetAsSelected);
    const selectedEvents = this.addEventForm.controls.events.value;
    for (let eachAssetGroupIndex = 0; eachAssetGroupIndex < this.eventListForRender.length; eachAssetGroupIndex++) {
      const eachAssetGroup = this.eventListForRender[eachAssetGroupIndex].eventgrp;

      if (selectedEvents && selectedEvents.length > 0 && selectedEvents !== null) {
        makeAllAssetAsSelected.push(this.eventListForRender[eachAssetGroupIndex].assetId);
      }

      for (let eachEventIndex = 0; eachEventIndex < eachAssetGroup.length; eachEventIndex++) {
        const eachEvent = eachAssetGroup[eachEventIndex].id;
        const eventIndexFound = selectedEvents?.findIndex(e => e == eachEvent);
        if (eventIndexFound == -1) {
          const assetIndexFound = makeAllAssetAsSelected.findIndex(assetId => assetId == this.eventListForRender[eachAssetGroupIndex].assetId);
          if (assetIndexFound > -1) {
            makeAllAssetAsSelected.splice(assetIndexFound, 1);
          }
        }
      }
    }
    this.selectedAsset = makeAllAssetAsSelected;
    if (this.showStaff && this.addEventForm.controls.staffId.value) {
      if (selectedEvents && selectedEvents.length > 0) {
        this.addEventForm.controls.allSelect.setValue(makeAllAssetAsSelected);
      }
    }
    if (this.showEquipment && this.addEventForm.controls.assetId.value) {
      if (selectedEvents && selectedEvents.length > 0) {
        this.addEventForm.controls.allSelect.setValue(makeAllAssetAsSelected);
      }
    }
    this.checkEventCheckedOrNot();
  }

  checkForAsssetGroup(assetPointer) {
    const eachAssetEventGroup = this.eventListForRender[assetPointer].eventgrp;
    const selectedAssets = this.addEventForm.controls.allSelect.value ? this.addEventForm.controls.allSelect.value : [];
    const allPresent = this.checkAllEventsSelectedInAssetGroup(assetPointer);
    const assetIndexFound = selectedAssets.findIndex(e => e == this.eventListForRender[assetPointer].assetId);
    if (assetIndexFound > -1 && !allPresent) {
      selectedAssets.splice(assetIndexFound, 1);
    }
    if (assetIndexFound == -1 && allPresent) {
      selectedAssets.push(this.eventListForRender[assetPointer].assetId);
    }
    if (this.addEventForm.controls.events.value && this.addEventForm.controls.events.value.length > 0) {
      this.addEventForm.controls.allSelect.setValue(selectedAssets);
    }
  }

  checkAllEventsSelectedInAssetGroup(assetPointer) {
    const eachAssetEventGroup = this.eventListForRender[assetPointer].eventgrp;
    let allEventsSelected = true;
    const selectedEvents = this.addEventForm.controls.events.value;
    for (let eachEventIndex = 0; eachEventIndex < eachAssetEventGroup.length; eachEventIndex++) {
      const eachEvent = eachAssetEventGroup[eachEventIndex].id;
      const eventIndexFound = selectedEvents.findIndex(e => e == eachEvent);
      if (eventIndexFound == -1) {
        allEventsSelected = false;
      }
    }
    return allEventsSelected;
  }

  checkAllEvents(event) {
    // console.log(event)
    for (let eachAssetGroupIndex = 0; eachAssetGroupIndex < this.eventListForRender.length; eachAssetGroupIndex++) {
      const eachAssetGroup = this.eventListForRender[eachAssetGroupIndex].eventgrp;
      for (let eachEventIndex = 0; eachEventIndex < eachAssetGroup.length; eachEventIndex++) {
        const eachEvent = eachAssetGroup[eachEventIndex];
        if (event.checked) {
          this.changeShiftTimeOnEvents(event, eachEvent.id, eachAssetGroupIndex, 'AllSelect');
        } else {
          this.changeShiftTimeOnEvents(event, eachEvent.id, eachAssetGroupIndex, 'AllUnSelect');
        }
      }
    }
  }

  setStartAndEndShiftValue(minTime, maxTime, dateOfSchedule?) {
    const startLeadTime = this.franchiseService.startLead;
    const endLeadTime = this.franchiseService.endLead;

    if (startLeadTime !== 0) {
      startLeadTime !== 0 ?
        this.addEventForm.controls.startShift.setValue(new Date(minTime - (+startLeadTime))) :
        this.addEventForm.controls.startShift.setValue(new Date(minTime.getTime()));
    } else {
      this.addEventForm.controls.startShift.setValue(new Date(minTime));
    }

    if (endLeadTime !== 0) {
      endLeadTime !== 0 ?
        this.addEventForm.controls.endShift.setValue(new Date(maxTime + (+endLeadTime))) :
        this.addEventForm.controls.endShift.setValue(new Date(maxTime.getTime()));
    } else {
      this.addEventForm.controls.endShift.setValue(new Date(maxTime));
    }
  }

  getAssetListData(brandIds?) {
    // if (brandIds && brandIds.length > 0) {
    this.selectedAssetsForView = [];
    this.franchiseService.getAllAssetsForFilter(brandIds).subscribe((result) => {
      this.assetsListForView = result.data.filter(e => e.activated == true).map(element =>
        ({ label: element.assetName, value: element.id, color: element.colorPicker, class: 'assigned', bgColor: element.activated ? '' : '#bab0b0' }));
      // this.sortAssetList();
      // this.assetsListForView.unshift({ label: this.commonBindingDataService.getLabel('label_unassigned'), value: 'UNASSIGNED', color: '#fff', class: 'unassigned' });
      this.assetListOptions = this.assetsListForView;
      if (this.assignPopupInputObj && this.assignPopupInputObj.equpmentMode && this.assignPopupInputObj.equipmentId) {
        this.addEventForm.controls.assetId.setValue(this.assignPopupInputObj.equipmentId);
        const assetFound = this.assetListOptions.find(asset => asset.value == this.assignPopupInputObj.equipmentId);
        if (!assetFound) {
          this.addEventForm.controls.assetId.setValue(null);
        }
      }
    });
  }

  sortAssetList() {
    this.assetsListForView = this.assetsListForView.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  //** commented in refactoring */
  // checkEquipmentModeValid() {
  //   if (this.showEquipment) {
  //     if (this.addEventForm.controls.brandId.value && this.addEventForm.controls.startDate.value) {
  //       // this.onSelectEquipment();
  //       this.getPreFillData();
  //       this.onSelectEquipment();
  //     } else {
  //       if (this.defaultBrand && this.addEventForm.controls.startDate.value) {
  //         this.shiftbrandId = this.defaultBrand;
  //         this.addEventForm.controls.brandId.setValue(this.shiftbrandId);
  //         this.getPreFillData();
  //         this.onSelectEquipment();
  //       }
  //     }
  //   }
  // }

  onSelectEquipment() { /** for equipment mode get selected Events */
    // this.eventListForRender = [];
    this.addEventForm.controls.events.setValue('');
    this.addEventForm.controls.tasks.setValue('');
    this.checkEventCheckedOrNot('checkedFalse');
    const assetId = this.addEventForm.controls.assetId.value;
    const start = this.addEventForm.controls.startShift.value ? this.addEventForm.controls.startShift.value.setHours(0, 0, 0, 1) : '';
    const end = this.addEventForm.controls.startShift.value ? this.addEventForm.controls.startShift.value.setHours(23, 59, 59, 1) : '';
    const brand = this.addEventForm.controls.brandId.value ? this.addEventForm.controls.brandId.value : this.shiftbrandId;
    const params = new HttpParams()
      .set('fromDate', start)
      .set('toDate', end)
      .set('brandId', brand)
      .set('assetId', assetId)
      .set('deleted', 'false')
      .set('limit', '199');
    this.franchiseService.getEventByAssets(params).subscribe((result) => {
      const asset = this.assetsListForView.find(e => e.value == assetId);

      if (result && result.length > 0) {
        const assetObj = [{
          assetColor: asset?.color,
          assetId: asset?.value,
          assetName: asset?.label,
          eventsGrid: result,
          assetSelected: true,
        }]
        this.findSelectedEventsList(assetObj);
      }
    });
    this.callSelectedTaskList();
  }

  callSelectedTaskList() { /** for equipment mode get selected task */
    const selectedAssetId = this.addEventForm.controls.assetId.value;
    const preselectedAssets = [];
    const start = this.addEventForm.controls.startShift.value ? this.addEventForm.controls.startShift.value.setHours(0, 0, 0, 1) : '';
    const end = this.addEventForm.controls.startShift.value ? this.addEventForm.controls.startShift.value.setHours(23, 59, 59, 1) : '';

    const params = new HttpParams()
      .set('fromDate', start)
      .set('toDate', end)
      .set('brandId', this.shiftbrandId)
      .set('assetId', this.addEventForm.controls.assetId.value)
      .set('deleted', 'false')
      .set('limit', '199');

    this.franchiseService.getAllTasksForBulkAssign(params).subscribe((result: any) => {
      if (result) {
        result.forEach(ele => {
          if (ele.assetIdsList && ele.assetIdsList.includes(selectedAssetId)) {
            preselectedAssets.push(ele.id);
            ele.checked = true;
          }
        })

        this.addEventForm.controls.tasks.setValue(preselectedAssets);
        if (this.selectedSchedule) {
          this.markCheckedEvent();
        }
      }
      setTimeout(() => {
        this.checkAllSelectedOrNot();
      }, 900);
    });
  }

  findSelectedEventsList(result) { //** for equipment mode find selected events */
    const preSelectedEvents = [];
    // this.eventListForRender = [];
    this.checkedEventsForEquipment = [];
    result.forEach(element => {
      if (element.eventsGrid.length > 0) {
        element.eventsGrid.map((item) => {
          this.totalEventsCount++;
          if (item.assetIdsList && item.assetIdsList.includes(element.assetId)) {
            preSelectedEvents.push(item.id);
            item.checked = true;
          } else {
            // item.checked = false;
          }
        })
      }
    });
    this.addEventForm.controls.events.setValue(preSelectedEvents);
    this.checkAllSelectedOrNot();
    // this.checkedEventsForEquipment = preSelectedEvents;
    // console.log('pre events', this.addEventForm.controls.events.value, preSelectedEvents)
  }

  checkEventCheckedOrNot(flag?) {
    const eventIds = this.addEventForm.controls.events.value;
    this.eventListForRender.forEach(element => {
      if (element.eventgrp.length > 0) {
        element.eventgrp.forEach(item => {
          if (eventIds && eventIds.length > 0) {
            if (eventIds.includes(item.id)) {
              item.checked = true;
            }
          }
          if (flag && flag == 'checkedFalse') {
            item.checked = false;
          }
        });
      }
    });
  }

  getAndCheckLeads() {
    this.franchiseService.getCommonStaffSchedulesLeads().subscribe((result) => {
      if (result && result.startTime >= 0 && result.endTime >= 0 && result.franchiseId) {
        this.startLead = this.commonService.gmtTOHHMM(result.startTime).toString();
        this.endLead = this.commonService.gmtTOHHMM(result.endTime).toString();
        this.franchiseService.startLead = result.startTime;
        this.franchiseService.endLead = result.endTime;
      }
    }, (error) => {
      console.log(error);
    });
  }

  changeStaff() {
    this.resetShiftTimes();
    this.getPreFillData();
    // this.validateEvent();
  }

  findSelectedevents(data) { //** for finding selected events of staff mode */
    if (data.length > 0) {
      const staffId = this.addEventForm.controls.staffId.value;
      const selectedAssetsArray = [];
      const selectedEventsArray = [];
      const shiftTimeArray = [];
      const startDate = new Date(this.addEventForm.controls.startDate.value);
      const selectedStartOfDay = startDate.setHours(0, 0, 1, 0);
      const selectedEndOfDay = startDate.setHours(23, 59, 59, 0); 

      data.forEach(eachAsset => {
        eachAsset.eventgrp.forEach(eachEvent => {
          if (eachEvent.staffsList && eachEvent.staffsList.length > 0) {
            eachEvent.staffsList.forEach(eachStaff => {
              if (eachStaff.userId == staffId) {
                // implemented multiday event functionality
                eachStaff.shiftList.forEach(eachShift => {
                  if(eachShift.shiftStartDateTime >= selectedStartOfDay && eachShift.shiftStartDateTime<=selectedEndOfDay) {
                    const indexFound = selectedEventsArray.findIndex(e => e == eachEvent.id);
                    if (indexFound == -1) {
                      // selectedAssetsArray.push()
                      selectedEventsArray.push(eachEvent.id);
                      shiftTimeArray.push(eachEvent.start);
                      shiftTimeArray.push(eachEvent.end);
                      this.eventsStartimes.push(eachEvent.start);
                      this.eventsEndtimes.push(eachEvent.end);

                      eachEvent.checked = true;
                    }
                  }
                });
              }
              
              // if (eachStaff.userId == staffId) {

              //   const indexFound = selectedEventsArray.findIndex(e => e == eachEvent.id);
              //   if (indexFound == -1) {
              //     // selectedAssetsArray.push()
              //     selectedEventsArray.push(eachEvent.id);
              //     shiftTimeArray.push(eachEvent.start);
              //     shiftTimeArray.push(eachEvent.end);
              //     this.eventsStartimes.push(eachEvent.start);
              //     this.eventsEndtimes.push(eachEvent.end);

              //     eachEvent.checked = true;
              //   }
              // }
            });
          }
        })
      });
      const eventStarts = [...new Set(this.eventsStartimes)];
      const eventEnd = [...new Set(this.eventsEndtimes)];
      this.eventsStartimes = [...eventStarts];
      this.eventsEndtimes = [...eventEnd];

      if (shiftTimeArray.length > 0) {
        const minTime = Math.min(...shiftTimeArray);
        const maxTime = Math.max(...shiftTimeArray);
        if (this.selectedSchedule) {
          this.setStartAndEndShiftValue(minTime, maxTime);
        } else if (this.clickedEventDate) {
          this.setStartAndEndShiftValue(minTime, maxTime);
        }
      }

      const start = this.addEventForm.controls.startShift.value;
      const end = this.addEventForm.controls.endShift.value;

      // console.log('mindate', minTime, maxTime);
      if (start && end && (new Date(start).getDate()) !== new Date(end).getDate()) {
        const newStart = new Date(new Date(start).setDate(new Date(end).getDate())).setHours(0, 1, 0);
        this.addEventForm.controls.startShift.setValue(new Date(newStart));
      }

      this.addEventForm.controls.events.setValue(selectedEventsArray);
      this.checkAllSelectedOrNot();
      // console.log('selectedEventsArray', selectedEventsArray, shiftTimeArray);
    }
  }

  findSelectedTask(data) {
    if (data.length > 0) {
      const staffId = this.addEventForm.controls.staffId.value;
      const selectedAssetsArray = [];
      const selectedEventsArray = [];
      const shiftTimeArray = [];
      data.forEach(eachAsset => {
        eachAsset.eventgrp.forEach(eachEvent => {
          if (eachEvent.staffsList && eachEvent.staffsList.length > 0) {
            eachEvent.staffsList.forEach(eachStaff => {
              if (eachStaff.userId == staffId) {
                const indexFound = selectedEventsArray.findIndex(e => e == eachEvent.id);
                if (indexFound == -1) {
                  // selectedAssetsArray.push()
                  selectedEventsArray.push(eachEvent.id);
                  shiftTimeArray.push(eachEvent.start);
                  shiftTimeArray.push(eachEvent.end);
                  this.eventsStartimes.push(eachEvent.start);
                  this.eventsEndtimes.push(eachEvent.end);
                }
              }
            });
          }
        })
      });

      if (shiftTimeArray.length > 0) {
        const minTime = Math.min(...shiftTimeArray);
        const maxTime = Math.max(...shiftTimeArray);
        if (this.selectedSchedule) {
          this.setStartAndEndShiftValue(minTime, maxTime);
        } else if (this.clickedEventDate) {
          this.setStartAndEndShiftValue(minTime, maxTime);
        }
      }

      const start = this.addEventForm.controls.startShift.value;
      const end = this.addEventForm.controls.endShift.value;

      // console.log('mindate', minTime, maxTime);
      if ((new Date(start).getDate()) !== new Date(end).getDate()) {
        const newStart = new Date(new Date(start).setDate(new Date(end).getDate())).setHours(0, 1, 0);
        this.addEventForm.controls.startShift.setValue(new Date(newStart));
      }

      this.addEventForm.controls.events.setValue(selectedEventsArray);
      console.log('selectedEventsArray', selectedEventsArray, shiftTimeArray);
    }
  }

  resetEventsTaskSelection() {
    this.staffList = [];
    this.eventListForRender = [];
    this.taskNameList = [];
    this.resetShiftTimes();
    // this.addEventForm.controls.staffId.setValue('');
    // this.checkPreviousStaff(this.selectedResource);
    // this.addEventForm.controls.events.setValue('');
    // this.addEventForm.controls.tasks.setValue('');
  }

  resetShiftTimes() {
    this.addEventForm.controls.startShift.setValue('');
    this.addEventForm.controls.endShift.setValue('');
  }

  checkPermissions() {
    this.saveDisabled = false;
    if (this.checkedCondition) {
      this.eventDisabled = false;
      this.taskDisabled = false;
      this.eventDisabled = !this.accessEventsAssignEquipment;
      this.taskDisabled = !this.accessTaskAssignEquipment;
      if (this.eventDisabled && this.taskDisabled) {
        this.saveDisabled = true;
      }
    } else {
      this.eventDisabled = false;
      this.taskDisabled = false;
      this.eventDisabled = !this.accessEventsAssignStaff;
      this.taskDisabled = !this.accessTaskAssignStaff;
      if (this.eventDisabled && this.taskDisabled) {
        this.saveDisabled = true;
      }
    }
  }

}
