
    <form [formGroup]="refundForm">
        <div class="form-field">
            <div class="form-label">
                <label for="refundAmount" translate>label_refund_amount</label>
            </div>
            <div class="form-control position-rel">
              <div class="input-container">
                <input formControlName="refundAmount" class="full-width prefix-pad" type="text" (blur) = "makeDecimalAmount('refundAmount')"
                    pInputText appTwoDecimal appNoWhiteSpace inputmode="numeric" />
                <label for="search-bar" class="fa fa-dollar input-icon percent-icon dollar-style"></label>
              </div>


                    <div class="form-errors"
                        *ngIf="refundForm.controls.refundAmount.errors  && (refundForm.controls.refundAmount.dirty || refundForm.controls.refundAmount.touched || submitted)">
                        <div [hidden]="!refundForm.controls.refundAmount.errors.required" translate>
                            field_is_required
                        </div>
                        <div [hidden]="!refundForm.controls.refundAmount.errors.min" translate>
                            Minimum order refund amount should be $1.00
                        </div>
                        <div [hidden]="!refundForm.controls.refundAmount.errors.max" translate>
                            Maximum order refund amount is ${{grandTotal}}
                        </div>
                    </div>

                    <!-- <div class="col-12">

                            <input type="radio"  formControlName="refundOptions" value="campaignDonationAmount" id="1"
                                (change)="onClickOptions('donation',campaignDonationAmount)"/>
                            <label class="custom-control-label" for="1">Campaign Donation &nbsp; ${{campaignDonationAmount}}</label>

                    </div> -->
                    <div class="col-12 p-mt-3 p-pl-0">

                            <input type="radio" name="refundOptions" formControlName="refundOptions" value="totalRefund" id="2" (change)="toggleRefundCheck('totalRefund')"/>
                            <label class="custom-control-label label-margin" for="2" translate>label_total_order_amount <span> - ${{grandTotal}}</span></label>
                    </div>
                    <div class="col-12 p-pl-0">

                            <input type="radio" name="refundOptions" formControlName="refundOptions" value="partialRefund" id="3" (change)="toggleRefundCheck('partialRefund')"/>
                            <label class="custom-control-label label-margin" for="3" translate>label_custom_amount</label>
                    </div>

            </div>
        </div>
        <div class="p-justify-end page-buttons">
        <button pButton [label]="'label_refund_cancel' | translate" type="submit" class="cancel-btn"
            (click)="onCancelClick()"></button>
        <button pButton [label]="'label_save' | translate" type="submit" class="btn-save"
            (click)="onConfirmClick()"></button>
        </div>
    </form>
