import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { ErrorBindingService } from '../../services/error-binding.service';
import { UiService } from '../../services/ui.service';
import { UserCommonService } from '../../services/user-common.service';

@Component({
  selector: 'app-shared-notification-send',
  templateUrl: './shared-notification-send.component.html',
  styleUrls: ['./shared-notification-send.component.css']
})
export class SharedNotificationSendComponent implements OnInit {

  @Input() fromComponent: any;
  eventDetails;
  eventForm: UntypedFormGroup;
  pageHeading = this.commonService.getLabel('label_add_notification');

  submitted = false;
  segments: any[] = [];
  users: any[] = [];
  messageTypes: any[] = [];
  EMAIL = "true";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private errorBindingService: ErrorBindingService,
    private franchiseService: FranchiseService,
    private commonService: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
    private commonServices: UserCommonService,
  ) { }

  ngOnInit(): void {
    this.getSegments();
    this.initForm();
    this.messageTypes = [
      {
        value: 'EMAIL',
        label: 'Email'
      },
      {
        value: 'SMS',
        label: 'SMS',
      },
      {
        value: 'PUSH NOTIFICATION',
        label: 'Push Notification',
      }
    ];
  }

  getSegments() {
    this.commonServices.getSegmentsList().subscribe(
      (result) => {
        this.segments = result.filter(element => {
          return element.activated === true;
        }).map(segment => ({ value: segment.segmentId, label: segment.segmentName }));
        // this.segments = (result) ? result.map(response => ({ label: response.segmentName, value: response.segmentId })) : [];
        this.segments.unshift({ label: this.commonService.getLabel('Select'), value: '' });
      }, (error) => {
        console.log(error);
      });
  }

  initForm() {
    this.eventForm = this.formBuilder.group({
      messageTitle: [(this.eventDetails && this.eventDetails.messageTitle) ? this.eventDetails.messageTitle : '',
      [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      segmentId: [(this.eventDetails && this.eventDetails.segmentId) ? this.eventDetails.segmentId : '',
      [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],

      //(this.eventDetails && this.eventDetails.messageType) ? this.eventDetails.messageType : 'EMAIL',
      //  [Validators.required]],
      messageType: [(this.eventDetails) && this.eventDetails.messageType == 'SMS' ? this.eventDetails.messageType : 'EMAIL', Validators.required],
      message: [(this.eventDetails && this.eventDetails.message) ? this.eventDetails.message : '',
      [Validators.required, Validators.minLength(1), Validators.maxLength(140)]],
    });
  }

  onSave() {
    const formValues = this.eventForm.getRawValue();
    this.submitted = true;
    if (this.eventForm.valid) {
      const eventModel = this.getFormData();
      this.confirmationService.confirm({
        message: this.commonService.getLabel('msg_send_notification'),
        accept: () => {
          this.commonServices.addNotification(eventModel).subscribe((result) => {
            this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
            this.goToPrevious();
          }, (error) => {
            console.log(error);
          });
        }
      });
    } else {
      this.errorBindingService.getFormValidationErrors(this.eventForm);
    }
  }

  getFormData() {
    const formControl = this.eventForm.getRawValue();
    const editableEventModel: any = {
      segmentId: formControl.segmentId,
      messageType: formControl.messageType,
      message: formControl.message,
      messageTitle: formControl.messageTitle,
    };
    return editableEventModel;
  }

  onCancel() {
    this.goToPrevious();
  }

  goToPrevious() {
    if (this.fromComponent === 'admin') {
      this.router.navigate(['admin/marketing/notifications']);
    } else {
      this.router.navigate(['franchise/marketing/notifications']);
    }
  }

  goBack() {
    window.history.back();
  }

}

