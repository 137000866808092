<div class="page-container">
    <div class="col-12 col-nopad" *ngIf = "showBreadCrumb">
        <app-bread-crumb [breadCrumb]="breadCrumbArray"></app-bread-crumb>
    </div>
    <div class="page-header">
        <div class="page-heading">
            <h1 class="heading p-ml-1">{{pageHeading}}</h1>
        </div>
        <div class="page-actions">
            <button pButton appAccessProvider [accessCode]="accessCode" [readonly] = "true" [readWrite] = "true" class="small-btn" (click)="onSave()" type="button" [label]="'btn_save'|translate"></button>
            <button pButton class="small-btn cancel-btn" (click)="onCancel()" type="button"
                [label]="'btn_cancel'|translate"></button>
        </div>
    </div>

    <div class="page-body" *ngIf = "files && files.length > 0">
        <p-treeTable [value]="files" [columns]="cols" selectionMode="checkbox" [(selection)]="selectedNodes"
            (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)" [columnResizeMode]="'expand'">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" >
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns; let i = index" ttEditableColumn [ngClass]="{'p-toggler-column': i === 0}" 
                    [colspan]="i === 0?2:1">
                        <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                        <p-treeTableCheckbox [value]="rowNode" *ngIf="i == 0 && checkAccessReadWrite()"></p-treeTableCheckbox>
                        <span *ngIf="i == 0"><img *ngIf="rowData['signUrl']" [src] =  "rowData['signUrl']" style="width: 3em;margin: 0 8px;" class="food-image" /></span>
                        <span *ngIf="i == 0">{{rowData[col.field]}}</span>
                        
                        <div *ngIf="i > 0 && !rowData['isCategory'] && !([col.field] == 'sequence')">
                            <div class="form-control selling-price-wrapper">
                                <div class="p-inputgroup">
                                  <span class="p-inputgroup-addon">$</span>
                                  <input pInputText type="text" class="price-input"  [(ngModel)]="rowData[col.field]" 
                                  [disabled] =  "!checkAccessReadWrite()" appFourDecimal appNoWhiteSpace>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="i > 0 && !rowData['isCategory'] && [col.field] == 'sequence'">
                            <div class="form-control selling-price-wrapper">
                                <div class="p-inputgroup">
                                  <input pInputText type="text" class="price-input" (keyup) = "valueChanged(rowData)" [(ngModel)]="rowData[col.field]"
                                  [disabled] = "!checkAccessReadWrite()" appFourDecimal appNoWhiteSpace >
                                </div>
                            </div>
                            <div  class="sequence-unique" *ngIf="rowData['sequence'] === ''">
                                Sequence is required
                            </div>
                            <div  class="sequence-unique" *ngIf="!rowData['isUniqueSequence']">
                                Sequence should be unique
                            </div>
                        </div>
                        <!-- <p-treeTableCellEditor *ngIf="i > 0">
                            <ng-template pTemplate="input">
                                <input pInputText type="text" (keyup) = "valueChanged()" [(ngModel)]="rowData[col.field]" >
                            </ng-template>
                            <ng-template pTemplate="output">{{rowData[col.field]}}</ng-template>
                        </p-treeTableCellEditor> -->
                    </td>
                </tr>
            </ng-template>
        </p-treeTable>
    </div>

    <div class="page-body" *ngIf = "files && files.length == 0">
          <div class="no-records">
            <div class="center-div" translate=""> label_no_records_found </div>
          </div>
    </div>
</div>