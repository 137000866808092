import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { ErrorBindingService } from '../../services/error-binding.service';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';
import { AdminService } from '../../../admin/services/admin.service';

@Component({
  selector: 'app-menu-editable-menu-category',
  templateUrl: './menu-editable-menu-category.component.html',
  styleUrls: ['./menu-editable-menu-category.component.scss']
})
export class MenuEditableMenuCategoryComponent implements OnInit {

  details;
  items: any = [];
  eventForm: UntypedFormGroup;
  categoryId;
  isClone;
  showAdditionalPaymentLabel = false;
  pageHeading = this.commonService.getLabel('label_menu_add_category');
  countryCodes = AppSettings.COUNTRY_CODE;
  generatedCampaignLink;

  fromDate = null;
  toDate = null;
  minDate: Date = new Date();
  maxDate: Date;
  firstStartDate = new Date();
  stateListOptions: any = [];
  countryListOptions = AppSettings.COUNTRY_LIST;
  deliveryOptions = AppSettings.DELIVERY_OPTIONS;

  yesNoOptions = AppSettings.YES_NO_OPTIONS;
  commentsOptions = AppSettings.CAMPAIGN_COMMENTS_OPTIONS;
  defaultImage = AppSettings.DEFAULT_NO_IMAGE;
  imageUrl;
  uploadedImgFileId;
  chooseLabel;
  loginData;

  place = 'dsds';
  eventErrors = {
    campaignName: {
      hasError: false,
      error: []
    }
  };

  topDonorsNumberRequired = false;
  topCommentsNumberRequired = false;
  submitted = false;
  quantityRequired = false;
  validMinimumQuantity = true;
  validMaximumQuantity = true;
  breadCrumbArray = [];
  showDetailsPopup = false;
  list1: any[] = [];
  list2: any[] = [];

  files: TreeNode[];
  selectedNodes: TreeNode[];
  cols: any[];
  primaryTreeStructure = [];
  brandNameOptions = [];
  allItems;
  itemDetails;
  loggedInUserId;
  loginUserRole;
  accessReadOnly = this.commonService.checkIfHavePermission('MANAGE_MENU', true);
  accessReadWrite = this.commonService.checkIfHavePermission('MANAGE_MENU', true, true);
  

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private errorBindingService: ErrorBindingService,
    private franchiseService: FranchiseService,
    private commonService: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
    private storageService: StorageService,
    private adminService: AdminService,
  ) {
    this.loggedInUserId = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA))?.franchiseId;
  }

  ngOnInit(): void {
    this.loginUserRole = this.commonService.getLoginRole();
    this.initForm();
    this.imageUrl = this.defaultImage;
    this.chooseLabel = this.commonService.getLabel('label_upload_image');
    this.categoryId = this.route.snapshot.queryParams.id;
    this.getBrandList(this.loggedInUserId);

    if (this.categoryId) {
      this.pageHeading = this.commonService.getLabel('label_menu_edit_category');
      this.getDetails();
    }

    // this.list1 = [{brand: 'abc'},{brand: 'def'}, {brand: 'ghi'}];
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    this.createBreadCrumb();
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'price', header: 'Price' },
      { field: 'sequence', header: 'Sequence' },
    ];
  }

  getBrandList(id) {
    if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE || this.loginUserRole == AppSettings.STAFF_ROLES['Manager']) {
      const isAdd = this.categoryId?false:true;
      this.franchiseService.getBrandList(id,isAdd).subscribe((result) => {
        this.brandNameOptions = result.map(element => ({
          label: element.brandName,
          value: element.brandId,
          disabled: !element.activated,
          activated: element.activated,
          deleted: element.deleted, inactive: false
        }));
        // this.brandNameOptions.unshift({ label: this.commonService.getLabel('Select'), value: '', brandName: '' });
        setTimeout(() => {
          this.getSelectedBrand();
        }, 900);
      });
    } else {
      this.adminService.getAllBrandsList().subscribe((result) => {
        this.brandNameOptions = result.map(element => ({
          label: element.brandName,
          value: element.id,
          activated: element.activated,
          deleted: element.deleted, inactive: false
        }));
        setTimeout(() => {
          this.getSelectedBrand();
        }, 900);
      });
    }

  }

  initForm() {
    this.eventForm = this.formBuilder.group({
      categoryName: [{ value: this.details ? this.details.categoryName : '', disabled: !this.accessReadWrite},
      [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      brandId: [this.details ? this.details.brandId : '', [Validators.required]],
      foodExtraItemIds: [this.details ? this.details.itemIds : []],
    });
  }

  getSelectedBrand(details?) {
    if (details) {
      this.eventForm.controls.brandId.setValue(details?.brandId);
      this.fetchAllItemsList(details?.brandId);
    } else {
      const selectedBrand = this.eventForm.controls.brandId.value ? this.eventForm.controls.brandId.value :
        this.brandNameOptions[0].value;
      this.fetchAllItemsList(selectedBrand);
    }
  }

  fetchAllItemsList(brandId?) {
    this.franchiseService.getAllMenuList(brandId).subscribe((result) => {
      const sourceList = result.data.map(eachFoodExtra => ({
        id: eachFoodExtra.id,
        signUrl: eachFoodExtra.signUrl ? eachFoodExtra.signUrl : AppSettings.DEFAULT_NO_IMAGE,
        itemName: eachFoodExtra.name,
        sellingPrice: eachFoodExtra.price,
      }))
      // console.log(sourceList, this.list1)
      this.list1 = [...sourceList];
      this.list2 = [];

      setTimeout(() => {
        if (this.details) {
          // this.onClickOptions();
          setTimeout(() => {
            this.setSelectedFoodItems();
          }, 1000);
        }
      }, 100);
    });
  }

  getDetails() {
    this.franchiseService.getMenuCatDetails(this.categoryId).subscribe((result) => {
      this.details = result;
      this.initForm();
      this.getSelectedBrand(this.details);
      this.eventForm.controls.brandId.disable();
    });
  }

  onChangeBrand() {
    const selectedBrand = this.eventForm.controls.brandId.value ? this.eventForm.controls.brandId.value : '';
    this.fetchAllItemsList(selectedBrand);
  }

  setSelectedFoodItems() {
    if (this.details && this.details.itemIds?.length > 0) {
      // this.list2 = [];
      if (this.list2.length < 1) {
        const selectedItemArray = this.details.itemIds;
        const selectedList = this.list1.filter(eachExtra => (selectedItemArray.includes(eachExtra.id)));
        this.list2 = [...selectedList];
        const unSelectedList = this.list1.filter(eachExtra => (!selectedItemArray.includes(eachExtra.id)));
        this.list1 = [...unSelectedList];
        // console.log("this.list2"+this.list2.length);
        // console.log(this.list1, 'list2', this.list2)
      }

    }
  }

  onSave() {
    this.submitted = true;
    if (this.eventForm.valid) {
      const eventModel = this.getFormData();
      if (this.categoryId) {
        this.confirmationService.confirm({
          message: this.commonService.getLabel('msg_edit_menu_cat'),
          accept: () => {
            this.franchiseService.updatMenuCategory(eventModel).subscribe((result) => {
              this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
              if (this.loginData) {
                const loginData = JSON.parse(this.loginData);
                if (loginData.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || loginData.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
                  this.router.navigate(['franchise/menu-section/menu-categories/'], { queryParams: { fromCancel: 'fromCancel' } });
                } else {
                  this.router.navigate(['admin/menu-section/menu-categories/'], { queryParams: { fromCancel: 'fromCancel' } });
                }
              }
            }, (error) => {
              console.log(error);
            });
          }
        });
      } else {
        this.confirmationService.confirm({
          message: this.commonService.getLabel('msg_add_menu_cat'),
          accept: () => {
            this.franchiseService.addMenuCategory(eventModel).subscribe((result) => {
              this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
              if (this.loginData) {
                const loginData = JSON.parse(this.loginData);
                if (loginData.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || loginData.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
                  this.router.navigate(['franchise/menu-section/menu-categories']);
                } else {
                  this.router.navigate(['admin/menu-section/menu-categories']);
                }
              }

            }, (error) => {
              console.log(error);
            });
          }
        });
      }
    } else {
      // this.errorBindingService.getFormValidationErrors(this.eventForm);
    }
  }

  getFormData() {
    const formControl = this.eventForm.getRawValue();
    const editableEventModel: any = {
      'categoryName': formControl.categoryName.trim(),
      'description': formControl.categoryName.trim(),
      'brandId': formControl.brandId.trim(),
      itemIds: this.list2.map(e => e.id),
    };
    if (this.details && this.details.id) {
      editableEventModel.id = this.details.id;
    }
    // console.log(editableEventModel);
    // return;
    return editableEventModel;
  }

  onClickOptions(type?) {
    this.list1 = [];
    this.list2 = [];
    this.fetchAllItemsList();
  }

  goToDetails(item) {
    // console.log(item);
    if (this.loginData) {
      const data = JSON.parse(this.loginData);
      if (this.categoryId) {
        this.storageService.setItem('fromAddCategory', false);
        this.storageService.setItem('categoryId', this.categoryId);
      } else {
        this.storageService.setItem('fromAddCategory', true);
      }

      if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE  || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
        this.router.navigate(['franchise/menu-section/menu-items/menu-details'], { queryParams: { id: item.id } });
      } else if (data.roles[0].roleCode === AppSettings.USER_ROLE_ADMIN) {
        this.router.navigate(['admin/menu-section/menu-items/menu-details'], { queryParams: { id: item.id } });
      } else {
        this.router.navigate(['franchise/menu-section/menu-items/menu-details'], { queryParams: { id: item.id } });
      }
    }
  }

  prepareTreeStructure() {

    this.primaryTreeStructure = []
    const primeryTree = [];
    this.allItems.forEach(eachCat => {
      // console.log('eachCat', eachCat);
      let firstLevel;
      firstLevel = {
        // partialSelected: true,
        data: {
          id: eachCat.foodExtraCategoryId,
          name: eachCat.foodExtraCategoryName,
          price: '--',
          sequence: '',
          signUrl: '',
        },
        children: [],
        expanded: true
      }
      if (eachCat.foodItemsIds.length > 0) {
        const temp = eachCat.foodExtraCategoryId;
        eachCat.foodItemsIds.forEach(eachFoodExtra => {
          let node = { data: {} };
          node = this.generateTreeNode(eachFoodExtra, temp);
          // console.log('node22', node);
          // node.data['categoryId'] = temp1;
          firstLevel['children'].push(node);
          // console.log('categoryId', eachCat.foodExtraCategoryId);
        });
      }
      primeryTree.push(firstLevel);
      // console.log('primaryTree 23', primeryTree);
    });
    // this.files = <TreeNode[]>primaryTreeStructure;

    this.files = <TreeNode[]>primeryTree;
    // console.log('primaryTreeStructure', this.files);
    this.showDetailsPopup = true;
    // this.prepareFinalTreeStructure(primeryTree);
  }

  generateTreeNode(eachItem, eachCatId) {
    let node;
    // console.log('cat', eachCatId);
    node = {
      data: {
        id: eachItem.id,
        categoryId: eachCatId,
        name: eachItem.foodExtraItemName,
        price: eachItem.sellingPrice,
        sequence: eachItem.sequence,
        signUrl: eachItem.signUrl,
      }
    }
    // node['data']['categoryId'] = eachCatId,

    // console.log('node11', node);
    return node;
  }

  onCancel() {

    if (this.loginData) {
      const data = JSON.parse(this.loginData);
      if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
        this.router.navigate(['franchise/menu-section/menu-categories'], { queryParams: { fromCancel: 'fromCancel' } });
      } else {
        this.router.navigate(['admin/menu-section/menu-categories'], { queryParams: { fromCancel: 'fromCancel' } });
      }
    }
  }

  goBack() {
    window.history.back();
  }
  createBreadCrumb() {
    this.breadCrumbArray = [
      {
        label: this.commonService.getLabel('label_manage_menu_category'),
        route: '',
        queryParams: { fromCancel: 'fromCancel' }
      },
      {
        label: this.pageHeading,
        route: '',
      }
    ];

    const data = JSON.parse(this.loginData);
    this.breadCrumbArray.forEach(bcrum => {
      if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
        bcrum.route = 'franchise/menu-section/menu-categories'
      } else {
        bcrum.route = 'admin/menu-section/menu-categories'
      }
    })
  }

  hidePopup() {
    this.files = [];
    this.showDetailsPopup = false;
  }

}