<div class="page-container legacy-page-wrapper">
    <div class="page-header">
      <div class="page-heading">
        <h1 class="heading" translate>label_legacy_data</h1>
      </div>
      <div class="page-actions">

      </div>
    </div>

    <div class="page-filters">
      <div class="filter-left-container grid">
        <div class="filter-item col-4">
          <div class="form-control input-container">
             <span class="p-input-icon-left">
               <i class="pi pi-search"></i>
               <input type="text" pInputText (keyup)= "onSearch($event)" [(ngModel)] = "search" class="search-input prefix-pad"
               [placeholder]="'label_search'|translate" appPreventSpecialCharacters pTooltip="{{'label_search_legacy_data' | translate}}">
             </span>
          </div>
        </div>
        <!-- <div class="filter-item col-4 lg:col-2 md:col-3 "  *ngIf="isAdminLogin">
          <div class="form-control input-container " >
            <p-multiSelect  #brandButton [options]="brandNameOptions" (onChange)="onSelectBrand($event)" [(ngModel)]="selectedBrandFromFilter"
              [maxSelectedLabels]="1" [showHeader]="false" [filter]="false" selectedItemsLabel="{0} Brands selected" placeholder="Select Brand" >
            </p-multiSelect>
          </div>
        </div> -->
      </div>
      <div class="filter-right-container " *ngIf="legacyDataReadWrite">
        <!-- <div class="filter-item p-mx-1">
            <app-file-uploader (onUpload)="uploadFile($event)" [inputUrl] = "UploadFileUrl" [accept]="acceptfile"
            [uploadButtonLabel]="label_upload_contact" (afterFileUploadEvent)="onUploadFile($event)" (afterFileUploadFailed)="onUploadFailed($event)">
            </app-file-uploader>
        </div> -->
        <div class="filter-item" >
            <button pButton (click)="addLegacyData()" class="" type="button"
            [label]="'label_add_new_contact'|translate"></button>
        </div>
        <div class="filter-item p-ml-1" >
          <button pButton (click)="onDeleteSelectedContacts($event)" class="btn-allDelete" type="button"
          [label]="'label_delete_contacts'|translate" ></button>
      </div>
      </div>
    </div>

    <div class="page-body md:col-12 p-0">
      <div class="total-selected-records" *ngIf="showDeleteRow"><a href="javascript:void(0)" (click)="deleteAllRecords()">Delete {{gridRef.totalRecords}} contacts</a> </div>
      <app-mi-grid-v2 [url]="gridurl" [columnDefs]="columns"
          (actionBtnClickedEvent)="onActionBtnClicked($event)" [actionButtonsDefs]="buttons" [isExportVisible]="false"
          divClass="data-grid-lines" [searchBar]="searchBar" [uniqueId] = "uniqueId" [fromCancelOffset]="saveFromCancelOffset"
          [isInputBrandId] = "franchiseLogin" (selectAllCheckbox)="onSelectAll($event)" (selectCheckbox) ='onSelectCheckbox($event)'>
      </app-mi-grid-v2>
    </div>

  </div>

  <p-dialog [dismissableMask]="false"
    [modal] = "true"[header]="'Campaign Details' | translate" [(visible)]="showDetailsPopup" *ngIf="showDetailsPopup && imgurl" class="campaign-details-dialog"
    dismissableMask="true" [closable] = "true" [draggable]="false" blockScroll="true" [resizable]="true" [focusOnShow]="false" >
    <iframe [src]="imgurl" width="100%" height="100%" ></iframe>
  </p-dialog>