import { MiCheckboxComponent } from './../mi-checkbox/mi-checkbox.component';
import { MiSelectComponent } from './../mi-select/mi-select.component';
import { MiButtonComponent } from './../mi-button/mi-button.component';
import {
  Directive,
  Type,
  OnChanges,
  OnInit,
  ComponentRef,
  Input,
  ComponentFactoryResolver,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { MiInputComponent } from './../mi-input/mi-input.component';

const components: { [type: string]: Type<Field> } = {
  input: MiInputComponent,
  button: MiButtonComponent,
  select: MiSelectComponent,
  checkbox: MiCheckboxComponent,
};

@Directive({
  selector: '[appMiDynamicField]',
})
export class MiDynamicFieldDirective implements Field, OnChanges, OnInit {
  @Input() config: FieldConfig;
  @Input() group: UntypedFormGroup;

  component: ComponentRef<Field>;
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
    }
  }

  ngOnInit() {
    if (!components[this.config.type]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
      );
    }
    const component = this.resolver.resolveComponentFactory<Field>(
      components[this.config.type]
    );
    this.component = this.container.createComponent(component);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}
