import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
  selector: 'app-mi-checkbox',
  templateUrl: './mi-checkbox.component.html',
  styleUrls: ['./mi-checkbox.component.scss'],
})
export class MiCheckboxComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;
}
