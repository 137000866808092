<p-dialog [dismissableMask]="false" [modal]="true" [header]="'Menu Item' | translate"
  [(visible)]="showAddClient"
  styleClass="add-foodextra-dialog form-container filter-container" dismissableMask="true" [closable]="true"
  [draggable]="false" blockScroll="true" [resizable]="true" [focusOnShow]="false" (onHide)="cancel()">
  
    <div class="form-item">

      <div class="dialog-outer-container">
        <div class="page-body">
          <form [formGroup]="pageForm">
            <div class="form-container-column grid col-12 lg:col-12 md:col-0 p-0">

              <div class="form-item col-12 lg:col-12 md:col-12">
                <div class="form-field">
                  <!-- <div class = "menu-image-div">
                    <img [src]="localImgUrl" class="menu-image-list" />
                    <span *ngIf="!checkDisable()" class="fa menu-edit-icon edit-icon-margin"
                      (click)="onEditImageClick()"></span>
                  </div> -->

                  <div class="menu-image-div">
                    <img [src]="localImgUrl" class="menu-image-list" />
                    <span *ngIf="!checkDisable()" class="fa menu-edit-icon edit-icon-margin"
                      (click)="onEditImageClick()"></span>
                    <div class="form-errors menu-image-list"
                      *ngIf="pageForm.controls.image.errors  && (pageForm.controls.image.dirty || pageForm.controls.image.touched || submitted)">
                      <div [hidden]="!pageForm.controls.image.errors.required" translate>
                        msg_select_image
                      </div>
                    </div>
                  </div>
                  <div class="brand-wrapper">
                    <div class="form-label">
                      <label for="brandName" translate>label_brandName</label>
                    </div>
                    <div class="form-control">
                      <input formControlName="brandName" id="brandName" class="full-width" type="text" 
                      appNoWhiteSpace pInputText appPreventSomeSpecialCharacters allowQuote="no" />
                    </div>
                  </div>
                  <div class="item-wrapper">
                    <div class="form-label">
                      <label for="itemName" translate>label_item<span class="required-star">*</span></label>
                    </div>
                    <div class="form-control">
                      <input formControlName="itemName" id="itemName" class="full-width" type="text" appNoWhiteSpace
                        pInputText appPreventSomeSpecialCharacters allowQuote="no"/>
                    </div>
                    <div class="form-errors"
                      *ngIf="pageForm.controls.itemName.errors  && (pageForm.controls.itemName.dirty || pageForm.controls.itemName.touched || submitted)">
                      <div [hidden]="!pageForm.controls.itemName.errors.required" translate>
                        field_is_required
                      </div>
                      <div [hidden]="!pageForm.controls.itemName.errors.minlength" translate>
                        min_length_1char
                      </div>
                      <div [hidden]="!pageForm.controls.itemName.errors.maxlength" translate>
                        max_length_50char
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-item col-6 lg:col-12 md:col-5">
                <div class="form-field">
                  <div class="form-label">
                    <label for="description" translate>label_description<span class="required-star">*</span></label>
                  </div>
                  <div class="form-control">
                    <input formControlName="description" id="description" class="full-width" type="text"
                      appNoWhiteSpace pInputText appPreventSomeSpecialCharacters allowQuote="no"/>
                  </div>
                  <div class="form-errors"
                    *ngIf="pageForm.controls.description.errors  && (pageForm.controls.description.dirty || pageForm.controls.description.touched || submitted)">
                    <div [hidden]="!pageForm.controls.description.errors.required" translate>
                      field_is_required
                    </div>
                    <div [hidden]="!pageForm.controls.description.errors.minlength" translate>
                      min_length_1char
                    </div>
                    <div [hidden]="!pageForm.controls.description.errors.maxlength" translate>
                      max_length_160char
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-item col-6 lg:col-12 md:col-5">
                <div class="form-field">
                  <div class="form-label">
                    <label for="sellingPrice" translate>label_selling_price<span class="required-star">*</span></label>
                  </div>
                  <div class="form-control selling-price-wrapper">
                    <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">$</span>
                      <input formControlName="sellingPrice" id="sellingPrice"
                      (keyup) ="onPriceChange()" class="full-width" type="text" appTwoDecimal appNoWhiteSpace pInputText /> 
                    </div>
                  </div>
                  <div class="form-errors"
                    *ngIf="pageForm.controls.sellingPrice.errors  && (pageForm.controls.sellingPrice.dirty || pageForm.controls.sellingPrice.touched || submitted)">
                    <div [hidden]="!pageForm.controls.sellingPrice.errors.required" translate>
                      field_is_required
                    </div>
                    <div [hidden]="!pageForm.controls.sellingPrice.errors.minlength" translate>
                      min_length_1char
                    </div>
                    <div [hidden]="!pageForm.controls.sellingPrice.errors.maxlength" translate>
                      Maximum 9 digits allowed.
                    </div>
                    
                  </div>
                </div>
              </div>

              <div class="col-12 p-justify-end page-buttons p-mt-2 p-mr-2 p-mb-2 save-button">
                <button pButton [label]="'btn_cancel' | translate" type="submit" class="cancel-btn"
                  (click)="onCancelClick()"></button>
                <button pButton [label]="'btn_save' | translate" type="submit" class="btn-save bottom-align"
                  (click)="onSave()"></button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>


    <p-dialog [dismissableMask]="false" [modal]="true" [header]="'label_select_image' | translate" modal="true"
      position="center" autoResize="true"  [draggable]="false" styleClass="menu-image extra-menusection"
      [(visible)]="showImageListPopUp" [focusOnShow]="false" *ngIf = "showImageListPopUp">
     
      <div class="col-12 menu-content">
        <div class="grid" *ngIf = "brandNameOptions.length > 0">
          <div class="col-6 lg:col-6 pad-l-0">
            <div class="form-field">
              <div class="form-label">
                <label for="menuBrand" translate>label_brandName<span class="required-star">*</span></label>
              </div>
              <div class="form-control">
                <p-dropdown (onChange) = "onChangeBrand($event)"  [(ngModel)]="menuBrand" [options]="brandNameOptions"
                 [disabled]="itemDetails?.id"></p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-6 lg:col-6 pad-r-0">
            <div class="form-field form-control">
              <label for="searchText" translate><br> </label>
              <input class="full-width" [(ngModel)]="searchText" (keyup)="onSearchImages()" type="text"
              [placeholder]="'label_search_image_text'|translate" pInputText appPreventSpecialCharacters
                pTooltip="{{'label_search_name' | translate}}" />
            </div>
          </div>
        </div>
        <div class="grid images-content p-mt-2">
          <div class="col-12 lg:col-4 md:col-4 align-center"
            *ngFor="let eachImage of localMenuImageList; let i = index;">
            <div class="col-12 label-img-div">
              <div class="fix-div-width-height">
                <label for="data{{i}}">
                  <img [src]="eachImage.signUrl" class="img-class" />
                </label>
              </div>
            </div>
            <div class="col-12">
              <input type="radio" id="menu" [(ngModel)]="selectedImageIndex" name="passengerAppApplicable"
                value="{{eachImage['id']}}" id="data{{i}}" />
              <label class="mar-t-b-0 " for="data{{i}}">{{eachImage.originalFileName}}</label>
            </div>
          </div>
          <div class="col-12 lg:col-8 md:col-8 error-align" *ngIf="localMenuImageList.length == 0" translate>
            label_no_records_found

          </div>
        </div>
      </div>
      <p-footer>
        <div class="image-selection-popup btn">

          <button appAccessProvider pButton class="small-btn" appRipple type="button" label="Save"
            (click)="onSelectImage()"></button>
          <button class="small-btn cancel-btn" pButton appRipple type="button" label="Cancel"
            (click)="onCancelPopUp()"></button>
        </div>
      </p-footer>
    </p-dialog>