import { Injectable } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AppSettings } from './../modules/shared/app.settings';
import { Router } from '@angular/router';
import { StorageService } from './../modules/shared/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  loginData: any;

  constructor(
    private router: Router,
    private storageService: StorageService,
  ) { }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);

    if (this.loginData) {
      const data = JSON.parse(this.loginData);
      if (route.data.roles.includes(data.roles[0].roleName) || (segments[0].path === 'profile') || data.roles[0].roleName == AppSettings.STAFF_ROLES['Manager']) {
        return true;
      } else {
        this.router.navigate(['/signin']);
        return false;
      }
    }
    /** this is due to when we logout in one tab and in other tab was redirecting to /#/ now it will redirect to signin page  */
    this.router.navigate(['/signin']);
    return false;
  }
}
