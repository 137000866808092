<div class="page-container">
    <div class="col-12 col-nopad">
        <app-bread-crumb [breadCrumb]="breadCrumbArray"></app-bread-crumb>
    </div>
    <div class="page-header">
        <div class="page-header-menu">
            <h1 class="heading">{{pageHeading}}</h1>
        </div>
        <div class="page-actions">
            <button pButton appAccessProvider accessCode="MANAGE_MENU" [readonly] = "true" [readWrite] = "true" class="small-btn" (click)="onSave()" type="button" [label]="'btn_save'|translate"></button>
            <button pButton class="small-btn cancel-btn" (click)="onCancel()" type="button"
                [label]="'btn_cancel'|translate"></button>
        </div>
    </div>

    <div class="page-body">
        <form [formGroup]="eventForm">
            <div class="form-container-column col-12 lg:col-8">

                <div class="grid">
                    <div class="col-6 lg:col-6 md:col-6">
                        <div class="form-field">
                            <div class="form-label">
                                <label for="categoryName" translate>label_menu_category_name<span
                                        class="required-star">*</span></label>
                            </div>
                            <div class="form-control">
                                <input formControlName="categoryName" id="categoryName" class="full-width" type="text"
                                     appNoWhiteSpace pInputText appPreventSomeSpecialCharacters allowQuote="no"/>
                            </div>
                            <div class="form-errors" *ngIf="eventForm.controls.categoryName.errors  && (eventForm.controls.categoryName.dirty || eventForm.controls.categoryName.touched ||
                               submitted)">
                                <div [hidden]="!eventForm.controls.categoryName.errors.required" translate>
                                    field_is_required
                                </div>
                                <div [hidden]="!eventForm.controls.categoryName.errors.minlength" translate>
                                    min_length_1char
                                </div>
                                <div [hidden]="!eventForm.controls.categoryName.errors.maxlength" translate>
                                    max_length_50char
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-6 lg:col-6 md:col-6">
                        <div class="form-field">
                            <div class="form-label">
                                <label for="categoryName" translate>label_brandName</label>
                            </div>
                            <div class="form-control">
                                <p-dropdown formControlName="brandId" [options]="brandNameOptions"
                                    (onChange)="onChangeBrand()" optionDisabled="inactive"></p-dropdown>
                            </div>
    
                        </div>
                    </div>
                </div>

                <div class="form-item col-12 lg:col-7 md:col-6">
                    <div class="form-field">
                        <div class="form-label">
                            <label for="categoryType" translate>label_food_extra_category_type<span
                                    class="required-star">*</span></label>
                        </div>
                    </div>

                    <div class="form-control">
                        <div class="col-12 pad-l-0 input-radio-container">
                            <input type="radio" name="categoryType" formControlName="categoryType" value="optional"
                                id="optional" (change)="onClickOptions('optional')"  [disabled] = "!accessReadWrite"/>
                            <label class="custom-control-label menu-radio-button" for="optional"
                                translate>label_make_selection_optional</label>
                        </div>
                        <div class="col-12 pad-l-0 input-radio-container">
                            <input type="radio" name="categoryType" formControlName="categoryType" value="mandatory"
                                id="mandatory" (change)="onClickOptions('mandatory')" [disabled] = "!accessReadWrite" />
                            <label class="custom-control-label menu-radio-button" for="mandatory"
                                translate>label_require_range</label>
                        </div>
                    </div>
                    <div class="form-errors"
                        *ngIf="eventForm.controls.categoryType.errors  && (eventForm.controls.categoryType.dirty || eventForm.controls.categoryType.touched || submitted)">
                        <div [hidden]="!eventForm.controls.categoryType.errors.required" translate>
                            field_is_required
                        </div>
                        <div [hidden]="!eventForm.controls.categoryType.errors.maxlength" translate>
                            max_length_50char
                        </div>
                    </div>
                </div>
                <div class="grid p-t-20">
                    <div class="col-6 lg:col-6 md:col-6">
                        <div class="form-field">
                            <div class="form-label">
                                <label for="minQuantity" translate>label_min_quentity<span class="required-star"
                                        *ngIf="quantityRequired">*</span></label>
                            </div>
                            <div class="form-control">
                                <input formControlName="minQuantity" class="full-width" type="text"
                                    (keyup)="checkMinMaxValueValid('minQuantity')" appTwoDecimal appNoWhiteSpace
                                    pInputText />
    
                            </div>
                            <div class="form-errors"
                                *ngIf="eventForm.controls.minQuantity.errors  && (eventForm.controls.minQuantity.dirty || eventForm.controls.minQuantity.touched || submitted)">
                                <div [hidden]="!eventForm.controls.minQuantity.errors.required" translate>
                                    field_is_required
                                </div>
                                <div [hidden]="!eventForm.controls.minQuantity.errors.maxlength" translate>
                                    max_length_50char
                                </div>
                            </div>
                            <div class="form-errors"
                                *ngIf="!validMinimumQuantity  && !eventForm.controls.minQuantity.errors" translate>
                                invalid minimum quantity
                            </div>
                        </div>
                    </div>
    
                    <div class="col-6 lg:col-6 md:col-6">
                        <div class="form-field">
                            <div class="form-label">
                                <label for="city" translate>label_max_quentity<span class="required-star"
                                        *ngIf="quantityRequired">*</span></label>
                            </div>
                            <div class="form-control">
                                <input formControlName="maxQuantity" id="maxQuantity" class="full-width" type="text"
                                    (keyup)="checkMinMaxValueValid('maxQuantity')" appTwoDecimal appNoWhiteSpace
                                    pInputText />
                            </div>
                            <div class="form-errors"
                                *ngIf="eventForm.controls.maxQuantity.errors  && (eventForm.controls.maxQuantity.dirty || eventForm.controls.maxQuantity.touched || submitted)">
                                <div [hidden]="!eventForm.controls.maxQuantity.errors.required" translate>
                                    field_is_required
                                </div>
                                <div [hidden]="!eventForm.controls.maxQuantity.errors.minlength" translate>
                                    min_length_10number
                                </div>
                                <!-- <div [hidden]="!eventForm.controls.maxQuantity.errors.maxlength" translate>
                                    max_length_10number
                                </div> -->
                            </div>
                            <div class="form-errors"
                                *ngIf="!validMaximumQuantity  && !eventForm.controls.maxQuantity.errors" translate>
                                invalid maximum quantity
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-container-column col-12 lg:col-12">
                <div class="form-item col-12 lg:col-12 md:col-12">
                    <div class="form-field">
                        <div class="form-label">
                            <label for="city" translate>Select Food Extra Items</label>
                        </div>
                        <div class="form-control pickList-Control">
                            <p-pickList [source]="list1" [target]="list2" sourceHeader="Available"
                                targetHeader="Selected" dragdrop="true" [responsive]="true"
                                [sourceStyle]="{'height':'400px'}" [targetStyle]="{'height':'400px'}"
                                filterBy="itemName" sourceFilterPlaceholder="Search" showTargetFilter="false"
                                [showSourceControls]="false" [showTargetControls]="false">
                                <ng-template let-eachItem pTemplate="item">
                                    <div class="product-item grid">
                                        <div class="col-nopad  col-2">
                                            <img class = "picklist-img" [src]="eachItem.signUrl">
                                        </div>
                                        <div class="product-list-detail col-8 col-nopad">
                                            <div class="grid m-0">
                                                <div class ="col-12 col-nopad">
                                                    <h5 class="mb-2">{{eachItem.itemName}}</h5>
                                                </div>
                                                <div  class="col-4 sub-items col-nopad"><span>Price</span>:
                                                    ${{eachItem.sellingPrice}}
                                                </div>
                                                <div  class="col-3 sub-items col-nopad" *ngIf="!selectionalOptional"><span>Min</span>:
                                                    ({{eachItem.minQtyAllowed}})
                                                </div>
                                                <div  class="col-3 sub-items col-nopad" *ngIf="!selectionalOptional"><span>Max</span>:
                                                    ({{eachItem.maxQtyAllowed}})
                                                </div>
                                                <div  class="col-2 sub-items col-nopad" *ngIf = "!eachItem.activated" >
                                                    <span>InActive</span>:
                                                </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                </ng-template>
                            </p-pickList>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>