import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';
import { V2Service } from '../../services/v2-service.service';
import { CommonBindingDataService } from '../../services/common-binding-data.service';

@Component({
  selector: 'app-generate-reports',
  templateUrl: './generate-reports.component.html',
  styleUrls: ['./generate-reports.component.scss']
})
export class GenerateReportsComponent implements OnInit {

  @Input() showGenerateReport: boolean;
  reportGenerateForm: UntypedFormGroup;
  userEmail: any;
  submitted: boolean;
  @Input() fromDate;
  @Input() source;
  @Input() eventId;
  @Input() toDate;
  @Input() activated;
  @Input() applyActivatedStatus;
  @Input() exportCompleteUrl = '';
  @Output() outputToggle: EventEmitter<any> = new EventEmitter();
  @Input() isPostData: any;
  @Input() isPostDataObj: any;
  @Input() isPost = false;
  emailNote:any;
  selectedBrands = [];

  constructor(private router: Router,
    private franchiseService: FranchiseService,
    private adminService: V2Service,
    private uiService: UiService,
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService,
    private commonBindingDataService: CommonBindingDataService) { }

  ngOnInit(): void {
    this.userEmail = this.storageService.getItem(AppSettings.USER_DETAILS_EMAIL);
    this.initGenerateForm();
    this.selectedBrands = JSON.parse(this.storageService.getItem('selectedBrands'));
  }

  initGenerateForm() {
    this.reportGenerateForm = this.formBuilder.group({
      email: [JSON.parse(this.userEmail) ? JSON.parse(this.userEmail) : '',
      [Validators.required, Validators.minLength(1), Validators.maxLength(120), Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      emailNote: ''
    });
  }

  onCancelClick() {
    this.outputToggle.emit(false);
  }

  onConfirmClick() {
    this.submitted = false;
    if (this.reportGenerateForm.valid) {
      console.log('prepostDAta',this.isPostDataObj);
      const email = this.reportGenerateForm.controls.email.value.trim();
      const emailNotes = this.reportGenerateForm.controls.emailNote.value.trim();
      this.isPostDataObj.email = email;
      this.isPostDataObj.emailNote = emailNotes;
      this.isPostDataObj.brandIds = this.selectedBrands;
      // this.isPostDataObj['fromDate'] = this.isPostDataObj.fromDate; 
      // this.isPostDataObj['toDate'] = this.isPostDataObj.toDate; 
      
      // delete this.isPostDataObj.brandIds;
      // // const reportExportUrl = this.exportCompleteUrl + `&email=${email}`;
      console.log('postDAta',this.isPostDataObj);
      // return;
      this.franchiseService.generateReport(this.exportCompleteUrl, this.isPostDataObj).subscribe((result) => {
        if (result) {
          this.uiService.showMessage('Report generated successfully', AppSettings.MESSAGE_TYPE.SUCCESS);
        }
        this.onCancelClick();
        this.reportGenerateForm.reset();
      }, error => {
        this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
      });
    } else {
      this.reportGenerateForm.markAllAsTouched();
    }
  }

  onConfirmPostClick() {
    this.submitted = false;
    if (this.reportGenerateForm.valid) {
      const email = this.reportGenerateForm.controls.email.value.trim();
      const emailNotes = this.reportGenerateForm.controls.emailNote.value.trim();
      this.isPostData.email = email;
      this.isPostData.emailNote = emailNotes;
      this.isPostData.brandIds = this.selectedBrands;
      // this.isPostDataObj.fromDate = this.isPostDataObj.fromDate;
      // this.isPostDataObj.toDate = this.isPostDataObj.toDate;
      
      // delete this.isPostData.brandIds;
      this.franchiseService.generatePostBodyReport(this.exportCompleteUrl, this.isPostData).subscribe((result) => {
        if (result) {
          this.uiService.showMessage('Report generated successfully', AppSettings.MESSAGE_TYPE.SUCCESS);
        }
        this.onCancelClick();
        this.reportGenerateForm.reset();
      }, error => {
        this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
      });
    } else {
      this.reportGenerateForm.markAllAsTouched();
    }
  }

}
