<div class="page-container task-page">
    <div class="col-12 col-nopad">
      <app-bread-crumb [breadCrumb]="breadCrumbArray"></app-bread-crumb>
    </div>
    <div class="page-header">
      <div class="page-heading">
        <h1 class="heading p-ml-1">{{pageHeading}}</h1>
      </div>
      <div class="page-actions">
        <button pButton class="small-btn" (click)="onSave()" type="button" [label]="'btn_save'|translate"></button>
        <button pButton class="small-btn cancel-btn" (click)="onCancel()" type="button" [label]="'btn_cancel'|translate"></button>
      </div>
    </div>
  
    <div class="page-body">
      <form [formGroup]="contactForm">
        <div class="form-container col-12 lg:col-8 pad-l-0">
  
          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="firstName" translate>label_first_name</label>
              </div>
              <div class="form-control">
                <input formControlName="firstName" id="firstName" class="full-width" type="text" appNoWhiteSpace pInputText />
              </div>
              <div class="form-errors" *ngIf="contactForm.controls.firstName.errors  && (contactForm.controls.firstName.dirty || contactForm.controls.firstName.touched ||
                         submitted)">
                
                <div [hidden]="!contactForm.controls.firstName.errors.minlength" translate>
                  min_length_1char
                </div>
                <div [hidden]="!contactForm.controls.firstName.errors.maxlength" translate>
                  max_length_50char
                </div>
              </div>
            </div>
          </div>
  
          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="lastName" translate>label_last_name</label>
              </div>
              <div class="form-control">
                <input formControlName="lastName" id="lastName" class="full-width" type="text" appNoWhiteSpace pInputText />
              </div>
              <div class="form-errors" *ngIf="contactForm.controls.lastName.errors  && (contactForm.controls.lastName.dirty || contactForm.controls.lastName.touched ||
                         submitted)">
               
                <div [hidden]="!contactForm.controls.lastName.errors.minlength" translate>
                  min_length_1char
                </div>
                <div [hidden]="!contactForm.controls.lastName.errors.maxlength" translate>
                  max_length_50char
                </div>
              </div>
            </div>
          </div>
  
          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="companyName" translate>label_company_name</label>
              </div>
              <div class="form-control">
                <input formControlName="companyName" id="companyName" class="full-width" type="text" appNoWhiteSpace pInputText />
              </div>
              <div class="form-errors" *ngIf="contactForm.controls.companyName.errors  && (contactForm.controls.companyName.dirty || contactForm.controls.companyName.touched || submitted)">
            
                <div [hidden]="!contactForm.controls.companyName.errors.minlength" translate>
                  min_length_1char
                </div>
                <div [hidden]="!contactForm.controls.companyName.errors.maxlength" translate>
                  max_length_50char
                </div>
              </div>
            </div>
          </div>

          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="title" translate>label_title</label>
              </div>
              <div class="form-control">
                <input formControlName="title" id="title" class="full-width" type="text" appNoWhiteSpace pInputText />
              </div>
              <div class="form-errors" *ngIf="contactForm.controls.title.errors  && (contactForm.controls.title.dirty || contactForm.controls.title.touched || submitted)">
               
                <div [hidden]="!contactForm.controls.title.errors.minlength" translate>
                  min_length_1char
                </div>
                <div [hidden]="!contactForm.controls.title.errors.maxlength" translate>
                  max_length_50char
                </div>
              </div>
            </div>
          </div>

          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="email" translate>label_email<span class="required-star">*</span></label>
              </div>
              <div class="form-control">
                <input formControlName="email" id="email" class="full-width" type="text" appNoWhiteSpace pInputText />
              </div>
              <div class="form-errors" *ngIf="contactForm.controls.email.errors  && (contactForm.controls.email.dirty || contactForm.controls.email.touched ||
                         submitted)">
                 <div [hidden]="!contactForm.controls.email.errors.required" translate>
                     field_is_required
                 </div>
                 <div [hidden]="!contactForm.controls.email.errors.pattern" translate>
                     error_email_invalid
                 </div>
                 <div [hidden]="!contactForm.controls.email.errors.minlength" translate>
                     min_length_1char
                 </div>
                 <div [hidden]="!contactForm.controls.email.errors.maxlength" translate>
                     max_length_120char
                 </div>
              </div>
            </div>
          </div>
  
          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="phoneNum" translate>label_phone_number</label>
              </div>
              <div class="form-control">
                  <p-inputMask formControlName="phoneNum" class="full-width" mask="(999) 999-9999" unmask="false" appNoWhiteSpace></p-inputMask>
              </div>
              
            </div>
          </div>
  
          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="mobilePhone" translate>label_mobile_phone</label>
              </div>
              <div class="form-control">
                  <p-inputMask formControlName="mobilePhone" class="full-width" mask="(999) 999-9999" unmask="false" appNoWhiteSpace></p-inputMask>
              </div>
              <div class="form-errors" *ngIf="contactForm.controls.mobilePhone.errors  && (contactForm.controls.mobilePhone.dirty || contactForm.controls.mobilePhone.touched || submitted)">
              </div>
            </div>
          </div>
  
          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="address1" translate>label_address1</label>
              </div>
              <div class="form-control">
                <input formControlName="address1" id="address1" class="full-width" type="text" appNoWhiteSpace pInputText />
              </div>
              <div class="form-errors" *ngIf="contactForm.controls.address1.errors  && (contactForm.controls.address1.dirty || contactForm.controls.address1.touched ||
                         submitted)">
  
                <div [hidden]="!contactForm.controls.address1.errors.minlength" translate>
                  min_length_1char
                </div>
                <div [hidden]="!contactForm.controls.address1.errors.maxlength" translate>
                  max_length_100char
                </div>
              </div>
            </div>
          </div>
          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="address2" translate>label_address2</label>
              </div>
              <div class="form-control">
                <input formControlName="address2" id="address2" class="full-width" type="text" appNoWhiteSpace pInputText />
              </div>
              <div class="form-errors" *ngIf="contactForm.controls.address2.errors  && (contactForm.controls.address2.dirty || contactForm.controls.address2.touched ||
                         submitted)">
  
                <div [hidden]="!contactForm.controls.address2.errors.minlength" translate>
                  min_length_1char
                </div>
                <div [hidden]="!contactForm.controls.address2.errors.maxlength" translate>
                  max_length_100char
                </div>
              </div>
            </div>
          </div>
          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="city" translate>label_city</label>
              </div>
              <div class="form-control">
                <input formControlName="city" class="full-width" type="text" pInputText />
              </div>
              <div class="form-errors" *ngIf="contactForm.controls.city.errors  && (contactForm.controls.city.dirty || contactForm.controls.city.touched || submitted)">
  
                <div [hidden]="!contactForm.controls.city.errors.minlength" translate>
                  min_length_1char
                </div>
                <div [hidden]="!contactForm.controls.city.errors.maxlength" translate>
                  max_length_100char
                </div>
              </div>
            </div>
          </div>
  
          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="state" translate>label_state</label>
              </div>
              <div class="form-control">
                <input formControlName="state" id="state" class="full-width" type="text" appNoWhiteSpace pInputText />
              </div>
              <div class="form-errors" *ngIf="contactForm.controls.state.errors  && (contactForm.controls.state.dirty || contactForm.controls.state.touched ||
                         submitted)">
  
                <div [hidden]="!contactForm.controls.state.errors.minlength" translate>
                  min_length_2char
                </div>
                <div [hidden]="!contactForm.controls.state.errors.maxlength" translate>
                  max_length_20char
                </div>
              </div>
            </div>
          </div>
  
          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="zipCode" translate>label_zip_code</label>
              </div>
              <div class="form-control">
                <input formControlName="zipCode" class="full-width" type="text" pInputText />
              </div>
              <div class="form-errors" *ngIf="contactForm.controls.zipCode.errors  && (contactForm.controls.zipCode.dirty || contactForm.controls.zipCode.touched || submitted)">
  
                <div [hidden]="!contactForm.controls.zipCode.errors.minlength" translate>
                  error_zip_min_length
                </div>
                <div [hidden]="!contactForm.controls.zipCode.errors.maxlength" translate>
                  error_admin_zip_max_length
                </div>
                <div [hidden]="!contactForm.controls.zipCode.errors.pattern" translate>
                  Invalid zip code
                </div>
              </div>
            </div>
          </div>
          <div class="form-item col-12 lg:col-5 md:col-5">
            <div class="form-field">
              <div class="form-label">
                <label for="emailOptInBrands" translate>label_email_opt_in</label>
              </div>
              <div class="form-control">
                  <p-multiSelect [options]="brandNameOptions"
                      [(ngModel)]="selectedBrand"
                       [maxSelectedLabels]="1" [filter]="false" 
                      selectedItemsLabel="{0} items selected"
                      placeholder="Select"
                      formControlName="emailOptInBrands" >
                    </p-multiSelect>
              </div>
            </div>
          </div>
        </div>
        <!--   Commented on client requirement, client may require following code -->
          <!-- <div class="border-div">
          </div>
          <div class="page-header">
            <div class="page-heading">
              <h3 class="heading p-ml-1" style="margin-bottom: 10px;" translate>Additional Information</h3>
            </div>
          </div>
          
          <div class="form-container col-12 lg:col-8 md:col-8 pad-l-0">
            <div class="form-item col-12 lg:col-4 md:col-4" style="margin-right: 0%;">
              <div class="form-field" style="width: 70%">
                <div class="form-label">
                  <label for="emailOptInBrands" translate>label_email_opt_in</label>
                </div>
                <div class="form-control">
                    <p-multiSelect [options]="brandNameOptions"
                        [(ngModel)]="selectedBrand"
                         [maxSelectedLabels]="1" [filter]="false" 
                        selectedItemsLabel="{0} items selected"
                        placeholder="Select"
                        formControlName="emailOptInBrands" >
                      </p-multiSelect>
                </div>
              </div>
            </div>
            <div class="form-item col-12 lg:col-4 md:col-4 adjust-margin">
              <div class="form-field">
                <div class="form-label">
                  <label for="optOutPhone" translate>label_opt_out_phone</label>
                </div>
                <span class="label p-mr-1">No</span>
                <p-inputSwitch onLabel="Yes" formControlName="optOutPhone" offLabel="No"></p-inputSwitch>
                <span class="label p-ml-1">Yes</span>
              </div>
            </div>
            <div class="form-item col-12 lg:col-4 md:col-4 adjust-margin">
              <div class="form-field">
                <div class="form-label">
                  <label for="optOutMobilePhone" translate>label_opt_out_mobile_phone</label>
                </div>
                <span class="label p-mr-1">No</span>
                <p-inputSwitch onLabel="Yes" formControlName="optOutMobilePhone" offLabel="No"></p-inputSwitch>
                <span class="label p-ml-1">Yes</span>
              </div>
            </div>
            <div class="form-item col-12 lg:col-4 md:col-4 adjust-margin">
              <div class="form-field">
                <div class="form-label">
                  <label for="optOutAddress" translate>label_opt_out_Address</label>
                </div>
                <span class="label p-mr-1">No</span>
                <p-inputSwitch onLabel="Yes" formControlName="optOutAddress" offLabel="No"></p-inputSwitch>
                <span class="label p-ml-1">Yes</span>
              </div>
            </div>

            <div class="form-item col-12 lg:col-4 md:col-4 adjust-margin">
              <div class="form-field">
                <div class="form-label">
                  <label for="seedRecord" translate>label_seedRecord</label>
                </div>
                <span class="label p-mr-1">No</span>
                <p-inputSwitch onLabel="Yes" formControlName="seedRecord" offLabel="No"></p-inputSwitch>
                <span class="label p-ml-1">Yes</span>
              </div>
            </div>

            <div class="form-item col-12 lg:col-4 md:col-4 adjust-margin">
              <div class="form-field">
                <div class="form-label">
                  <label for="userOptOutEmail" translate>label_userOptOutEmail</label>
                </div>
                <span class="label p-mr-1">No</span>
                <p-inputSwitch onLabel="Yes" formControlName="userOptOutEmail" offLabel="No"></p-inputSwitch>
                <span class="label p-ml-1">Yes</span>
              </div>
            </div>

            <div class="form-item col-12 lg:col-4 md:col-4 adjust-margin">
              <div class="form-field">
                <div class="form-label">
                  <label for="userOptOutAddress" translate>label_userOptOutAddress</label>
                </div>
                <span class="label p-mr-1">No</span>
                <p-inputSwitch onLabel="Yes" formControlName="userOptOutAddress" offLabel="No"></p-inputSwitch>
                <span class="label p-ml-1">Yes</span>
              </div>
            </div>

            <div class="form-item col-12 lg:col-4 md:col-4 adjust-margin">
              <div class="form-field">
                <div class="form-label">
                  <label for="emailDeliverable" translate>label_emailDeliverable</label>
                </div>
                <span class="label p-mr-1">No</span>
                <p-inputSwitch onLabel="Yes" formControlName="emailDeliverable" offLabel="No"></p-inputSwitch>
                <span class="label p-ml-1">Yes</span>
              </div>
            </div>
            <div class="form-item col-12 lg:col-4 md:col-4 adjust-margin">
              <div class="form-field">
                <div class="form-label">
                  <label for="usableEmail" translate>label_usableEmail</label>
                </div>
                <span class="label p-mr-1">No</span>
                <p-inputSwitch onLabel="Yes" formControlName="usableEmail" offLabel="No"></p-inputSwitch>
                <span class="label p-ml-1">Yes</span>
              </div>
            </div>
            <div class="form-item col-12 lg:col-4 md:col-4 adjust-margin">
              <div class="form-field">
                <div class="form-label">
                  <label for="usableAddress" translate>label_usableAddress</label>
                </div>
                <span class="label p-mr-1">No</span>
                <p-inputSwitch onLabel="Yes" formControlName="usableAddress" offLabel="No"></p-inputSwitch>
                <span class="label p-ml-1">Yes</span>
              </div>
            </div>
            <div class="form-item col-12 lg:col-4 md:col-4 adjust-margin">
              <div class="form-field">
                <div class="form-label">
                  <label for="validEmail" translate>label_validEmail</label>
                </div>
                <span class="label p-mr-1">No</span>
                <p-inputSwitch onLabel="Yes" formControlName="validEmail" offLabel="No"></p-inputSwitch>
                <span class="label p-ml-1">Yes</span>
              </div>
            </div>
          </div> -->

      </form>
  
    </div>
  </div>
  