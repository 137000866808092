import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from './../../models/field-config.interface';

@Component({
  selector: 'app-mi-input',
  templateUrl: './mi-input.component.html',
  styleUrls: ['./mi-input.component.scss'],
})
export class MiInputComponent implements OnInit {
  config: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}

  ngOnInit(): void { }
}
