<div class="dynamic-field form-input" [formGroup]="group">
  <label for="float-input">{{ config.label }}</label>
  <div class="ui-label">
    <input
      [formControlName]="config.name"
      [value]="config.value || ''"
      id="float-input"
      type="text"
      size="50"
      pInputText
      appPreventSomeSpecialCharacters allowQuote="no"
    />
  </div>
</div>
