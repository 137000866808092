<div class="page-container">
    <div class="page-header">
      <div class="page-heading">
        <h1 class="heading" translate>label_notifications</h1>
      </div>
      <div class="col-12 lg:col-6 md:col-6 p-justify-end page-buttons">
        <button pButton (click)="onAdd()" class = "marg-t-0" type="button" [label]="'label_create_new'|translate"></button>
    </div>
    </div>
  
    <div class="page-filters">
      <div class="filter-left-container grid">
        <div class="filter-item col-12 lg:col-2 md:col-3">
          <div class="form-control input-container">
             <span class="p-input-icon-left">
               <i class="pi pi-search"></i>
               <input type="text" pInputText (keyup)= "onSearch()" [(ngModel)] = "search" class="search-input prefix-pad"
               [placeholder]="'label_search'|translate" appPreventSpecialCharacters pTooltip="{{'label_search_client' | translate}}">
             </span>
          </div>
        </div>
      </div>
    </div>
  
    <div class="page-body">
      <app-mi-grid-v2 [url]="gridurl" [columnDefs]="columns"
          (actionBtnClickedEvent)="onActionBtnClicked($event)" [actionButtonsDefs]="buttons"
          divClass="data-grid-lines" [searchBar]="searchBar" [exportUrl]="exportBaseUrl" [isExportVisible]="false">
      </app-mi-grid-v2>
    </div>
  
  </div>