import { Directive, ElementRef, Input } from '@angular/core';
import { CommonBindingDataService } from '../services/common-binding-data.service';

@Directive({
  selector: '[appAccessProvider]'
})
export class AccessProviderDirective {

  @Input() accessCode;
  @Input() readonly;
  @Input() readWrite;
  private _el: HTMLElement;
  private menuAccessList: any[];

  constructor(el: ElementRef, private commonBindingDataService: CommonBindingDataService) {
    this._el = el.nativeElement;
  }

  ngOnChanges() {
    this.menuAccessList = JSON.parse(this.commonBindingDataService.getSelectedMenuAccessesList());
    // console.log('menuAccessList', this.menuAccessList);
    if (this.menuAccessList && this.menuAccessList.length > 0) {
      let hasAccess;
      if (this.readWrite) {
        hasAccess = this.menuAccessList.find((eachEntry) => (eachEntry.accessCode == this.accessCode &&
          eachEntry.readonly == this.readonly && eachEntry.readWrite == this.readWrite));
      } else {
        hasAccess = this.menuAccessList.find((eachEntry) => (eachEntry.accessCode == this.accessCode &&
          eachEntry.readonly == this.readonly));
      }
      // console.log('hasAccess', hasAccess);
      if (!hasAccess) {
        this._el.style.display = 'none';
      }
    }
  }
}
