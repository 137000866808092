import { BehaviorSubject, Observable, Subject, scheduled } from 'rxjs';

import { Injectable } from '@angular/core';
import { RestApiService } from '../../shared/services/rest-api.service';
import { HttpClient } from '@angular/common/http';

import { TreeNode } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class FranchiseService {
  staffNotifyEmail(staffModel: any) {
    throw new Error('Method not implemented.');
  }
  public bookingTabCountChange = new Subject<any>();
  menuItemsImageList: any = [];
  foodExtraItemsImageList: any = [];
  public testVariable = 2;
  public previousSelectedOrderId = '';
  foodExtraItemsList: any = [];
  public fromEditEventForMenuSelection = false;
  public fromEditClientStorageUpdated = false;
  public clientMenuesOnly;
  public quickAddEventForm;
  public dateSubject = new BehaviorSubject<any>(null);
  public dateSubject$ = this.dateSubject.asObservable();
  public salesTotalChange = new Subject<any>();
  public newClientAdded = false;
  setStatus;
  setTimeframe;
  public taskColor;
  public defaultMenuSelected;
  public taskBrand;

  public onlyPageOffSetSave = false;

  public getDate() {
    return this.dateSubject$;
  }

  public setDate(data) {
    this.dateSubject.next(data);
  }

  sharingData = new Subject();

  // reports --> orders page
  public reportsFilters = {
    search: '',
    orderStatus: '',
    paymentStatus: '',
    orderPlatform: '',
    fromDate: '',
    toDate: '',
  };

  public salesReportsFilters = {
    search: '',
    fromDate: '',
    toDate: '',
    industryTypeId: '',
    clientId: '',
    assetId: ''
  };

  public staffReportsFilters = {
    search: '',
    fromDate: '',
    toDate: '',
    staffId: ''
  };

  // events--> orders page
  public ordersFilters = {
    eventId: '',
    search: '',
    key: '',
  };

  // reports --> campaign reports page
  public campaignReportsFilters = {
    search: '',
  };

  // reports --> All donations page
  public allDonationsReportsFilters = {
    search: '',
  };

  public dashboardFilters = {
    timeframe: '',
    fromDate: '',
    toDate: '',
    comparetimeframe: '',
    comparedfromDate: '',
    comparedtoDate: ''
  }

  public pageEventsFilters = {
    FilterSetFromHyperLink: false,
    FilterIsSet: false,
    FilterEventdisplayType: '',
    FilterSearch: '',
    FilterEventStatus: '',
    FilterTimeFrame: '',
    FilterFromDate: '',
    FilterToDate: '',
    FilterEmailStatusIds: [],
    FilterManualStatusIds: [],
    FilterCheckedCondition: true,
    FilterSwitchAssetIds: [],
    FilterSwitchStaffIds: [],
    FilterSwitchStatusIds: [],
    FilterSelectedStatusTask: '',
    fromEditInnerPage: false, // for nevigation
    startEndDate: {}, // for calender navigation label
    displayDateForDayView: '', // for calender navigation label,
    FilterBrand: []
  }

  public eventsFilters = {
    fromEditInnerPage: false,
    search: '',
    fromDate: '',
    toDate: '',
    activeEvent: '',
    timeframe: '',
    assetIds: [],
    statusList: [],
    emailStatus: [],
    eventStatus: [],
    startEndDate: {}, // for calender navigation label
    displayDateForDayView: '', // for calender navigation label
  };


  public eventDayFilter = {
    selectedDate: null
  }

  public eventWeekMonthFilter = {
    search: '',
    fromDate: 0,
    toDate: 0,
    activeEvent: '',
    timeframe: '',
    assetIds: [],
    statusList: [],
    userIds: [],
    checkedCondition: false
  };

  public clientsFilters = {
    fromEditInnerPage: false,
    status: '',
    search: '',
    industryType: [],
  };

  public fbrFilters = {
    fromEditInnerPage: false,
    search: '',
    readStatus: '',
    timeframe: '',
    fromDate: 0,
    toDate: 0,
    selectedEventType: [],
    selectedManualStatus: [],
  };

  public FBRDetails: any = {};

  public quickAddClientDetails = {
    state: false,
    details: []
  }

  public franchiseSettings = {
    yesColor: '',
    noColor: ''
  }

  public MenuManageCorporateTemplateFilters = {
    search: ''
  };

  public MenuManageFranchiseTemplateFilters = {
    search: ''
  };

  public MenuCommonSearchFilters = {
    search: ''
  };

  firstDay;
  // schedule page
  startLead;
  endLead;

  // Quick Event page
  quickEventData: any;
  eventViewState: any;
  eventViewDate = {
    startDateTime: null,
    endDateTime: null
  }

  // Quick Task page
  quickTaskData: any;
  taskViewState: any;
  taskViewDate = {
    startDateTime: null,
    endDateTime: null
  }

  constructor(private restApiService: RestApiService,
    private http: HttpClient) { }

  resetEventsListFilter() {
    this.eventsFilters = {
      fromEditInnerPage: false,
      search: '',
      fromDate: '',
      toDate: '',
      activeEvent: '',
      timeframe: '',
      assetIds: [],
      statusList: [],
      emailStatus: [],
      eventStatus: [],
      startEndDate: {}, // for calender navigation label
      displayDateForDayView: '', // for calender navigation label
    };
  }

   public schedulesFilters = {
    filterIsSet: false,
    checkedCondition: true,
    staffIds: [],
    assetIds: [],
    statusList: [],
    startEndDate: {}, // for calender navigation label
    displayDateForDayView: '', // for calender navigation label
  };

  getProfileData(id): Observable<any> {
    return this.restApiService.get('getProfile', `/secure/users/${id}`, 'page-center');
  }

  getStateList(id): Observable<any> {
    return this.restApiService.get('getMenu', `/states?countryCode=USA`, 'page-center');
  }

  getMenuList(itemCategoryId): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/menus/grid-data?itemCategoryId=${itemCategoryId}&limit=9999`, 'page-center');
  }
  getMenuCategoryList(templateId): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/menus-categories/grid-data?templateId==${templateId}`, 'page-center');
  }
  getActiveMenuList(deletedFlag = false, brandId?): Observable<any> {
    // return this.restApiService.get('getMenu',
    //   '/secure/menus/grid-data?applyActivatedStatus=true&activated=true&limit=9999', 'page-center');
    return this.restApiService.get('getMenu',
      `/secure/menus/grid-data?limit=9999&deleted=${deletedFlag}&brandIds=${brandId}`, 'page-center');
  }

  getMenuImageList(type?): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/files-list?fileIds=&type=${type}`, 'page-center');
  }


  getMenuDetails(itemId): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/menus/item-details/${itemId}`, 'page-center');
  }

  addMenu(data): Observable<any> {
    return this.restApiService.post('addMenuItem', '/secure/menus', data, 'page-center');
  }

  activeInactiveMenu(data): Observable<any> {
    return this.restApiService.put('updateMenuItem', '/secure/menus/activate-deactivate/status', data, 'page-center');
  }

  deleteMenuItem(data): Observable<any> {
    return this.restApiService.put('updateMenuItem', '/secure/menus/delete-menu-item', data, 'page-center');
  }

  addEvent(data): Observable<any> {
    return this.restApiService.post('addMenuItem', '/secure/events', data, 'page-center');
  }

  addQuickEvent(data): Observable<any> {
    return this.restApiService.post('addMenuItem', '/secure/events/quick-add', data, 'page-center');
  }

  activeInactiveEvent(data): Observable<any> {
    return this.restApiService.put('activeInactiveEvent', '/secure/events/event-status', data, 'page-center');
  }

  getBrandList(id, isNew?): Observable<any> {
    if (isNew) {
      return this.restApiService.get('getBrandList', `/secure/franchise-brands/${id}/list?activated=${isNew}&deleted=false`, 'page-center');
    } else {
      return this.restApiService.get('getBrandList', `/secure/franchise-brands/${id}/list`, 'page-center');
    }
  }

  getBrandListForFilter(id): Observable<any> {
    return this.restApiService.get('getBrandList', `/secure/franchise-brands/${id}/list?deleted=false`, 'page-center')
  }

  geteventDetails(id, isDeleted?): Observable<any> {
    if (isDeleted) {
      return this.restApiService.get('getMenu', `/secure/events/details/${id}?deleted=${isDeleted}`, 'page-center');
    } else {
      return this.restApiService.get('getMenu', `/secure/events/details/${id}`, 'page-center');
    }
  }

  geteventDetailsOptimal(id, isDeleted?): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/events/details-minimal/${id}?deleted=${isDeleted ? isDeleted : 'false'}`, 'page-center');
  }

  getClientList(): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/clients-list`, 'page-center');
  }

  getClientListBySearchText(text): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/clients-list?searchText=${text}`, 'page-center');
  }

  updatEevent(data): Observable<any> {
    return this.restApiService.put('updateMenuItem', '/secure/events', data, 'page-center');
  }

  // only from event popup
  updatEeventOptimal(data): Observable<any> {
    return this.restApiService.put('updateMenuItem', '/secure/events/update-minimal', data, 'page-center');
  }

  updateEventStaffMinimal(data): Observable<any> {
    return this.restApiService.put('updateMenuItem', '/secure/events/update-event-staff', data, 'page-center');
  }

  uploadFile(data, eventId): Observable<any> {
    return this.restApiService.putFile('updateMenuItem', `/secure/event-files/${eventId}`, data, 'page-center');
  }

  addCampaign(data): Observable<any> {
    return this.restApiService.post('addCampaign', '/secure/campaigns', data, 'page-center');
  }

  updatCampaign(data): Observable<any> {
    return this.restApiService.put('updateCampaign', '/secure/campaigns', data, 'page-center');
  }

  activeInactiveCampaign(data): Observable<any> {
    return this.restApiService.put('activeInactiveCampaign', '/secure/campaigns/campaign-status', data, 'page-center');
  }

  activeInactiveComment(data): Observable<any> {
    return this.restApiService.put('activeInactiveComment', `/secure/comments/${data.id}/activate-deactivate/status`, data, 'page-center');
  }

  refundDirectDonation(data): Observable<any> {
    return this.restApiService.put('refundDirectDonation', `/secure/campaign-donation/${data.anonymousId}/refund`, data, 'page-center');
  }

  getCampaignDetails(id): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/campaigns/details/${id}`, 'page-center');
  }

  verifyAndGetlink(data): Observable<any> {
    return this.restApiService.post('verifyLink', '/secure/events/verify-link', data, 'page-center');
  }

  getOrderDetails(id): Observable<any> {
    return this.restApiService.get('getOrderDetails', `/secure/orders/details/${id}`, 'page-center');
  }

  getRefundDistribution(orderId): Observable<any> {
    return this.restApiService.get('getOrderDetails', `/secure/orders/${orderId}/order-amounts`, 'page-center');
  }

  changeOrderStatus(data): Observable<any> {
    return this.restApiService.put('changeOrderStatus', '/secure/orders/order-status', data, 'page-center');
  }

  changeBulkOrderStatus(data): Observable<any> {
    return this.restApiService.put('changeOrderStatus', '/secure/orders/multiple/order-status', data, 'page-center');
  }

  geteventkeyValues(id): Observable<any> {
    return this.restApiService.get('keyValues', `/secure/orders/keys-list/${id}`, 'page-center');
  }

  updatProfile(data, id, roleCode?): Observable<any> {
    if (roleCode) {
      return this.restApiService.put('updateMenuItem', `/secure/staff-users/${id}`, data, 'page-center');
    } else {
      return this.restApiService.put('updateMenuItem', `/secure/users/${id}`, data, 'page-center');
    }
  }

  changePassword(data, id): Observable<any> {
    return this.restApiService.put('changePassword', `/secure/change-password/${id}`, data, 'page-center');
  }

  adminUpdatProfile(data, id): Observable<any> {
    return this.restApiService.put('updateProfile', `/secure/superadmin-users/${id}`, data, 'page-center');
  }

  public downloadExcelAdminReport(url: string) {
    return this.restApiService.excel(url, 'export.xls', 'page-center');
  }

  getAllReportsForbulkUpdate(url): Observable<any> {
    return this.restApiService.get('getMenu', `${url}`, 'page-center');
  }

  getCampaignList(searchText, limit, offset): Observable<any> {
    return this.restApiService.get('event-list',
      `/secure/campaigns/autocomplete?searchText=${searchText}`, 'page-center');
    // .pipe(map((result: any) => result));
  }

  // phase 1 start
  addFoodExtraCategory(data): Observable<any> {
    return this.restApiService.post('addFoodExtraCategorie', '/secure/food-extra-categories', data, 'page-center');
  }

  deleteEvent(data, eventId): Observable<any> {
    return this.restApiService.put('deleteEvent', `/secure/events/${eventId}/delete-event`, data, 'page-center');
  }
  deleteEventTask(data): Observable<any> {

    return this.restApiService.put('deleteEventTask', `/secure/tasks/delete-restore/status`, data, 'page-center');
  }

  deleteStaffEvent(id): Observable<any> {
    return this.restApiService.delete('deleteStaffEvent', `/secure/staffs/dutystatus/entry/${id}`);
  }

  approveRejectTime(data): Observable<any> {
    return this.restApiService.put('deleteStaffEvent', `/secure/staffs/approve-reject-dutystatus`, data, 'page-center');
  }

  getFoodExtraCatDetails(id): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/food-extra-categories/category-details/${id}`, 'page-center');
  }

  updateFoodExtraCategory(data): Observable<any> {
    return this.restApiService.put('updateCampaign', '/secure/food-extra-categories', data, 'page-center');
  }

  activeInactiveFoodExtraCategory(data): Observable<any> {
    return this.restApiService.put('activeInactiveCampaign', '/secure/food-extra-categories/activate-deactivate/status',
      data, 'page-center');
  }

  deleteFoodExtraCategory(data): Observable<any> {
    return this.restApiService.put('deleteFoodExtraCategory', '/secure/food-extra-categories/delete-food-extra-categories',
      data, 'page-center');
  }

  getFoodExtraItemsList(id): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/food-extra-items/grid-data?foodExtraCategoryId=${id}&limit=9999`,
      'page-center');
  }

  addFoodExtraItem(data): Observable<any> {
    return this.restApiService.post('addMenuItem', '/secure/food-extra-items', data, 'page-center');
  }

  updateFoodExtraItem(data): Observable<any> {
    return this.restApiService.put('updateMenuItem', '/secure/food-extra-items', data, 'page-center');
  }

  activeInactiveFoodExtraItems(data): Observable<any> {
    return this.restApiService.put(
      'updateStatusFoodExtraItems',
      '/secure/food-extra-items/activate-deactivate/status',
      data,
      'page-center');
  }

  deleteFoodExtraItem(data): Observable<any> {
    return this.restApiService.put('deleteFoodExtraItem', '/secure/food-extra-items/delete-food-extra-items', data, 'page-center');
  }

  addAsset(data): Observable<any> {
    return this.restApiService.post('addAsset', '/secure/assets', data, 'page-center');
  }

  getAssetTypeList(): Observable<any> {
    return this.restApiService.get('AssetTypeList', `/secure/assets-types/list`, 'page-center');
  }

  getAssetTypeListWithBrands(brandIds?): Observable<any> {
    return this.restApiService.get('AssetTypeList', `/secure/franchise-assets-types/list?activated=true&brandIds=${brandIds}`, 'page-center');
  }

  getFranchiseAssetTypeList(addNew?): Observable<any> {
    if (addNew) {
      return this.restApiService.get('FranchiseAssetTypeList', `/secure/franchise-assets-types/list?activated=true&brandIds=ALL`, 'page-center');
    } else {
      return this.restApiService.get('FranchiseAssetTypeList', `/secure/franchise-assets-types/list?brandIds=ALL`, 'page-center');
    }
  }

  mapAssetsToEvent(data): Observable<any> {
    return this.restApiService.put('MapAssetsToEvent', '/secure/events/map-assets', data, 'page-center');
  }

  getMapAssetList(id): Observable<any> {
    return this.restApiService.get('MapAssetList', `/secure/events/asset-list/${id}`, 'page-center');
    // return this.restApiService.get('AssetTypeList', `/secure/assets-types/list`, 'page-center');
  }

  getAssignAssetList(): Observable<any> {
    return this.restApiService.get('AssetTypeList', `/secure/assets/list`, 'page-center');
    // return this.restApiService.get('AssetTypeList', `/secure/assets-types/list`, 'page-center');
  }

  getAssetDetails(id): Observable<any> {
    return this.restApiService.get('assetDetails', `/secure/assets/asset-details/${id}`, 'page-center');
  }

  updateAsset(data): Observable<any> {
    return this.restApiService.put('updatAsset', '/secure/assets', data, 'page-center');
  }

  activeInactiveAsset(data): Observable<any> {
    return this.restApiService.put('activeInactiveAsset', '/secure/assets/activate-deactivate/status',
      data, 'page-center');
  }

  // marketing start
  activeInactiveSegment(data, segmentId): Observable<any> {
    return this.restApiService.put('activeInactiveSegment', `/secure/segments/status/${segmentId}`, data, 'page-center');
  }

  getUserDefinedRoles(): Observable<any> {
    return this.restApiService.get('getUserDefinedRoles', `/secure/roles-list`, 'page-center');
  }

  getUsers(roleId, orderCount?): Observable<any> {
    if (orderCount) {
      return this.restApiService.get('get-users', `/secure/users-list?role=${roleId}&count=${orderCount}`, 'page-center');
    } else {
      return this.restApiService.get('get-users', `/secure/users-list?role=${roleId}`, 'page-center');
    }
  }

  addSegment(data): Observable<any> {
    return this.restApiService.post('addSegment', '/secure/segments', data, 'page-center');
  }

  getSegmentsList(): Observable<any> {
    return this.restApiService.get('getSegmentsList', `/secure/segments/segments-list`, 'page-center');
  }

  addNotification(data): Observable<any> {
    return this.restApiService.post('addmotification', '/secure/admin-announcement', data, 'page-center');
  }

  resendNotification(id): Observable<any> {
    return this.restApiService.post('resendNotification', `/secure/admin-announcement/${id}/resend`, '', 'page-center');
  }
  //https://konaschoolsidedevapi.mi2.in/api/v1/secure/templates/57b13bacbdf9404994a6435c1a37ec3a/clone
  copyTemplate(templateId): Observable<any> {
    return this.restApiService.post('copytemplate', `/secure/templates/${templateId}/clone`, '', 'page-center');
  }
  // marketing end

  // Settings Start
  getEmailTemplate(id): Observable<any> {
    return this.restApiService.get('getEmailtemplate', `/secure/mail-templates/${id}`, 'page-center');
  }

  updateEmailTemplate(data, id): Observable<any> {
    return this.restApiService.put('updateTemplate', `/secure/mail-templates/${id}`, data, 'page-center');
  }

  getSettings(franchiseId): Observable<any> {
    return this.restApiService.get('getSettings', `/secure/settings/${franchiseId}`, 'page-center');
  }

  updateSettings(data): Observable<any> {
    return this.restApiService.put('updateSettings', `/secure/settings`, data, 'page-center');
  }

  updateEmployeeTC(userId): Observable<any> {
    return this.restApiService.put('updateEmployeeTC', `/secure/settings/${userId}/dutystatus/entry`, userId, 'page-center');
  }

  getEventsOrdersForRoute(eventId, data): Observable<any> {
    return this.restApiService.post('getEventsOrdersForRoute',
      `/secure/events/${eventId}/google-map/directions`, data, 'page-center');
  }

  getEventsOrdersGrid(eventId, sortColumnName, orderStatus = 'NEW'): Observable<any> {
    return this.restApiService.get('getMenu',
      `/secure/orders/grid-data?source=event&eventId=${eventId}&limit=9999&sortColumn=${sortColumnName}&sortType=asc`,
      'page-center');
  }

  saveRouteAddress(eventId, data): Observable<any> {
    return this.restApiService.post('addmotification', `/secure/events/${eventId}/update/start-address`, data, 'page-center');
  }

  notifyUser(orderId, data): Observable<any> {
    return this.restApiService.post('addmotification', `/secure/orders/${orderId}/send-order-sms`, data, 'page-center');
  }

  getOrderAttributes(franchiseId, search): Observable<any> {
    return this.restApiService.get('orderAttributeList',
      `/secure/events/order-attribute-search?franchiseId=${franchiseId}&orderAttribute=${search}`,
      'page-center');
  }

  // Phase II
  getClientIndustry(): Observable<any> {
    return this.restApiService.get('getMenu', '/secure/client-industries-types', 'page-center');
  }

  updateClient(data): Observable<any> {
    return this.restApiService.put('updateClient', '/secure/clients', data, 'page-center');
  }

  addClient(data): Observable<any> {
    return this.restApiService.post('addClient', '/secure/clients', data, 'page-center');
  }

  detailsClient(clientId): Observable<any> {
    return this.restApiService.get('addClient', `/secure/clients/details/${clientId}`, 'page-center');
  }

  quickAddClient(data): Observable<any> {
    return this.restApiService.post('quickAddClient', '/secure/clients/quick-add', data, 'page-center');
  }

  getContactList(id) {
    return this.restApiService.get('contactList', `/secure/contact-persons/list/${id}`, 'page-center');
  }

  activeInactiveClient(data): Observable<any> {
    return this.restApiService.put('activeInactiveClient', '/secure/clients/activate-deactivate/status',
      data, 'page-center');
  }

  getClientDetails(id): Observable<any> {
    return this.restApiService.get('clientDetails', `/secure/clients/details/${id}`, 'page-center');
  }

  //  Email template

  getTemplateDetails(id): Observable<any> {
    return this.restApiService.get('templateDetails', `/secure/mail-templates/details/${id}`, 'page-center');
  }

  // updateEmailTemplate(data, id): Observable<any> {
  //   return this.restApiService.put('updateEmailTemplate', `/secure/mail-templates/${id}`, data, 'page-center');
  // }

  addEmailTemplate(data): Observable<any> {
    return this.restApiService.post('addEmailTemplate', '/secure/mail-templates', data, 'page-center');
  }

  // deleteEmailTemplate(data, templateId): Observable<any> {
  //   return this.restApiService.delete('deleteEmailTemplate', `/secure/mail-templates-future-requests/${templateId}`, data, 'page-center');
  // }
  deleteEmailTemplate(data, templateId): Observable<any> {
    return this.restApiService.put('deleteEmailTemplate', `/secure/mail-templates/delete/${templateId}`, data, 'page-center');
  }
  // No replay email template

  getNoReplayTemplateDetails(id): Observable<any> {
    return this.restApiService.get('templateDetails', `/secure/mail-templates-future-requests/details/${id}`, 'page-center');
  }

  addNoReplayEmailTemplate(data): Observable<any> {
    return this.restApiService.post('addNoReplayEmailTemplate', '/secure/mail-templates-future-requests', data, 'page-center');
  }

  updateNoReplayEmailTemplate(data, id): Observable<any> {
    return this.restApiService.put('updateNoReplayEmailTemplate', `/secure/mail-templates-future-requests/${id}`, data, 'page-center');
  }

  deleteNoReplayEmailTemplate(templateId): Observable<any> {
    return this.restApiService.delete('deleteNoReplayEmailTemplate', `/secure/mail-templates-future-requests/${templateId}`, 'page-center');
  }

  deleteContact(data, contactPersonId): Observable<any> {
    return this.restApiService.put('deleteContact', `/secure/contact-persons/${contactPersonId}/delete`, data, 'page-center');
  }

  getAllTemplates(): Observable<any> {
    return this.restApiService.get('allTemplates', `/secure/mail-templates/grid-data?&searchText=&offset=0&limit=999`, 'page-center');
  }

  getEventRelatedTemplates(eventId, type): Observable<any> {
    return this.restApiService.get('allTemplates', `/secure/events/template/email?eventId=${eventId}&mailType=${type}`, 'page-center');
  }

  getNoReplayTemplates(futureBookingRequestId, eventType, brandId): Observable<any> {
    return this.restApiService.get('NoReplayTemplates', `/secure/future-booking-request/email/template/${futureBookingRequestId}?eventType=${eventType}&brandId=${brandId}`, 'page-center');
  }

  getEmailDetails(id): Observable<any> {
    return this.restApiService.get('getEmailDetails', `/secure/emails/details/${id}`, 'page-center');
  }

  getTemplateTags(type?): Observable<any> {
    if (type) {
      return this.restApiService.get('getTemplateTags', `/secure/template-tags?type=${type}`, 'page-center');
    } else {
      return this.restApiService.get('getTemplateTags', `/secure/template-tags`, 'page-center');
    }

  }

  sendMail(data): Observable<any> {
    return this.restApiService.post('sendMail', '/secure/emails', data, 'page-center');
  }

  sendNoReplyMail(data): Observable<any> {
    return this.restApiService.post('sendMail', '/secure/future-booking-request/manual/send-email', data, 'page-center');
  }

  sendConfirmThankyouMail(data, emailTemplateType?): Observable<any> {
    return this.restApiService.post('sendMail', '/secure/events/confirm-thankyou-email', data, 'page-center');
    // return this.restApiService.post('sendMail', `/secure/events/template/email?eventId=${data.eventId}&mailType=${emailTemplateType}`,
    //  data, 'page-center');
  }

  sendCustomMail(data, emailTemplateType?): Observable<any> {
    return this.restApiService.post('sendMail', '/secure/events/custom-email', data, 'page-center');
  }

  sendAgingMail(data): Observable<any> {
    return this.restApiService.post('sendMail', '/secure/reports/client/aging-reports/send-email', data, 'page-center');
  }


  // Task

  activeInactiveTask(data): Observable<any> {
    return this.restApiService.put('activeInactiveTask', '/secure/tasks/activate-deactivate/status',
      data, 'page-center');
  }

  // new updated api for seriease task
  activeDeactiveTask(data): Observable<any> {
    return this.restApiService.put('activeDeactiveTask', '/secure/tasks/activate-deactivate/status',
      data, 'page-center');
  }

  getTaskDetails(id): Observable<any> {
    return this.restApiService.get('getTaskDetails', `/secure/tasks/details/${id}`, 'page-center');
  }


  updateTask(data): Observable<any> {
    return this.restApiService.put('updateTask', '/secure/tasks', data, 'page-center');
  }

  addTask(data): Observable<any> {
    return this.restApiService.post('addTask', '/secure/tasks', data, 'page-center');
  }

  getStaffList(brandId?): Observable<any> {
    return this.restApiService.get('getStaffList', `/secure/users-list?role=Staff&brandIds=${brandId}`, 'page-center');
  }

  getStaffListWithParams(params): Observable<any> {
    return this.restApiService.get('getStaffList', `/secure/users-list?${params}`, 'page-center');
  }

  getTaskStaffListWithParams(params): Observable<any> {
    return this.restApiService.get('getStaffList', `/secure/staffs/availability-grid-data?${params}`, 'page-center');
  }

  getEventsList(franchiseId, startTask, endTask, brandId?): Observable<any> {
    return this.restApiService.get('getEventsList', `/secure/events/list/${franchiseId}?searchText=&fromDate=${startTask}&toDate=${endTask}&brandId=${brandId}`, 'page-center');
  }


  // Staff

  activeInactiveStaff(data): Observable<any> {
    return this.restApiService.put('activeInactiveStaff', '/secure/staffs/activate-deactivate/status',
      data, 'page-center');
  }

  getStaffDetails(id): Observable<any> {
    return this.restApiService.get('getStaffDetails', `/secure/staffs/details/${id}`, 'page-center');
  }

  updateStaff(data): Observable<any> {
    return this.restApiService.put('updateStaff', '/secure/staffs', data, 'page-center');
  }

  addStaff(data): Observable<any> {
    return this.restApiService.post('addStaff', '/secure/staffs', data, 'page-center');
  }

  getStaffRoleList(): Observable<any> {
    return this.restApiService.get('getStaffRoleList', `/secure/roles?type=staff`, 'page-center');
  }

  // for staff scheduled
  getAllStaff(fromDate, toDate, brandIds = 'ALL'): Observable<any> {
    // return this.restApiService.get('getStaffList', '/secure/staffs/grid-data?limit=9999&applyActivatedStatus=true&activated=true', 'page-center');
    return this.restApiService.get('getStaffList',
      `/secure/staffs/grid-data?limit=9999&fromDate=${fromDate}&toDate=${toDate}&brandIds=${brandIds}`,
      'page-center');
  }

  getAllAssets(params?): Observable<any> {
    return this.restApiService.get('getAsestList', `/secure/assets/grid-data?limit=9999&applyActivatedStatus=true&activated=true&${params}`, 'page-center');
  }

  getAllAssetsForTask(params?): Observable<any> {
    return this.restApiService.get('getAsestList', `/secure/assets-task/grid-data?limit=9999&applyActivatedStatus=true&activated=true&${params}`, 'page-center');
  }

  getAllAssetsForFilter(selectedBrands = 'ALL'): Observable<any> {
    if (selectedBrands) {
      return this.restApiService.get('getAsestList', `/secure/assets/grid-data?limit=9999&brandIds=${selectedBrands}`, 'page-center');
    }
  }

  getAllAssetsForFilterWithBrands(selectedBrands): Observable<any> {
    return this.restApiService.get('getAsestList', `/secure/assets/grid-data?limit=9999&brandIds=${selectedBrands}`, 'page-center');
  }

  getSalesTypes(): Observable<any> {
    return this.restApiService.get('getSalesTypes', '/secure/events-sales-types', 'page-center');
  }

  getAllEvents(fromDate, toDate): Observable<any> {
    return this.restApiService.get('getAllEvents', `/secure/events/grid-data?fromDate=${fromDate}&toDate=${toDate}&deleted=false&limit=9991`, 'page-center');
  }

  getAllEventsWithStaffAndAssets(fromDate, toDate): Observable<any> {
    return this.restApiService.get('getAllEventsWithStaffAndAssets', `/secure/events/staff-schedule/grid-data?fromDate=${fromDate}&toDate=${toDate}&deleted=false&limit=9991`, 'page-center');
  }

  getEventGroupByAssets(data): Observable<any> {
    return this.restApiService.post('getEventGroupByAssets', '/secure/staff-schedule/events/list/assetgroup', data, 'page-center');
  }

  //** bulk assign events to asseet page, */
  getEventByAssets(data): Observable<any> {
    return this.restApiService.get('getEventGroupByAssets',
      `/secure/assign-equipment/event-list?${data}`, 'page-center');
  }

  getAllTasksForBulkAssign(data): Observable<any> {
    return this.restApiService.get('getAllTasksForEquipment',
      `/secure/assign-equipment/task-list?${data}`, 'page-center');
  }

  assignEquipmentToBulkEvents(data): Observable<any> {
    return this.restApiService.put('schduleStaff', '/secure/events/assign-bulk-equipment', data, 'page-center');
  }

  assignStaffToBulkEventsTask(data): Observable<any> {
    return this.restApiService.post('updateSchedule', `/secure/staff-schedule/assign-bulk-staff`, data, 'page-center');
  }
  //** bulk assign events to asseet page end */

  getEventsByURL(url): Observable<any> {
    return this.restApiService.get('getEventsByURL', `${url}`, 'page-center');
  }

  getAllEventsForStaff(data): Observable<any> {
    return this.restApiService.post('getAllEventsForStaff', '/secure/events/staff-schedules', data, 'page-center');
  }

  getAllTasksForStaff(data): Observable<any> {
    return this.restApiService.post('getAllTasksForStaff', '/secure/tasks/staff-schedules', data, 'page-center');
  }

  getMonthlySchedule(data): Observable<any> {
    return this.restApiService.post('getMonthlySchedule', '/secure/staffs/schedules-monthly', data, 'page-center');
  }

  getMonthlyAvailability(data): Observable<any> {
    return this.restApiService.post('getMonthlySchedule', '/secure/staffs/availability/calendor-view', data, 'page-center');
  }

  getWeeklySchedule(data, selectedSwitch): Observable<any> {
    return this.restApiService.post('getWeeklySchedule', `/secure/staffs/${selectedSwitch}`, data, 'page-center');
  }

  getScheduleDetails(id): Observable<any> {
    return this.restApiService.get('getEventDetails', `/secure/staff-schedule/${id}`, 'page-center');
  }

  deleteSchedule(id, data): Observable<any> {
    return this.restApiService.put('deleteEvent', `/secure/staff-schedule/${id}/delete-schedule`, data, 'page-center');
  }

  schduleStaff(data): Observable<any> {
    return this.restApiService.post('schduleStaff', '/secure/staff-schedule', data, 'page-center');
  }


  updateSchedule(data): Observable<any> {
    return this.restApiService.put('updateSchedule', `/secure/staff-schedule`, data, 'page-center');
  }

  getCommonStaffSchedulesLeads(): Observable<any> {
    return this.restApiService.get('commonLeads', `/secure/staff-schedules/leads/details`, 'page-center');
  }

  addCommonStaffSchedulesLeads(data): Observable<any> {
    return this.restApiService.post('schduleStaff', '/secure/staff-schedules/leads', data, 'page-center');
  }

  updateCommonStaffSchedulesLeads(data): Observable<any> {
    return this.restApiService.put('updateSchedule', `/secure/staff-schedules/leads`, data, 'page-center');
  }

  fetchStaffScheduleEvents(fromDate, toDate, brandIds?): Observable<any> {
    return this.restApiService.get('fetchStaffScheduleEvents', `/secure/staff-schedule/events/list?activeEvent=&fromDate=${fromDate}&toDate=${toDate}&brandIds=${brandIds}&limit=999`, 'page-center');
  }

  schduleStaffPublishNotifyEmail(data): Observable<any> {
    return this.restApiService.post('staffNotifyEmail', '/secure/staff-schedule/publish-notify-email', data, 'page-center');
  }

  notifyStaff(data): Observable<any> {
    return this.restApiService.post('notifyStaff', '/secure/events/staff/shift-notify-email', data, 'page-center');
  }

  //**** monthly api before optimise **/
  // getMonthlyEvents(data): Observable<any> {
  //   return this.restApiService.post('getMonthlyEvents', '/secure/events/monthly', data, 'page-center');
  // }

  //** monthly api after optimise **/
  getMonthlyEvents(data): Observable<any> {
    return this.restApiService.post('getMonthlyEvents', '/secure/events/minimal/monthly', data, 'page-center');
  }

  getMonthlyTasks(data): Observable<any> {
    return this.restApiService.post('getMonthlyTasks', '/secure/tasks/monthly', data, 'page-center');
  }

  getClientDetailsWithContactPersons(id): Observable<any> {
    return this.restApiService.get('clientDetails', `/secure/clients/details/${id}?contactPersons=true`, 'page-center');
  }

  // events week calender for asset selection
  getWeeklyEvents(data): Observable<any> {
    return this.restApiService.post('getWeeklyEvents', '/secure/events/events-weekly-asset', data, 'page-center');
  }

  getClients(): Observable<any> {
    return this.restApiService.get('getClients', '/secure/clients/grid-data?source=report&searchText=&offset=0&limit=10000&sortColumn=&sortType=asc', 'page-center');
  }

  getGiveBackReport(data): Observable<any> {
    return this.restApiService.post('getGiveBackReport', `/secure/reports/giveback/grid-data`, data, 'page-center');
  }

  getStaffReport(data): Observable<any> {
    return this.restApiService.post('getStaffReport', `/secure/reports/employee-time-card/grid-data`, data, 'page-center');
  }

  getSalesHistory(data): Observable<any> {
    return this.restApiService.post('getSalesHistory', `/secure/reports/sales-history/grid-data`, data, 'page-center');
  }

  getHoursWorked(data): Observable<any> {
    return this.restApiService.post('getHoursWorked', `/secure/reports/hours-worked-summary/grid-data`, data, 'page-center');
  }

  getEquipmentTotal(data): Observable<any> {
    return this.restApiService.post('getSalesHistory', `/secure/reports/asset-usage/grid-data`, data, 'page-center');
  }

  getClientRankingTotal(data): Observable<any> {
    return this.restApiService.post('getClientRankingTotal', `/secure/reports/client-ranking/grid-data`, data, 'page-center');
  }

  getAllScheduledStaff(fromDate): Observable<any> {
    // return this.restApiService.get('getClients', `/secure/staffs-schedule/users-list?fromDate=${fromDate}&limit=999&applyActivatedStatus=true&activated=true`, 'page-center');
    return this.restApiService.get('getClients', `/secure/staffs-schedule/users-list?fromDate=${fromDate}&limit=999`, 'page-center');
  }

  getAllScheduledStaffWithStatus(params): Observable<any> {
    // return this.restApiService.get('getClients', `/secure/staffs-schedule/users-list?fromDate=${fromDate}&limit=999&applyActivatedStatus=true&activated=true`, 'page-center');
    return this.restApiService.get('getClients', `/secure/staffs-schedule/users-list?${params}`, 'page-center');
  }

  // assign Staff getting staff list
  fetchStaffListForAssign(startofFirstDay, endOfFirstDay, eventId, selectedBrandId?): Observable<any> {
    return this.restApiService.get('fetchStaffScheduleEvents',
      `/secure/staffs/availability-grid-data?applyActivatedStatus=true&activated=true&fromDate=${startofFirstDay}&toDate=${endOfFirstDay}&eventId=${eventId}&brandIds=${selectedBrandId}`,
      'page-center');
  }

  getVersion(): Observable<any> {
    return this.restApiService.get('getVersion', '/metadata', 'page-center');
  }

  checkVersion(): Observable<any> {
    return this.restApiService.get('Check Version', '/version', 'page-center');
  }

  checkTimeZone(): Observable<any> {
    return this.restApiService.get('Check TimeZone', '/secure/franchise-timezone');
  }

  updatEventStatus(data): Observable<any> {
    return this.restApiService.put('updateEventStatus', '/secure/events/event-manual-status', data, 'page-center');
  }

  generateReport(exportCompleteUrl, data?): Observable<any> {
    return this.restApiService.post('generateReport', `${exportCompleteUrl}`, data, 'page-center');
  }

  generatePostBodyReport(exportCompleteUrl, data): Observable<any> {
    return this.restApiService.post('generatePostBodyReport', `${exportCompleteUrl}`, data, 'page-center');
  }

  downloadReport(id): Observable<any> {
    return this.restApiService.get('downloadReport', `/secure/file-export/${id}`, 'page-center');
  }

  //event attachments
  deleteEventAttachment(eventFileId, objeToSend): Observable<any> {
    return this.restApiService.put('deleteEventAttachment', `/secure/event-files/${eventFileId}/delete`,
      objeToSend, 'page-center');
  }

  //client attachments
  deleteClientAttachment(clientFileId, objeToSend): Observable<any> {
    return this.restApiService.put('deleteClientAttachment', `/secure/client-files/${clientFileId}/delete`,
      objeToSend, 'page-center');
  }

  getStaffReportData(id): Observable<any> {
    return this.restApiService.get('getStaffReportData', `/secure/staffs/dutystatus/entry/${id}`, 'page-center');
  }

  updateEmployeeTCData(data): Observable<any> {
    return this.restApiService.put('updateEmployeeTCData', `/secure/staffs/dutystatus/entry`, data, 'page-center');
  }

  addEmployeeTCData(data): Observable<any> {
    return this.restApiService.post('addEmployeeTCData', `/secure/staffs/dutystatus/entry`, data, 'page-center');
  }

  activeInactiveCustomer(data): Observable<any> {
    return this.restApiService.put('activeInactiveCustomer', '/secure/customers/activate-deactivate/status', data, 'page-center', 'useV2');
  }


  getAllPayoutReportEventsWithParam(params): Observable<any> {
    return this.restApiService.get('getAllPayoutReportEventsWithParam', `/secure/payout-report/grid-data?${params}`, 'page-center');
  }

  fetchClientDashboard(clientId): Observable<any> {
    return this.restApiService.get('getClientDashboard', `/secure/clients/dashboard?applyActivatedStatus=true&activated=true&clientId=${clientId}`, 'page-center');
  }

  deleteBlockOffDate(data, dateId): Observable<any> {
    return this.restApiService.put('deleteBlockOffDate', `/secure/block-off-days/${dateId}`, data, 'page-center');
  }

  addBlockOffDate(data): Observable<any> {
    return this.restApiService.post('addBlockOffDate', '/secure/block-off-days', data, 'page-center');
  }

  updateLeaveStatus(data, leaveRequestId): Observable<any> {
    return this.restApiService.put('updateRequest', `/secure/leave-request/update-status/${leaveRequestId}`, data, 'page-center');
  }

  getConfictShifts(data, userId) {
    return this.restApiService.post('getConfictShifts', `/secure/leave-request/${userId}/conflict-shift`, data, 'page-center');
  }

  mergeClients(data) {
    return this.restApiService.post('mergeClients', '/secure/clients/merge-client', data, 'page-center');
  }

  updateShiftSwapRequest(switchShiftRequestId, switchShiftStatus): Observable<any> {
    return this.restApiService.put('updateRequest', `/secure/staffs/switch-shift/approve-reject-request?switchShiftRequestId=${switchShiftRequestId}&switchShiftStatus=${switchShiftStatus}`, '', 'page-center');
  }

  /** not in use after new menu changes */
  getCorporateTemplateDetails(templateID): Observable<any> {
    return this.restApiService.get('CorporateTemplateDetails', `/secure/templates-detail/${templateID}`, 'page-center');
  }

  //** common menu related api */
  getFoodExtraDetails(itemId): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/food-extra-items/item-details/${itemId}`, 'page-center');
  }

  //** common menu related api */
  getAllFoodExtraList(type): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/food-extra-items/grid-data?searchText=&selection=${type}&limit=1000&applyActivatedStatus=true&activated=true`,
      'page-center');
  }

  getAllFoodExtraListWithBrand(brandId?, type?): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/food-extra-items/grid-data?searchText=&selection=${type}&limit=1000&applyActivatedStatus=true&activated=true&brandIds=${brandId}`,
      'page-center');
  }

  //** common menu related api */
  getMenuItemDetails(itemId): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/menus/item-details/${itemId}`, 'page-center');
  }

  //** common menu related api */
  getFoodExtraItemsWithCategory(deletedFlag?, brandId?): Observable<any> {
    return this.restApiService.get('getMenu',
      `/secure/food-extra-categories/category-items?limit=999&deleted=${deletedFlag}&brandIds=${brandId}`,
      'page-center');
  }

  //** common menu related api */
  updateMenu(data): Observable<any> {
    return this.restApiService.put('updateMenuItem', '/secure/menus', data, 'page-center');
  }


  //** common menu related api */
  //** fetching all menu for selection on category */
  getAllMenuList(brandId?): Observable<any> {
    if (brandId) {
      return this.restApiService.get('getMenu',
        `/secure/menus/new/grid-data?applyActivatedStatus=true&activated=true&searchText=&limit=1000&brandIds=${brandId}`,
        'page-center');
    } else {
      return this.restApiService.get('getMenu',
        `/secure/menus/new/grid-data?applyActivatedStatus=true&activated=true&searchText=&limit=1000`,
        'page-center');
    }
  }

  //** common menu related api */
  addMenuCategory(data): Observable<any> {
    return this.restApiService.post('addMenuCategorie', '/secure/menus-categories', data, 'page-center');
  }

  //** common menu related api */
  updatMenuCategory(data): Observable<any> {
    return this.restApiService.put('updateMenuCategory', '/secure/menus-categories', data, 'page-center');
  }

  //** common menu related api */
  getMenuCatDetails(id): Observable<any> {
    return this.restApiService.get('getMenuCat', `/secure/menus-categories/category-details/${id}`, 'page-center');
  }

  //** common menu related api */
  activeInactiveMenuItemCategory(data): Observable<any> {
    return this.restApiService.put('activeInactiveMenuItemCategory', '/secure/menus-categories/activate-deactivate/status',
      data, 'page-center');
  }

  //** common menu related api */
  deleteMenuItemCategory(data): Observable<any> {
    return this.restApiService.put('deleteMenuItemCategory', '/secure/menus-categories/delete-menu-item-category',
      data, 'page-center');
  }

  //** common menu related api */
  getmenuItemsItemsWithCategory(): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/menus-categories?limit=1000`, 'page-center');
  }

  getmenuItemsItemsWithCategoryWithBrands(brandId?): Observable<any> {
    return this.restApiService.get('getMenu', `/secure/menus-categories?limit=1000&brandIds=${brandId}`, 'page-center');
  }

  //** common menu related api */
  getTemplateByTemplateId(templateId): Observable<any> {
    return this.restApiService.get('franchiseDetails', `/secure/templates/${templateId}`, 'page-center');
  }

  activateDeactivateCampaign(data): Observable<any> {
    return this.restApiService.put('activateDeactivateCampaign', '/secure/mailchimp-campaigns/activate-deactivate/status',
      data, 'page-center');
  }

  optOutCampaign(data): Observable<any> {
    return this.restApiService.post('optoutCampaign', '/secure/mailchimp-opt-out-campaigns',
      data, 'page-center');
  }

  optInCampaign(franchiseOptOutCampaignId): Observable<any> {
    return this.restApiService.delete('deleteoptInCampaign', `/secure/mailchimp-opt-out-campaigns/${franchiseOptOutCampaignId}`);
  }

  deleteLegacyContact(legacyContactId): Observable<any> {
    return this.restApiService.delete('deleteLegacyContact', `/secure/vya-customers?vyaCustomerId=${legacyContactId}`);
  }

  deleteMultipleCustomers(legacyContactId): Observable<any> {
    return this.restApiService.delete('deleteMultipleCustomers', `/secure/vya-customers/all?vyaCustomerIds=${legacyContactId}`);

  }

  deleteAllCustomers(): Observable<any> {
    return this.restApiService.delete('deleteMultipleCustomers', `/secure/vya-customers/all`);
  }

  getLegacyContactDetails(id): Observable<any> {
    return this.restApiService.get('getLegacyContactDetails', `/secure/vya-customers/details/${id}`, 'page-center');
  }

  addLegacyCustomer(data): Observable<any> {
    return this.restApiService.post('addLegacyDetails', '/secure/vya-customers', data, 'page-center');
  }

  updateLegacyCustomer(data): Observable<any> {
    return this.restApiService.put('updateLegacyCustomer', '/secure/vya-customers', data, 'page-center');
  }

  getSignTemplates(brandId?): Observable<any> {
    if (brandId) {
      return this.restApiService.get('franchiseDetails', `/secure/mail-signature-list?brandIds=${brandId}`, 'page-center');
    } else {
      return this.restApiService.get('franchiseDetails', `/secure/mail-signature-list`, 'page-center');
    }
  }

  addSignTemplate(data): Observable<any> {
    return this.restApiService.post('addSignTemplate', '/secure/mail-signature', data, 'page-center');
  }

  getSignTemplateDetails(id): Observable<any> {
    return this.restApiService.get('signTemplateDetails', `/secure/mail-signature/details/${id}`, 'page-center');
  }

  updateSignTemplate(data, id): Observable<any> {
    return this.restApiService.put('updateSignTemplate', `/secure/mail-signature/${id}`, data, 'page-center');
  }

  deleteSignTemplate(data, templateId): Observable<any> {
    return this.restApiService.put('deleteSignTemplate', `/secure/mail-signature/delete/${templateId}`, data, 'page-center');
  }

  getCustomTemplateList(id): Observable<any> {
    return this.restApiService.get('getCustomTemplateList', `/secure/mail-templates/custom/list?brandId=${id}`, 'page-center');
  }

  getCustomTemplateDetails(id, eventId): Observable<any> {
    return this.restApiService.get('getCustomTemplateDetails', `/secure/events/template/custom/email?templateId=${id}&eventId=${eventId}`, 'page-center');
  }

  getCustomTemplateDetailsForAgingReport(id, clientId): Observable<any> {
    return this.restApiService.get('getCustomTemplateDetailsForAgingReport', `/secure/clients/template/custom/email?templateId=${id}&clientId=${clientId}`, 'page-center');
  }

  //** FBR starts */
  getRequestDetails(id): Observable<any> {
    return this.restApiService.get('getRequestDetails', `/secure/future-booking-request/details/${id}`, 'page-center');
  }

  readStatusChangeFBR(data): Observable<any> {
    return this.restApiService.put('readStatusChangeFBR', '/secure/future-booking-request/activate-deactivate/read-status',
      data, 'page-center');
  }

  upFBRRequest(data, id): Observable<any> {
    return this.restApiService.put('upFBRRequest', `/secure/future-booking-request/${id}`, data, 'page-center');
  }

  deleteFBR(id): Observable<any> {
    return this.restApiService.delete('deleteFBR', `/secure/future-booking-request/${id}`, 'page-center');
  }

  getmatchingClients(id): Observable<any> {
    return this.restApiService.get('getRequestDetails', `/secure/clients-list-FBR?${id}`, 'page-center');
  }

  setAsPrimaryContact(contactPersonId): Observable<any> {
    return this.restApiService.put('setAsPrimaryContact', `/secure/contact-persons/shuffle-contact/${contactPersonId}`, '', 'page-center')
  }

  generatePrepayOrdersReport(payload): Observable<any> {
    return this.restApiService.post('generatePrepayOrdersReport', `/secure/events/manually/complete/email-order-report`, payload, 'page-center');
  }

  addFBR(data): Observable<any> {
    return this.restApiService.post('addFBR', '/contact-form/future-booking-request', data, 'page-center');
  }

  copyContactFromEvent(clientId): Observable<any> {
    return this.restApiService.put('copyContact', `/secure/clients/update-buisness-name-client/${clientId}`, '', 'page-center')
  }

  addBroadcastMessage(data): Observable<any> {
    return this.restApiService.post('addBroadcastMessage', '/secure/super-settings/broadcast-messages', data, 'page-center');
  }

  updateBroadcastMessage(data): Observable<any> {
    return this.restApiService.put('updateBroadcastMessage', '/secure/super-settings/broadcast-messages', data, 'page-center');
  }

  deleteBroadcastMessage(broadcastId): Observable<any> {
    return this.restApiService.put('deleteBroadcastMessage', `/secure/super-settings/broadcast-messages/delete/${broadcastId}`, 'page-center');
  }

  publishBroadcastMessage(broadcastId): Observable<any> {
    return this.restApiService.put('deleteBroadcastMessage', `/secure/super-settings/broadcast-messages/publish/${broadcastId}`, 'page-center');
  }

  getBroadcastMessage(broadcastId): Observable<any> {
    return this.restApiService.get('deleteBroadcastMessage', `/secure/super-settings/broadcast-messages/details/${broadcastId}`, 'page-center');
  }

  updateViewedBroadcastMessage(userId): Observable<any> {
    return this.restApiService.put('deleteBroadcastMessage', `/secure/super-settings/broadcast-messages/user-viewed/${userId}?broadcastMessageViewed=true`,
      'page-center');
  }

  geGridTemplateList(id): Observable<any> {
    return this.restApiService.get('geGridTemplateList', `/secure/mail-templates-future-requests/grid-data?brandIds=${id}`, 'page-center');
  }

  getPrepaidEmailTemplateDetails(fbrId, templateId): Observable<any> {
    return this.restApiService.get('getCustomTemplateList', `/secure/mail-templates-future-requests/email/template/${templateId}?futureBookingRequestId=${fbrId}`, 'page-center');
  }

  addStaffNote(data): Observable<any> {
    return this.restApiService.post('addStaffNote', '/secure/staff-record', data, 'page-center');
  }

  getStaffNoteDetails(staffRecordId): Observable<any> {
    return this.restApiService.get('getStaffNoteDetails', `/secure/staff-record/details/${staffRecordId}`, 'page-center');
  }

  updateStaffNote(data): Observable<any> {
    return this.restApiService.put('updateStaffNote', '/secure/staff-record', data, 'page-center');
  }

  deleteStaffNote(staffRecordId): Observable<any> {
    return this.restApiService.put('deleteStaffNote', `/secure/staff-record/${staffRecordId}/delete-record`, 'page-center');
  }

  addEquipmentLog(data): Observable<any> {
    return this.restApiService.post('addEquipmentLog', '/secure/asset-record', data, 'page-center');
  }

  getEquipmentLogDetails(EquipmentLogRecordId): Observable<any> {
    return this.restApiService.get('getEquipmentLogDetails', `/secure/asset-record/details/${EquipmentLogRecordId}`, 'page-center');
  }

  updateEquipmentLog(data): Observable<any> {
    return this.restApiService.put('updateEquipmentLog', '/secure/asset-record', data, 'page-center');
  }

  deleteEquipmentLog(EquipmentLogRecordId): Observable<any> {
    return this.restApiService.put('deleteEquipmentLog', `/secure/asset-record/${EquipmentLogRecordId}/delete-record`, 'page-center');
  }

  getCampaignReport(franchiseId, id, brandId): Observable<any> {
    return this.restApiService.get('getCampaignReportDetails', `/secure/mailchimp-campaign-reports?franchiseId=${franchiseId}&brandId=${brandId}&masterCampaignId=${id}`, 'page-center');
  }

  // notify staff by franchise api's strat
  addStaffNotificationByFranchise(data): Observable<any> {
    return this.restApiService.post('addStaffNotification', '/secure/staff-notify', data, 'page-center');
  }

  getStaffNotificationDetails(id): Observable<any> {
    return this.restApiService.get('StaffNotificationDetails', `/secure/staff-notify/details/${id}`, 'page-center');
  }

  updateStaffNotificationByFranchise(data): Observable<any> {
    return this.restApiService.put('updateStaffNotification', '/secure/staff-notify', data, 'page-center');
  }
  // notify staff by franchise api's strat

}
