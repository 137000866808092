<div style="padding-right: 7px;">
    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:0cm;text-align:center;font-size:21px;font-family:"Times New Roman","serif";color:black;font-weight:bold;'><span style="font-size:15px;">KONA ICE, INC.</span></p>
    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:0cm;text-align:center;font-size:21px;font-family:"Times New Roman","serif";color:black;font-weight:bold;'><span style="font-size:15px;">Privacy Notice for California Residents</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style='font-family:"Times New Roman","serif";color:black;'>Effective Date</span></strong><span style='font-family:"Times New Roman","serif";color:black;'>: January 1, 2020</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><span style='font-family:"Times New Roman","serif";color:black;'>Last Reviewed</span></strong><span style='font-family:"Times New Roman","serif";color:black;'>: August 24, 2020</span></p>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
        <p><span style='font-family:"Times New Roman","serif";font-size:15px;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; I. Overview</span></p>
    </div>
    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>You should read Kona&rsquo;s entire Privacy Notice for California Residents, but here is a partial list of some of the key topics. &nbsp;&nbsp;</span></p>
    <ul style="list-style-type: disc;margin-left:26px;">
        <li><span style='font-family:"Times New Roman","serif";'>Click here<span style="color:black;">&nbsp;to learn who we are and who is covered by this Privacy Notice.</span></span></li>
        <li><span style='font-family:"Times New Roman","serif";'>Click here<span style="color:black;">&nbsp;to learn what categories of information we collect from California consumers and why we collect it.</span></span></li>
        <li><span style='font-family:"Times New Roman","serif";'>Click here<span style="color:black;">&nbsp;to learn about our data collection, use, and disclosure practices during the 12-month period preceding the Effective Date.</span></span></li>
        <li><span style='font-family:"Times New Roman","serif";color:black;'>We do not, and will not, sell personal information of consumers. We have not sold any such information in the last 12 months. &nbsp;</span></li>
        <li><span style='font-family:"Times New Roman","serif";'>Click here<span style="color:black;">&nbsp;to learn about your rights and choices with respect to your personal information and how to exercise those rights.</span></span></li>
        <li><span style='font-family:"Times New Roman","serif";color:black;'>We will not discriminate against you for exercising any rights you may have been granted under the California Consumer Privacy Act.</span></li>
        <li><span style='font-family:"Times New Roman","serif";'>Click here<span style="color:black;">&nbsp;to learn how to contact us for more information about our privacy practices.</span></span></li>
    </ul>
    <p><span style='font-family:"Times New Roman","serif";font-size:15px;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; II. Introduction</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";color:black;'><span style='font-family:"Times New Roman","serif";'>This <strong>Privacy Notice for California Residents</strong> supplements the information contained in Kona Ice, Inc&rsquo;s (&ldquo;<strong>Kona</strong>,&rdquo; &ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>us</strong>,&rdquo; or &ldquo;<strong>our</strong>&rdquo;) website privacy policies and describes our privacy practices with respect to individuals who reside in the State of California (&ldquo;<strong>consumers</strong>&rdquo; or &ldquo;<strong>you</strong>&rdquo;). &nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";color:black;'><span style='font-family:"Times New Roman","serif";'>This Privacy Notice does not apply to information we collect when:</span></p>
    <ul style="list-style-type: disc;margin-left:84.5px;">
        <li><span style='font-family:"Times New Roman","serif";'>You are acting as a job applicant to us;</span></li>
        <li><span style='font-family:"Times New Roman","serif";'>You are acting in your capacity as a current or former employee, owner, director, officer, medical staff member, or contractor for us;</span></li>
        <li><span style='font-family:"Times New Roman","serif";'>You have been designated as an emergency contact for one of the persons listed above and your information has been collected for use solely within that context;</span></li>
        <li><span style='font-family:"Times New Roman","serif";'>We need your personal information to administer benefits obtained through one of the persons listed above.</span></li>
    </ul>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";color:black;'><span style='font-family:"Times New Roman","serif";'>For information on our privacy practices as they relate to any of the above persons contact us by using the contact information in Section IX (Who to contact for more information) below. &nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";color:black;'><span style='font-family:"Times New Roman","serif";'>We adopt this notice to comply with the California Consumer Privacy Act of 2018, as may be amended, replaced, or superseded as well as any implementing regulations (CCPA). Any terms defined in the CCPA have the same meaning when used in this notice. &nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";color:black;'><span style='font-family:"Times New Roman","serif";'>The CCPA temporarily exempts personal information reflecting written or verbal business-to-business communications (&ldquo;<strong>B2B personal information</strong>&rdquo;) from many of its requirements. &nbsp;This Privacy Policy applies specifically to you as a user of our Website. &nbsp; This Privacy Notice does not disclose our practices with respect to B2B personal information.</span></p>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
        <p><span style='font-family:"Times New Roman","serif";font-size:15px;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; III. Information&nbsp;</span><span style='font-family:"Times New Roman","serif";font-size:15px;'>we collect about California consumers</span></p>
    </div>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>We collect information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (&ldquo;<strong>personal information</strong>&rdquo;). &nbsp;&nbsp;</span><span style='font-family:"Times New Roman","serif";'>However, personal information does not include:</span></p>
    <ul style="list-style-type: disc;">
        <li><span style="line-height:107%;font-size:15px;">Publicly available information from government records.</span></li>
        <li><span style="line-height:107%;font-size:15px;">Deidentified or aggregated consumer information.</span></li>
        <li><span style="line-height:107%;font-size:15px;">Information excluded from the CCPA&rsquo;s scope, like:</span>
            <ul style="list-style-type: disc;">
                <li><span style="line-height:107%;font-size:15px;">health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data; and</span></li>
                <li><span style="line-height:107%;font-size:15px;">personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver&rsquo;s Privacy Protection Act of 1994.</span></li>
            </ul>
        </li>
    </ul>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-indent:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;line-height:107%;'><span style="font-size:15px;line-height:107%;">Specifically, we collect the following categories of personal information: &nbsp;</span></p>
    <table style="float: left;width:99.1%;border-collapse:collapse;border:none;margin-left:6.75pt;margin-right:6.75pt;">
        <thead>
            <tr>
                <td style="width: 21.32%;border: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:   6.0pt;margin-left:13.5pt;font-size:16px;font-family:"Times New Roman","serif";color:black;text-indent:-13.5pt;'><strong><span style="font-size:15px;">Category</span></strong></p>
                </td>
                <td style="width: 62%;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><strong><span style="font-size:15px;">Examples</span></strong></p>
                </td>
                <td style="width: 16.7%;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><strong><span style="font-size:15px;">Do we collect this information?</span></strong></p>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style="width: 21.32%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:  6.0pt;margin-left:13.5pt;font-size:16px;font-family:"Times New Roman","serif";color:black;text-indent:-13.5pt;'><span style="font-size:15px;">A. &nbsp; &nbsp; &nbsp; &nbsp;Identifiers.</span></p>
                </td>
                <td style="width: 62%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver&rsquo;s license number, passport number, or other similar identifiers.</span></p>
                </td>
                <td style="width: 16.7%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;text-align:center;'><span style="font-size:15px;">YES</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 21.32%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:  6.0pt;margin-left:13.5pt;font-size:16px;font-family:"Times New Roman","serif";color:black;text-indent:-13.5pt;'><span style="font-size:15px;">B. &nbsp; &nbsp; &nbsp; &nbsp; Personal information categories listed in the California Customer Records statute (Cal. Civ. Code &sect; 1798.80(e)).</span></p>
                </td>
                <td style="width: 62%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver&rsquo;s license or state identification card number, insurance policy number, credit or debit card number, bank account number, or any other financial information, medical information, or health insurance information.</span></p>
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">Some personal information included in this category may overlap with other categories.</span></p>
                </td>
                <td style="width: 16.7%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;text-align:center;'><span style="font-size:15px;">YES</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 21.32%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:  6.0pt;margin-left:13.5pt;font-size:16px;font-family:"Times New Roman","serif";color:black;text-indent:-13.5pt;'><span style="font-size:15px;">C. &nbsp; &nbsp; &nbsp; &nbsp; Protected classification characteristics under California or federal law.</span></p>
                </td>
                <td style="width: 62%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</span></p>
                </td>
                <td style="width: 16.7%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;text-align:center;'><span style="font-size:15px;">NO</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 21.32%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:  6.0pt;margin-left:13.5pt;font-size:16px;font-family:"Times New Roman","serif";color:black;text-indent:-13.5pt;'><span style="font-size:15px;">D. &nbsp; &nbsp; &nbsp; &nbsp;Commercial information.</span></p>
                </td>
                <td style="width: 62%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">Records of products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</span></p>
                </td>
                <td style="width: 16.7%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;text-align:center;'><span style="font-size:15px;">YES</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 21.32%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:  6.0pt;margin-left:13.5pt;font-size:16px;font-family:"Times New Roman","serif";color:black;text-indent:-13.5pt;'><span style="font-size:15px;">E. &nbsp; &nbsp; &nbsp; &nbsp; Biometric information.</span></p>
                </td>
                <td style="width: 62%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</span></p>
                </td>
                <td style="width: 16.7%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;text-align:center;'><span style="font-size:15px;">NO</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 21.32%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:  6.0pt;margin-left:13.5pt;font-size:16px;font-family:"Times New Roman","serif";color:black;text-indent:-13.5pt;'><span style="font-size:15px;">F. &nbsp;Internet or other similar network activity.</span></p>
                </td>
                <td style="width: 62%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">Browsing history, search history, information on a consumer&rsquo;s interaction with a website, application, or advertisement.</span></p>
                </td>
                <td style="width: 16.7%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;text-align:center;'><span style="font-size:15px;">YES</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 21.32%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:  6.0pt;margin-left:13.5pt;font-size:16px;font-family:"Times New Roman","serif";color:black;text-indent:-13.5pt;'><span style="font-size:15px;">G. &nbsp; &nbsp; &nbsp; &nbsp;Geolocation data.</span></p>
                </td>
                <td style="width: 62%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">Physical locations and movements. &nbsp;&nbsp;</span></p>
                </td>
                <td style="width: 16.7%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;text-align:center;'><span style="font-size:15px;">YES</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 21.32%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;height: 34.6pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:  6.0pt;margin-left:13.5pt;font-size:16px;font-family:"Times New Roman","serif";color:black;text-indent:-13.5pt;'><span style="font-size:15px;">H. &nbsp; &nbsp; &nbsp; &nbsp;Sensory data.</span></p>
                </td>
                <td style="width: 62%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;height: 34.6pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'>Audio, electronic, visual, thermal, olfactory, or similar information.</p>
                </td>
                <td style="width: 16.7%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;height: 34.6pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;text-align:center;'><span style="font-size:15px;">NO</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 21.32%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:  6.0pt;margin-left:13.5pt;font-size:16px;font-family:"Times New Roman","serif";color:black;text-indent:-13.5pt;'><span style="font-size:15px;">I. &nbsp;Professional or employment-related information.</span></p>
                </td>
                <td style="width: 62%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">Current or past job history or performance evaluations.</span></p>
                </td>
                <td style="width: 16.7%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;text-align:center;'><span style="font-size:15px;">NO</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 21.32%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:  6.0pt;margin-left:13.5pt;font-size:16px;font-family:"Times New Roman","serif";color:black;text-indent:-13.5pt;'><span style="font-size:15px;">J. &nbsp;Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</span></p>
                </td>
                <td style="width: 62%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.&nbsp;</span></p>
                </td>
                <td style="width: 16.7%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;text-align:center;'><span style="font-size:15px;">NO</span></p>
                </td>
            </tr>
            <tr>
                <td style="width: 21.32%;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:  6.0pt;margin-left:13.5pt;font-size:16px;font-family:"Times New Roman","serif";color:black;text-indent:-13.5pt;'><span style="font-size:15px;">K. &nbsp; &nbsp; &nbsp; &nbsp;Inferences drawn from other personal information.</span></p>
                </td>
                <td style="width: 62%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">Profile reflecting a person&rsquo;s preferences, including brand or product preferences and purchasing preferences.</span></p>
                </td>
                <td style="width: 16.7%;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;text-align:center;'><span style="font-size:15px;">YES</span></p>
                </td>
            </tr>
        </tbody>
    </table>
    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-indent:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">We collect and share personal information in the above categories for the following business purposes:</span></p>
    <ul style="list-style-type: disc;margin-left:84.5px;">
        <li><span style='font-family:"Times New Roman","serif";color:windowtext;font-size:15px;color:windowtext;'>To provide you with the information, Kona Ice products, services or support that you request from us or to fulfill any other purpose for which you provide it.</span></li>
        <li><span style="font-size:15px;">To verify your identity so that we can respond to a request you make under the applicable law.</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:windowtext;font-size:15px;color:windowtext;'>To manage our relationship with you which may include notifying you about changes to our terms or privacy policy, providing you notices about your account, asking you to leave a review or take a survey, addressing any concerns you may have about our products or services.</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:windowtext;font-size:15px;color:windowtext;'>To deliver relevant content, advertisements, and messaging to you and to measure or understand the effectiveness of such content, advertisements, and messaging.&nbsp;</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:windowtext;font-size:15px;color:windowtext;'>To use data analytics to improve our website, products or services, marketing, customer relationships, and experiences.</span></li>
        <li><span style="font-size:15px;color:windowtext;">To make suggestions and recommendations to you about our own and third-parties&rsquo; products or services that may be of interest to you. If you do not want us to use your information in this way or for more information on how to adjust your preferences, see <em>Your Rights and Choices.</em></span></li>
        <li><span style="font-size:15px;">To help maintain the safety, security, and integrity of our services, databases and other technology assets, and business.</span></li>
        <li><span style="font-size:15px;">To report to regulatory authorities as required by law. &nbsp;</span></li>
        <li><span style="font-size:15px;">To respond to law enforcement requests and other requests as required by applicable law, court order, or governmental regulations.</span></li>
        <li><span style="font-size:15px;">To protect and enforce our and others&rsquo; intellectual property rights; to protect or defend our legal rights or property, our services, or other users; and to protect the health and safety of our services&rsquo; users or the general public.</span></li>
        <li><span style="font-size:15px;">To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about consumers is among the assets transferred.</span></li>
        <li><span style="font-size:15px;">As described to you when collecting your personal information.&nbsp;</span></li>
        <li><span style="font-size:15px;">For any purpose with your consent.</span></li>
    </ul>
    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;text-indent:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</span></p>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
        <p><span style='font-family:"Times New Roman","serif";font-size:15px;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; IV. We do not sell your personal information</span></p>
    </div>
    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;text-align:left;font-size:16px;font-family:"Times New Roman","serif";color:black;font-weight:bold;line-height:107%;'><span style="font-size:15px;line-height:107%;font-weight:normal;">We do not, and will not, sell personal information of consumers and have not sold any such personal information within the last twelve (12) months.&nbsp;</span></p>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
        <p><span style='font-family:"Times New Roman","serif";font-size:15px;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; V. Notice of personal information that we have collected or disclosed in the last 12 months</span></p>
    </div>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;line-height:107%;'><span style="font-size:15px;line-height:107%;">In the last twelve (12) months, we have collected or disclosed the following categories of personal information for a business purpose:&nbsp;</span></p>
    <p><em><span style="line-height:107%;font-size:15px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;1. Categories of information collected in the last 12 months</span></em></p>
    <ul style="list-style-type: disc;margin-left:84.5px;">
        <li><span style="font-size:15px;">Category A: Identifiers.</span></li>
        <li><span style="font-size:15px;">Category B: California Customer Records personal information categories.</span></li>
        <li><span style="font-size:15px;">Category C: Protected classification characteristics under California or federal law.</span></li>
        <li><span style="font-size:15px;">Category D: Commercial information.</span></li>
        <li><span style="font-size:15px;">Category F: Internet or other similar network activity.</span></li>
        <li><span style="font-size:15px;">Category G: Geolocation data.</span></li>
        <li><span style="font-size:15px;">Category I: &nbsp;Professional or employment-related information.</span></li>
        <li><span style="font-size:15px;">Category K: Inferences drawn from other personal information.&nbsp;</span></li>
    </ul>
    <p><em><span style="line-height:107%;font-size:15px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2. How we collected this information</span></em></p>
    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:54.0pt;text-indent:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">We collected the above categories of your personal information from the following sources:</span></p>
    <ul style="list-style-type: disc;margin-left:80px;">
        <li><strong><span style="font-size:15px;">Directly from you</span></strong><span style="font-size:15px;">. For example, from forms you complete to receive products, newsletters, to make comments or inquiries concerning products you purchase and services you may obtain when you reach out to us online or offline.</span></li>
        <li><strong><span style="font-size:15px;">Indirectly from you</span></strong><span style="font-size:15px;">. For example, from observing your actions as you use our Services.</span></li>
        <li><strong><span style="font-size:15px;">From our service providers</span></strong><span style="font-size:15px;">. This includes businesses that collect your personal information on our behalf in order to help us respond to consumer inquiries, conduct market research, process payments, and comply with legally mandated disclosure requirements.</span></li>
        <li><strong><span style="font-size:15px;">From other third parties</span></strong><span style="font-size:15px;">. For example, when you make a comment on a third-party website such as social media, we may collect your name and contact information to respond to your concern.</span></li>
    </ul>
    <p><em><span style="line-height:107%;font-size:15px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3. Why we collected personal information</span></em></p>
    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:54.0pt;text-indent:0cm;font-size:16px;font-family:"Times New Roman","serif";color:black;'><span style="font-size:15px;">We have collected and disclosed personal information in the above categories for the following business purposes:</span></p>
    <ul style="list-style-type: disc;margin-left:84.5px;">
        <li><span style='font-family:"Times New Roman","serif";color:windowtext;font-size:15px;color:windowtext;'>To provide you with the information, Kona Ice products, services, or support that you request from us or to fulfill any other purpose for which you provide it.</span></li>
        <li><span style="font-size:15px;">To verify your identity so that we can respond to a request you make under the applicable law.</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:windowtext;font-size:15px;color:windowtext;'>To send you products that you have ordered, including to process payment, and provide you updates about the status of your order.&nbsp;</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:windowtext;font-size:15px;color:windowtext;'>To manage our relationship with you which may include notifying you about changes to our terms or privacy policy, providing you notices about your account, asking you to leave a review or take a survey, addressing any concerns you may have about our products or services.</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:windowtext;font-size:15px;color:windowtext;'>To deliver relevant content, advertisements, and messaging to you and to measure or understand the effectiveness of such content, advertisements, and messaging.&nbsp;</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:windowtext;font-size:15px;color:windowtext;'>To allow you to enter a sweepstake, contest or promotion that we may offer. If we plan to make additional uses of data collected in connection with any sweepstake, contest, or promotion, we will inform you at the time of entry. If we offer a co-branded promotion, then our co-sponsor may have different privacy practices, which we will inform you about before you provide your information.&nbsp;</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:windowtext;font-size:15px;color:windowtext;'>To use data analytics to improve our website, products or services, marketing, customer relationships, and experiences.</span></li>
        <li><span style="font-size:15px;color:windowtext;">To make suggestions and recommendations to you about our own and third-parties&rsquo; products or services that may be of interest to you. If you do not want us to use your information in this way or for more information on how to adjust your preferences, see <em>Your Rights and Choices.</em></span></li>
        <li><span style="font-size:15px;">To help maintain the safety, security, and integrity of our services, databases and other technology assets, and business.</span></li>
        <li><span style="font-size:15px;">To report to regulatory authorities as required by law. &nbsp;</span></li>
        <li><span style="font-size:15px;">To respond to law enforcement requests and other requests as required by applicable law, court order, or governmental regulations.</span></li>
        <li><span style="font-size:15px;">To protect and enforce our and others&rsquo; intellectual property rights; to protect or defend our legal rights or property, our services, or other users; and to protect the health and safety of our services&rsquo; users or the general public.</span></li>
        <li><span style="font-size:15px;">To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about consumers is among the assets transferred.</span></li>
        <li><span style="font-size:15px;">As described to you when collecting your personal information.&nbsp;</span></li>
        <li><span style="font-size:15px;">For any purpose with your consent.</span></li>
    </ul>
    <p><em><span style="line-height:107%;font-size:15px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;4. Who we have shared this information with for a business purpose</span></em></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:54.0pt;font-size:16px;font-family:"Times New Roman","serif";color:black;line-height:107%;'><span style="font-size:15px;line-height:107%;">For each of the above categories, we shared your personal information with the following categories of third parties:</span></p>
    <ul style="list-style-type: disc;margin-left:80px;">
        <li><span style="line-height:107%;font-size:15px;">Service providers and vendors&nbsp;</span></li>
        <li><span style="line-height:107%;font-size:15px;">Taxing entities</span></li>
        <li><span style="line-height:107%;font-size:15px;">Insurance companies</span></li>
        <li><span style="line-height:107%;font-size:15px;">Legal counsel</span></li>
        <li><span style="line-height:107%;font-size:15px;">Our franchisees who directly support our activities</span></li>
    </ul>
    <p><span style='font-family:"Times New Roman","serif";font-size:15px;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; VI. Your Rights and Choices</span><sup><span style='font-family:"Times New Roman","serif";font-size:15px;'>&nbsp;</span></sup></p>
    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</span></p>
    <ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="A">
        <li style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";color:black;'><strong><em><span style='font-family:"Times New Roman","serif";'>Access to Specific Information and Data Portability Rights</span></em></strong></li>
    </ol>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>You have the right to request that we disclose certain information to you about our collection, use, disclosure, and sale of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request (see&nbsp;</span><span style='font-family:"Times New Roman","serif";color:black;'><em>Exercising Access, Data Portability, and Deletion Rights</em></span><span style='font-family:"Times New Roman","serif";color:black;'>&nbsp;below), we will disclose to you:</span></p>
    <ul style="list-style-type: disc;">
        <li><span style='font-family:"Times New Roman","serif";color:black;'>The categories of personal information we collected about you.</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:black;'>The categories of sources for the personal information we collected about you.</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:black;'>Our business or commercial purpose for collecting or selling that personal information.<sup>&nbsp;</sup>The categories of third parties with whom we share that personal information.</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:black;'>The specific pieces of personal information we collected about you (also called a data portability request).</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:black;'>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</span>
            <ol style="list-style-type: circle;">
                <li><span style='font-family:"Times New Roman","serif";color:black;'>sales, identifying the personal information categories that each category of recipient purchased; and&nbsp;</span></li>
                <li><span style='font-family:"Times New Roman","serif";color:black;'>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.&nbsp;</span></li>
            </ol>
        </li>
    </ul>
    <ol start="2" style="margin-bottom:0cm;margin-top:0cm;" type="A">
        <li style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";color:black;'><strong><em><span style='font-family:"Times New Roman","serif";'>Deletion Request Rights&nbsp;</span></em></strong></li>
    </ol>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request (see&nbsp;</span><span style='font-family:"Times New Roman","serif";color:black;'><em>Exercising Access, Data Portability, and Deletion Rights</em></span><span style='font-family:"Times New Roman","serif";color:black;'>&nbsp;below), we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.&nbsp;</span></p>
    <ol start="3" style="margin-bottom:0cm;margin-top:0cm;" type="A">
        <li style='margin-top:6.0pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";color:black;'><strong><em><span style='font-family:"Times New Roman","serif";'>Exercising Access, Data Portability, and Deletion Rights</span></em></strong></li>
    </ol>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0cm;list-style-type: disc;">
            <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><em><span style='font-family:"Times New Roman","serif";color:black;'>How to submit requests</span></em></strong></li>
        </ul>
    </div>
    <p style="margin-bottom:.0001pt;text-align:justify;text-indent:36.0pt;line-height:normal;border:none;"><span style='font-family:"Times New Roman","serif";color:black;'>To exercise your access, data portability, and deletion rights described above, please submit a verifiable consumer request by contacting us via email at Korporate&#64;kona-ice.com or 859-371-3203</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>&nbsp;or web link below that corresponds to the appropriate brand:</span></p>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0cm;list-style-type: disc;">
            <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><em><span style='font-family:"Times New Roman","serif";color:black;'>Who may submit requests</span></em></strong></li>
        </ul>
    </div>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child. &nbsp;You may designate an authorized agent to make a request on your behalf by registering such person or entity with the California Secretary of State.&nbsp;</span></p>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0cm;list-style-type: disc;">
            <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><em><span style='font-family:"Times New Roman","serif";color:black;'>How often you can submit requests</span></em></strong></li>
        </ul>
    </div>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>You may only make a verifiable consumer request for access or data portability twice within a 12-month period.</span></p>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0cm;list-style-type: disc;">
            <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><em><span style='font-family:"Times New Roman","serif";color:black;'>How we verify and respond to requests</span></em></strong></li>
        </ul>
    </div>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>Your request must be verifiable. The request must:</span></p>
    <ul style="list-style-type: disc;margin-left:111.5px;">
        <li><span style='font-family:"Times New Roman","serif";color:black;'>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative, which may include requiring you to provide us at least two data points, depending on the type of personal information you are requesting to know or are requesting to be deleted. &nbsp;</span></li>
        <li><span style='font-family:"Times New Roman","serif";color:black;'>Describe your request with enough detail that allows us to properly understand, evaluate and respond to it.</span></li>
    </ul>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>We will only use personal information provided in a verifiable consumer request to verify the requestor&rsquo;s identity or authority to make the request.&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.</span></p>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
        <ul style="margin-bottom:0cm;list-style-type: disc;">
            <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong><em><span style='font-family:"Times New Roman","serif";color:black;'>Response Timing and Format</span></em></strong></li>
        </ul>
    </div>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time, we will inform you of the reason and extension period in writing within forty-five (45) days of receiving your verifiable consumer request. &nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>We will deliver our written response by mail or electronically, at your option.&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request&rsquo;s receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable.&nbsp;</span><span style='font-family:"Times New Roman","serif";color:black;'>&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</span></p>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
        <p><span style='font-family:"Times New Roman","serif";font-size:15px;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; VII. Non-Discrimination</span></p>
    </div>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>We will not discriminate against you for exercising any of your CCPA rights. You have a right not to receive discriminatory treatment by us for exercising your privacy rights.</span></p>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
        <p><span style='font-family:"Times New Roman","serif";font-size:15px;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; VIII. Other California Privacy Rights</span></p>
    </div>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>California&rsquo;s &ldquo;Shine the Light&rdquo; law (Civil Code Section &sect; 1798.83) permits users of our Websites that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. At this time, we do not engage in this type of disclosure.&nbsp;</span></p>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
        <p><span style='font-family:"Times New Roman","serif";font-size:15px;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; IX. Who to contact for more&nbsp;</span><span style='font-family:"Times New Roman","serif";font-size:15px;'>information?</span></p>
    </div>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>For questions or concerns about our privacy policy and practices, you can contact us at the method set forth below.</span></p>
    <p style="margin-bottom:.0001pt;text-align:justify;text-indent:36.0pt;line-height:normal;border:none;"><strong><span style='font-family:"Times New Roman","serif";color:black;'>Phone</span></strong><span style='font-family:"Times New Roman","serif";color:black;'>: 859-371-3203</span></p>
    <p style="margin-bottom:.0001pt;text-align:justify;text-indent:36.0pt;line-height:normal;border:none;"><strong><span style='font-family:"Times New Roman","serif";'>Email</span></strong><span style='font-family:"Times New Roman","serif";'>:&nbsp;</span><span style='font-family:"Times New Roman","serif";color:black;'>Korporate&#64;kona-ice.com</span><span style="font-size:15px;">&nbsp;</span></p>
    <p style="margin-bottom:6.0pt;text-indent:36.0pt;line-height:normal;"><strong><span style='font-family:"Times New Roman","serif";color:black;'>Postal Address</span></strong><span style='font-family:"Times New Roman","serif";color:black;'>:&nbsp;</span></p>
    <p style="margin-bottom:.0001pt;text-align:justify;text-indent:36.0pt;line-height:normal;border:none;"><span style='font-family:"Times New Roman","serif";color:black;'>KONA ICE, INC. &nbsp;&nbsp;</span></p>
    <p style="margin-bottom:.0001pt;text-align:justify;text-indent:36.0pt;line-height:normal;border:none;"><span style='font-family:"Times New Roman","serif";color:black;'>5945 Centennial Circle</span></p>
    <p style="margin-bottom:.0001pt;text-align:justify;text-indent:36.0pt;line-height:normal;border:none;"><span style='font-family:"Times New Roman","serif";color:black;'>Florence, KY 41042</span></p>
    <p style='margin-top:6.0pt;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";text-align:justify;border:none;'><span style='font-size:16px;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";text-indent:36.0pt;'><span style='font-family:"Times New Roman","serif";color:black;'>&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-family:"Times New Roman","serif";color:black;'>&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman","serif";'>&nbsp;</span></p>
    </div>
