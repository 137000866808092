import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

import { Component } from '@angular/core';
import { UserCommonService } from './modules/shared/services/user-common.service';
import { LoaderService } from './modules/shared/components/loader/loader.service';
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  metaLoaded = false;
  constructor(
    public userCommonService: UserCommonService,
    public router: Router,
    private loaderService: LoaderService) {
      this.initializeRouterEvents();
  }

  private initializeRouterEvents(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loaderService.show('page-center');
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loaderService.hide();
        if (event instanceof NavigationEnd && typeof gtag === 'function') {
          gtag('config', 'xx-xxxxx-xx', {
            'page_path': event.urlAfterRedirects
          });
        }
      }
    });
  }

}
