<div class="page-container">
    <div class="col-12 col-nopad">
        <app-bread-crumb [breadCrumb]="breadCrumbArray"></app-bread-crumb>
    </div>
    <div class="page-header">
        <div class="page-header-menu">
            <h1 class="heading">{{pageHeading}}</h1>
        </div>
        <div class="page-actions">
            <button pButton appAccessProvider accessCode="MANAGE_MENU" [readonly] = "true" [readWrite] = "true" class="small-btn" (click)="onSave()" type="button" [label]="'btn_save'|translate"></button>
            <button pButton class="small-btn cancel-btn" (click)="onCancel()" type="button"
                [label]="'btn_cancel'|translate"></button>
        </div>
    </div>

    <div class="page-body">
        <form [formGroup]="eventForm">
            <div class="form-container-column col-12 lg:col-6 p-r-30">
                <div class="grid">

                    <div class="col-6 lg:col-6 md:col-6">
                        <div class="form-field">
                            <div class="form-label">
                                <label for="categoryName" translate>label_menu_category_name<span
                                        class="required-star">*</span></label>
                            </div>
                            <div class="form-control">
                                <input formControlName="categoryName" id="categoryName" class="full-width" type="text"
                                    appNoWhiteSpace pInputText appPreventSomeSpecialCharacters allowQuote="no"/>
                            </div>
                            <div class="form-errors" *ngIf="eventForm.controls.categoryName.errors  && (eventForm.controls.categoryName.dirty || eventForm.controls.categoryName.touched ||
                               submitted)">
                                <div [hidden]="!eventForm.controls.categoryName.errors.required" translate>
                                    field_is_required
                                </div>
                                <div [hidden]="!eventForm.controls.categoryName.errors.minlength" translate>
                                    min_length_1char
                                </div>
                                <div [hidden]="!eventForm.controls.categoryName.errors.maxlength" translate>
                                    max_length_50char
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class=" col-6 lg:col-6 md:col-6">
                        <div class="form-field">
                            <div class="form-label">
                                <label for="categoryName" translate>label_brandName</label>
                            </div>
                            <div class="form-control">
                                <p-dropdown formControlName="brandId" [options]="brandNameOptions"
                                    (onChange)="onChangeBrand()"></p-dropdown>
                                    <!-- optionDisabled="inactive" -->
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
            

            <div class="form-container-column col-12 lg:col-12">
                <div class="form-item col-12 lg:col-12 md:col-12">
                    <div class="form-field">
                        <div class="form-label">
                            <label for="city" translate>Select Menu Items<span class="required-star"
                                    *ngIf="quantityRequired">*</span></label>
                        </div>
                        <div class="form-control pickList-Control">
                            <p-pickList [source]="list1" [target]="list2" sourceHeader="Available"
                                targetHeader="Selected" dragdrop="true" [responsive]="true"
                                [sourceStyle]="{'height':'400px'}" [targetStyle]="{'height':'400px'}"
                                filterBy="itemName" sourceFilterPlaceholder="Search" showTargetFilter="false"
                                [showSourceControls]="false" [showTargetControls]="false">
                                <ng-template let-eachItem pTemplate="item">
                                    <div class="product-item grid">
                                        <div class="col-nopad  col-2">
                                            <img class="picklist-img" [src]="eachItem.signUrl">
                                        </div>
                                        <div class="product-list-detail col-8 col-nopad">
                                            <div class="grid">
                                                <div class="col-12 col-nopad">
                                                    <h5 class="mb-2">{{eachItem.itemName}}</h5>
                                                </div>
                                                <div class="col-4 sub-items col-nopad"><span>Price</span>:
                                                    ${{eachItem.sellingPrice}}
                                                </div>

                                            </div>
                                        </div>
                                        <!-- <div class="product-list-detail col-2 col-nopad ">
                                            <div class="grid details-icon" title="Mange Food Extra" (click)="goToDetails(eachItem)">
                                            </div>
                                        </div> -->

                                    </div>
                                </ng-template>
                            </p-pickList>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<p-dialog [dismissableMask]="false" [modal]="true" [header]="'Menu Food Extra' | translate"
    [(visible)]="showDetailsPopup" styleClass="menu-foodextra-details form-container filter-container"
    dismissableMask="true" [closable]="true" [draggable]="false" blockScroll="true" [resizable]="true"
    [focusOnShow]="false" (onHide)="hidePopup()">

    <div class="page-container" *ngIf="showDetailsPopup && (files.length > 0)">
        <div class="page-body">
            <p-treeTable [value]="files" [columns]="cols" [columnResizeMode]="'expand'">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns; let i = index" ttEditableColumn
                            [ngClass]="{'p-toggler-column': i === 0}" [colspan]="i === 0?2:1">
                            <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                            <span *ngIf="i == 0">{{rowData[col.field]}}</span>
                            <span *ngIf="i == 0"><img [src]="rowData['signUrl']" style="width: 3em;" /></span>

                            <span *ngIf="i > 0">{{rowData[col.field]}}</span>
                            <!-- <p-treeTableCellEditor *ngIf="i > 0">
                            <ng-template pTemplate="input"> {{rowData[col.field]}}
                                <input pInputText type="text" (keyup) = "valueChanged()" [(ngModel)]="rowData[col.field]" >
                            </ng-template>
                            <ng-template pTemplate="output">{{rowData[col.field]}}</ng-template>
                        </p-treeTableCellEditor> -->
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="columns.length">
                            No records found
                        </td>
                    </tr>
                </ng-template>
            </p-treeTable>
        </div>
    </div>

    <div class="page-container" *ngIf="showDetailsPopup && (files.length == 0)">
        <div class="page-body">
            <h4>No Food Extra Attached to This Menu Item</h4>
        </div>
    </div>

</p-dialog>