<div class="page-container">
    <div class="page-header">
        <div class="page-heading">
            <h1 class="heading" translate>label_menu_items</h1>
        </div>
        <div class="page-actions">
            <button pButton appAccessProvider accessCode="MANAGE_MENU" [readonly] = "true" [readWrite] = "true" class="medium-btn" type="button" (click)="onAddNewClick()"
                [label]="'Add Item'|translate"></button>
        </div>
    </div>

    <div class="page-filters">
        <div class="filter-left-container grid">
            <div class="filter-item col-12 lg:col-2 md:col-3">
                <div class="form-control input-container">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input type="text" pInputText (keyup)="onSearch($event)" [(ngModel)]="search"
                            class="search-input prefix-pad" [placeholder]="'label_search'|translate"
                            appPreventSpecialCharacters pTooltip="{{'label_search_client_by_Item' | translate}}">
                    </span>
                </div>
            </div>
            <div class="filter-item col-6 lg:col-2 md:col-3 " *ngIf="isAdminLogin">
                <div class="form-control input-container " >
                  <p-multiSelect  #brandButton [options]="brandNameOptions" (onChange)="onSelectBrand($event)" [(ngModel)]="selectedBrandFromFilter"
                    [maxSelectedLabels]="1" [showHeader]="false" [filter]="false" selectedItemsLabel="{0} Brands selected" placeholder="Select Brand" >
                  </p-multiSelect>
                </div>
            </div>
        </div>
    </div>

    <div class="page-body">
        <app-mi-grid-v2 [url]="gridurl" [columnDefs]="columns" [isExportVisible]="false"
            (actionBtnClickedEvent)="onActionBtnClicked($event)" [actionButtonsDefs]="buttons"
            divClass="data-grid-lines" [searchBar]="searchBar" [uniqueId] = "uniqueId" 
            [fromCancelOffset]="saveFromCancelOffset" [isInputBrandId] = "isInputBrandId">
        </app-mi-grid-v2>

    </div>
</div>

<div *ngIf="showAddMenu">
    <app-editable-menu-item (reloadMenuList)="onReloadMenuList()" (onCancelAddNew)="onCancelAddNew()"
    [itemDetails] = "itemDetails" (onHideAddNew)="hideNewAddPopup($event)" >
    </app-editable-menu-item>
</div>