<div class="page-container">
    <div class="page-header">
        <div class="page-heading">
            <h1 class="heading" translate>{{pageHeading}}</h1>
        </div>
        <div class="page-actions" >
            <button pButton class="small-btn" [disabled]="!pageForm.valid" (click)="onSave()" type="button"
                [label]="'btn_save'|translate"></button>
            <button pButton class="small-btn cancel-btn" (click)="onCancel()" type="button"
                [label]="'btn_cancel'|translate"></button>
        </div>
    </div>

    <div class="page-body" *ngIf="pageForm">
        <form [formGroup]="pageForm">
            <div class="form-container col-12 lg:col-8">
                <div class="form-item col-12 lg:col-5 md:col-5">
                    <div class="form-field">
                        <div class="form-control position-rel">
                            <img src="{{profileUrl}}" alt="" class="image-upload" style="width: 50%;"><span class="required-star">*</span>
                            <div class='large-btn display-image profile-btn'>
                                <app-file-uploader (afterFileUploadEvent)="onUploadProfileImage($event)"
                                    [uploadButtonLabel]="chooseLabel" accept=".jpg, .jpeg, .png"></app-file-uploader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container col-12 lg:col-8">

                <div class="form-item col-12 lg:col-5 md:col-5">
                    <div class="form-field">
                        <div class="form-label">
                            <label for="menuTitle" translate>label_intranet_app_display_name<span class="required-star">*</span>
                            </label>
                        </div>
                        <div class="form-control">
                            <input formControlName="menuTitle" class="full-width" type="text" pInputText appPreventSomeSpecialCharacters allowQuote="no"/>
                        </div>
                        <div class="form-errors"
                            *ngIf="pageForm.controls.menuTitle.errors  && (pageForm.controls.menuTitle.dirty || pageForm.controls.menuTitle.touched)">
                            <div [hidden]="!pageForm.controls.menuTitle.errors.required" translate>
                                field_is_required
                            </div>
                            <div [hidden]="!pageForm.controls.menuTitle.errors.minlength" translate>
                                min_length_1char
                            </div>
                            <div [hidden]="!pageForm.controls.menuTitle.errors.maxlength" translate>
                                max_length_30char
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-item col-12 lg:col-5 md:col-5">
                    <div class="form-field">
                        <div class="form-label">
                            <label for="targetUrl" translate>label_intranet_app_link<span class="required-star">*</span> </label>
                        </div>
                        <div class="form-control">
                            <input formControlName="targetUrl" class="full-width" type="text" pInputText />
                        </div>
                        <div class="form-errors"
                            *ngIf="pageForm.controls.targetUrl.errors  && (pageForm.controls.targetUrl.dirty || pageForm.controls.targetUrl.touched)">
                            <div [hidden]="!pageForm.controls.targetUrl.errors.required" translate>
                                field_is_required
                            </div>
                            <div [hidden]="!pageForm.controls.targetUrl.errors.minlength" translate>
                                min_length_1char
                            </div>
                            <div [hidden]="!pageForm.controls.targetUrl.errors.maxlength" translate>
                                max_length_100char
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>

</div>