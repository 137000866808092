import { Component, OnInit } from '@angular/core';
import { FranchiseService } from '../../../../franchise/services/franchise-service.service';
import { StorageService } from '../../../services/storage.service';
import { AppSettings } from '../../../app.settings';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html'
})
export class EmailTemplatesComponent implements OnInit {

  franchiseId: string;
  templates: any;

  constructor(
    private franchiseService: FranchiseService,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.getEmailTemplates();
  }

  getEmailTemplates() {
    const loginData = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA));
    this.franchiseId = loginData?.franchiseId;

    this.franchiseService.getEmailTemplate(this.franchiseId).subscribe((result) => {
      this.templates = result;
    }, (error) => {
      console.log(error);
    });
  }

  getHeaderName(name) {
    let n = name.replace(/_/g, ' ');
    n = n.charAt(0).toUpperCase() + n.toLowerCase().slice(1);
    return n;
  }

  updateTemplate() {
    this.getEmailTemplates();
  }

}
