import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonBindingDataService } from '../../services/common-binding-data.service';

@Component({
  selector: 'app-assign-staff-list',
  templateUrl: './assign-staff-list.component.html',
  styleUrls: ['./assign-staff-list.component.scss']
})
export class AssignStaffListComponent implements OnInit, OnChanges {

  @Input() allStaffInputData;
  @Input() selectedStaffList;
  @Output() assignEvent = new EventEmitter<any>();
  staffCheckBoxList = [];
  displayList = false;
  constructor(private commonService: CommonBindingDataService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.createCheckBoxList();
    }, 500);
  }

  ngOnChanges() {
    if (this.allStaffInputData && this.allStaffInputData) {
      this.createCheckBoxList();
    }
  }

  createCheckBoxList() {
    this.staffCheckBoxList = [];
    this.allStaffInputData.forEach(element => {
      if (this.selectedStaffList && this.selectedStaffList.includes(element.userId)) {
        this.staffCheckBoxList.push({id: element.userId, value:true,
          name: element.firstName+" "+element.lastName,
          disabled: this.checkForDisable(element,true)});
      }else{
        this.staffCheckBoxList.push({id: element.userId, value:false,
          name: element.firstName+" "+element.lastName,
          disabled: this.checkForDisable(element)});
      }
    });
    if (this.selectedStaffList) {
      // console.log(this.selectedStaffList);
    }
  }

  checkForDisable(eachStaff,amongSelectedStaffList?) {
    let disable =  false;
    if(eachStaff.notAvailableForAssign){ // not available
      disable =  true;
      if(amongSelectedStaffList){ // previously selected
        disable = true; // #77 issue disable checkbox if staff disabled.
      }
    }
    return disable;
  }

  onassignClick() {
    // console.log(this.staffCheckBoxList);
    let finalStaffList = [];
    finalStaffList = this.staffCheckBoxList.filter(eachStaff => eachStaff.value == true);
    // console.log(finalStaffList)
    this.assignEvent.emit(finalStaffList);
  }

}
