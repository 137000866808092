<div class="content content-wrapper">
    <!-- <h1>Printable Content</h1> -->
    <table>
        <tbody>
            <tr>
                <td>
                    <div class="container">
                        <div class="content-inner">
                            <div class="mobiscroll-container week-view-print" id="mobiscroll-container">
                                <button pButton id="print-weekly-schedule" (click)="print()" class="small-btn print-button" label=""
                                    type="button"><i class="fa fa-print" style="display: none;"></i></button>
                                <mbsc-eventcalendar #myCal [modules]="myModules" [view]="weekView" [data]="weekEvents"
                                    [resources]="weekViewResources" [resourceTemplate]="resourceTemp"
                                    [headerTemplate]="customTemplate" [options]="calendarOptions"
                                    [(selectedDate)]="displayDateForDayView" [scheduleEventTemplate]="eventTemplate">

                                    <ng-template #customTemplate>
                                        <div class="md-custom-header-controls">
                                            <span class="custom-date">{{startDate | date:'dd MMM yyyy'}} - {{endDate |
                                                date:'dd MMM
                                                yyyy'}}</span>
                                        </div>
                                    </ng-template>

                                    <!--------------------------------------------------------------------------------------------->
                                    <ng-template #resourceTemp let-resource let-event>
                                        <div class="header-resource-template-content header-wrapper"
                                            style="padding:4px;">

                                            <div class="header-resource-name staff-cell" *ngIf="checkedCondition">
                                                {{resource.name | titlecase}}
                                            </div>

                                            <div class="header-resource-name staff-cell grid header-wrapper-cancel"
                                                *ngIf="!checkedCondition" style="min-width: 8em;">
                                                <div class="col-9" style="padding: 0; padding-bottom: 2px;">
                                                    <div class="user-name-print" style="padding-bottom: 2px;"
                                                        [title]="resource.name">
                                                        {{resource.name | titlecase}}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </ng-template>


                                    <ng-template #eventTemplate let-data>
                                        <div *ngIf="data?.original?.default" class="grid default-cell">
                                            <span class="col-2 img-div upper-space"
                                                *ngIf="data.original?.title == 'Available All Day'">
                                                <img src="./assets/images/staff/check_icon.png" class="avail-icon"
                                                    alt="">
                                            </span>

                                            <div class="col-2 img-div upper-space"
                                                *ngIf="data?.original?.available && !data?.original?.allDay">
                                                <img src="./assets/images/staff/partial_day_icon.png" class="avail-icon"
                                                    alt="">
                                            </div>
                                            <div class="col-2 img-div upper-space"
                                                *ngIf="!data?.original?.available && data?.original?.allDay">
                                                <img src="./assets/images/staff/unavailable_all_day_icon.png"
                                                    class="avail-icon" alt="">
                                            </div>
                                            <div class="col-2 img-div upper-space"
                                                *ngIf="!data?.original?.available && !data?.original?.allDay">
                                                <img src="./assets/images/staff/unavailable_all_day_icon.png"
                                                    class="avail-icon" alt="">
                                            </div>

                                            <div class="col-10 upper-space">
                                                <div>
                                                    <label class="cell-text"
                                                        [ngClass]="data.original?.title == 'Approved time-off' ? 'approvedTimeOff':''"
                                                        [title]="data.original?.title"
                                                        style="cursor: pointer;">{{data.original?.title}}</label>
                                                </div>
                                                <div>
                                                    <label *ngIf="!data.original.allDay" style="font-size: 9px;">
                                                        {{data.original?.partialTime}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="data?.original?.defaultSchedule"
                                            class="md-custom-event-cont card-padding"
                                            [ngStyle]="{background: data.color}" style="padding: 0%; display: block;">
                                            <div class="md-custom-event-wrapper" style="display: block;">

                                                <div class="cell-content"
                                                    [ngStyle]=" {'color': getBackgroundColorForDayView(data)}">

                                                    <div
                                                        style="display: flex;align-items: center;text-align: center;justify-content: center;">
                                                        <div class="cell-title">{{data.title}}
                                                            <div>({{data?.original?.shiftTime}})</div>
                                                        </div>
                                                        <div class="cell-icons" style="padding-right: 2px;"
                                                            *ngIf="data?.original?.description?.notify || data?.original?.description?.isShiftViewd">
                                                            <i class="fa fa-fw fa-bell-o" aria-hidden="true"
                                                                *ngIf="data?.original?.description?.notify"></i>
                                                            <i class="fa fa-fw fa-thumbs-o-up" aria-hidden="true"
                                                                *ngIf="data?.original?.description?.isShiftViewd"></i>
                                                        </div>
                                                    </div>

                                                    <div class="cell-icons"
                                                        *ngIf="data?.original?.description == 'availability'">
                                                        <i class="fa fa-fw fa-check-square" aria-hidden="true"></i>
                                                    </div>

                                                    <div *ngFor="let item of data?.original?.eventNames">
                                                        <div class="event-div">
                                                            <div class="fa fa-circle circle-class"></div>
                                                            <div class="event-text"> {{item}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="event-div"
                                                        *ngFor="let item of data?.original?.taskNames">
                                                        <div class="fa fa-check circle-class"></div>
                                                        <div class="event-text"> {{item}}</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>

                                </mbsc-eventcalendar>
                            </div>
                        </div>
                    </div>

                </td>
            </tr>
        </tbody>
    </table>
</div>