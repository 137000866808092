<div #dataTable class="grid mi-grid-component" (window:resize)="onResize($event)" [class]="divClass">

  <div class="search-and-export-data-panel col-12 grid" *ngIf="isExportVisible">
    <div class="export-button">
      <span class="header-action-btn export" *ngIf="(exportButton === 'top' && totalRecords > 0)" (click)="exportGridExcel($event)"
        title="Download Excel">
        Export <i class="fa fa-fw fa-file-excel-o" aria-hidden="true"></i>
      </span>
    </div>
  </div>


  <p-table  #dt  [resizableColumns]="false" [lazy]="true" [rows]="paginationPageSize" [value]="datasource" [paginator]="pagination"
    [totalRecords]="totalRecords"  (onLazyLoad)="loadDatasourceLazy($event)" [selectionMode]="rowSelection"
    (onRowSelect)="onRowSelect($event)" [sortField]="sortF" [sortOrder]="sortOrderVal" (onSort)="onSort()" (onRowUnselect)="onRowUnselect($event)" [(selection)]="selectedRecords"
    class="col-12 " [responsive]="false" [scrollable]="totalRecords === 0?false:true"  styleClass="p-datatable-gridlines">
    <!-- [rowsPerPageOptions]="[10,20,30]" -->

    <!-- <ng-template pTemplate="colgroup" let-columns>
      <colgroup *ngIf="totalRecords > 0">
          <col *ngFor="let col of columnDefs" [ngStyle] = "{'width': col.width? col.width:'100px'}">
      </colgroup>
      <colgroup *ngIf="actionButtonsDefs.length && totalRecords > 0 ">
        <col class = "action-width">
      </colgroup>
    </ng-template> -->

    <ng-template pTemplate="header">
      <tr *ngIf="totalRecords > 0">

        <ng-container *ngFor="let col of columnDefs">
          <td style="width:40px" *ngIf="(col.isCheckBox && col.header === '')" translate>
            <p-checkbox binary="true" (onChange)="onSelectionAll($event)" [(ngModel)]="allSelectFlag" >
            </p-checkbox>
          </td>
          <td  *ngIf="(col.isCheckBox && col.header)"
          [ngStyle] = "{'width': col.width? col.width:'50px'}" pResizableColumn translate>
            {{col.header}}
        </td>
          <td *ngIf = "!col.isCheckBox"
          [ngStyle] = "{'width': col.width? col.width:'110px'}" pResizableColumn  [pSortableColumn]="(col?.sort) ? col.field: ''" [ngClass]="(col?.center) ? 'text-center': ''">
            {{col.header}}
            <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
      </td>

          <td *ngIf="!col.resize && !col.isCheckBox"
          [ngStyle] = "{'width': col.width? col.width:'130px'}" pResizableColumn [pSortableColumn]="(col?.sort) ? col.field: ''">
            {{col.header}}
            <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
    </td>
        </ng-container>
        <td style="width: 150px;" *ngIf="actionButtonsDefs.length"  translate>grid_actions</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <!-- <tr class="" [attr.colspan]="columnDefs.length" *ngIf="totalRecords == 0">
        <td>
          <div class="no-records" *ngIf="totalRecords == 0">
            <div class="center-div" translate> lbl_no_records_found </div>
          </div>
        </td>
      </tr> -->
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr class="" [attr.colspan]="columnDefs.length" *ngIf="totalRecords == 0 || totalRecords === null">
        <td>
          <div class="no-records" *ngIf="totalRecords == 0">
            <div class="center-div"> {{labelNoRecordFound}} </div>
          </div>
        </td>
      </tr>
    </ng-template>


    <ng-template pTemplate="body" let-rowData let-columns="columnDefs" let-rowIndex="rowIndex">
     <!-- for grouping header -->
      <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" class="ui-widget-header" [class]="rowIndex%2==0?'even-row':'odd-row'"  *ngIf="rowGroupMetadata[rowData.eventDate].index === rowIndex && isGrouping">
        <td colspan="4" class="rowHeaderTd">
            <span class="rowHeader">{{rowData.eventDate}} </span>
        </td>
      </tr>


      <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [class]="rowIndex%2==0?'even-row':'odd-row'" >
        <!-- <td *ngIf="rowGroupMetadata[rowData.eventDate].index === rowIndex" [attr.colspan]="rowGroupMetadata[rowData.eventDate].size">
          {{rowData.eventDate | date}}
        </td> -->
        <td *ngFor="let col of columnDefs" [class]="col.class" [ngStyle] = "{'width': col.width? col.width:'110px'}">
          <!-- {{rowData[col.field]}} -->

          <!-- <div class="ui-column-title" [pSortableColumn]="(col?.sort) ? col.field: ''">{{col.header}}
            <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
          </div> -->
          <div *ngIf="!col?.isDropDown && !col?.isCheckBox && !col?.isBackgroundColor && !col?.isEquipment && col?.isStaff"  title="{{rowData[col.field]}}" [ngClass]="[col?.isStaff ? getStaffColorBgClass(rowData): '']" [ngStyle]="{ 'background-color': getStaffColorBgColor(rowData) }" innerHTML="{{rowData[col.field]}}" (click)="toggleMenu()"></div>

          <div *ngIf="!col?.isDropDown && !col?.isCheckBox && !col?.isBackgroundColor && !col?.isEquipment  && !col?.isStaff && col?.isMobileStaff" [ngClass]="[col?.isMobileStaff ? getMobileStaffColorBgClass(rowData): '']" [ngStyle]="{ 'background-color': getMobileStaffColorBgColor(rowData) }">
            <div *ngFor="let staffnames of rowData.staffNamesList; let i = index">
              <div class="row-item p-l-r-10">
                <div class="staff" style="padding: 0;" innerHTML="{{staffnames}}"></div>
              </div>
            </div>
          </div>

          <div *ngIf="!col?.isDropDown && !col?.isCheckBox && !col?.isBackgroundColor && !col?.isEquipment  && !col?.isStaff && !col?.isMobileStaff && !col.isBrand && col?.isShiftList">
            <div *ngFor="let shift of rowData.staffScheduleList; let i = index">
              <div class="row-item p-l-r-10">
                <!-- <div class="staff" style="padding: 0;">{{commonBindingDataService.getUTCTimeWithLocalTime(shift.startDateTime)}} - {{commonBindingDataService.getUTCTimeWithLocalTime(shift.endDateTime)}}</div> -->
                <div class="staff" style="padding: 0;">{{commonBindingDataService.convertedTimeAsPerTz1(shift.startDateTime) | date: "hh:mm a"}} -
                 {{commonBindingDataService.convertedTimeAsPerTz1(shift.endDateTime) | date: "hh:mm a"}}
                 </div>
              </div>
            </div>
          </div>

          <div *ngIf="!col?.isDropDown && !col?.isCheckBox && !col?.isBackgroundColor && !col?.isEquipment  && !col?.isStaff && !col?.isMobileStaff && col.isBrand">
            <div *ngFor="let brands of rowData.staffBrandList; let i = index">
              <div class="row-item p-l-r-10">
                <div style="padding: 0;">{{brands.brandName}}</div>
              </div>
            </div>
          </div>

          <div *ngIf="!col?.isDropDown && !col?.isCheckBox && !col?.isBackgroundColor && !col?.isEquipment && !col?.isStaff && !col?.isLimit && !col?.isMobileEquipment && !col?.isMobileStaff"  title="" innerHTML="{{rowData[col.field]}}" (click)="toggleMenu()" (click)="actionBtnClick(rowData)"></div>

          <!-- <div *ngIf="!col?.isDropDown && !col?.isCheckBox && !col?.isBackgroundColor && col?.isEquipment && !col?.isStaff" [ngClass]="[col?.isEquipment ? getEquipmentColorBgClass(rowData): '']" [ngStyle]="{ 'background-color': getEquipmentColorBgColor(rowData) }">
            <div *ngFor="let names of rowData.assetNamesList; let i = index">
              <div class="row-item p-l-r-10">
                <div [ngStyle]="{'background-color': rowData.assetColorsList[i]=== 'NOCOLOR' ? '#707070' : rowData.assetColorsList[i]}" class= "equipment-circle"></div><div class="staff" style="padding: 0;" innerHTML="{{names}}"></div>
              </div>
            </div>
          </div> -->

          <div *ngIf="!col?.isDropDown && !col?.isCheckBox && !col?.isBackgroundColor && !col?.isEquipment && !col?.isStaff && !col?.isLimit && col?.isMobileEquipment" [ngClass]="[col?.isMobileEquipment ? getMobileEquipmentColorBgClass(rowData): '']" [ngStyle]="{ 'background-color': getMobileEquipmentColorBgColor(rowData) }">
            <div *ngFor="let names of rowData.assetNamesList; let i = index">
              <div class="row-item p-l-r-10">
                <div [ngStyle]="{'background-color': rowData.assetColorsList[i]=== 'NOCOLOR' ? '#707070' : rowData.assetColorsList[i]}" class= "equipment-circle"></div><div class="staff" style="padding: 0;" innerHTML="{{names}}"></div>
              </div>
            </div>
          </div>

          <div *ngIf="!col?.isDropDown && !col?.isCheckBox && !col?.isBackgroundColor && !col?.isEquipment && !col?.isStaff && !col?.isLimit && col?.isEventEquipment">
            <div *ngFor="let names of rowData.staffScheduleEventGrid; let i = index">
              <div class="row-item p-l-r-10">
                <div [ngStyle]="{'background-color': names.staffScheduleEventAssetGrid?.length > 1 ? '#707070' : names.staffScheduleEventAssetGrid[0]?.assetColor}" [ngClass]="names.staffScheduleEventAssetGrid?.length > 0 ? 'noBorder': ''" class= "equipment-circle"></div><div class="staff" style="padding: 0;">{{names.eventName}}</div>
              </div>
            </div>
            <div *ngFor="let names of rowData.staffScheduleTaskGrid; let i = index">
              <div class="row-item p-l-r-10">
                <div class="staff" style="padding: 3px 3px;font-size:13px"><strong>T </strong><span>{{names.taskName}}</span></div>
              </div>
            </div>
          </div>

          <!-- <p-menu #menu [model]="items" *ngIf="menuModel"></p-menu> -->
          <!-- Menu on click of edit,hold, delete -->
          <div *ngIf="col?.isBackgroundColor" title="{{rowData[col.field]}}"
          [ngStyle] = "{'background-color': rowData[col.field],'height': '20px','width': '20px','border-radius':'15px'}">
          </div>
          <div *ngIf="col?.isDropDown" class="grid-dropdown-section filter-drop-down width-100per"
            [ngClass]="[col?.condColorClass ? getDropdownColor(rowData): '']">
            <p-dropdown [options]="col.options" appendTo="body" [disabled]="col.disabled? true: false"
              [placeholder]="'label_select' | translate" [(ngModel)]="rowData[col.field]" [style]="{'width':'100%'}"
              data-action-type="DROP-DOWN" (onChange)="onDropDownChange($event, rowData, col?.type)">
            </p-dropdown>
            <br />
          </div>
          <div *ngIf="col?.isCheckBox && !col.header && !col.isShowCheckBox">
            <p-checkbox name="groupname" [value]="rowData[col.field]" [(ngModel)]="selectedValues" (onChange)="onChangeSelection()"> </p-checkbox>
          <br />
        </div>
         <div *ngIf="col?.isCheckBox && !col.header && col.isShowCheckBox">
          <p-checkbox name="groupname" [ngStyle]="{ 'visibility': showCheckBox(rowData) }" [value]="rowData[col.field]" [(ngModel)]="selectedValues" (onChange)="onChangeSelection()"> </p-checkbox>
          <br />
        </div>
        <div *ngIf="(col.isCheckBox && col.bgColorClass && col.isBook)">
          <div class="statusbox" [ngStyle]="{ 'background-color': getNotificationColor(rowData) }">
            <p-checkbox name="emailstatus" [binary]="true" [readonly]="true" [value]="rowData[col.field]" [(ngModel)]="rowData[col.field]" class="bookEmailstatus"> </p-checkbox>
          </div>
        </div>
        <div *ngIf="(col.isCheckBox && col.bgColorClass && col.isConf)">
          <div class="statusbox" [ngStyle]="{ 'background-color': getNotificationColor1(rowData) }">
            <p-checkbox name="emailstatus" [binary]="true" [readonly]="true" [value]="rowData[col.field]" [(ngModel)]="rowData[col.field]" class="confirmEmailstatus"> </p-checkbox>
          </div>
        </div>
        <div *ngIf="(col.isCheckBox && col.bgColorClass && col.isThank)">
          <div class="statusbox" [ngStyle]="{ 'background-color': getNotificationColor2(rowData) }">
            <p-checkbox name="emailstatus" [binary]="true" [readonly]="true" [value]="rowData[col.field]" [(ngModel)]="rowData[col.field]" class="tyEmailstatus"> </p-checkbox>
          </div>
        </div>
        <div *ngIf="(col.isCheckBox && col.bgColorClass && col.isCompleted)">
          <div class="statusbox" [ngStyle]="{ 'background-color': getNotificationColor5(rowData) }">
            <p-checkbox name="emailstatus" [binary]="true" [readonly]="true" [value]="rowData[col.field]" [(ngModel)]="rowData[col.field]" > </p-checkbox>
          </div>
        </div>
        <div *ngIf="(col.isCheckBox && col.bgColorClass && col.isInvoice)">
          <div class="statusbox" [ngStyle]="{ 'background-color': getNotificationColor3(rowData) }">
            <p-checkbox name="emailstatus" [binary]="true" [readonly]="true" [value]="rowData[col.field]" [(ngModel)]="rowData[col.field]" > </p-checkbox>
          </div>
        </div>
        <div *ngIf="(col.isCheckBox && col.bgColorClass && col.isGive)">
          <div class="statusbox" [ngStyle]="{ 'background-color': getNotificationColor4(rowData) }">
            <p-checkbox name="emailstatus" [binary]="true" [readonly]="true" [value]="rowData[col.field]" [(ngModel)]="rowData[col.field]" > </p-checkbox>
          </div>
        </div>
        </td>

        <td *ngIf="actionButtonsDefs.length" class="action-icons1">
          <!-- <span class="ui-column-title" translate>grid_actions</span> -->
          <!-- (click)="actionBtnClick($event, rowData)" -->
          <div class="action-icon1">
            <div class="grid-menu1" appClickOutside (clickOutside)="close($event)" style="display: flex;">
              <div *ngFor="let btn of actionButtonsDefs" class="grid-menu-item">
                <div *ngIf = "rowData[btn.field] !== ''" innerHTML="{{rowData[btn.field]}}" (click)="actionBtnClick(rowData, btn.class)" [title]="btn.label"
                  ></div> <!-- [label]="btn.label" -->
                <div *ngIf = "rowData[btn.field] == undefined" (click)="actionBtnClick(rowData, btn.class)" [title]="btn.label" [class]="btn.class"
                  ></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer" >
      <tr *ngIf="totalRecords !== 0">
          <th *ngFor="let col of footerTotal" id="showFooter"><div class="footer-info">{{col}}</div></th>
      </tr>
    </ng-template>

  </p-table>
  <!-- <p-paginator [rows]="paginationPageSize" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"></p-paginator> -->
  <!-- <div class="entries-record entries-summery" *ngIf="paginationRecord">
    <span class="label"
      *ngIf="paginationRecord.totalEntries">{{'lbl_showing_entries' | translate: paginationRecord}}</span>
  </div> -->

  <p-overlayPanel #op3>
    <ng-template pTemplate>
      <div class = "grid">
        <div class = "col-12 pointer">Occurrence</div>
        <div class = "col-12 pointer">Series</div>
      </div>
    </ng-template>
  </p-overlayPanel>
</div>
