import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { UserCommonService } from '../../../shared/services/user-common.service';
import { AppSettings } from '../../../shared/app.settings';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-week-component',
  templateUrl: './week.component.html',
  styleUrls: ['./week.component.scss']
})
export class WeekComponent implements OnInit {
  @Output() weekChangeEvent = new EventEmitter<any>();
  @Output() dateClickedEvent = new EventEmitter<any>();
  @Input() eventSchedule;
  @Input() weekSchedules;
  @Input() daysUtilizedList;
  @Input() showWeekChange = true;
  @Input () fromCancelDate;
  // currentDate = new Date().getTime();
  firstDay;
  lastDay;
  weekArray: any = [];
  scheduleList: any[];
  openShiftSchedules: any;
  selectedSchedule: any;
  clickedEventDate: Date;
  staffAssetId;
  today: any = new Date();
  eventWithoutScheduleArray: any = [];
  startDayOfWeek = this.userCommonService.getUpdatedStoredValue(AppSettings.START_DAY_OF_WEEK_FOR_MONTH);
  curr: Date;

  constructor(
    private franchiseService: FranchiseService,
    private commonService: CommonBindingDataService,
    private userCommonService: UserCommonService,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
    // console.log(this.daysUtilizedList)
    this.today = this.commonService.toLocalTime(this.commonService.startOfDayToGMT(this.today));
    this.getCurrentWeek();
    this.allDates(this.firstDay);
    const obj = { firstDay: this.firstDay, lastDay: this.lastDay };
    this.weekChangeEvent.emit(obj);
  }

  ngOnChanges() {
    if (this.daysUtilizedList) {
      // console.log(this.daysUtilizedList);
      this.allDates(this.firstDay);
    }
  }

  getCurrentWeek() {
    this.fromCancelDate =  JSON.parse(this.storageService.getItem('fromCancelDate'));

    if (this.fromCancelDate == null || this.fromCancelDate == undefined){
      this.curr = new Date(); // get current date
    }else {
      this.curr = new Date(this.fromCancelDate); // from cancle saved date
    }

    if (this.curr.getDay() === 0 && this.startDayOfWeek === 1) {
      this.firstDay = new Date(this.curr.setDate((this.curr.getDate() - this.curr.getDay()) - 6));
      this.lastDay = new Date(this.curr.setDate((this.curr.getDate()) + 6));
    }else if (this.curr.getDay() !== 0 && this.startDayOfWeek === 1){
      this.firstDay = new Date(this.curr.setDate((this.curr.getDate() - this.curr.getDay())+ this.startDayOfWeek));
      this.lastDay = new Date(this.curr.setDate((this.curr.getDate() - this.curr.getDay()) + 6 + this.startDayOfWeek));
    }else{
      this.firstDay = new Date(this.curr.setDate((this.curr.getDate() - this.curr.getDay())));
      this.lastDay = new Date(this.curr.setDate((this.curr.getDate() - this.curr.getDay()) + 6));
    }

    // const first = this.curr.getDate() - this.curr.getDay() + this.startDayOfWeek;
    // const last = first + 6; // last day is the first day + 6
    // this.firstDay = new Date(new Date().setDate(first));
    // this.lastDay = new Date(new Date().setDate(last));
  }

  prevWeek() {
    this.lastDay = new Date(moment(this.firstDay).subtract(1, 'days').valueOf());
    this.firstDay = new Date(moment(this.lastDay).subtract(6, 'days').valueOf());
    this.allDates(this.firstDay);
    const obj = { firstDay: this.firstDay, lastDay: this.lastDay };
    this.weekChangeEvent.emit(obj);

  }

  nextWeek() {
    this.firstDay = new Date(moment(this.lastDay).add(1, 'days').valueOf());
    this.lastDay = new Date(moment(this.lastDay).add(7, 'days').valueOf());
    this.allDates(this.firstDay);
    const obj = { firstDay: this.firstDay, lastDay: this.lastDay };
    this.weekChangeEvent.emit(obj);
  }

  getCurrentWeekSchedule() { // for today button
    this.storageService.removeItem('fromCancelDate');
    this.getCurrentWeek();
    this.allDates(this.firstDay);
    const obj = { firstDay: this.firstDay, lastDay: this.lastDay };
    this.weekChangeEvent.emit(obj);
  }

  allDates(start) {
    this.weekArray = [];
    const curr = new Date;
    for (let i = 0; i <= 6; i++) {
      const tempDate = {};
      tempDate['startOfDate'] = (new Date(moment(start).add(i, 'days').valueOf()));
      tempDate['available'] = this.checkAvailable((new Date(moment(start).add(i, 'days').valueOf())).getTime());
      tempDate['selected'] = this.todaysDate((new Date(moment(start).add(i, 'days').valueOf())).getTime())
      this.weekArray.push(tempDate);
      // this.weekArray.push(this.gateDateString(tempDate.getTime()));
    }
    // console.log(this.weekArray)
  }

  checkAvailable(date) {
    let match = false;
    this.daysUtilizedList.forEach(element => {
      let convertTzDateTime = this.commonService.convertedTimeAsPerTz1(element.startDate,'Date');
      if (new Date(date).setHours(0, 0, 0, 0).valueOf() === convertTzDateTime.setHours(0, 0, 0, 0).valueOf()) {
        match = true
      }
    });
    return match;
  }

  todaysDate(date) {
    // console.log(new Date(date).setHours(0, 0, 0, 0).valueOf(), new Date().setHours(0, 0, 0, 0).valueOf());
    return (new Date(date).setHours(0, 0, 0, 0).valueOf() === new Date().setHours(0, 0, 0, 0).valueOf());
  }

  clickOnDate(dateObject, index) {
    for (let dateIndex = 0; dateIndex < this.weekArray.length; dateIndex++) {
      if (dateObject['startOfDate'] === this.weekArray[dateIndex]['startOfDate']) {
        this.weekArray[dateIndex]['selected'] = true;
      } else {
        this.weekArray[dateIndex]['selected'] = false;
      }
    }
    this.dateClickedEvent.emit(index);
  }

}
