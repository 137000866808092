import { MiDynamicFieldDirective } from './mi-components/mi-dynamic-field/mi-dynamic-field.directive';
import { NavtabComponent } from './components/navtab/navtab.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
  TranslateDirective,
  MissingTranslationHandler,
} from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FranchiseService } from '../franchise/services/franchise-service.service';

import { MiGridComponent } from './components/mi-grid/mi-grid.component';
import { GridService } from './components/mi-grid/grid.service';
import { UiService } from './services/ui.service';
import { RestApiService } from './services/rest-api.service';
import { UserCommonService } from './services/user-common.service';
import { StorageService } from './services/storage.service';
import { CommonMessageTransferService } from './services/common-message-transfer.service';
import { CommonBindingDataService } from './services/common-binding-data.service';
import { SidebarService } from './services/sidebar.service';
import { AuthenticationValidationService } from './services/authentication-validation.service';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { MiTranslateLoaderService } from './services/mi-translate-loader.service';
import { MiMissingTranslationHandlerService } from './services/mi-missing-translation-handler.service';
import { AssignSearchComponent } from './components/assign-search/assign-search.component';
import { LoaderService } from './components/loader/loader.service';
import { LoaderComponent } from './components/loader/loader.component';
import { FileUploaderComponent } from './components/file-uploader/file.uploader.component';
import { AgoPipe } from './pipes/ago.pipe';
import { DateFormatPipe } from './pipes/dateformat.pipe';
import { DateTimeFormatPipe } from './pipes/datetimeformat.pipe';
import { MinutsFormatPipe } from './pipes/minutesformat.pipe';
import { SearchLocationDirective } from './directives/search-location.directive';
import { DisableControlDirective } from './directives/input-disabled.directive';
import { LayoutComponent } from './components/layout/layout.component';
import { MiInputComponent } from './mi-components/mi-input/mi-input.component';
import { MiDynamicFormComponent } from './mi-components/mi-dynamic-form/mi-dynamic-form.component';
import { MiButtonComponent } from './mi-components/mi-button/mi-button.component';
import { MiSelectComponent } from './mi-components/mi-select/mi-select.component';
import { MiCheckboxComponent } from './mi-components/mi-checkbox/mi-checkbox.component';
import { NoWhitespaceDirective } from './directives/no-whitespace.directive';
import { ErrorBindingService } from './services/error-binding.service';
import { TwoDecimalDirective } from './directives/two-decimal.directive';
import { MessageComponent } from './components/message/message.component';
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';
import { FooterTermsContentComponent } from './components/footer-terms-content/footer-terms-content.component';
import { FooterPrivacyContentComponent } from './components/footer-privacy-content/footer-privacy-content.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { FooterCcpaContentComponent } from './components/footer-ccpa-content/footer-ccpa-content.component';
import { SharedNotificationsComponent } from './components/shared-notifications/shared-notifications.component';
import { SharedNotificationSendComponent } from './components/shared-notification-send/shared-notification-send.component';
import { SharedSegmentEditableComponent } from './components/shared-segment-editable/shared-segment-editable.component';
import { SharedSegmentsComponent } from './components/shared-segments/shared-segments.component';
import { EmailTemplatesComponent } from './components/settings/email-templates/email-templates.component';
import { TemplateComponent } from './components/settings/template/template.component';
import { PreventSpecialCharactersDirective } from './directives/prevent-special-characters.directive';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { DatePipe } from '@angular/common';
import { DashboardContentComponent } from './components/dashboard/dashboard-content/dashboard-content.component';
import { FourDecimalDirective } from './directives/four-decimal.directive';
import { StaffLayoutComponent } from './components/staff-layout/staff-layout.component';
import { WeekComponent } from './components/week/week.component';
import { AssignStaffListComponent } from './components/assign-staff-list/assign-staff-list.component';
import { ExportReportComponent } from './components/export-report/export-report.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { GenerateReportsComponent } from './components/generate-reports/generate-reports.component';
import { OrderRefundComponent } from '../franchise/pages/order-refund/order-refund.component';
import { DashboardFiltersComponent } from './components/dashboard/dashboard-filters/dashboard-filters.component';
import { BarChartSalesGivbackComponent } from './components/bar-chart-sales-givback/bar-chart-sales-givback.component';
import { BarChartSalesEventsWeekDayComponent } from './components/bar-chart-sales-events-week-day/bar-chart-sales-events-week-day.component';

import { ClientDashboardComponent } from './components/client-dashboard/client-dashboard.component';

import { BarChartPrepayEventsComponent } from './components/bar-chart-prepay-events/bar-chart-prepay-events.component';
import { ChartSalesByLocationComponent } from './components/chart-sales-by-location/chart-sales-by-location.component';
import { ChartSalesByIndustryComponent } from './components/chart-sales-by-industry/chart-sales-by-industry.component';

import { ChartStaffOverviewComponent } from './components/chart-staff-overview/chart-staff-overview.component';
import { ChartTopClientsComponent } from './components/chart-top-clients/chart-top-clients.component';
import { AdminSettingListComponent } from './components/admin-setting-list/admin-setting-list.component';
import { AdminSettingEditableComponent } from './components/admin-setting-editable/admin-setting-editable.component';
import { ChatEquipmentSummaryComponent } from './components/chat-equipment-summary/chat-equipment-summary.component';
import { PanelMenuModule } from 'primeng/panelmenu';
import { RatingModule } from 'primeng/rating';
import { ButtonModule } from 'primeng/button';
import { EditorModule } from 'primeng/editor';
import { CalendarModule } from 'primeng/calendar';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { PaginatorModule } from 'primeng/paginator';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmationService } from 'primeng/api';
import {SidebarModule} from 'primeng/sidebar';
import {PickListModule} from 'primeng/picklist';
import { TreeTableModule } from 'primeng/treetable';

import { EditableFoodExtraComponent } from './components/editable-food-extra/editable-food-extra.component';
import { MenuFoodExtraCategoryListComponent } from './components/menu-food-extra-category-list/menu-food-extra-category-list.component';
import { MenuEditableFoodExtraCategoryListComponent } from './components/menu-editable-food-extra-category-list/menu-editable-food-extra-category-list.component';
import { MenuItemsListComponent } from './components/menu-items-list/menu-items-list.component';
import { EditableMenuItemComponent } from './components/editable-menu-item/editable-menu-item.component';
import { MenuItemDetailsComponent } from './components/menu-item-details/menu-item-details.component';
import { MenuEditableMenuCategoryComponent } from './components/menu-editable-menu-category/menu-editable-menu-category.component';
import { MenuEditableTemplateComponent } from './components/menu-editable-template/menu-editable-template.component';
import { ManageTemplateListComponent } from './components/manage-template-list/manage-template-list.component';
import { CampaignComponent } from './components/campaign/campaign.component';
import { LegacyDataComponent } from './components/campaign/legacy-data/legacy-data.component';
import { AddLegacyDataComponent } from './components/campaign/add-legacy-data/add-legacy-data.component';
import { InputMaskModule } from 'primeng/inputmask';
import { RoleAccessComponent } from './components/role-access/role-access.component';
import { AccessProviderDirective } from './directives/access-provider.directive';
import { AggrementTermsOfServiceComponent } from './components/aggrement-terms-of-service/aggrement-terms-of-service.component';
import { PreventSomeSpecialCharactersDirective } from './directives/prevent-some-special-characters.directive';
import { AssignBulkEventToAssetComponent } from './components/assign-bulk-event-to-asset/assign-bulk-event-to-asset.component';
import { ClinetDocumentListComponent } from './components/client-document-list/client-document-list.component';
import { CustomLoaderComponent } from './components/custom-loader/custom-loader.component';
import { NotificationStripComponent } from './components/notification-strip/notification-strip.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PanelMenuModule,
    RatingModule,
    ButtonModule,
    EditorModule,
    ConfirmDialogModule,
    CalendarModule,
    MenuModule,
    // ChartModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    DialogModule,
    FormsModule,
    FileUploadModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    BlockUIModule,
    OverlayPanelModule,
    PanelModule,
    TabMenuModule,
    TabViewModule,
    TableModule,
    ToastModule,
    PaginatorModule,
    MultiSelectModule,
    TooltipModule,
    InputSwitchModule,
    SidebarModule,
    PickListModule,
    TreeTableModule,
    InputMaskModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: MiTranslateLoaderService,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MiMissingTranslationHandlerService,
      },
    }),
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    DisableControlDirective,
    SearchLocationDirective,
    AssignSearchComponent,
    LoaderComponent,
    FileUploaderComponent,
    AgoPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    MinutsFormatPipe,
    NavtabComponent,
    MiGridComponent,
    LayoutComponent,
    MiInputComponent,
    MiDynamicFieldDirective,
    MiDynamicFormComponent,
    MiButtonComponent,
    MiSelectComponent,
    MiCheckboxComponent,
    NoWhitespaceDirective,
    TwoDecimalDirective,
    FourDecimalDirective,
    MessageComponent,
    AdminLayoutComponent,
    FooterTermsContentComponent,
    FooterPrivacyContentComponent,
    ForgotPasswordComponent,
    SetPasswordComponent,
    FooterCcpaContentComponent,
    SharedNotificationsComponent,
    SharedNotificationSendComponent,
    SharedSegmentEditableComponent,
    SharedSegmentsComponent,
    EmailTemplatesComponent,
    TemplateComponent,
    PreventSpecialCharactersDirective,
    BreadCrumbComponent,
    DashboardContentComponent,
    DashboardFiltersComponent,
    StaffLayoutComponent,
    WeekComponent,
    AssignStaffListComponent,
    ExportReportComponent,
    DocumentListComponent,
    ClinetDocumentListComponent,
    GenerateReportsComponent,
    OrderRefundComponent,
    BarChartSalesGivbackComponent,
    BarChartSalesEventsWeekDayComponent,

    ClientDashboardComponent,

    BarChartPrepayEventsComponent,
    ChartSalesByLocationComponent,
    ChartSalesByIndustryComponent,

    ChartStaffOverviewComponent,
    ChartTopClientsComponent,

    AdminSettingListComponent,
    AdminSettingEditableComponent,
    ChatEquipmentSummaryComponent,
    EditableFoodExtraComponent,
    MenuFoodExtraCategoryListComponent,
    MenuEditableFoodExtraCategoryListComponent,
    MenuItemsListComponent,
    EditableMenuItemComponent,
    MenuItemDetailsComponent,
    MenuEditableMenuCategoryComponent,
    MenuEditableTemplateComponent,
    ManageTemplateListComponent,
    CampaignComponent,
    LegacyDataComponent,
    AddLegacyDataComponent,
    RoleAccessComponent,
    AccessProviderDirective,
    AggrementTermsOfServiceComponent,
    PreventSomeSpecialCharactersDirective,
    AssignBulkEventToAssetComponent,
    CustomLoaderComponent,
    NotificationStripComponent
  ],
  exports: [
    DropdownModule,
    CalendarModule,
    HeaderComponent,
    SidebarComponent,
    PageHeaderComponent,
    TranslateModule,
    TabViewModule,
    TooltipModule,
    InputSwitchModule,
    SidebarModule,
    SearchLocationDirective,
    DisableControlDirective,
    AssignSearchComponent,
    LoaderComponent,
    FileUploaderComponent,
    AgoPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    MinutsFormatPipe,
    NavtabComponent,
    MiGridComponent,
    MiInputComponent,
    MiDynamicFieldDirective,
    MiDynamicFormComponent,
    NoWhitespaceDirective,
    TwoDecimalDirective,
    FourDecimalDirective,
    MessageComponent,
    ForgotPasswordComponent,
    SetPasswordComponent,
    SharedNotificationsComponent,
    SharedNotificationSendComponent,
    SharedSegmentEditableComponent,
    SharedSegmentsComponent,
    PreventSpecialCharactersDirective,
    BreadCrumbComponent,
    WeekComponent,
    AssignStaffListComponent,
    DocumentListComponent,
    ClinetDocumentListComponent,
    GenerateReportsComponent,
    OrderRefundComponent,
    ClientDashboardComponent,
    EditableFoodExtraComponent,
    AccessProviderDirective,
    AggrementTermsOfServiceComponent,
    AssignBulkEventToAssetComponent,
    PreventSomeSpecialCharactersDirective,
    CustomLoaderComponent
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        LoaderService,
        FranchiseService,
        RestApiService,
        UiService,
        UserCommonService,
        StorageService,
        CommonMessageTransferService,
        CommonBindingDataService,
        SearchLocationDirective,
        DisableControlDirective,
        AuthenticationValidationService,
        ConfirmationService,
        TranslateService,
        SidebarService,
        GridService,
        TranslateDirective,
        NoWhitespaceDirective,
        ErrorBindingService,
        TwoDecimalDirective,
        FourDecimalDirective,
        PreventSpecialCharactersDirective,
        AccessProviderDirective,
        PreventSomeSpecialCharactersDirective,
        DatePipe
      ],
    };
  }

  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }
}
