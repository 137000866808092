import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { UiService } from '../../services/ui.service';
import { StorageService } from '../../services/storage.service';
import { AdminService } from '../../../admin/services/admin.service';

@Component({
  selector: 'app-editable-menu-item',
  templateUrl: './editable-menu-item.component.html',
  styleUrls: ['./editable-menu-item.component.scss']
})
export class EditableMenuItemComponent implements OnInit {

  @Input() itemDetails: any;
  @Input() isAddItem;
  @Output() reloadMenuList: EventEmitter<any> = new EventEmitter();
  @Output() onCancelAddNew: EventEmitter<any> = new EventEmitter();
  @Output() onHideAddNew: EventEmitter<any> = new EventEmitter();

  pageForm: FormGroup;
  showAddClient = false;
  submitted = false;
  localImgUrl = AppSettings.DEFAULT_NO_IMAGE;

  showImageListPopUp = false;
  newMenuSelectedImageFileId;
  isImageSelected = true;
  menuImageList = this.franchiseService.menuItemsImageList;
  selectedImageIndex;
  selectedImageObject;

  itemName;
  itemDescription;
  itemPrice;

  ID;
  eventDetails;
  localMenuImageList: any[] = [];
  searchText = '';
  foodExtraAlreadyMapped  = [];
  brandNameOptions: any = [];
  loggedInUserId = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA)).franchiseId;
  defaultBrand: any;
  menuBrand: any;
  menuItemsImageList = [];
  selectedBrandObject: any;
  loginUserRole;

  constructor(private formBuilder: FormBuilder,
    private franchiseService: FranchiseService,
    private commonService: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
    private storageService: StorageService,
    private adminService: AdminService,) {
  }

  ngOnInit(): void {
    this.loginUserRole = this.commonService.getLoginRole();
    this.loggedInUserId = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA))?.franchiseId;
    this.initForm();
    this.showAddClient = true;
    this.menuImageList = this.franchiseService.menuItemsImageList;
  }

  getBrandList(id, isNew?) {
    if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE) {
      this.franchiseService.getBrandList(id, isNew).subscribe((result) => {
        this.brandNameOptions = result.map(element => ({
          label: element.brandName,
          value: element.brandId
        }));
        this.checkAndSetCommonVars();
      });
    } else {
      this.adminService.getAllBrandsList().subscribe((result) => {
        this.brandNameOptions = result.map(element => ({
          label: element.brandName,
          value: element.id
        }));
        this.checkAndSetCommonVars();
      });
    }
  }

  checkAndSetCommonVars() {
    const selectedMenuBrand = this.pageForm.controls.brandId.value ? this.pageForm.controls.brandId.value : '';
    const selectedMenuBrandObject = selectedMenuBrand ? this.brandNameOptions.filter(eb => eb.value == selectedMenuBrand)[0] : '';
    this.selectedBrandObject = selectedMenuBrandObject ? selectedMenuBrandObject : this.brandNameOptions[0];
    this.menuBrand = selectedMenuBrand ? selectedMenuBrand : this.selectedBrandObject.value;
    this.loadImagesOnSelectedbrand(this.menuBrand);
  }

  loadImagesOnSelectedbrand(menuBrand) {
    this.localMenuImageList = this.franchiseService.menuItemsImageList.filter((item) => {
      return item.brandId == this.selectedBrandObject.value;
    });
    this.menuImageList = this.localMenuImageList;
    this.menuItemsImageList = this.localMenuImageList;
    // this.setImagePageForm(menuBrand, this.searchText);
  }

  // setImagePageForm(brandId?, searchText?) {
  //   let selectedbrand = this.brandNameOptions.filter((item) => {
  //     return item.brandId == brandId
  //   });
  //   this.menuBrand = selectedbrand[0].value;
  // }

  initForm() {
    this.pageForm = this.formBuilder.group({
      itemName: [this.itemDetails ? this.itemDetails.name : '',
      [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      brandId: [this.itemDetails ? this.itemDetails.brandId : ''],
      brandName: [{value:this.itemDetails ? this.itemDetails.brandName : '',
      disabled:this.itemDetails ? true: false}],
      sellingPrice: [this.itemDetails && this.itemDetails.price ? this.itemDetails.price.toFixed(2) : '',
      [Validators.required, Validators.maxLength(9)]],
      description: [this.itemDetails && this.itemDetails.description ? this.itemDetails.description : '',
      [Validators.required, Validators.maxLength(160)]],
      image: [this.itemDetails && this.itemDetails.imageFileId ? this.itemDetails.imageFileId : '',
      [Validators.required]],
    });
    if (this.itemDetails && this.itemDetails.signUrl) {
      this.localImgUrl = this.itemDetails.signUrl;
      this.foodExtraAlreadyMapped  = this.itemDetails.signUrl;
    }
  }

  onCancelClick() {
    this.itemDetails = '';
    this.pageForm.reset();
    this.showAddClient = false;
    this.onCancelAddNew.emit();
  }

  checkDisable() {
    return false;
  }

  onEditImageClick() {
    // this.menuImageList = this.franchiseService.menuItemsImageList;
    // this.localMenuImageList = this.franchiseService.menuItemsImageList;
    this.showImageListPopUp = true;
    const isNew = this.itemDetails?false:true;
    this.getBrandList(this.loggedInUserId, isNew);
    this.searchText = '';
    this.selectedImageIndex = this.pageForm.controls.image.value ? this.pageForm.controls.image.value : '';
  }

  onSearchImages() {
    let filteredArray = [];
    if (this.searchText) {
      const trimSearch = this.searchText.trim().toLowerCase();
      // filteredArray = this.menuImageList.filter(eachImage => (eachImage.originalFileName.trim().toLowerCase().indexOf(trimSearch) !== -1)
      // );
      filteredArray =  this.menuImageList.filter(eachImage =>
        eachImage.originalFileName.trim().toLowerCase().includes(trimSearch)
      );
    } else {
      filteredArray = this.menuItemsImageList;
    }
    this.localMenuImageList = filteredArray;
  }

  onCancelPopUp() {
    this.showImageListPopUp = false;
  }

  onSelectImage() {
    // console.log('selectedImageIndex', this.selectedImageIndex)
    this.selectedImageObject = this.franchiseService.menuItemsImageList.find(eachImage => eachImage.id === this.selectedImageIndex);
    if (this.selectedImageObject) {
      this.localImgUrl = this.selectedImageObject.signUrl;
      this.pageForm.controls.image.setValue(this.selectedImageObject.id);
      this.pageForm.controls.itemName.setValue(this.selectedImageObject.originalFileName);
      this.pageForm.controls.brandName.setValue(this.selectedBrandObject.label);
      this.pageForm.controls.brandName.disable();
      if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE) {
        this.pageForm.controls.brandId.setValue(this.selectedBrandObject.value);
      } else {
        this.pageForm.controls.brandId.setValue(this.selectedBrandObject.value);
      }
      this.showImageListPopUp = false;
    } else {
      this.uiService.showMessage(this.commonService.getLabel('msg_select_image'), AppSettings.MESSAGE_TYPE.ERROR);
    }
  }

  onSave() {
    if (this.pageForm.valid) {

      const data = this.pageForm.getRawValue();
      if (this.pageForm.controls.image.value) {
        this.isImageSelected = true;
        const newItem = {
          imageFileId: this.pageForm.controls.image.value,
          name: data.itemName,
          description: data.description,
          price: data.sellingPrice,
          brandId: data.brandId,
        };
        if (this.itemDetails) {
          newItem['id'] = this.itemDetails.id;
          newItem['foodCategoryItemMappingList'] = this.itemDetails.foodCategoryItemMappingList;
        }
        // console.log('data',data,newItem);
        // return;

        if (this.itemDetails) { // update
          this.confirmationService.confirm({
            message: this.commonService.getLabel('msg_edit_menu'),
            accept: () => {
              this.franchiseService.updateMenu(newItem).subscribe((result) => {
                this.reloadMenuList.emit();
                this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
                this.onCancelClick();
              }, error => {
              }
              );
            }
          });
        } else { // add
          this.confirmationService.confirm({
            message: this.commonService.getLabel('msg_add_menu'),
            accept: () => {
              this.franchiseService.addMenu(newItem).subscribe((result) => {
                this.reloadMenuList.emit();
                this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
                this.onCancelClick();
              }, error => {
              }
              );
            }
          });
        }
      } else {
        this.isImageSelected = false;
        this.uiService.showMessage(this.commonService.getLabel('msg_select_image'), AppSettings.MESSAGE_TYPE.ERROR);
      }
    }
  }

  onPriceChange() {
    if (this.pageForm.controls.sellingPrice.value <= 0) {
      this.pageForm.controls.sellingPrice.setValue('');
    }
  }

  cancel() {
    this.onHideAddNew.emit(true);
  }

  onChangeBrand(event) {

    this.selectedBrandObject = this.brandNameOptions.filter(eachBrand => eachBrand.value == event.value)[0];
    this.localMenuImageList = this.franchiseService.menuItemsImageList.filter((item) => {
      return item.brandId == event.value;
    });
    this.searchText = '';
    this.menuImageList = this.localMenuImageList;
    this.menuItemsImageList = this.localMenuImageList;
    console.log(this.selectedBrandObject);
  }
}



