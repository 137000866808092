<div class="layout-dashboard page-layout" >
<div class="padding-1per food-extra-mapping" *ngIf = "clientDetails !== null && clientDetails !== undefined ">
    <h3>{{clientDetails.businessName}}</h3>
    
</div>
    <div class = "grid padding-1per food-extra-mapping">
        <div class = "col-12 pad-sm-zero">
            
                <div class = "col-12 menu-offered pad-sm-zero" *ngIf=" clientDashboardData ">
                    
                    <div class="grid col-12 web-header-dashboard bg-grey">
                        <div class="col-12 lg:col-3 md:col-2 extra-small-foodextra-item align-center" translate>lable_client_dashboard_years</div>
                        <div class="col-12 lg:col-2 md:col-2 medium-foodextra-item align-right" translate>lable_client_dashboard_sales</div>
                        <div class="col-12 lg:col-3 md:col-2 medium-foodextra-item align-center" translate>lable_client_dashboard_events</div>
                        <div class="col-12 lg:col-2 md:col-2 small-foodextra-item align-right" translate>lable_client_dashboard_givebacks</div>
                    </div>

                    
                    <div  *ngFor="let clientDashboard of clientDashboardData; index as i" class="">
                            <div  class = "grid form-container single-item p-ai-center col-12"   [ngClass]="{'bg-grey text-bold text-bold': ( clientDashboardData.length - 1 )== i }">
                                <div class="col-12 lg:col-3 md:col-2 medium-foodextra-item text-field align-center">
                                    <div class="menu-title" translate>lable_client_dashboard_years</div>
                                    {{clientDashboard.years}}
                                </div>
                                <div class="col-12 lg:col-2 md:col-2 medium-foodextra-item text-field align-right">
                                    <div class="menu-title" translate>lable_client_dashboard_sales</div>
                                    
                                    {{ "$" }} {{ clientDashboard.eventSalesCollected | number : '1.2-2': 'en-US' }}
                                </div>
                                <div class="col-12 lg:col-3 md:col-2 medium-foodextra-item text-field align-center">
                                    <div class="menu-title" translate>lable_client_dashboard_events</div>
                                    {{clientDashboard.eventCount}}
                                </div>
                                <div class="col-12 lg:col-2 md:col-2 medium-foodextra-item text-field align-right">
                                    <div class="menu-title" translate>lable_client_dashboard_givebacks</div>
                                    {{ "$" }} {{ clientDashboard.giveback | number : '1.2-2': 'en-US' }}
                                </div>
                            </div>
                    
                </div>
                    
                </div>

                <div  class="no-records ng-star-inserted" *ngIf="!clientDashboardData">
                    <div class="center-div"> No Records Found </div>
                </div>
            
            
        </div>
    </div>
    <div class="padding-1per food-extra-mapping" *ngIf = "clientDashboardData && clientDetails !== null && clientDetails !== undefined ">
        <!-- <a href="{{hrefUrl}}">Click here to view Event Sales</a> -->
        <a appAccessProvider accessCode="MANAGE_REPORT" [readonly] = "true" (click) = "goToSales()" class = "document">Click here to view Event Sales</a>
    </div>
</div>
