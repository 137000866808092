import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';
import { LoaderService } from '../loader/loader.service';
import { MiGridComponent } from '../mi-grid/mi-grid.component';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {

  params = '';
  gridDefaultUrl = '/secure/event-files/grid-data';
  gridurl = '';

  updatedUrl = '';
  columns: any[] = [];
  buttons: any[] = [];
  searchBar = 'top';
  uploadedFiles;
  btnUploadLabel = this.commonBindingDataService.getLabel('btn_upload_attachment');
  noRecordsMsgFromParent = this.commonBindingDataService.getLabel('label_no_attachments_found');
  maxFileSizeMessage;
  isExportVisible = false;

  @ViewChild(MiGridComponent) gridRef: MiGridComponent;
  search;
  timer = null;

  // Add Client pop-up
  showAddClient = false;
  submitted = false;
  addClientForm: UntypedFormGroup;

  eventId;
  fileName = '';
  UploadFileUrl = AppSettings.BASE_URL + `/secure/event-files/`;


  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private commonBindingDataService: CommonBindingDataService,
    private uiService: UiService,
    private franchiseService: FranchiseService,
    private location: LocationStrategy,
    private route: ActivatedRoute,
    private http: HttpClient,
    private loaderService: LoaderService,
  ) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    this.maxFileSizeMessage = this.commonBindingDataService.getLabel('max_file_attachment_upload_size');
    this.eventId = this.route.snapshot.queryParams.id;
    this.params = `?eventId=${this.eventId}&deleted=false`;
    this.gridurl = `${this.gridDefaultUrl}${this.params}`;
    this.UploadFileUrl = this.UploadFileUrl + this.eventId;

    this.commonBindingDataService.checkHasAccessOrNot('Franchise');
    this.createGrid();
  }

  createGrid() {
    this.columns.push({
      field: 'originalFileName', width: '80%',
      header: this.commonBindingDataService.getLabel('label_file_name'), sort: true, resize: true, class: 'eclipse',
      // htmlRenderer: (data) => {
      //   const value = `${data.originalFileName}`;
      //   const t = this.commonBindingDataService.telFormat(value);
      //   const finalValue = `<div title = "${value}">
      //   <div>${value}</div>
      //   </div>`;
      //   return finalValue;
      // }
    });


    this.buttons.push({
      field: 'eventHold',
      label: '',
      class: 'event-delete-icon', show: (data) => {
        if (!data.deleted) {
          return '<div class="event-delete-icon deactivate" title = "Delete"></div>';
        }
      }
    });

    this.buttons.push({
      field: 'deleted',
      label: '',
      class: 'pi pi-download', show: (data) => {
        if (!data.deleted) {
          return '<div class="pi pi-download event-file-download" title = "Download"></div>';
        }
      }
    });
  }

  onActionBtnClicked(e: any) {
    const actionType = e.class;
    const data = e.row;
    if (actionType !== undefined) {
      switch (actionType) {
        case 'event-delete-icon':
          this.changeEventStatus(data);
          break;
        case 'pi pi-download':
          this.download(data);
          break;
      }
    }
  }

  changeEventStatus(data) {
    const objectToSend = {};
    const msg = this.commonBindingDataService.getLabel('msg_attachment_delete');

    const fileId = data.id;
    const objeToSend = {
      fileId: data.fileId
    }

    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.franchiseService.deleteEventAttachment(fileId, objeToSend).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          // this.gridRef.updateUrl(this.gridurl);
          this.applyFilter('apply');
        }, error => {
        }
        );
      }
    });
  }

  download(data) {
    // console.log(data)
    const link = document.createElement('a');
    link.download = 'Client_import_template';
    link.target = '_blank';
    link.href = data.signUrl;
    link.click();
    link.remove();
  }

  onClickFilter() {
    this.applyFilter('apply');
  }

  applyFilter(event?) {
    if (event === 'apply') {
      this.gridRef.updateUrl(this.gridurl);
    }
  }


  ////// upload related ////////////

  onFileSelected(event) {
    const url = AppSettings.BASE_URL + `/secure/event-files/${this.eventId}`;
    // let file = [];
    const file: File = event.target.files[0];
    this.fileName = '';
    const formData = new FormData();
    // formData.append("thumbnail", file);
    
    this.franchiseService.uploadFile(formData, this.eventId).subscribe((result) => {

    }, (error) => {
      console.log(error);
    });

    const head = { headers: this.getHttpFileHeaders(), withCredentials: true };
    const upload$ = this.http.put(url, formData, head);

    upload$.subscribe();
    // console.log(file);
  }

  private getHttpFileHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      'Content-Type': 'multipart/form-data',
      // 'Accept': AppSettings.HEADER_CONTENT_TYPE,
    });
  }

  // onBeforeFileUploadEvent(response) {
  // console.log(response);
  // this.loaderService.show('page-center');
  // }

  onUploadFile(response) {
    // console.log(response)
    if (response.originalEvent && response.originalEvent.status == 200) {
      this.uiService.showMessage(response.originalEvent.body.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
      this.applyFilter('apply');
    } else {
    }
  }

  onUploadFailed(response) {
    this.uiService.showMessage(response.error.error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
  }

}
