import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {

  @Input() breadCrumb: any = '';
  @Input() userId: string;
  id: any;
  tabIndex: any;
  clientId: any;
  kurbSide: boolean;
  eventId: any;
  parentId: any;
  isOpenShiftEvent: boolean;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private franchiseService: FranchiseService) {
    this.userId = this.route.snapshot.queryParams.userId;
    this.parentId = this.route.snapshot.queryParams?.parentId;
    this.id = this.route.snapshot.queryParams?.id;
    this.isOpenShiftEvent = JSON.parse(this.storageService.getItem('isOpenShiftEvent'));
    this.eventId = this.route.snapshot.queryParams?.eventId;

    this.tabIndex = this.route.snapshot.queryParams.tabIndex;
    this.clientId = this.route.snapshot.queryParams?.clientId;
    this.kurbSide = this.route.snapshot.queryParams?.kurbSide;

  }

  ngOnInit() {
  }

  onClick(data) {
    let fromCancel = '';
    let displayType = '';
    if (data.displayType) {
      displayType = data.displayType;
    }

    if (data.queryParams && !data.useSameQueryParams) {
      fromCancel = 'fromCancel';
      //fromCancel = "fromCancel: 'fromCancel', displayType: " + displayType;
    }
    if (data.route && this.userId) {
      this.router.navigate([data.route], { queryParams: { id: this.userId } });
    } else if (data.route && this.parentId && this.tabIndex) {
      this.router.navigate([data.route], { queryParams: { id: this.parentId, tabIndex: 3 } });
    } else if (data.route && data.useSameQueryParams) {
      this.router.navigate([data.route], { queryParams: data.queryParams });

    } else if (data.route && this.clientId && this.tabIndex) {
      if(fromCancel === 'fromCancel') {
        this.router.navigate([data.route], { queryParams: { id: this.clientId, tabIndex: this.tabIndex, fromCancel: 'fromCancel', displayType: displayType  } });
      }else{
        this.router.navigate([data.route], { queryParams: { id: this.clientId, tabIndex: this.tabIndex } });
      }

    } else if (data.route && this.tabIndex) {
      this.router.navigate([data.route], { queryParams: { tabIndex: this.tabIndex } });

    } else if (data.route && this.eventId && this.kurbSide && displayType) {
      this.router.navigate([data.route], { queryParams: { id: this.eventId, kurbSide: this.kurbSide, eventType: displayType } });
    } else if (data.route && data.queryParams.editClient) {
      this.router.navigate([data.route], { queryParams: { id: data.queryParams.id } });
    } else if (data.route && this.eventId && this.kurbSide) {
      this.router.navigate([data.route], { queryParams: {id: this.eventId, kurbSide: this.kurbSide } });
    }else if (data.route && this.id && this.isOpenShiftEvent) {
      this.router.navigate([data.route], { queryParams: {id: this.id, isOpenShiftEvent: this.isOpenShiftEvent } });
    }else {
      if (fromCancel && fromCancel === 'fromCancel') {
        this.router.navigate([data.route], { queryParams: { fromCancel: 'fromCancel', displayType: displayType } });
      } else {
        if (data.fromDetails === 'fromDetails') {
          const fromOrderDetails = 'fromDetails';
          this.router.navigate([data.route], { queryParams: { fromDetails: fromOrderDetails } });
        } else {
          this.router.navigate([data.route]);
        }

      }
    }   
  }
}
