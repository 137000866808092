import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../../../services/ui.service';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { FranchiseService } from '../../../../franchise/services/franchise-service.service';
import { StorageService } from '../../../services/storage.service';
import { AppSettings } from '../../../app.settings';
import * as moment from 'moment';

@Component({
  selector: 'app-add-legacy-data',
  templateUrl: './add-legacy-data.component.html',
  styleUrls: ['./add-legacy-data.component.scss']
})
export class AddLegacyDataComponent implements OnInit {

  pageHeading = this.commonService.getLabel('label_add_legacy_contact');
  countryListOptions = AppSettings.COUNTRY_LIST;
  countryCodes = AppSettings.COUNTRY_CODE;
  breadCrumbArray = [];
  contactId: any;
  contactDetails: any;

  // FormGroup
  contactForm: UntypedFormGroup;
  submitted = false;
  loggedInUserId: any;
  brandNameOptions: any = [];
  selectedBrand: any=[];
  perviousBrand: any[];
  modified: boolean;
  newChangedBrands: any[];
  today = new Date();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonBindingDataService,
    private uiService: UiService,
    private franchiseService: FranchiseService,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.contactId = this.route.snapshot.queryParams.id;
    this.loggedInUserId = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA))?.franchiseId;
    this.getBrandList(this.loggedInUserId);
    if (this.contactId) {
      this.pageHeading = this.commonService.getLabel('label_edit_legacy_contact');
      this.getLegacyContactDetails();
    }
    this.initForm();
    this.createBreadCrumb();
  }

  getBrandList(id?) {
    this.franchiseService.getBrandListForFilter(id).subscribe((result) => {
      this.brandNameOptions = result.map(element => ({
        label: element.brandName, value: element.brandId, brandId: element.brandId,
        activated: element.activated,
        deleted: element.deleted, inactive: false }));
    });
  }

  getLegacyContactDetails() {
    this.franchiseService.getLegacyContactDetails(this.contactId).subscribe((result) => {
      this.selectedBrand = result.emailOptInBrands?result.emailOptInBrands.map((item) => item.brandId):[];
      this.contactDetails = result;
      this.initForm();
      
    });
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      firstName: [this.contactDetails ? this.contactDetails.firstName : '',
      [Validators.minLength(1), Validators.maxLength(50)]],
      lastName: [this.contactDetails ? this.contactDetails.lastName : '',
      [ Validators.minLength(1), Validators.maxLength(50)]],
      email: [this.contactDetails ? this.contactDetails.email : '',
      [Validators.required, Validators.minLength(1), Validators.maxLength(120), Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      companyName: [this.contactDetails ? this.contactDetails.companyName : '',
        [Validators.minLength(1), Validators.maxLength(50)]],

      title: [this.contactDetails ? this.contactDetails.title : '',
        [ Validators.minLength(1), Validators.maxLength(50)]],

      phoneNum: [this.contactDetails ? this.contactDetails.phone : ''],

      mobilePhone: [this.contactDetails ? this.contactDetails.mobilePhone : ''],

      address1: [this.contactDetails ? this.contactDetails.address?.address1 : '',
      [Validators.minLength(1), Validators.maxLength(100)]],
      address2: [this.contactDetails ? this.contactDetails.address?.address2 : '',
      [Validators.minLength(1), Validators.maxLength(100)]],

      city: [this.contactDetails ? this.contactDetails.address?.city : '',
      [Validators.minLength(1), Validators.maxLength(100)]],
      state: [this.contactDetails ? this.contactDetails.address?.stateCode : '',
      [Validators.minLength(2)]],
      country: [{
        value: this.contactDetails && this.contactDetails.country ? this.contactDetails.country :
          AppSettings.DEFAULT_COUNTRY_STRING, disabled: true
      },
      [Validators.minLength(1), Validators.maxLength(20)]],

      zipCode: [this.contactDetails ? this.contactDetails.address?.zipCode : '',
      [Validators.minLength(5), Validators.maxLength(10), Validators.pattern(AppSettings.NUMBER_PATTERN)]],
      emailOptInBrands: [''],

      // optOutEmail: [this.contactDetails ? this.contactDetails.optOutEmail : false],
      // optOutPhone: [this.contactDetails ? this.contactDetails.optOutPhone : false],
      // optOutMobilePhone: [this.contactDetails ? this.contactDetails.optOutMobilePhone : false],
      // optOutAddress: [this.contactDetails ? this.contactDetails.optOutAddress : false],

      // seedRecord: [this.contactDetails ? this.contactDetails.seedRecord : false],
      // userOptOutEmail: [this.contactDetails ? this.contactDetails.userOptOutEmail : false],
      // userOptOutAddress: [this.contactDetails ? this.contactDetails.userOptOutAddress : false],
      // emailDeliverable: [this.contactDetails ? this.contactDetails.emailDeliverable : false],

      // usableEmail: [this.contactDetails ? this.contactDetails.usableEmail : false],
      // usableAddress: [this.contactDetails ? this.contactDetails.usableAddress : false],
      // validEmail: [this.contactDetails ? this.contactDetails.validEmail : false],
    });

  }

  checkFormIsValid() {
    if (!this.contactForm.valid) {
      this.uiService.showMessage(this.commonService.getLabel('error_fill_required_field'), AppSettings.MESSAGE_TYPE.ERROR);
    }
    return this.contactForm.valid;
  }

  onSave() {
    this.submitted = true;
    if (this.checkFormIsValid()) {

      const contactModel = this.getFormData();

      if (this.contactId) {
        this.franchiseService.updateLegacyCustomer(contactModel).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.router.navigate(['franchise/marketing/legacy-data'], { queryParams: { fromCancel: 'fromCancel' } });
        }, (error) => {
        });
      } else {
        this.franchiseService.addLegacyCustomer(contactModel).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.router.navigate(['franchise/marketing/legacy-data']);
        }, (error) => {
        });
      }

    }
  }

  getFormData() {
    const formControl = this.contactForm.controls;
    const address = {
      address1: formControl.address1.value ? formControl.address1.value.trim() : '',
      address2: formControl.address2.value ? formControl.address2.value.trim() : '',
      city: formControl.city.value ? formControl.city.value.trim() : '',
      stateCode: formControl.state.value ? formControl.state.value.trim() : '',
      zipCode: formControl.zipCode.value ? formControl.zipCode.value.trim() : '',
    }

    const contactForm: any = {
      firstName: formControl.firstName.value ? formControl.firstName.value.trim() : '',
      lastName: formControl.lastName.value ? formControl.lastName.value.trim() : '',
      title: formControl.title.value ? formControl.title.value.trim() : '',
      companyName: formControl.companyName.value ? formControl.companyName.value.trim() : '',
      email: formControl.email.value ? formControl.email.value.trim() : '',
      phone: formControl.phoneNum.value ? formControl.phoneNum.value.trim() : '',
      mobilePhone: formControl.mobilePhone.value ? formControl.mobilePhone.value.trim() : '',

      // optOutEmail: formControl.optOutEmail.value ? formControl.optOutEmail.value : false,
      // optOutPhone: formControl.optOutPhone.value ? formControl.optOutPhone.value : false,
      // optOutMobilePhone: formControl.optOutMobilePhone.value ? formControl.optOutMobilePhone.value : false,
      // optOutAddress: formControl.optOutAddress.value ? formControl.optOutAddress.value : false,
      // seedRecord: formControl.seedRecord.value ? formControl.seedRecord.value : false,
      // userOptOutEmail: formControl.userOptOutEmail.value ? formControl.userOptOutEmail.value : false,
      // userOptOutAddress: formControl.userOptOutAddress.value ? formControl.userOptOutAddress.value : false,
      // emailDeliverable: formControl.emailDeliverable.value ? formControl.emailDeliverable.value : false,
      // usableEmail: formControl.usableEmail.value ? formControl.usableEmail.value : false,
      // usableAddress: formControl.usableAddress.value ? formControl.usableAddress.value : false,
      // validEmail: formControl.validEmail.value ? formControl.validEmail.value : false,
      address: address
    };

    if (this.contactId) {
      contactForm.id = this.contactId;
      contactForm.franchiseId = this.loggedInUserId;
    }

    let emailOptInBrandsArray = [];
    if(this.selectedBrand.length){
      this.selectedBrand.forEach((item)=>{
        emailOptInBrandsArray.push({'brandId': item})
      });
    }
    contactForm['emailOptInBrands'] = emailOptInBrandsArray;

    return contactForm;
  }

  onCancel() {
    this.router.navigate(['franchise/marketing/legacy-data'], { queryParams: { fromCancel: 'fromCancel' } });
  }

  createBreadCrumb() {
    this.breadCrumbArray = [
      {
        label: this.commonService.getLabel('label_legacy_data'),
        route: 'franchise/marketing/legacy-data',
        queryParams: 'fromCancel'
      },
      {
        label: this.pageHeading,
        route: '',
      }
    ];
  }

}

