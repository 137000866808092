<div class="page-container">
  <div class="col-12 col-nopad">
    <app-bread-crumb [breadCrumb]="breadCrumbArray"></app-bread-crumb>
  </div>
  <div class="page-header">
    <div class="page-heading">
      <h1 class="heading p-ml-1">{{pageHeading}}</h1>
    </div>
    <div class="page-actions extra-header-labels">
      <button pButton appAccessProvider accessCode="MANAGE_MENU" [readonly] = "true" [readWrite] = "true" class="small-btn"
       type="button" [label]="'btn_save'|translate" (click)="onSave()"></button>
      <button pButton class="large-btn" type="button" [label]="'btn_save_client_temp'|translate" (click)="onSaveClientTemp()"
      *ngIf="showUpdateClientBtn && accessClientReadWrite && accessMenuReadWrite" style="width: 200px;"></button>
      <button pButton class="small-btn cancel-btn" type="button" [label]="'btn_cancel'|translate"
        (click)="onCancelAndGoBack()"></button>
    </div>
  </div>

  <div class="page-body">
    <form [formGroup]="eventForm">
      <div class="form-container col-12 lg:col-8">
        <div class="form-item col-6 lg:col-56 md:col-5">
          <div class="form-field">
            <div class="form-label">
              <label for="name" translate>label_menu_template_name<span class="required-star">*</span></label>
            </div>
            <div class="form-control">
              <input id="name" formControlName="name" class="full-width" type="text" appNoWhiteSpace pInputText appPreventSomeSpecialCharacters allowQuote="no"/>
            </div>
            <div class="form-errors"
              *ngIf="eventForm.controls.name.errors  && (eventForm.controls.name.dirty || eventForm.controls.name.touched || submitted)">
              <div [hidden]="!eventForm.controls.name.errors.required" translate>
                field_is_required
              </div>

            </div>
          </div>
        </div>
        <div class=" col-6 lg:col-5 md:col-5">
          <div class="form-field">
              <div class="form-label">
                  <label for="categoryName" translate>label_brandName</label>
              </div>
              <div class="form-control">
                  <p-dropdown formControlName="brandId" [options]="brandNameOptions"
                      (onChange)="onChangeBrand($event)" ></p-dropdown> 
                      <!-- optionDisabled="inactive" -->
              </div>
          </div>
        </div>
        <div class="form-item col-12 lg:col-7 md:col-6">
          <div class="form-control">
            <div class="col-12 pad-l-0">
              <p-checkbox name="groupname" [formControl]="eventForm.controls['pos']" binary="true"
                class="checkbox-margin"></p-checkbox>
              <label class="custom-control-label" for="optional" class="label-one" translate>Online Menu</label>
              <p-checkbox name="groupname" [formControl]="eventForm.controls['defaultTemplate']" binary="true"
                class="checkbox-margin"></p-checkbox>
              <label class="custom-control-label" for="optional" class="label-two" translate>Default Menu</label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="page-body" *ngIf = "files && files.length > 0">
    <p-treeTable [value]="files" [columns]="cols" selectionMode="checkbox" [(selection)]="selectedNodes"
      (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)" [columnResizeMode]="'expand'">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
          <td *ngFor="let col of columns; let i = index" ttEditableColumn [ngClass]="{'p-toggler-column': i === 0}"
            [colspan]="i === 0?2:1">
            <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
            <p-treeTableCheckbox [value]="rowNode" *ngIf="i == 0"></p-treeTableCheckbox>
            <span *ngIf="i == 0"><img *ngIf="rowData['signUrl']" [src]="rowData['signUrl']"
                style="width: 3em;margin: 0 8px;" /></span>
            <span *ngIf="i == 0">{{rowData[col.field]}}</span>

            <!-- <span *ngIf="i == 0 && (!rowData['activated'])" style="
                        margin-left: 1em;
                        font-weight: 600;
                        color: #d85c5c;">(Inactive)</span> -->

            <div *ngIf="i > 0 && i < 3">
              <div *ngIf="!rowData['isCategory']  && !([col.field] == 'sequence')">
                <div class="form-control selling-price-wrapper">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">$</span>
                    <input pInputText type="text" class="price-input" (keyup)="valueChanged(rowData)"
                      [(ngModel)]="rowData[col.field]" appFourDecimal appNoWhiteSpace>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="i > 0 && !rowData['isCategory'] && [col.field] == 'sequence'">
              <div class="form-control selling-price-wrapper">
                <div class="p-inputgroup">
                  <input pInputText type="text" (keyup)="valueChanged(rowData)" [(ngModel)]="rowData[col.field]"
                    appFourDecimal appNoWhiteSpace>
                </div>
              </div>
              <div class="sequence-unique" *ngIf="rowData['sequence'] === ''">
                Sequence is required
              </div>
              <div class="sequence-unique" *ngIf="!rowData['isUniqueSequence'] && rowData['sequence'] !== ''">
                Sequence should be unique
              </div>
            </div>
            <!-- <p-treeTableCellEditor *ngIf="i > 0 && i < 3">
                            <ng-template pTemplate="input" *ngIf="!rowData['isCategory']">
                                <input pInputText type="text" (keyup) = "valueChanged()" [(ngModel)]="rowData[col.field]" >
                            </ng-template>
                            <ng-template pTemplate="output">{{rowData[col.field]}}</ng-template>
                        </p-treeTableCellEditor> -->
            <div *ngIf="i == 3 && rowData[col.field]">
              <button pButton class="small-btn" type="button" [label]="'btn_manage'|translate"
                (click)="onClickManage(rowData)"></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-treeTable>
  </div>

  <div class="no-records select-filter" *ngIf = "files && files.length == 0" translate>
        error_no_menu_message
  </div>

</div>