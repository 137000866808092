<!-- <input type="file" #file id="file" (change)="onFileChange($event)" /> -->

<div style="margin-bottom: 0.8em; color: #568EE8;">
  <span>Upload vertical image for better result on customer portal,
    max file size 5mb</span>
</div>
<div class="outer">
  <div class="croper-div">
    <div *ngIf="imageExist" style="width: 100%;">
      <img [src]="uploadedImage" alt="uploadedImage" style="width: 100%;">
    </div>
    <div *ngIf="!imageExist && isUploadDisabled">
      <div class="empty-div">
        <p>(Eg. resolution 800px X 1100px)</p>
      </div>
    </div>
  </div>
</div>


<div class="outer">
  <div class="croper-div">
    <div *ngIf="!imageExist">
      <image-cropper [imageChangedEvent]="imageChangedEvent" output="base64" format="jpeg"
        (imageCropped)="imageCropped($event)" [(transform)]="transform" 
        [aspectRatio]="3 / 4"
        [resizeToWidth]="resizeToWidth" [resizeToHeight]="resizeToHeight" onlyScaleDown="true">
        <!--
        [cropperMinWidth]="cropperMinWidth" [cropperMinHeight]="cropperMinHeight"
        -->
      </image-cropper>
    </div>
  </div>
</div>

<div>
  @if(!isUploadDisabled && !imageExist){
  <div class="buttons-block zoom-btn uploader">
    <button pButton class="btn-zoom-in-out btn-marg-r" icon="pi pi-minus" (click)="zoomOut()"></button>
    <button pButton class="btn-zoom-in-out" icon="pi pi-plus" (click)="zoomIn()"></button>
  </div>
  }
  <div *ngIf="imageExist" class="buttons-block zoom-btn uploader">
    <button pButton class="btn-zoom-in-out" [label]="'Delete' | translate" (click)="deleteImage()"></button>
  </div>

  <div id="uniquebtndiv11" class="buttons-block" *ngIf="!imageExist">
    <input type="file" #file id="file" (change)="fileChangeEvent($event)" [disabled]="disableFile"
      accept="image/png, image/jpeg" style="display: none;" />
    <button pButton [label]="'Choose a File' | translate" type="button" class="btn-upload btn-marg-r"
      (click)="chooseBtnClick()"></button>
    <button pButton id="uniquebtn" [label]="'Upload file' | translate" type="button" class="btn-upload"
      (click)="onUploadClick()" [disabled]="isUploadDisabled"></button>
  </div>
</div>